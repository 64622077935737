import React, { useState, useEffect } from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import petition_get from "../../../../petitions/petition_get";
import petition_patch from "../../../../petitions/petition_patch";
import {
    Button,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Input,
    Badge,
    Spinner,
} from "reactstrap";

import Switch from "react-switch";
import toastr from "toastr";

export default function ModalUtilityDelete({
    modal,
    setModal,
    setReloadDataEdit
}) {
    const params = new URL(document.location).searchParams;
    const id = params.get("id");
    const [loading, setLoading] = useState(false)

    const [form, setForm] = useState({})

    toastr.options = {
        showDuration: 300,
    };

    useEffect(() => {
        if (modal) setForm(modal.metadata)
    }, [modal])


    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                No
            </div>
        );
    };
    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                Yes
            </div>
        );
    };
    const submitData = () => {


        if (form.porting_billing_name && form.porting_billing_name.length > 25) return toastr.error("Field porting_billing_name has a maximum of 25 characters", "Error");
        if (form.porting_zip_code && form.porting_zip_code.length > 5) return toastr.error("Field porting_zip_code  has a maximum of 5 characters", "Error");

        setLoading(true)
        petition_patch("orderItemUpdate", { id, order_item_id: modal.pk, data: { metadata: form }, })
            .then((result) => { setReloadDataEdit(true); setLoading(false); setModal(false); toastr.success("Action completed", "Success"); })
            .catch((error) => { setLoading(false); toastr.error("Action failed", "Error"); });



    }

    return (
        <Modal centered isOpen={modal === false ? false : true} className="info modal-lg" >
            <ModalHeader className="bg-soft-primary">

                <Row>
                    <Col xs="7">
                        <div className="text-primary p-3">
                            <h3 className="text-primary">Update</h3>
                        </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                        <img src={phoneImg} alt="" className="img-fluid" />
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <Row>
                    {modal && Object.keys(modal.metadata).map(element => (<Col className="col-12 col-lg-4 mt-3">
                        <label className="mb-1 col-12 p-0" htmlFor=""> {element}: </label>
                        {(modal.metadata[element] === true || modal.metadata[element] === false)
                            ? <Switch
                                uncheckedIcon={<Offsymbol />}
                                className="mr-1"
                                checkedIcon={<OnSymbol />}
                                onColor="#626ed4"
                                onChange={() => {
                                    setForm({ ...form, [element]: form[element] === true ? false : true });
                                }}
                                checked={form[element]}
                            />
                            : <input onChange={(e) => { setForm({ ...form, [element]: e.target.value }) }} value={form[element]} type="text" className="form-control" />}

                    </Col>))}

                </Row>

            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    disabled={loading}
                    color="secondary"
                    onClick={() => {
                        setModal(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    disabled={loading}
                    onClick={submitData}
                >
                    {loading && (
                        <Spinner
                            style={{ marginRight: "0.5em" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                    Save
                </Button>

            </ModalFooter>
        </Modal>
    );
}
