import React, {useEffect, useState} from "react";
import {
  Container,
  Table,
  CustomInput,
  Button,
  Row,
  Col,
  Card,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import petition_get from "../../../../petitions/petition_get";
import petition_delete from "../../../../petitions/petition_delete";
import petition_patch from "../../../../petitions/petition_patch";
import {useHistory} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";

import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddIcon from "@material-ui/icons/Add";
import styled from "styled-components";
import ButtonHover from "../../../../../components/utils/ButtonHover";
import EditIcon from "@material-ui/icons/Edit";
import ModalUtility from "../ModalUtility/ModalUtility";
import ModalUtilityDelete from "../ModalUtility/ModalUtilityDelete";
import ModalUtilityDisconnect from "../ModalUtility/ModalUtilityDisconnect";

import {v4 as uuidv4} from "uuid";
import toastr from "toastr";
import CustomTable from "../../../../../components/utils/CustomTable/CustomTable";

export default function TabAllNumbers({update, setUpdate}) {
  toastr.options = {
    showDuration: 300,
  };

  const history = useHistory();
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const cantPagination = 10;
  const [numbers, setNumbers] = useState(null);
  const [keyNumbers, setKeyNumbers] = useState(null);
  const [AllData, setAllData] = useState(null);
  const [pagination, setPagination] = useState(cantPagination);
  const [reloadDataEdit, setReloadDataEdit] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingDisconnect, setLoadingDisconnect] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalDisconnect, setModalDisconnect] = useState(false);
  const [elementNumber, setElementNumber] = useState(null);
  const [allUsers, setAllUsers] = useState(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectCheckbox, setSelectCheckbox] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [allColflows, setAllColflows] = useState(null);
  const [defaultNumber, setDefaultNumber] = useState({});
  const [viewCallSettingsUsersAll, setViewCallSettingsUsersAll] =
    useState(null);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [dataTable, setDataTable] = useState(null);

  useEffect(() => {
    if (reloadDataEdit) {
      setLoadingTwo(false);
      setLoadingData(false);
      setReloadDataEdit(false);
      setAllColflows(null);
      setViewCallSettingsUsersAll(null);

      const petition = async () => {
        let saveUsers,
          saveCallflows = null;

        petition_get("accountDetails", {id: id})
          .then(({data: result}) =>
            setDefaultNumber(
              result.result.default_outbound_callerid_number
                ? {
                    name: result.result.default_outbound_callerid_number,
                    value: result.result.default_outbound_callerid_number,
                  }
                : ""
            )
          )
          .catch((error) => console.log(error));

        await petition_get("itpvoiceUsers", {id: id})
          .then(({data: result}) => {
            setAllUsers(result.result);
            saveUsers = result.result;
          })
          .catch((error) => {
            console.log(error);
          });
        await petition_get("allCallflows", {id: id})
          .then(({data: result}) => {
            saveCallflows = result.result;
            setAllColflows(result.result);
          })
          .catch((error) => {
            console.log(error);
          });

        await petition_get("view", {id: id})
          .then(({data: result}) => {
            let saveNumbers = []; //Save key numbers
            let petitions = []; //Promise all
            let detailsNumbers = []; //Save object complete numbers

            result.result.map((element) =>
              petitions.push(
                petition_get("viewDetails", {id, phoneNumber: element.number})
                  .then((res) => {
                    let findUser = null;

                    if (res.data.result.owner_id)
                      findUser = saveUsers.find(
                        (elementTwo) =>
                          elementTwo.pk === res.data.result.owner_id
                      );

                    let assigned_user = findUser
                      ? `${findUser.first_name} ${findUser.last_name} ${findUser.presence_id}`
                      : `${
                          element.used_by === "callflow"
                            ? "Callflow"
                            : "Not Assigned"
                        }`;
                    let findCallflow = null;

                    if (saveCallflows) {
                      saveCallflows.map((elementTwo) => {
                        let findNumber = elementTwo.numbers.find(
                          (elementThree) => elementThree === element.number
                        );

                        if (
                          findNumber &&
                          elementTwo.type !== "MainUserCallflow"
                        )
                          findCallflow = elementTwo.pk;
                      });
                    }
                    detailsNumbers.push({
                      ...element,
                      callflowId: findCallflow,
                      assigned_user,
                      statusReal: res.data.result.itpvoice_metadata
                        ? res.data.result.itpvoice_metadata.state.replace(
                            "_",
                            " "
                          )
                        : "null",
                      owner_id: res.data.result.owner_id || null,
                    });
                  })
                  .catch((err) => console.log(err))
              )
            );

            Promise.all(petitions)
              .then((res) => {
                setLoadingData(true);
                setNumbers(detailsNumbers);
                setAllData(detailsNumbers);
                setLoadingTwo(true);

                setDataTable({
                  title: [
                    {title: "NUMBER", key: "number"},
                    {title: "STATUS", key: "status"},
                    {
                      title: "DESTINATION",
                      key: "assigned_user",
                    },
                    {
                      title: "DESCRIPTION",
                      key: "description",
                    },
                  ],
                  content: [
                    ...detailsNumbers.map((number) => ({
                      ...number,
                      description: number.description || "",
                    })),
                  ],
                  actions: {
                    title: "Actions",
                    content: [
                      {
                        icon: <DeleteIcon />, //Svg icon
                        handleClick: (e) => {
                          activeModalDelete(e);
                        }, //Function
                      },
                      {
                        icon: <EditIcon />, //Svg icon
                        handleClick: (e) => {
                          setModal(true);
                          activeModalEdit(e);
                        }, //Function
                      },
                    ],
                  },
                  search: {
                    label: "Search Numbers",
                  },
                  addButton: {
                    label: "Add Number",
                    handleClick: () => {
                      setUpdate("3");
                    },
                  },
                });
              })
              .catch((err) => console.log(err));
          })
          .catch((error) => console.log(error));
      };

      petition();

      petition_get("viewCallSettingsUsersAll", {id: id})
        .then(({data: result}) => {
          setViewCallSettingsUsersAll(result.result);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [reloadDataEdit]);

  function disconnectAll() {
    let data = [];
    if (selectCheckbox.length) {
      setLoadingDisconnect(true);
      selectCheckbox.forEach((element) => {
        data.push(
          petition_delete("deleteNumber", {
            id: id,
            phone: element.number,
          })
        );
      });
      Promise.all(data)
        .then((values) => {
          setTimeout(() => {
            setLoadingDisconnect(false);
            setModalDisconnect(false);
            toastr.success("Action completed", "Success");
            setReloadDataEdit(true);
            setSelectCheckbox([]);
          }, 20000);
        })
        .catch((reason) => {
          setReloadDataEdit(true);
          setLoadingDisconnect(false);
          setModalDisconnect(false);
          console.log(reason);
          setSelectCheckbox([]);
          toastr.error("Action failed", "Error");
        });
    }
  }

  function activeModalEdit(val) {
    /*  if (defaultNumber.value === val.number) return toastr.error("This is the Caller id number, please change caller id number to update", "Error");
         if (val.used_by === "callflow") return toastr.error("This number is used in one callflow, please change number in the callflow to update", "Error"); */
    setElementNumber(val);
  }

  function activeModalDelete(val) {
    if (val.number === defaultNumber.value)
      return toastr.error(
        "This is the Caller id number, can't be delete",
        "Error"
      );
    if (val.used_by === "callflow")
      return toastr.error(
        "This number is used in one callflow, can't be delete",
        "Error"
      );
    setModalDelete(val);
  }

  async function deleteNumber() {
    try {
      setLoadingDelete(true);

      if (modalDelete.callflowId) {
        await petition_get("callflowsDetails", {
          id,
          callflow: modalDelete.callflowId,
        })
          .then(async ({data: result}) => {
            let filterNumbers = result.result.numbers.filter(
              (element) => element !== modalDelete.number
            );

            if (filterNumbers.length === 0)
              filterNumbers.push(`nonum_${uuidv4().split("-")[0]}`);

            result.result.numbers = filterNumbers;

            await petition_patch("callflowEdit", {
              id,
              callflow_id: modalDelete.callflowId,
              data: {...result.result},
            })
              .then((result) => console.log(result))
              .catch((error) => {
                throw error;
              });

            petition_delete("deleteNumber", {id: id, phone: modalDelete.number})
              .then(({data: result}) => {
                const newNumbers = numbers.filter(
                  (element) => element.number !== modalDelete.number
                );
                setNumbers(newNumbers);
                setLoadingDelete(false);
                setModalDelete(false);

                setDataTable({
                  title: [
                    {title: "NUMBER", key: "number"},
                    {title: "STATUS", key: "status"},
                    {
                      title: "DESTINATION",
                      key: "assigned_user",
                    },
                    {
                      title: "DESCRIPTION",
                      key: "description",
                    },
                  ],
                  content: [
                    ...newNumbers.map((number) => ({
                      ...number,
                      description: number.description || "",
                    })),
                  ],
                  actions: {
                    title: "Actions",
                    content: [
                      {
                        icon: <DeleteIcon />, //Svg icon
                        handleClick: (e) => {
                          activeModalDelete(e);
                        }, //Function
                      },
                      {
                        icon: <EditIcon />, //Svg icon
                        handleClick: (e) => {
                          setModal(true);
                          activeModalEdit(e);
                        }, //Function
                      },
                    ],
                  },
                  search: {
                    label: "Search Numbers",
                  },
                  addButton: {
                    label: "Add Number",
                    handleClick: () => {
                      setUpdate("3");
                    },
                  },
                });

                toastr.success("Action completed", "Success");
              })
              .catch((error) => {
                throw error;
              });
          })
          .catch((error) => {
            throw error;
          });
      } else {
        petition_delete("deleteNumber", {id: id, phone: modalDelete.number})
          .then(({data: result}) => {
            const newNumbers = numbers.filter(
              (element) => element.pk !== modalDelete.pk
            );
            setNumbers(newNumbers);

            setDataTable({
              title: [
                {title: "NUMBER", key: "number"},
                {title: "STATUS", key: "status"},
                {
                  title: "DESTINATION",
                  key: "assigned_user",
                },
                {
                  title: "DESCRIPTION",
                  key: "description",
                },
              ],
              content: [
                ...newNumbers.map((number) => ({
                  ...number,
                  description: number.description || "",
                })),
              ],
              actions: {
                title: "Actions",
                content: [
                  {
                    icon: <DeleteIcon />, //Svg icon
                    handleClick: (e) => {
                      activeModalDelete(e);
                    }, //Function
                  },
                  {
                    icon: <EditIcon />, //Svg icon
                    handleClick: (e) => {
                      setModal(true);
                      activeModalEdit(e);
                    }, //Function
                  },
                ],
              },
              search: {
                label: "Search Numbers",
              },
              addButton: {
                label: "Add Number",
                handleClick: () => {
                  setUpdate("3");
                },
              },
            });

            setLoadingDelete(false);
            setModalDelete(false);
            toastr.success("Action completed", "Success");
          })
          .catch((error) => {
            throw error;
          });
      }
    } catch (error) {
      console.log(error);
      setLoadingDelete(false);
      setModalDelete(false);
      toastr.error("Action failed", "Error");
    }
  }

  useEffect(() => {
    if (AllData) {
      const keyNumbersCopy = [];
      const newData = AllData.filter((element) => {
        if (element.number.includes(inputSearch)) {
          keyNumbersCopy.push(element.number);
          return element;
        }
      });
      setKeyNumbers(keyNumbersCopy);
      setNumbers(newData);
      setPagination(10);
    }
  }, [inputSearch]);

  const onClickNext = () => {
    if (pagination < keyNumbers.length) {
      setPagination(pagination + cantPagination);
    }
  };

  const onClickBack = () => {
    if (pagination > cantPagination) {
      setPagination(pagination - cantPagination);
    }
  };

  const handleCheck = (value) => {
    //If it is a single number
    if (value.number) {
      let allNumbers = AllData.map((element, i) => {
        if (element.num === value.index) {
          element.checked = !element.checked;
        }
        return element;
      });
      setAllData(allNumbers);
      let allSelectedCheckBox;
      if (value.evento) {
        //Add Numbers
        allSelectedCheckBox = selectCheckbox;
        allSelectedCheckBox.push(value.number);
      } else {
        allSelectedCheckBox = selectCheckbox.filter(
          (element) => element.num !== value.number.num
        );
      }

      setSelectCheckbox(allSelectedCheckBox);
    } else {
      let allNumbers = AllData.map((element, i) => {
        element.checked = value.checked;
        return element;
      });
      setAllData(allNumbers);
      if (value.checked) {
        return setSelectCheckbox(allNumbers);
      }
      setSelectCheckbox([]);
    }
  };

  return (
    <>
      {modal && allUsers && elementNumber != null ? (
        <ModalUtility
          modal={modal}
          setModal={setModal}
          elementNumber={elementNumber}
          allUsers={allUsers}
          setReloadDataEdit={setReloadDataEdit}
          viewCallSettingsUsersAll={viewCallSettingsUsersAll}
          defaultNumber={defaultNumber}
        />
      ) : null}
      <ModalUtilityDelete
        modal={modalDelete}
        setModal={setModalDelete}
        deleteNumber={deleteNumber}
        loading={loadingDelete}
      />
      <ModalUtilityDisconnect
        modal={modalDisconnect}
        setModal={setModalDisconnect}
        disconnectNumbers={disconnectAll}
        loading={loadingDisconnect}
      />

      <CustomTable data={dataTable} />
    </>
  );
}
