import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  CardTitle,
  Button,
  Input,
  Label,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Spinner,
} from "reactstrap";

import petition_get from "../../../../petitions/petition_get";
import petition_post from "../../../../petitions/petition_post";
import petition_patch from "../../../../petitions/petition_patch";
import petition_put from "../../../../petitions/petition_put";
import ModalUtilityAdd from "./ModalUtility/ModalUtilityAdd";
import toastr from "toastr"

export default function UserDetails() {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [loading, setLoading] = useState(false);
  const [loadingPetition, setLoadingPetition] = useState(false);
  const [modalPassword, setModalPassword] = useState(false);
  const [data, setData] = useState(null)
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    // password: "",
  });

  useEffect(() => {
    petition_get("userDetails", { id: id })
      .then(({ data: result }) => {
        setLoadingPetition(true)
        setData(result.result)
        setForm({
          first_name: result.result.first_name,
          last_name: result.result.last_name,
          email: result.result.email,
        })
      })
      .catch((error) => {
      });
  }, []);

  function passGenerator(length) {
    let caracteres = "abcdefghijkmnpqrtuvwxyzABCDEFGHIJKLMNPQRTUVWXYZ12346789";
    let pass = "";
    for (let i = 0; i < length; i++)
      pass += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    return pass;
  }

  const onChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    setLoading(true)
    e.preventDefault();

    let newData = { ...data, ...form }
    if (!newData.callforward_keep_caller_caller_id) delete newData.callforward_keep_caller_caller_id
    if (!newData.callforward_queue_calls) delete newData.callforward_queue_calls
    delete newData.account_id
    petition_patch("userDetails", { id: id, data: newData })
      .then(async ({ data: result }) => {
        await petition_get("voicemail-box-details", { id, idVoicemail: result.result.voicemail_box_id })
          .then(({ data: result }) => {
            petition_patch("updateVoicemailBox", {
              id: id,
              voicemail_box_id: result.result.pk,
              data: { ...result.result, name: `${form.first_name} ${form.last_name}`, mailbox: form.extension, },
            }).catch((error) => {
              console.log(error);
            });

          })
          .catch((error) => {
            console.log(error);
          });
        setLoading(false)

        toastr.success("Action completed", "Success");
      })
      .catch((error) => {
        setLoading(false)
        toastr.error("Action failed", "Error");
      });
  };

  return (
    <React.Fragment>
      <ModalUtilityAdd modal={modalPassword} setModal={setModalPassword} />
      <form action="" onSubmit={onSubmitForm}>
        <Card body>
          <CardTitle className="mt-4">User Details </CardTitle>
          <div className="mt-4">


            {loadingPetition ? <> <Row>
              <Col md={3} className="mt-1">
                <Label for="first_name">First Name</Label>
              </Col>
              <Col md={9}>
                <Input
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="First Name"
                  value={form.first_name}
                  onChange={onChangeForm}
                />
              </Col>
            </Row>
              <Row className="mt-4">
                <Col md={3} className="mt-1">
                  <Label for="last_name">Last Name</Label>
                </Col>
                <Col md={9}>
                  <Input
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder="Last Name"
                    value={form.last_name}
                    onChange={onChangeForm}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={3} className="mt-1">
                  <Label for="exampleEmail">Email</Label>
                </Col>
                <Col md={9}>
                  <Input
                    disabled={true}
                    type="email"
                    name="email"
                    id="exampleEmail"
                    placeholder="with a placeholder"
                    value={form.email}
                    onChange={onChangeForm}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={3} className="mt-1">
                  <Label for="examplePassword">Password</Label>
                </Col>
                <Col md={9}>
                  {/* <InputGroup>
                  <Input
                    type="text"
                    name="password"
                    id="examplePassword"
                    placeholder="with a placeholder"
                    value={form.password}
                    onChange={onChangeForm}
                  />
                  <InputGroupAddon addonType="prepend">
                    <Button
                      variant="outline-secondary"
                      onClick={() => {
                        setForm({ ...form, password: passGenerator(40) });
                      }}
                    >
                      Generator
                    </Button>
                  </InputGroupAddon>
                </InputGroup> */}
                  <Button onClick={() => setModalPassword(data)}>
                    Change Password
                  </Button>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col className="col-12  text-right mt-4">
                  <button
                    type="submit"
                    disabled={
                      loading ||
                      form.first_name === "" ||
                      form.last_name === "" ||
                      form.email === ""
                    }
                    className="btn btn-primary ml-4 pl-4 pr-4 font-size-16"
                  >
                    {loading && (
                      <Spinner
                        style={{ marginRight: "0.5em" }}
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}{" "}
                    Save
                  </button>
                </Col>{" "}
              </Row> </> : <div style={{ textAlign: "center" }}>
              <Spinner
                animation="grow"
                style={{
                  margin: "10vh auto",
                  height: "10vh",
                  width: "10vh",
                }}
              />{" "}
            </div>}
          </div>
        </Card>

      </form>
    </React.Fragment>
  );
}
