import {all, call, fork, takeEvery, put} from "redux-saga/effects";
import {
  SIP,
  USERAGENT_START,
  USERAGENT_END,
  DISCONNECTED_PHONE,
} from "./actionsTypes";
import {UserAgent, Registerer, Inviter} from "sip.js";
import {sipSuccess, disconnected} from "./actions.js";
import {select} from "redux-saga/effects";
import {UserAgentClient} from "sip.js/lib/core";
import toastr from "toastr";

const json = require("../../config.json");
const wssServer = json.prod
  ? "wss://sip.ws.itpvoice.net:5069"
  : "wss://sip.ws.itpvoice.net:5066";

async function sipStart({UAgent, Error}) {
  let result;
  const registerer = new Registerer(UAgent);
  await UAgent.start()
    .then((log) => {
      registerer.register();
      result = true;
    })
    .catch((err) => {
      result = false;
      if (Error) Error();
    });

  return result;
}

function* Sip({payload}) {
  let onInvite = payload.onInvite;

  const transportOptions = {
    server: wssServer,
  };

  const uri = UserAgent.makeURI(`sip:${payload.username}@${payload.realm}`);

  const userAgentOptions = {
    authorizationPassword: payload.password,
    authorizationUsername: payload.username,
    transportOptions,
    delegate: {
      onInvite,
      onDisconnect: (err) => {
        console.log("disconnect", err);
        payload.setDisconnectPhone(true);
      },
    },
    uri,
  };

  const userAgent = new UserAgent(userAgentOptions);

  const result = yield call(sipStart, {
    UAgent: userAgent,
    Error: () => {
      payload.setErrorConnect(true);
    },
  });

  yield put(sipSuccess(userAgent, result));
}

function* userAgentStart() {
  let userAgent = yield select((state) => {
    return state.sip.userAgent;
  });

  if (!userAgent) return;
  const result = yield call(sipStart, {UAgent: userAgent});

  yield put(sipSuccess(userAgent, result));
}

function* userAgentEnd() {
  const userAgent = yield select((state) => {
    return state.sip.userAgent;
  });

  if (!userAgent) return;

  userAgent
    .stop()
    .then(() => {
      put(sipSuccess(userAgent, true));
    })
    .catch((err) => {
      put(sipSuccess(userAgent, false));
    });
}

export function* watchSip() {
  yield takeEvery(SIP, Sip);
}
export function* watchUserAgentStart() {
  yield takeEvery(USERAGENT_START, userAgentStart);
}
export function* watchUserAgentEnd() {
  yield takeEvery(USERAGENT_END, userAgentEnd);
}

function* SipSaga() {
  yield all([
    fork(watchSip),
    fork(watchUserAgentStart),
    fork(watchUserAgentEnd),
  ]);
}

export default SipSaga;
