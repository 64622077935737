import React, {useState, useEffect} from "react";
import petition_post from "../../../petitions/petition_post";
import petition_get from "../../../petitions/petition_get";
import petition_patch from "../../../petitions/petition_patch";
import toastr from "toastr";
import {Row, Col} from "reactstrap";
import Switch from "react-switch";
export default function TimeGroupForm({
  viewTimeDate,
  setLoading,
  setModal,
  setReloadData,
  activeSubmitTimeGroup,
}) {
  let validateOverrideCodes = async (listOverrideCodes) => {
    return new Promise(async (resolve, reject) => {
      let numbersResult = [
        listOverrideCodes[0].input,
        listOverrideCodes[1].input,
        listOverrideCodes[2].input,
      ];

      let findOne = false;
      await petition_get("allCallflows", {id})
        .then(({data: result}) => {
          result.result.map((element) =>
            element.numbers.map((elementTwo) => {
              if (
                numbersResult.find(
                  (elementThree) => `*${elementThree}` === elementTwo
                )
              )
                findOne = elementTwo;
            })
          );
        })
        .catch((error) => console.log(error));

      if (
        switchOne &&
        (listOverrideCodes[0].input.trim(0) === "" ||
          listOverrideCodes[1].input.trim() === "" ||
          listOverrideCodes[2].input.trim() === "")
      ) {
        toastr.error(
          `You need one number in each field of the overrides codes`,
          "Error"
        );
        reject(false);
        return setLoading(false);
      }

      if (switchOne && findOne) {
        toastr.error(
          `The number ${findOne} is used in other Callflow.`,
          "Error"
        );
        reject(false);
        return setLoading(false);
      }

      const findRepeatNumbers = [...new Set(numbersResult)];
      if (switchOne && findRepeatNumbers.length !== 3) {
        toastr.error(
          `You have duplicate feature codes entered, please revise and try again.`,
          "Error"
        );
        reject(false);
        return setLoading(false);
      }
      resolve(true);
    });
  };

  let createCallFlowsOfOverridesCodes = (pk, listOverrideCodes) => {
    return new Promise((resolve, reject) => {
      let petitions = [];
      let timeId = pk;

      listOverrideCodes.map((element, i) => {
        let action;
        if (i === 0) action = "enable";
        else if (i === 1) action = "disable";
        else if (i === 2) action = "reset";

        let dataTemporalRoute = {
          callflow_object: {
            flow: {
              data: {
                action,
                id: timeId,
              },
              module: "timegroup",
              children: {},
            },
            type: "featureCode",
          },
          type: "featureCode",

          numbers: [`*${element.input}`],
          patterns: [],
        };
        petitions.push(
          petition_post("callflow", {id, data: dataTemporalRoute})
        );
      });

      Promise.all(petitions)
        .then((result) => resolve(result))
        .catch((err) => reject(false));
    });
  };

  useEffect(() => {
    if (activeSubmitTimeGroup.activeSubmitTimeGroup) {
      activeSubmitTimeGroup.setActiveSubmitTimeGroup(false);

      setLoading(true);

      let petitions = async () => {
        listOverrideCodes.map(
          (element) => element.input === `*${element.input}`
        );

        let validateError = await validateOverrideCodes(listOverrideCodes);
        if (!validateError) return;

        petition_post("timeGroup", {id, data: formTimeGroup})
          .then(({data: result}) => {
            let tempData = result.result;

            if (switchOne) {
              let tempPk = result.result.pk;
              createCallFlowsOfOverridesCodes(
                result.result.pk,
                listOverrideCodes
              ).then((result) => {
                petition_patch("timeGroup", {
                  id,
                  data: {
                    ...formTimeGroup,
                    forced_enable_code_callflow_id:
                      result[0].data.result.pk.toString(),
                    forced_disable_code_callflow_id:
                      result[1].data.result.pk.toString(),
                    forced_reset_code_callflow_id:
                      result[2].data.result.pk.toString(),
                    itpvoice_metadata: {listOverrideCodes},
                  },
                  time_id: tempPk,
                })
                  .then(({data: result}) => {
                    setLoading(false);
                    setModal(false);
                    toastr.success("Action completed", "Success");
                    setReloadData(true);
                    setFormTimeGroup({name: ""});
                    viewTimeDate(tempData, "new");
                  })
                  .catch((error) => {
                    setLoading(false);
                    toastr.error("Action failed", "Error");
                  });
              });
            } else {
              setLoading(false);
              setModal(false);
              toastr.success("Action completed", "Success");
              setReloadData(true);
              setFormTimeGroup({name: ""});
              viewTimeDate(tempData, "new");
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            toastr.error("Action failed", "Error");
          });
      };

      petitions();
    }
  }, [activeSubmitTimeGroup.activeSubmitTimeGroup]);

  toastr.options = {showDuration: 300};
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [formTimeGroup, setFormTimeGroup] = useState({name: ""});

  const [listOverrideCodes, setListOverridesCodes] = useState([
    {title: "Forced Enable Code", numbers: [], input: ""},
    {title: "Forced Disable Code", numbers: [], input: ""},
    {title: "Forced Reset Code", numbers: [], input: ""},
  ]);

  const [switchOne, setSwitchOne] = useState(false);

  const handleChangeGroup = (e) => {
    setFormTimeGroup({...formTimeGroup, [e.target.name]: e.target.value});
  };

  const handleChangeOverride = (e, i) => {
    let newOverrideList = [...listOverrideCodes];
    newOverrideList[i].input = e;
    setListOverridesCodes(newOverrideList);
  };

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{marginTop: "1.5em"}}>No</p>
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{marginTop: "1.5em"}}>Yes</p>
      </div>
    );
  };

  return (
    <Row>
      <Col className="col-12 d-flex align-items-center">
        <label htmlFor="name" className="m-0 pr-2" style={{width: "25%"}}>
          Name:
        </label>
        <input
          onChange={handleChangeGroup}
          name="name"
          value={formTimeGroup.name}
          className="form-control"
          type="text"
        />
      </Col>

      <Col className="col-12 pt-5 ">
        <label htmlFor="time_window_start" className="m-0 pr-4">
          Enable Override Codes
        </label>
        <Switch
          uncheckedIcon={<Offsymbol />}
          className="mr-1"
          checkedIcon={<OnSymbol />}
          onColor="#626ed4"
          onChange={() => {
            setSwitchOne(!switchOne);
          }}
          height={24}
          width={48}
          checked={switchOne}
        />
      </Col>

      {switchOne &&
        listOverrideCodes.map((element, i) => (
          <Col className="col-12 pt-3">
            <label htmlFor="start_date"> {element.title} </label>
            <div className="d-flex">
              <span className="pr-2">*</span>
              <input
                onChange={(e) => {
                  handleChangeOverride(e.target.value, i);
                }}
                value={element.input}
                className="form-control"
                type="number"
              />
            </div>
          </Col>
        ))}
    </Row>
  );
}
