import React, { useState } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane, Button, Spinner } from "reactstrap";
import classnames from 'classnames';
import petition_patch from "../../../../../petitions/petition_patch"
import toastr from "toastr";
export default function CallRecording({ data, setData }) {

    toastr.options = {
        showDuration: 300,
    };

    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const [loading, setLoading] = useState(false)

    const [toggleRecordingInternal, setToggleRecordingInternal] = useState(data.call_recording_internal)
    const [toggleRecordingExternal, setToggleRecordingExternal] = useState(data.call_recording_external)

    const handleUpdateCallRecording = () => {

        setLoading(true)
        let newData = { call_recording_internal: toggleRecordingInternal, call_recording_external: toggleRecordingExternal }

        setLoading(true)
        petition_patch("callerIdExternal", { id, data: newData })
            .then(({ data: result }) => {
                setLoading(false);
                setData(result.result)
                toastr.success("Action completed", "Success");
            })
            .catch((error) => {
                setLoading(false)

                toastr.error("Action failed", "Error");
                console.log(error.response);
            });


    }

    return (
        <>
            <div className="pt-5">

                <label htmlFor=""> Call Recording Internal: </label>
                <div className="border" style={{ borderWidth: "2px" }}>


                    <Nav pills justified>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: toggleRecordingInternal === 'optional' })}
                                onClick={() => { setToggleRecordingInternal('optional'); }}
                            >
                                <i className="bx bx-chat font-size-19 d-sm-none"></i>
                                <span className="d-none d-sm-block">Optional</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: toggleRecordingInternal === 'enabled' })}
                                onClick={() => { setToggleRecordingInternal('enabled'); }}
                            >
                                <i className="bx bx-group font-size-19 d-sm-none"></i>
                                <span className="d-none d-sm-block">Enabled</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: toggleRecordingInternal === 'disabled' })}
                                onClick={() => { setToggleRecordingInternal('disabled'); }}
                            >
                                <i className="bx bx-book-content font-size-19 d-sm-none"></i>
                                <span className="d-none d-sm-block">Disabled</span>
                            </NavLink>
                        </NavItem>

                    </Nav >
                </div>
            </div>
            <div className="pt-5">

                <label htmlFor=""> Call Recording External: </label>
                <div className="border" style={{ borderWidth: "2px" }}>

                    <Nav pills justified>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: toggleRecordingExternal === 'optional' })}
                                onClick={() => { setToggleRecordingExternal('optional'); }}
                            >
                                <i className="bx bx-chat font-size-19 d-sm-none"></i>
                                <span className="d-none d-sm-block">Optional</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: toggleRecordingExternal === 'enabled' })}
                                onClick={() => { setToggleRecordingExternal('enabled'); }}
                            >
                                <i className="bx bx-group font-size-19 d-sm-none"></i>
                                <span className="d-none d-sm-block">Enabled</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: toggleRecordingExternal === 'disabled' })}
                                onClick={() => { setToggleRecordingExternal('disabled'); }}
                            >
                                <i className="bx bx-book-content font-size-19 d-sm-none"></i>
                                <span className="d-none d-sm-block">Disabled</span>
                            </NavLink>
                        </NavItem>

                    </Nav >
                </div>
            </div>
            <div className="col-12 text-right mt-3">

                <Button color="primary" onClick={handleUpdateCallRecording} disabled={loading} >

                    {loading && (
                        <Spinner
                            style={{ marginRight: "0.5em" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}

                    Save

                </Button>

            </div>
        </>
    )
}
