import React, {Component} from "react";

import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {} from "../../store/actions";
import Footer from "./Footer";
//Simple bar
import SimpleBar from "simplebar-react";

//i18n
import {withNamespaces} from "react-i18next";
import SidebarContent from "./SidebarContent";
import SidebarContentLegacy from "./SidebarContentLegacy";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  legacy() {
    if (
      window.location.pathname === "/DashboardITPVoiceLegacy" ||
      window.location.pathname === "/AddNumbersPBX" ||
      window.location.pathname === "/ListAllNumbersPBX" ||
      window.location.pathname === "/transfer-your-number-pbx" ||
      window.location.pathname === "/ListAllNumbersPBX" ||
      window.location.pathname === "/transfers-in-progress-pbx"
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu relative">
          {this.props.layout.admin !== null && (
            <div data-simplebar className="h-100">
              {this.props.type !== "condensed" ? (
                <SimpleBar style={{maxHeight: "100%"}}>
                  {this.legacy() ? (
                    <SidebarContentLegacy />
                  ) : (
                    <SidebarContent
                      toggleMenuCallback={this.props.toggleMenuCallback}
                    />
                  )}
                </SimpleBar>
              ) : (
                <>
                  {" "}
                  {this.legacy() ? (
                    <SidebarContentLegacy />
                  ) : (
                    <SidebarContent
                      toggleMenuCallback={this.props.toggleMenuCallback}
                    />
                  )}
                </>
              )}
            </div>
          )}

          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withNamespaces()(Sidebar)));
