import React, { useEffect, useState } from 'react'
import petition_get from '../pages/petitions/petition_get'
import petition_post from '../pages/petitions/petition_post'
import axios from "axios";
import toastr from "toastr";
export default function useContacts() {

    toastr.options = {
        showDuration: 300,
    };
    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const [defaultUsers, setDefaultUsers] = useState(null);
    const [userContacts, setUserContacts] = useState(null);
    const [data, setData] = useState(null);
    const [dataImport, setDataImport] = useState(null)
    const [loading, setLoading] = useState(false)

    const [selectedItem, setSelectedItem] = useState(null)



    useEffect(() => {



        let timeoutId
        if (params.get("code")) {
            timeoutId = setTimeout(() => {
                return getImportContacts()
            }, 3000)
        }
        else getContacts();


        return () => {
            clearTimeout(timeoutId)
        }

    }, [])


    const reloadData = () => { getContacts() }

    const getImportContacts = (source) => {

        petition_get("importContactsGmail", { id, code: params.get("code") })
            .then(({ data: result }) => setDataImport(result.result))
            .catch((error) => {
                getContacts();
            });
    }


    const getContacts = () => {
        petition_get("contacts", { id })
            .then(({ data: result }) => {

                /*    setDefaultUsers(result.result.model_list.default_contacts);
                   setUserContacts(result.result.model_list.user_contacts); */




                const newDefault = [
                    ...result.result.model_list.default_contacts.map((element) => {
                        let cutNameSave = ''
                        element.name.split(" ").map((element, index) => {
                            if (element.trim() !== "" && index < 2) cutNameSave = cutNameSave + element[0]
                        })
                        element.default = true;
                        element.cutName = cutNameSave
                        return element;
                    }),
                ];

                const newUserContacts = [...result.result.model_list.user_contacts.map(element => {
                    let cutNameSave = ''
                    element.name.split(" ").map((element, index) => {
                        if (element.trim() !== "" && index < 2) cutNameSave = cutNameSave + element[0]
                    })
                    element.cutName = cutNameSave
                    return element;
                })]



                const newResults = [...newUserContacts, ...newDefault];
                if (newResults.length > 0) setSelectedItem(newResults[0])
                setData(newResults);
            })
            .catch((error) => { /* if (error.response.status === 404) setError404(true);  */ });
    }


    const onChangeSelectedItem = (item) => { setSelectedItem(item) }

    const createContactsGoogle = () => {

        let newObjectContacts = []

        dataImport.map(element => {

            let newContact = {}

            newContact.name = element.title
            if (element.number) { newContact.number = element.number; newContact.number_label = "Mobile" }
            if (element.email) { newContact.email = element.email; newContact.email_label = "Mobile" }

            newObjectContacts.push(newContact)


        })

        setLoading(true)

        petition_post("importContactCsv", { id, data: { new_contacts: newObjectContacts } })
            .then(({ data: result }) => {
                setDataImport(null)
                setLoading(false);
                toastr.success("Action completed", "Success");
                reloadData(true)

            })
            .catch((error) => {
                toastr.error("Action Error", "Error");
                console.log(error);
                setLoading(false);
            });


    }

    const backToContacts = () => { getContacts(); setDataImport(null) }



    return { createContactsGoogle, loading, selectedItem, defaultUsers, userContacts, data, onChangeSelectedItem, reloadData, dataImport, backToContacts }
}
