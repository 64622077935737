import React, {useState, useEffect} from "react";
import CustomForm from "../../../../../../components/utils/CustomForm";
import {get} from "lodash";
export default function Queue({
  handleSubmitModule,
  defaultOption,
  json,
  activeSidebar,
}) {
  const [customForm, setCustomForm] = useState(null);

  const {data} = defaultOption;

  const formConfig = {
    defaultValues: {
      menu: data.menu || "",
    },
  };

  useEffect(() => {
    let data = get(JSON.parse(json), activeSidebar.text.node.route);

    let currentOptions = Object.keys(data.children);

    let options = [
      {label: "1", value: "1"},
      {label: "2", value: "2"},
      {label: "3", value: "3"},
      {label: "4", value: "4"},
      {label: "5", value: "5"},
      {label: "6", value: "6"},
      {label: "7", value: "7"},
      {label: "8", value: "8"},
      {label: "9", value: "9"},
      {label: "0", value: "0"},
      {label: "Timeout", value: "timeout"},
      {label: "Invalid", value: "invalid"},
    ];

    let filterOptions = [];

    options.map((option) => {
      let findOption = currentOptions.find(
        (current) => current === option.value
      );
      if (!findOption) filterOptions.push(option);
    });

    setCustomForm([
      {
        type: "select",
        options: filterOptions,
        label: "Option",
        name: "menu",
      },
    ]);
  }, []);

  return (
    <div>
      <CustomForm
        onSubmit={handleSubmitModule}
        customForm={customForm}
        formConfig={formConfig}
      />
    </div>
  );
}
