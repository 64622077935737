import React, { useEffect, useState } from "react";

import json from "../../../config.json"
import petition_get from "../../petitions/petition_get";
import CustomBreadCrum from "../../../components/utils/CustomBreadCrum/CustomBreadCrum"
import Loading from "../../../components/utils/Loading"
import CardUsers from "./CardUsers/CardUsers";
import Tools from "./Tools/Tools";
import Queues from "./Queues/Queues";
import CallParkingSlots from "./CallParkingSlot/CallParkingSlot";


import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

function OperatorPanel({ layout }) {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const urlBase = !json.prod ? `wss://websockets.api.itp247.net/itpvoice?account_id=${id}` : `wss://websockets.api.itp247.com/itpvoice?account_id=${id}`

  const [users, setUsers] = useState(null)
  const [activeUsers, setActiveUsers] = useState(null)
  const [listDragAndDrop, setListDragAndDrop] = useState([{ type: "cardUser" }, { type: "callParking" }]);
  const [loading, setLoading] = useState(false)
  const [queues, setQueues] = useState([]);

  const [reload, setReload] = useState(1)
  const [searchFilter, setSearchFilter] = useState("");

  //Switch Tools
  const [switchOne, setSwitchOne] = useState(true);
  const [switchTwo, setSwitchTwo] = useState(true);
  const [switchThree, setSwitchThree] = useState(true);
  const [switchFour, setSwitchFour] = useState(true);

  //Parking Call 
  const [parkingCall, setParkingCall] = useState(null)


  const [usersCardsInfoFavorite, setUsersCardsInfoFavorite] = useState([]);
  const [usersCardsInfo, setUsersCardsInfo] = useState([]);
  const [accountData, setAccountData] = useState([]);

  let orderUserByIds = (users, arrayPositions, activeFavorite) => {

    let tempUsers = []
    //Search Users Position
    arrayPositions.map(pkUser => {
      let findUser = users.find(user => user.pk === pkUser)
      if (findUser) tempUsers.push(findUser)
    })

    //Search Older users
    if (!activeFavorite) {
      users.map(user => {
        let findUser = tempUsers.find(tempUser => tempUser.pk === user.pk)
        if (!findUser) tempUsers.push(user)

      })
    }
    return tempUsers
  }


  let getUsersOperatorPanel = async () => {
    setLoading(false);
    let positionExtension;
    let dataUser;
    await petition_get("userDetails", { id })
      .then(({ data: result }) => {
        dataUser = result.result.pk
        setAccountData(result.result)
        positionExtension = result.result.operator_panel?.panel_settings
      })
      .catch((error) => console.log(error.response));

    await petition_get("operationPanel", { id })
      .then(({ data: result }) => {
        const newUsers = [];

        setParkingCall(result.result.parked_calls)

        result.result.users.map(element => {
          let activeDevice = element.pk === dataUser ? true : false
          const newChannels = element.channels.map(elementTwo => ({ answered: elementTwo.state === 4 ? true : false, time: elementTwo.call_seconds, withNumber: elementTwo.receiving ? elementTwo.from : elementTwo.to, uniqueId: elementTwo.callid }))
          newUsers.push({ ...element, channels: newChannels, devices_registered: (activeDevice || element.registered_devices.length > 0) ? true : false })
        })
        setSwitchThree(positionExtension.view_favorite_extensions)
        setUsersCardsInfo(orderUserByIds(newUsers, positionExtension.extensions))
        setUsersCardsInfoFavorite(orderUserByIds(newUsers, positionExtension.favorite_extensions, true))
        setUsers(newUsers);
        setLoading(true)
        setActiveUsers(true)
      })
      .catch((error) => console.log(error));

  }

  useEffect(() => { getUsersOperatorPanel() }, [])


  useEffect(() => {

    if (users && parkingCall) {

      let saveUsers = users;

      const onmessage = (socket) => {

        const token = JSON.parse(localStorage.getItem("authUser"));

        //Error
        socket.onerror = (e) => { }

        //Connect 
        socket.onopen = (e) => {

          socket.send(JSON.stringify({
            action: "login",
            payload: {
              jwt_token: token.access_token.replace(/['"]+/g, ""),
              account_id: id,
            }
          }))
        }

        socket.onmessage = (e) => {


          let socketInfo = JSON.parse(e.data);

          try { socketInfo = JSON.parse(socketInfo) }
          catch (error) { console.log("TESTING", error) }

          console.log("TESTING", socketInfo, socketInfo.event_name)

          if (socketInfo.event_name) {
            let usersCopy;
            let { from_user_id, to_user_id, uniqueId } = socketInfo.metadata


            let findFromNumber = saveUsers.find(element => element.pk === from_user_id)
            let findToNumber = saveUsers.find(element => element.pk === to_user_id)

            switch (socketInfo.event_name) {
              case "USER_DIAL":


                usersCopy = saveUsers.map(element => {

                  if (element.pk === from_user_id || element.pk === to_user_id) {

                    let withNumber = element.pk === from_user_id ? `${findToNumber?.presence_id || to_user_id}` : `${findFromNumber?.presence_id || from_user_id}`

                    if (element.channels.find(elementTwo => elementTwo.withNumber === withNumber)) return element;

                    element.devices_registered = true;
                    element.time_end = 0;
                    element.channels.push({ answered: false, time: 0, withNumber, uniqueId })
                  }
                  return element
                })
                saveUsers = usersCopy
                setUsers(usersCopy)
                break;

              case "USER_ANSWER":

                usersCopy = saveUsers.map(element => {
                  if (element.channels.length > 0) {



                    let saveIdChannels;
                    element.channels.map((elementTwo, i) => {
                      if (elementTwo.uniqueId === uniqueId)
                        saveIdChannels = i;
                    })

                    if (element.channels[saveIdChannels]) element.channels[saveIdChannels].answered = true;
                  }

                  return element;
                })

                saveUsers = usersCopy
                setUsers(usersCopy)
                break;

              /*  case "USER_HANGUP": */
              case "USER_HANGUP":

                usersCopy = saveUsers.map(element => {
                  if (element.channels.length > 0) {

                    let saveIdChannels;
                    element.channels.map((elementTwo, i) => {
                      if (elementTwo.uniqueId === uniqueId)
                        saveIdChannels = i;
                    })
                    if (element.channels[saveIdChannels])
                      element.channels[saveIdChannels].terminated = 1;
                  }
                  return element;
                })

                saveUsers = usersCopy
                setUsers(usersCopy)
                break;

              case "CALLPARKING_PARKED":
                let findUser = users.find(elementTwo => elementTwo.pk === socketInfo.metadata.from_user_id)
                setParkingCall(preview => preview.map(element => element.slot_number === socketInfo.metadata.parking_slot ? { ...element, call_id: socketInfo.metadata.uniqueid, callerid_num: findUser?.presence_id || "" } : element))
                break;
              case "CALLPARKING_ABANDONED":
                setParkingCall(preview => preview.map(element => element.slot_number === socketInfo.metadata.parking_slot ? { ...element, call_id: "", callerid_num: "" } : element))
                break;

              default: break;
            }
          }
        }
      }


      let socket = new WebSocket(urlBase);
      onmessage(socket)

      //Interval of opening socket
      const intervalIdTwo = setInterval(() => {
        if (socket.readyState === 3) {
          socket = new WebSocket(urlBase);
          onmessage(socket);
        }
      }, 10000);


      const intervalIdOne = setInterval(() => {



        saveUsers.map((element) => {
          if (element.channels.length === 0) return element


          if (!element.channels[0].terminated) {
            element.time_end = element.time_end + 1;
            element.channels[0].time = element.channels[0].time + 1;
          }
          else if (element.channels[0].terminated < 5)
            element.channels[0].terminated = element.channels[0].terminated + 1;

          else
            element.channels = element.channels.filter(elementTwo => !elementTwo.terminated)




        });



        setReload(new Date())
        setUsers(saveUsers);

      }, 1000);

      return () => {
        clearInterval(intervalIdOne);
        clearInterval(intervalIdTwo);
      }
    }
  }, [activeUsers])


  const onDragEnd = (e) => {
    const srcI = e.source.index;

    if (e.destination) {
      const destI = e.destination.index;
      let ListUser = listDragAndDrop;
      ListUser.splice(destI, 0, ListUser.splice(srcI, 1)[0]);
      const newListUser = ListUser.filter((element) => element);

      setListDragAndDrop(newListUser);
    }
  };

  return (
    <div className="page-content">
      <div className="padding-page">
        <CustomBreadCrum title="Apps" subtitle="Operator Panel" />
        <div style={{ paddingBottom: "10rem", transition: "1s all", width: "100%", }} >
          {!loading ? <Loading /> : (
            <>
              <Row>
                <Col className="col-12 ">
                  <Tools
                    accountData={accountData}
                    refreshAll={getUsersOperatorPanel}
                    users={users}
                    queues={queues}
                    searchFilter={searchFilter}
                    setSearchFilter={setSearchFilter}
                    switchOne={switchOne}
                    setSwitchOne={setSwitchOne}
                    switchTwo={switchTwo}
                    setSwitchTwo={setSwitchTwo}
                    switchThree={switchThree}
                    setSwitchThree={setSwitchThree}
                    switchFour={switchFour}
                    setSwitchFour={setSwitchFour}
                  />
                </Col>
              </Row>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="panels">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {listDragAndDrop.map((element, i) => {
                        if (element.type === "cardUser") {
                          return (
                            <Draggable
                              key={i}
                              index={i}
                              draggableId={`key-${i}`}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <div
                                    {...provided.dragHandleProps}
                                    className="d-flex"
                                    style={{
                                      alignItems: "center",
                                      paddingBottom: "1rem",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="mt-5">
                                        <h3 style={{ margin: "0" }}>
                                          EXTENSIONS
                                        </h3>
                                      </div>
                                    </div>
                                  </div>
                                  <CardUsers
                                    accountData={accountData}
                                    searchFilter={searchFilter}
                                    users={
                                      switchThree === true
                                        ? usersCardsInfoFavorite
                                        : usersCardsInfo
                                    }
                                    switchOne={switchOne}
                                    switchTwo={switchTwo}
                                    id={id}
                                    layout={layout}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        }

                        else if (element.type === "callParking") {
                          return (
                            <Draggable
                              key={i}
                              index={i}
                              draggableId={`key-${i}`}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <div
                                    className="mt-5"
                                    {...provided.dragHandleProps}
                                  >
                                    <h3 className="m-0">  Call Parking Slot </h3>
                                  </div>
                                  <CallParkingSlots parkingCall={parkingCall} />
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}

        </div>

      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
    ITPVoice: state.ITPVoice,
  };
};

export default connect(mapStateToProps)(OperatorPanel);
