import React, {useState, useEffect} from "react";
import CustomBreadCrum from "../../../components/utils/CustomBreadCrum/CustomBreadCrum";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import CustomTable from "../../../components/utils/CustomTable/CustomTable";
export default function SmsCampaigns() {
  const [dataTable, setDataTable] = useState(null);

  useEffect(() => {
    setDataTable({
      title: [
        {title: "Name", key: "name"},

        {title: "Date started", key: "date_start"},
        {title: "Date end", key: "date_end"},
        {title: "Status", key: "status"},
        {title: "People received", key: "received"},
        {title: "Errors", key: "errors"},
      ],
      firstWidth: "30%",
      content: [
        {
          name: "Test",
          date_start: "29-07-2022",
          date_end: "29-07-2022",
          status: "completed",
          received: "20",
          errors: "5",
        },
        {
          name: "Test",
          date_start: "29-07-2022",
          date_end: "29-07-2022",
          status: "completed",
          received: "20",
          errors: "5",
        },
        {
          name: "Test",
          date_start: "29-07-2022",
          date_end: "29-07-2022",
          status: "completed",
          received: "20",
          errors: "5",
        },
        {
          name: "Test",
          date_start: "29-07-2022",
          date_end: "29-07-2022",
          status: "completed",
          received: "20",
          errors: "5",
        },
        {
          name: "Test",
          date_start: "29-07-2022",
          date_end: "29-07-2022",
          status: "completed",
          received: "20",
          errors: "5",
        },
      ],
      actions: {
        title: "Action",
        content: [
          {
            icon: <DeleteIcon />, //Svg icon
            handleClick: (e) => {},
          },
          {
            icon: <EditIcon />, //Svg icon
            handleClick: (e) => {},
          },
          {
            icon: <DescriptionIcon />, //Svg icon
            handleClick: (e) => {},
          },
        ],
      },
      search: {
        label: "Search Campaign",
      },
      addButton: {
        label: "Add Campaign",
        handleClick: () => {
          /* setModalAdd(true); */
        },
      },
    });
  }, []);

  return (
    <div className="page-content px-5">
      <CustomBreadCrum title="Apps" subtitle="Call Center App" />
      <CustomTable responsive={true} data={dataTable} />
    </div>
  );
}
