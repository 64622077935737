import React from "react";
import { ConfirmOrderStyled } from "../Style/Style";
import { Link } from "react-router-dom";
import { Checkmark } from "react-checkmark";
import { Button } from "reactstrap"
export default function ConfirmOrder({ resultOrder, setUpdate }) {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  return (
    <ConfirmOrderStyled>
      {resultOrder ? (
        <div className="order-confirm">
          <Checkmark size="150px" />
          <div className="container-back">
            <Button onClick={() => { if (setUpdate) setUpdate("1") }} color="primary">Back</Button>
          </div>
        </div>
      ) : (
        <div className="order-fail">the creation of the order failed</div>
      )}
    </ConfirmOrderStyled>
  );
}
