import React, {useState, useEffect} from "react";
import CustomForm from "../../../../../../components/utils/CustomForm";

export default function ExternalTransfer({
  handleDeleteModule,
  handleSubmitModule,
  defaultOption,
}) {
  const [customForm, setCustomForm] = useState(null);

  const {data} = defaultOption || {data: {}};

  const formConfig = {
    defaultValues: {
      number: data?.destination || "",
      require_keypress: data?.confirm_call || false,
      keep_caller_id: data?.keep_original_callerid || true,
      timeout: data?.dial_timeout || "30",
    },
  };

  useEffect(() => {
    setCustomForm([
      {type: "number", label: "External Number", name: "number"},
      {type: "number", label: "Timeout", name: "timeout"},
      {
        type: "check",
        label: "Confirm Call?",
        name: "require_keypress",
      },
      {
        type: "check",
        label: "Kepp Caller's Caller ID?",
        name: "keep_caller_id",
      },
    ]);
  }, []);

  return (
    <div>
      <CustomForm
        activeDelete={!data.new ? handleDeleteModule : null}
        onSubmit={handleSubmitModule}
        customForm={customForm}
        formConfig={formConfig}
      />
    </div>
  );
}
