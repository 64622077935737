import React, { useState, useRef, useEffect } from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";

import petition_get from "../../../../petitions/petition_get";
import petition_post from "../../../../petitions/petition_post";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Spinner,
} from "reactstrap";
import toastr from "toastr"
import PhoneInput from 'react-phone-input-2'
import Autocomplete from "react-autocomplete-select";
export default function ModalUtility({ modal, setModal, getFaxData }) {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([])
  const [reload, setReload] = useState(1);
  const [error, setError] = useState({})
  const [contacts, setContacts] = useState(null)
  const [countryCode, setCountryCode] = useState("+1 ")
  const [form, setForm] = useState({
    media_name: "",
    language: "en",
    media_type: "audio",
    tts_text: "",
    number: "",
    to_number: "",
    listNumbers: null
  });


  useEffect(() => {

    petition_get("contacts", { id })
      .then(({ data: result }) => setContacts(result.result))
      .catch((error) => console.log(error))

  }, [])



  useEffect(() => {


    if (contacts) {
      let findContacts = []

      let findNumber = (form.to_number.split(" ")[form.to_number.split(" ").length - 1]).toLowerCase()

      contacts.model_list.user_contacts.map(element => {
        element.numbers.map(elementTwo => (elementTwo.number.includes(findNumber) && elementTwo.label === "Mobile") && findContacts.push(`${countryCode}${element.name} +${elementTwo.number}`))
      })
      contacts.model_list.user_contacts.map(element => {

        if (element.name.toLowerCase().includes(findNumber)) {

          let findMobileNumber = element.numbers.find(elementTwo => elementTwo.label === "Mobile")
          if (findMobileNumber) {
            let findExistRecomendation = findContacts.find(elementThree => elementThree.number === ` ${element.name} ${findMobileNumber.number}`)
            if (!findExistRecomendation) findContacts.push(`${countryCode}${element.name} +${findMobileNumber.number}`)
          }

        }

      })
      if (form.to_number === "") return setOptions([])

      let reduceValues = findContacts.reduce((result, current) => { return result.includes(current) ? result : [...result, current] }, [])
      setOptions(reduceValues)
    }


  }, [form.to_number, contacts])


  const getNumbersFax = async () => {


    let tempPk;
    await petition_get("userDetails", { id: id })
      .then(({ data: result }) => tempPk = result.result.pk)
      .catch((error) => console.log(error));


    await petition_get("viewNumbersFilterFax", { id, smsFilter: modal.pk })
      .then(({ data: result }) => setForm({ ...form, number: result.result[0].number, listNumbers: result.result.map(element => element.number) }))
      .catch((error) => console.log(error.response));

  }


  useEffect(() => { getNumbersFax() }, [])


  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {

    setError({})





    //Validation error 
    if (form.media_type.trim() === "" || (form.number.trim() === "" || form.to_number.trim() === "" || form.media_type === "audio" && !inputRef.current) || (form.media_type === "tts" && (form.media_name.trim() === "" || form.tts_text.trim() === "" || form.language.trim() === "")))
      return toastr.error("Error in the form ", "Error");


    setLoading(true);

    if (form.media_type === "audio") {


      const formData = new FormData();


      let number = form.to_number.split(" ")[form.to_number.split(" ").length - 1]

      let toNumberTemp;

      if (`${number.substring(0, countryCode.length - 1)} ` === countryCode) toNumberTemp = number
      else toNumberTemp = `${countryCode.split(" ")[0]}${number}`


      formData.append("from_callerid_number", form.number);
      formData.append("to_callerid_number", toNumberTemp);

      if (inputRef.current) formData.append("file", inputRef.current.files[0]);
      else {
        formData.append("tts_text", form.tts_text);
        formData.append("language", form.language);
      }

      petition_post("fax", { id: id, data: formData })
        .then(({ data: result }) => {
          setModal(false);
          setLoading(false);
          setForm({
            media_name: "",
            language: "en",
            media_type: "audio",
            tts_text: "",
            number: "",
            to_number: "",
            listNumbers: null
          });
          toastr.success("Action completed", "Success");
          getFaxData()

        })
        .catch((error) => {
          toastr.error("Action failed", "Error");
          console.log(error);
          setLoading(false);
        });
    } else {

      petition_post("createTtsMedia", { id: id, data: { language: form.language, gender: "male", text: form.tts_text, name: form.media_name } })
        .then(({ data: result }) => {
          setModal(false);
          setLoading(false);
          setForm({
            media_name: "",
            language: "en",
            media_type: "audio",
            tts_text: "",
            number: "",
            to_number: "",
            listNumbers: null
          });
          toastr.success("Action completed", "Success");
          getFaxData()

        })
        .catch((error) => {
          toastr.error("Action failed", "Error");
          console.log(error);
          setLoading(false);
        });

    }
  };

  const handleChangeInput = (value) => {

    if (value === `${countryCode.substring(0, countryCode.length - 1)}`) return
    setForm({ ...form, to_number: value })

  }

  return (
    <Modal centered={true} isOpen={modal ? true : false} className="info ">
      <ModalHeader className="bg-soft-primary">

        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary fax-select">Send Fax</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {form.listNumbers ? <Row>
          <Col
            className={`d-flex col-12 pt-4 ${error.name && "input-error-form"}`}
            style={{ display: "flex", flexDirection: "column" }}
          >

            <Label
              htmlFor="media_name"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              From number
            </Label>

            <select value={form.number} onChange={onChange} name="number" className={` form-control ${error.number && "form-control-error"}`} name="" id="">


              {form.listNumbers.map((element, i) => <option key={i} value={element}>
                {element}
              </option>)}


            </select>

          </Col>
          <Col
            className={`d-flex col-12 pt-4 ${error.name && "input-error-form"}`}
            style={{ display: "flex", flexDirection: "column" }}
          >

            <Label
              htmlFor="media_name"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              To number
            </Label>

            <div className="d-flex ">

              <div className="inputFlag">
                <PhoneInput
                  country={'us'}
                  value={""}
                  onChange={(e) => { setCountryCode(`+${e} `); setForm({ ...form, to_number: e }) }}

                />
              </div>

              <div style={{ width: "90%" }}>

                <Autocomplete
                  searchPattern={'containsString'}
                  placeholder=""
                  maxOptionsLimit={20}
                  searchEnabled={true}

                  getItemValue={(item) => { return item }}
                  optionsJSX={(value) => <span>{value}</span>}

                  itemsData={options}

                  onChange={handleChangeInput}


                  inputJSX={(props) => <input {...props} value={form.to_number} className="form-control" />}
                />
              </div>






            </div>

            {/*  <input
              className={`form-control ${error.to_number && "form-control-error"}`}
              id="to_number"
              name="to_number"
              type="text"
              onChange={onChange}
              value={form.to_number}
              placeholder=""
            /> */}
          </Col>

          <Col
            className="d-flex col-12 pt-4"
            style={{ display: "flex", flexDirection: "column" }}
          >

            <Label
              htmlFor="media_type"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              Media Type
            </Label>
            <select
              id="media_type"
              className="form-control"
              name="media_type"
              onChange={onChange}
              value={form.media_type}
            >
              <option value="audio">File</option>
              {/* <option value="tts">TTS</option> */}
            </select>
          </Col>
          <Col
            className="d-flex col-12 pt-4"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {form.media_type === "tts" && (
              <>
                <textarea
                  onChange={onChange}
                  value={form.tts_text}
                  className="form-control"
                  placeholder="Type Text To Speech "
                  name="tts_text"
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </>
            )}
            {form.media_type === "audio" && (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    style={{
                      color: "white",
                      marginRight: "1rem",
                      marginBottom: "0",
                    }}
                    className="bg-primary btn"
                    htmlFor="input_file"
                  >
                    Browse Files
                  </label>

                  <input
                    onChange={(e) => {
                      setTimeout(() => {
                        setReload(reload + 1);
                      }, [100]);
                    }}
                    id="input_file"
                    className="d-none"
                    type="file"
                    accept="application/msword,image/*"
                    ref={inputRef}
                  />
                  <span>Only Word or Images Files Are Allowed</span>
                </div>
                <span className="mt-3">
                  {inputRef.current &&
                    inputRef.current.files.length !== 0 &&
                    inputRef.current.files[0].name}
                </span>
              </>
            )}
          </Col>
        </Row> :
          <p className="text-center" style={{ fontSize: "1.2rem" }}>
            You do not have available numbers to send fax, please add a number

          </p>
        }

      </ModalBody>
      <ModalFooter>
        <Button
          outline
          color="secondary"
          onClick={() => {
            setModal(false);
            setForm({
              media_name: "",
              language: "en",
              media_type: "audio",
              tts_text: "",
              number: "",
              to_number: "",
              listNumbers: null
            });
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            onSubmitForm();
          }}
        >
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
