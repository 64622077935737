import React, {useState} from "react";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import {Button} from "reactstrap";
import tutorialJsons from "../../../pages/Utility/tutorialJsons";
import {Steps, Hints} from "intro.js-react";

export default function CustomBreadCrum({
  title,
  subtitle,
  tutorial,
  activeTutorial,
  removePadding,
}) {
  const [activeStep, setActiveStep] = useState(false);

  const onBeforeChange = (nextStepIndex) => activeTutorial(nextStepIndex);

  return (
    <div
      className="d-flex"
      style={{
        alignItems: "center",
        paddingBottom: removePadding ? "2rem" : "8rem",
      }}
    >
      {tutorialJsons[tutorial] && (
        <Steps
          onExit={() => {
            setActiveStep(false);
          }}
          enabled={activeStep}
          steps={tutorialJsons[tutorial]}
          initialStep={0}
          onBeforeChange={onBeforeChange}
        />
      )}

      <h3 style={{margin: "0", fontSize: "20px"}}>{subtitle}</h3>
      {/* <span>
        <DoubleArrowIcon className="icon-arrow" />
      </span>
      <h4
        style={{
          color: "#a6b0cf",
          fontWeight: "400",
          margin: "0",
          fontSize: "15px",
          paddingTop: "7px",
        }}
      >
        {subtitle}
      </h4> */}
      {tutorialJsons[tutorial] && (
        <Button
          className="ml-2"
          color="primary"
          onClick={() => {
            setActiveStep(true);
            activeTutorial(0);
          }}
        >
          View tutorial
        </Button>
      )}
    </div>
  );
}
