import React, {useState} from "react";
import {Row, Col, Label, Input, Spinner} from "reactstrap";
import Switch from "react-switch";
import toastr from "toastr";
import petition_patch from "../../../../../petitions/petition_patch";
export default function CallForwarding({data, id}) {
  toastr.options = {
    showDuration: 300,
  };

  const [switchOne, setSwitchOne] = useState(
    data ? data.callforward_enable : false
  );
  const [switchTwo, setSwitchTwo] = useState(false);
  const [switchThree, setSwitchThree] = useState(
    data?.callforward_queue_calls || false
  );
  const [switchFour, setSwitchFour] = useState(
    data?.callforward_keep_caller_caller_id || false
  );
  const [form, setForm] = useState({number: data?.callforward_number});
  const [loading, setLoading] = useState(false);

  const onChangeNumber = (e) => {
    setForm({[e.target.name]: e.target.value});
  };

  const onClickSubmit = (e) => {
    setLoading(true);
    const info = {
      callforward_enable: !switchOne ? false : switchOne,
      callforward_number: !switchOne ? "" : form.number,
      callforward_keep_caller_caller_id: !switchOne ? false : switchFour,
      callforward_queue_calls: !switchOne ? false : switchThree,
    };

    petition_patch("callForward", {id, data: info})
      .then(({data: result}) => {
        setLoading(false);
        if (!switchOne) {
          setSwitchTwo(false);
          setSwitchThree(false);
          setSwitchFour(false);
          setForm({number: data.number});
        }
        toastr.success("Action completed", "Success");
      })
      .catch((error) => {
        toastr.error("Action failed", "Error");
        console.log(error.response);
      });
  };

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        Yes
      </div>
    );
  };
  return (
    <>
      <Row>
        <Col className="col-12">
          {" "}
          <div className="d-flex" style={{padding: "2rem 0 "}}>
            <Switch
              uncheckedIcon={<Offsymbol />}
              className="mr-1"
              checkedIcon={<OnSymbol />}
              onColor="#626ed4"
              onChange={() => {
                setSwitchOne(!switchOne);
              }}
              checked={switchOne}
            />{" "}
            <span
              style={{
                paddingLeft: "1.5rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              Enable Call Forwarding
            </span>
          </div>
        </Col>

        {switchOne && (
          <Col className="col-12  col-lg-9  col-xl-7 d-flex row">
            <Col
              className="col-12 col-md-8 "
              style={{display: "flex", alignItems: "center"}}
            >
              {" "}
              <Label htmlFor="number" style={{margin: "0", padding: "0"}}>
                Number or Extension to forward calls to:
              </Label>
            </Col>
            <Col className="mt-2 mt-lg-0 col-6 col-md-4">
              {" "}
              <Input
                id="number"
                name="number"
                type="text"
                value={form.number}
                onChange={onChangeNumber}
              ></Input>
            </Col>
          </Col>
        )}
        <Col className="col-12">
          <hr />
        </Col>
        {switchOne && (
          <Col className="col-12">
            {/* <div style={{ padding: "1rem 0 " }}>
              {" "}
              <Switch
                uncheckedIcon={<Offsymbol />}
                className="mr-1"
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                onChange={() => {
                  setSwitchTwo(!switchTwo);
                }}
                checked={switchTwo}
              />{" "}
              <br className="d-md-none" />
              <br className="d-md-none" />
              <span style={{ paddingLeft: "1.5rem" }}>
                Try to leave voicemal on forwarder number
              </span>
            </div>{" "} */}
            <div style={{padding: "1rem 0 "}}>
              {" "}
              <Switch
                uncheckedIcon={<Offsymbol />}
                className="mr-1"
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                onChange={() => {
                  setSwitchThree(!switchThree);
                }}
                checked={switchThree}
              />{" "}
              <br className="d-md-none" />
              <br className="d-md-none" />
              <span style={{paddingLeft: "1.5rem"}}>
                Forward direct calls only (ignore queues, ring groups, etc.)
              </span>
            </div>{" "}
            <div style={{padding: "1rem 0 "}}>
              {" "}
              <Switch
                uncheckedIcon={<Offsymbol />}
                className="mr-1"
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                onChange={() => {
                  setSwitchFour(!switchFour);
                }}
                checked={switchFour}
              />{" "}
              <br className="d-md-none" />
              <br className="d-md-none" />
              <span style={{paddingLeft: "1.5rem"}}>
                Keep Original Caller-ID
              </span>
            </div>
          </Col>
        )}

        <Col className="col-12 row">
          <Col className="col-12  text-right mt-4">
            <button
              disabled={loading || form.number === "" ? true : false}
              onClick={onClickSubmit}
              className="btn btn-primary ml-4 pl-4 pr-4 font-size-16"
            >
              {loading && (
                <Spinner
                  style={{marginRight: "0.5em"}}
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}{" "}
              Save
            </button>
          </Col>{" "}
        </Col>
      </Row>
    </>
  );
}
