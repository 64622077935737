import React, {useState, useEffect} from "react";
import {
  Card,
  Row,
  Col,
  CardTitle,
  Button,
  Input,
  Label,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  Table,
  Badge,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import ModalUtility from "../ModalUtility/ModalUtility";
import ButtonHover from "../../../../../../components/utils/ButtonHover";
import EditIcon from "@material-ui/icons/Edit";
import CallHendling from "./CallHendling/CallHendling";
import CallPhoneNumbers from "./CallPhoneNumbers/CallPhoneNumbers";
import CallForwarding from "./CallForwarding/CallForwarding";
import CallRecording from "./CallRecording/CallRecording";
import petition_get from "../../../../../petitions/petition_get";
import CallerIdSettings from "./CallerIdSettings/CallerIdSettings";

export default function CallSettings({
  myDevices,
  id,
  accountDetails,
  infoUser,
}) {
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [modal, setModal] = useState(false);
  const [deviceElement, setDeviceElement] = useState(false);
  const [data, setData] = useState(null);
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [keyNumbers, setKeyNumbers] = useState(null);
  const [callForwardData, setCallForwardData] = useState(null);

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  }

  useEffect(() => {
    const petitions = () => {
      petition_get("viewCallSettingsUsers", {id, user_id: infoUser.pk})
        .then(({data: result}) => {
          setData(result.result);
          setCallForwardData(result.result);
          setLoading(true);
        })
        .catch((error) => {
          console.log(error);
        });
      petition_get("numbersUsers", {id: id, owner_id: infoUser.pk})
        .then(({data: result}) => {
          setNumbers(result.result);
          setLoadingTwo(true);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    petitions();
  }, []);

  return (
    <React.Fragment>
      <ModalUtility
        setModal={setModal}
        modal={modal}
        deviceElement={deviceElement}
        id={id}
        setDeviceElement={setDeviceElement}
        accountDetails={accountDetails}
        numbers={numbers}
      />
      <Card body>
        <CardTitle className="mt-4">Call Settings </CardTitle>
        <CardBody>
          <Nav
            tabs
            className="nav-tabs-custom  flex-column flex-md-row  text-center  "
          >
            <NavItem className="pt-3 pt-md-0">
              <NavLink
                style={{cursor: "pointer"}}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                <span>Phone Numbers</span>
              </NavLink>
            </NavItem>
            <NavItem className="pt-3 pt-md-0">
              <NavLink
                style={{cursor: "pointer"}}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                <span>Devices</span>
              </NavLink>
            </NavItem>
            <NavItem className="pt-3 pt-md-0">
              <NavLink
                style={{cursor: "pointer"}}
                className={classnames({
                  active: customActiveTab === "3",
                })}
                onClick={() => {
                  toggleCustom("3");
                }}
              >
                <span>Call Forwarding</span>
              </NavLink>
            </NavItem>
            {/*  
            <NavItem className="pt-3 pt-md-0">
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "4",
                })}
                onClick={() => {
                  toggleCustom("4");
                }}
              >
                <span>Call Handling</span>
              </NavLink>
            </NavItem> */}
            <NavItem className="pt-3 pt-md-0">
              <NavLink
                style={{cursor: "pointer"}}
                className={classnames({
                  active: customActiveTab === "5",
                })}
                onClick={() => {
                  toggleCustom("5");
                }}
              >
                <span>Caller ID Settings</span>
              </NavLink>
            </NavItem>
            <NavItem className="pt-3 pt-md-0">
              <NavLink
                style={{cursor: "pointer"}}
                className={classnames({
                  active: customActiveTab === "6",
                })}
                onClick={() => {
                  toggleCustom("6");
                }}
              >
                <span>Call Recording</span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={customActiveTab}>
            <TabPane tabId="1" className="p-3">
              <CallPhoneNumbers
                infoUser={infoUser}
                numbers={numbers}
                keyNumbers={keyNumbers}
                loadingTwo={loadingTwo}
              />
            </TabPane>
            <TabPane tabId="2" className="p-3">
              {myDevices.length === 0 ? (
                <div className="text-center">
                  {" "}
                  <Spinner
                    animation="grow"
                    style={{
                      margin: "10vh auto",
                      height: "10vh",
                      width: "10vh",
                    }}
                  />{" "}
                </div>
              ) : (
                <Row>
                  <Col sm="12">
                    <Table responsive>
                      <thead className="thead-light">
                        <tr>
                          <th style={{textAlign: "center"}}>Type</th>
                          {/*   <th style={{ textAlign: "center" }}>Make / Model</th> */}
                          {/* <th style={{maxWidth:"5em"}}>PAYMENT STATUS</th> */}
                          {/*  <th style={{ textAlign: "center" }}>MAC Address</th>
                          <th style={{ textAlign: "center" }}>Enabled</th> */}
                          <th style={{textAlign: "center"}}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {myDevices.map((element, i) => (
                          <tr key={i}>
                            <td style={{textAlign: "center"}}>
                              {element.device_type.replace("_", " ")}
                            </td>
                            {/*  <td style={{ textAlign: "center" }}>
                              Polycom VVX400
                            </td> */}
                            {/*  <td style={{ textAlign: "center" }}>
                              {element.mac_address}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {element.enabled === true ? (
                                <Badge color="success">Enabled</Badge>
                              ) : (
                                <Badge color="danger">Disable</Badge>
                              )}
                            </td> */}
                            <td style={{textAlign: "center"}}>
                              <ButtonHover
                                onClick={() => {
                                  setDeviceElement(element);
                                  setModal(true);
                                }}
                              >
                                <EditIcon />
                              </ButtonHover>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
            </TabPane>
            <TabPane tabId="3" className="p-3">
              <Row>
                <Col sm="12">
                  {callForwardData || callForwardData === undefined ? (
                    <CallForwarding
                      id={id}
                      data={callForwardData}
                      user_id={infoUser.pk}
                    />
                  ) : (
                    <p>
                      {loading ? (
                        <>
                          Cannot find active call forwards in this account from
                          the backend
                        </>
                      ) : (
                        <div className="text-center">
                          <Spinner
                            animation="grow"
                            style={{
                              margin: "10vh auto",
                              height: "10vh",
                              width: "10vh",
                            }}
                          />
                        </div>
                      )}
                    </p>
                  )}
                </Col>
              </Row>
            </TabPane>
            {/*  <TabPane tabId="4" className="p-3">
              <Row>
                <Col sm="12">
                  {module && devices && myDevices ? (
                    <CallHendling
                      id={id}
                      data={devices}
                      devices={myDevices}
                      module={module}
                      user_id={infoUser.id}
                    />
                  ) : (
                    <div className="text-center">
                      {" "}
                      <Spinner
                        animation="grow"
                        style={{
                          margin: "10vh auto",
                          height: "10vh",
                          width: "10vh",
                        }}
                      />{" "}
                    </div>
                  )}
                </Col>
              </Row>
            </TabPane> */}
            <TabPane tabId="5" className="p-3">
              <CallerIdSettings
                data={data}
                setData={setData}
                user_id={infoUser.pk}
              />
            </TabPane>
            <TabPane tabId="6" className="p-3">
              {data && (
                <CallRecording
                  data={data}
                  setData={setData}
                  user_id={infoUser.pk}
                />
              )}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
