import React, { useState } from 'react'
import CustomBreadCrum from '../../../../components/utils/CustomBreadCrum/CustomBreadCrum'
import NavTabCustom from '../../../../components/utils/NavTabCustom'
import TabAllNumbers from "./TabAllNumbers/TabAllNumbers"
import TabTransferNumbers from "./TabAllNumbers/TabTransferNumbers"
import TabPortRequests from "./TabAllNumbers/TabPortRequests"
import OrderNewNumber from "../OrderNewNumbers/OrderNewNumbers"
export default function AllNumbers() {
  const [update, setUpdate] = useState(null)
  return (
    <div className="page-content">

      <CustomBreadCrum title="Phone Numbers" subtitle="All Numbers" />

      <NavTabCustom update={update}
        options={[
          { title: "All Numbers", component: <TabAllNumbers setUpdate={setUpdate} update={update} /> },
          { title: "Transfer Numbers ", component: <TabTransferNumbers /> },
          { title: "Order New Number ", component: <OrderNewNumber setUpdate={setUpdate} /> },
          { title: "Port Requests", component: <TabPortRequests /> }
        ]} />


    </div>
  )
}
