import React, {useEffect, useState} from "react";
import {
  Container,
  Card,
  Row,
  Col,
  CardTitle,
  Button,
  Table,
  FormGroup,
  Input,
  Label,
  CardBody,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

//Icons
import PhoneIcon from "@material-ui/icons/Phone";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import UserDetails from "./UserDetails/UserDetails";
import CallSettings from "./CallSettings/CallSettings";
import FaxSettings from "./FaxSettings/FaxSettings";
import petition_get from "../../../petitions/petition_get";

import Alert404 from "../../../../components/404alert/Alert404";
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum";

export default function SettingsAndDevices() {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [selector, setSelector] = useState(1);
  const [myDevices, setMyDevices] = useState([]);
  const [accountDetails, setAccountDetails] = useState([]);
  const [error404, setError404] = useState(false);
  const [allNumbers, setAllNumbers] = useState(null);

  useEffect(() => {
    petition_get("MyDevices", {id: id})
      .then(({data: result}) => {
        let filterDevices = result.result.filter(
          (element) =>
            element.device_type !== "web_phone" &&
            element.device_type !== "app_phone"
        );

        setMyDevices(filterDevices);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          setError404(true);
        }
      });
    petition_get("accountDetails", {id: id})
      .then(({data: result}) => {
        setAccountDetails(result.result);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          setError404(true);
        }
      });

    petition_get("viewNumbers", {id: id})
      .then(({data: result}) =>
        setAllNumbers(result.result.map((element) => element.number))
      )
      .catch((error) => console.log(error));
  }, []);

  const onClickCard = (type) => {
    setSelector(type);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="padding-page">
          {error404 ? (
            <Alert404 name="Settings And Devices" />
          ) : (
            <Container fluid>
              <CustomBreadCrum
                title="My Extensions"
                subtitle="Settings and Devices"
              />
              <Row>
                {/* /////////////////////////// duwan */}

                <Col md={3}>
                  <Card
                    onClick={() => {
                      onClickCard(1);
                    }}
                    body
                    className={`${selector === 1 && "bg-primary"}`}
                    style={{cursor: "pointer"}}
                  >
                    {" "}
                    <PersonOutlineOutlinedIcon
                      style={{
                        margin: "0 auto",
                        fontSize: "4rem",
                        color: `${selector === 1 ? "white" : "inherit"}`,
                      }}
                    />
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "1rem",
                        color: `${selector === 1 ? "white" : "inherit"}`,
                      }}
                    >
                      User Details
                    </p>
                  </Card>
                  <Card
                    style={{cursor: "pointer"}}
                    body
                    onClick={() => {
                      onClickCard(2);
                    }}
                    className={`${selector === 2 && "bg-primary"}`}
                  >
                    <PhoneIcon
                      style={{
                        margin: "0 auto",
                        fontSize: "4rem",
                        color: `${selector === 2 ? "white" : "inherit"}`,
                      }}
                    />
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "1rem",
                        color: `${selector === 2 ? "white" : "inherit"}`,
                      }}
                    >
                      Call Settings
                    </p>
                  </Card>
                  {/* <Card
                style={{cursor: "pointer"}}
                body
                onClick={() => {
                  onClickCard(3);
                }}
                className={`${selector === 3 && "bg-primary"}`}
              >
                <i
                  className="fas fa-fax"
                  style={{
                    margin: "0 auto",
                    fontSize: "4rem",
                    color: `${selector === 3 ? "white" : "inherit"}`,
                  }}
                ></i>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "1rem",
                    color: `${selector === 3 ? "white" : "inherit"}`,
                  }}
                >
                  Fax Settings
                </p>
              </Card> */}
                </Col>

                <Col md={9}>
                  {selector === 1 && (
                    <>
                      <UserDetails />
                    </>
                  )}
                  {selector === 2 && (
                    <>
                      <CallSettings
                        myDevices={myDevices}
                        id={id}
                        accountDetails={accountDetails}
                        allNumbers={allNumbers}
                      />
                    </>
                  )}
                  {/* {selector === 3 && <FaxSettings />} */}
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
