import React from "react";
import Editor from "@monaco-editor/react";
import styled from "styled-components";
export default function GraphJson({json, setJson}) {
  const editorOptions = {
    formatOnPaste: true,
    minimap: {
      enabled: false,
    },
  };

  const StyledEditorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    user-select: none;
  `;

  const StyledWrapper = styled.div`
    display: grid;
    height: calc(50vh);
    width: 500px;
    grid-template-columns: 100%;
    grid-template-rows: minmax(0, 1fr);
  `;

  return (
    <StyledEditorWrapper>
      <StyledWrapper>
        <Editor
          height="100%"
          defaultLanguage="json"
          value={json}
          theme={"vs-dark"}
          options={editorOptions}
          /*  loading={<Loading message="Loading Editor..." />} */
          onChange={(value) => setJson(value)}
        />
      </StyledWrapper>
    </StyledEditorWrapper>
  );
}
