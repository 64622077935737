import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Input, Button } from "reactstrap";
import SearchIcon from "@material-ui/icons/Search";
import Switch from "react-switch";
import FavoritesModal from "../ModalUtility/FavoritesModal";
import LoadingModal from "../ModalUtility/LoadingModal";
import petition_patch from "../../../petitions/petition_patch";
export default function Tools({
  switchOne,
  setSwitchOne,
  switchTwo,
  setSwitchTwo,
  switchThree,
  setSwitchThree,
  switchFour,
  setSwitchFour,
  searchFilter,
  setSearchFilter,
  users,
  queues,
  refreshAll,
  accountData
}) {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [modalFavorite, setModalFavorite] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>No</p>
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{ marginTop: "1.5em" }}>Yes</p>
      </div>
    );
  };

  const onChangeSearch = (e) => {
    setSearchFilter(e.target.value);
  };

  const favoriteUserView = () => {
    setSwitchThree(!switchThree);
    ///////////// aquí modal de loading
    setModalLoading(true);
    petition_patch("editCards", {
      id: id,
      data: { operator_panel: { panel_settings: { ...accountData.operator_panel.panel_settings, view_favorite_extensions: !switchThree } } },
    })
      .then(({ data: result }) => {
        /////////////aqui se quita el modal de loading
        setModalLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setModalLoading(false);
      });
  };

  const favoriteQueueView = () => {
    setSwitchFour(!switchFour);
    ///////////// aquí modal de loading
    setModalLoading(true);
    petition_patch("editCards", {
      id: id,
      data: { operator_panel: { panel_settings: { ...accountData.operator_panel.panel_settings, queues: !switchFour } } },
    })
      .then(({ data: result }) => {
        /////////////aqui se quita el modal de loading
        setModalLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setModalLoading(false);
      });
  };

  return (
    <>
      <LoadingModal modal={modalLoading} />
      <FavoritesModal
        refreshAll={refreshAll}
        users={users}
        queues={queues}
        modal={modalFavorite}
        setModal={setModalFavorite}
        accountData={accountData}
      />
      <Card>
        <CardBody>
          <Row className="row text-left">
            <Col className="col-6">
              <SearchIcon
                style={{ position: "absolute", top: "7px", left: "20px" }}
              />
              <Input
                placeholder="Search Number"
                type="text"
                style={{ paddingLeft: "40px", fontSize: "0.8em" }}
                value={searchFilter}
                onChange={onChangeSearch}
              />
            </Col>
            <Col className="  col-6 pt-0 text-right">
              <Button
                onClick={() => {
                  setModalFavorite(true);
                }}
                color="primary"
              >
                Favorites
              </Button>
            </Col>
            <Col
              className="col-6 col-lg-3 d-flex pt-4 pt-lg-3 justify-content-center  justify-content-lg-left"
              style={{ alignItems: "center" }}
            >
              <Switch
                uncheckedIcon={<Offsymbol />}
                className="mr-1"
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                onChange={() => {
                  setSwitchOne(!switchOne);
                }}
                height={24}
                width={48}
                checked={switchOne}
              />{" "}
              <p style={{ margin: "0", fontSize: "0.8em" }}>
                Show Users On Calls
              </p>
            </Col>

            <Col
              className="col-6 col-lg-3 d-flex pt-4 pt-lg-3 justify-content-center  justify-content-lg-left"
              style={{ alignItems: "center" }}
            >
              <Switch
                uncheckedIcon={<Offsymbol />}
                className="mr-1"
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                onChange={() => {
                  setSwitchTwo(!switchTwo);
                }}
                height={24}
                width={48}
                checked={switchTwo}
              />{" "}
              <p style={{ margin: "0", fontSize: "0.8em" }}>Show Users Offline</p>
            </Col>
            <Col
              className="col-6 col-lg-3 d-flex pt-4 pt-lg-3 justify-content-center  justify-content-lg-left"
              style={{ alignItems: "center" }}
            >
              <Switch
                uncheckedIcon={<Offsymbol />}
                className="mr-1"
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                onChange={() => {
                  favoriteUserView();
                }}
                height={24}
                width={48}
                checked={switchThree}
              />{" "}
              <p style={{ margin: "0", fontSize: "0.8em" }}>
                Show Favorite Users
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
