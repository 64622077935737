import React, {useState, useEffect} from "react";
import {Button, CustomInput} from "reactstrap";
import {SidebarItem} from "../../../components/Index";
import {get} from "lodash";
import {data} from "jquery";

export default function Queues({
  setDetails,
  updateAction,
  state,
  startPoint,
  StartItemStyle,
  queues,
  selectNode,
  timeGroups,
  dataCallFlow,
}) {
  const [queuesSearch, setQueuesSearch] = useState("");
  const [activeItemQueues, setActiveItemQueues] = useState(false);
  const [activeTimeGroups, setActiveTimeGroups] = useState(false);
  const [selectTimeGroup, setSelectTimeGroup] = useState(null);
  const [defaultValue, setDefaultValue] = useState(true);
  const [timeGroupsValues, setTimeGroupsValues] = useState([]);

  const [activeMenu, setActiveMenu] = useState(false);
  const [menuOptions, setMenuOptions] = useState(false);
  const [selectMenu, setSelectMenu] = useState(null);

  const onChangeAutocompleteMenu = (e) => {
    setSelectMenu(e.target.value);
  };

  useEffect(() => {
    if (selectNode && selectNode.type.split("_")[0] === "Time") {
      setDefaultValue(true);

      let values;
      if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
      else {
        values = get(dataCallFlow.flow, selectNode.childrenUrl).children;
      }
      const timeGroupsKeys = Object.keys(values);
      const newTimeGroups = [];
      timeGroups.map((element) => {
        const findOne = timeGroupsKeys.find(
          (elementTwo) => elementTwo === element.id
        );
        if (!findOne) {
          newTimeGroups.push(element);
        }
      });

      if (values._) {
        setDefaultValue(false);
        setSelectTimeGroup(newTimeGroups.length > 0 ? newTimeGroups[0].id : "");
      } else {
        setSelectTimeGroup("_");
      }
      setTimeGroupsValues(newTimeGroups);
      setActiveTimeGroups(false);
    } else if (selectNode && selectNode.type.split("_")[0] === "Menu") {
      let MenuOptions = [
        {id: "1"},
        {id: "2"},
        {id: "3"},
        {id: "4"},
        {id: "5"},
        {id: "6"},
        {id: "7"},
        {id: "8"},
        {id: "9"},
        {id: "0"},
        {id: "timeout"},
        {id: "invalid"},
      ];
      let values;
      if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
      else values = get(dataCallFlow.flow, selectNode.childrenUrl).children;

      const MenuKeys = Object.keys(values);
      const newMenu = [];
      MenuOptions.map((element) => {
        const findOne = MenuKeys.find(
          (elementTwo) => elementTwo === element.id
        );
        if (!findOne) {
          newMenu.push(element);
        }
      });
      setSelectMenu(newMenu[0].id);
      setMenuOptions(newMenu);
      setActiveMenu(true);
      setActiveTimeGroups(true);
    } else {
      setActiveTimeGroups(true);
    }
  }, []);

  const onChangeAutocompleteQueues = (e) => {
    setQueuesSearch(e.target.value);
  };

  const onChangeAutocompleteTimeGroup = (e) => {
    setSelectTimeGroup(e.target.value);
  };
  return (
    <>
      {activeTimeGroups ? (
        <>
          {activeMenu ? (
            <>
              <select
                onChange={onChangeAutocompleteMenu}
                className="form-control"
                list="datalistOptions"
                id="exampleDataList"
                placeholder="Type to search..."
              >
                {menuOptions.map((element, i) => (
                  <option key={i} value={element.id}>
                    {element.id}
                  </option>
                ))}
              </select>
              {selectMenu && (
                <Button
                  onClick={async () => {
                    setActiveMenu(false);
                  }}
                  className=" mt-3 w-100"
                  color="primary"
                >
                  Create
                </Button>
              )}
            </>
          ) : (
            <>
              <select
                onChange={onChangeAutocompleteQueues}
                className="form-control"
                list="datalistOptions"
                id="exampleDataList"
                placeholder="Type to search..."
              >
                <option value="">Select One</option>
                {queues.map((element, i) => (
                  <option key={i} value={element.pk}>
                    {element.queue_name}
                  </option>
                ))}
              </select>
              <Button
                onClick={async () => {
                  const findQueue = queues.find(
                    (element) => element.pk === parseInt(queuesSearch)
                  );
                  if (!findQueue) return;
                  setDetails({
                    pk: queuesSearch,
                    timeGroups: selectTimeGroup,
                    menuOption: selectMenu,
                  });
                  setActiveItemQueues(
                    `${findQueue.pk}_${findQueue.queue_name}`
                  );
                }}
                className=" mt-3 w-100"
                color="primary"
              >
                Create
              </Button>
              {activeItemQueues && (
                <SidebarItem
                  updateAction={updateAction}
                  state={state}
                  label={`Queues: ${activeItemQueues.split("_")[1]}`}
                  type={`queues_${activeItemQueues.split("_")[0]}`}
                  ports={startPoint(`queues_${activeItemQueues.split("_")[0]}`)}
                  ItemStyle={StartItemStyle}
                />
              )}{" "}
            </>
          )}
        </>
      ) : (
        <>
          <select
            onChange={onChangeAutocompleteTimeGroup}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            placeholder="Type to search..."
          >
            {defaultValue && <option value="_">All Other Times</option>}
            {selectTimeGroup === "" && (
              <option value="">You have no more options </option>
            )}

            {timeGroupsValues.map((element, i) => (
              <option key={i} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>
          {selectTimeGroup && (
            <Button
              disabled={selectTimeGroup === ""}
              onClick={async () => {
                setActiveTimeGroups(true);
              }}
              className=" mt-3 w-100"
              color="primary"
            >
              Create
            </Button>
          )}
        </>
      )}
    </>
  );
}
