import React, {createContext, useState} from "react";
import {useEffect} from "react";
import {Canvas, Edge, Add} from "reaflow";
import {getEdgeNodes} from "./utils/helpers";

import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "react-zoom-pan-pinch";

import GraphNode from "./GraphNode/GraphNode";
export default function GraphChart({
  layout,
  json,
  activeSidebar,
  setActiveSidebar,
}) {
  const layouts = {
    0: "DOWN",
    1: "RIGHT",
    2: "UP",
    3: "LEFT",
  };

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const [active, setActive] = useState(false);

  const [size, setSize] = React.useState({
    width: 2000,
    height: 2000,
  });

  useEffect(() => {
    const {nodes, edges} = getEdgeNodes(JSON.parse(json), true);
    setNodes(nodes);
    setEdges(edges);
  }, [json]);

  const onInit = (ref) => {
    /*     ref.zoomIn(); */
    console.log("ref", ref);
    /*   updateSetting("zoomPanPinch", ref); */
  };

  const onLayoutChange = (layout) => {
    if (layout.width && layout.height)
      setSize({width: layout.width, height: layout.height});
  };

  const wheelOptions = {
    step: 0.05,
  };

  return (
    <div style={{height: "70vh"}}>
      <TransformWrapper
        maxScale={1.8}
        minScale={0.4}
        initialScale={1}
        /*   initialPositionX={500}
        initialPositionY={500} */
        wheel={wheelOptions}
        onInit={onInit}
        centerOnInit={true}
      >
        <TransformComponent
          wrapperStyle={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Canvas
            maxWidth={size.width + 100}
            maxHeight={size.height + 100}
            readonly
            zoomable={false}
            direction={layouts[layout]}
            key={layout}
            nodes={nodes}
            edges={edges}
            edge={
              <Edge
                add={<Add hidden={false} />}
                onAdd={(event, edge) => {
                  console.log(event, edge);
                }}
              />
            }
            onLayoutChange={onLayoutChange}
            node={(props) => (
              <GraphNode
                setActiveSidebar={setActiveSidebar}
                onClick={(e) => {
                  if (props.properties.text?.module)
                    setActiveSidebar(props.properties);
                }}
                {...props}
              />
            )}
          />
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
}
