import React, { useState, useEffect } from "react";
import { Row, Col, Table, Badge, Spinner } from "reactstrap";
import ButtonHover from "../../../../../../components/utils/ButtonHover";
import EditIcon from "@material-ui/icons/Edit";
import ModalUtility from "./Modal/ModalUtility";
import petition_get from "../../../../../petitions/petition_get";
export default function CallPhoneNumbers() {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [modal, setModal] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [loading, setLoading] = useState(false);
  const onClickModal = (number) => {
    setModal(number);
  };

  useEffect(() => {
    const petitions = async () => {
      petition_get("viewNumbers", { id: id })
        .then(({ data: result }) => {
          setNumbers(result.result)
          setLoading(true);
        })
        .catch((error) => console.log(error));
    };
    petitions();
  }, []);

  return (
    <Row>
      <ModalUtility modal={modal} setModal={setModal} />
      <Col sm="12">
        {loading ? (
          <Table responsive>
            <thead className="thead-light">
              <tr>
                <th style={{ textAlign: "center" }}>Phone Numbers</th>
                {/*   <th style={{textAlign: "center"}}>Caller Id Prefix</th> */}
                {/* <th style={{maxWidth:"5em"}}>PAYMENT STATUS</th> */}
                {/*  <th style={{textAlign: "center"}}>Assigned To</th>
                <th style={{textAlign: "center"}}>E911 Enabled</th> */}
                <th style={{ textAlign: "center" }}>Actions</th>
              </tr>
            </thead>
            <tbody>

              {numbers.map((element, i) => {
                return (
                  <tr key={i}>
                    <td style={{ textAlign: "center" }}>{element.number}</td>
                    {/*  <td style={{textAlign: "center"}}>ABC Company</td>
                    <td style={{textAlign: "center"}}>{element.assigned_to}</td>
                    <td style={{textAlign: "center"}}>
                      {" "}
                      {element.state === "in_service" ? (
                        <Badge color="success">Enabled</Badge>
                      ) : (
                        <Badge color="danger">Disable</Badge>
                      )}
                    </td> */}
                    <td style={{ textAlign: "center" }}>
                      <ButtonHover
                        onClick={() => {
                          onClickModal(element.number);
                        }}
                      >
                        <EditIcon />
                      </ButtonHover>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Spinner
              animation="grow"
              style={{
                margin: "10vh auto",
                height: "10vh",
                width: "10vh",
              }}
            />{" "}
          </div>
        )}
      </Col>
    </Row>
  );
}
