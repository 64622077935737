import axios from "axios";
export default function post_user(key, value) {
  const json = require("../../config.json");
  const urlBase = json.urlBase;
  const token = JSON.parse(localStorage.getItem("authUser"));
  var url;
  var data;
  if (token.access_token) {
    switch (key) {
      case "profile":
        url = urlBase + "/crm/profile";
        data = value;
        break;
      case "NewCreditcard":
        url = urlBase + "/crm/billing/account/" + value[0];
        data = value[1];
        break;
      case "editUser":
        url = urlBase + `/itpvoice/v2/${value[0].id}/users/${value[0].user_id}`;
        data = value[1];
        break;
      case "viewNumberDetails":
        url =
          urlBase +
          `/itpvoice/v2/${value.id}/my-extension/numbers/${value.number}`;
        data = value.data;
        break;
      case "numberEdit":
        url =
          urlBase + `/itpvoice/v2/${value[0].id}/numbers/${value[0].number}`;
        data = value[1];
        break;
      case "editFaxbox":
        url =
          urlBase +
          `/itpvoice/v2/${value.id}/my-extension/faxbox/${value.number}`;
        data = value.data;
        break;
      case "viewCallSettings":
        url = urlBase + `/itpvoice/v2/${value.id}/my-extension`;
        data = value.data;
        break;
      case "callHandling":
        url = urlBase + `/itpvoice/v2/${value.id}/my-extension/callflows`;
        data = value.data;
        break;
      case "callflowsDetails":
        url = urlBase + `/itpvoice/v2/${value.id}/callflows/${value.callflow}`;
        data = value.data;
        break;
      case "callForward":
        url = urlBase + `/itpvoice/v2/${value.id}/my-extension/users`;
        data = value.data;
        break;
      case "callerIdExternal":
        url = urlBase + `/itpvoice/v2/${value.id}/my-extension/users`;
        data = value.data;
        break;
      case "ringGroup":
        url =
          urlBase +
          `/itpvoice/v2/${value.id}/ring-groups/${value.ring_group_id}`;
        data = value.data;
        break;
      case "QueuesDetails":
        url = urlBase + `/itpvoice/v2/${value.id}/queues/${value.idQueque}`;
        data = value.data;
        break;
      case "editCards":
        url = urlBase + `/itpvoice/v2/${value.id}/my-extension/users`;
        data = value.data;
        break;
      case "changeAccount":
        url = urlBase + `/itpvoice/v2/${value.id}/action`;
        data = value.data;
        break;
      case "updateAccountSettings":
        url = urlBase + `/itpvoice/v2/${value.id}`;
        console.log(value);
        data = value.data;
        break;
      case "updateContactList":
        url =
          urlBase +
          `/itpvoice/v2/${value.id}/my-extension/contacts/${value.contact_id}`;
        data = value.data;
        break;
      case "userDetails":
        url = urlBase + `/itpvoice/v2/${value.id}/my-extension/users`;
        data = value.data;
        break;
      case "deviceEdit":
        url =
          urlBase +
          `/itpvoice/v2/${value.id}/my-extension/devices/${value.device_id}`;
        data = value.data;
        break;
      case "deviceEditCallflow":
        url = urlBase + `/itpvoice/v2/${value.id}/devices/${value.deviceId}`;
        data = value.data;
        break;
      case "updateAccontNumber":
        url = urlBase + `/itpvoice/v2/${value.id}`;
        data = value.data;
        break;
      case "timeGroup":
        url = urlBase + `/itpvoice/v2/${value.id}/time-groups/${value.time_id}`;

        data = value.data;
        break;
      case "timeDate":
        url = urlBase + `/itpvoice/v2/${value.id}/time-dates/${value.time_id}`;

        data = value.data;
        break;
      case "callflowEdit":
        url =
          urlBase + `/itpvoice/v2/${value.id}/callflows/${value.callflow_id}`;
        data = value.data;
        break;
      case "permissions":
        url =
          urlBase +
          `/itpvoice/v2/${value.id}/app-store/installed-apps/${value.app_id}/permissions`;
        data = value.data;
        break;
      case "updateMenu":
        url = urlBase + `/itpvoice/v2/${value.id}/menus/${value.menuId}`;
        data = value.data;
        break;
      case "queueAgents":
        url =
          urlBase + `/itpvoice/v2/${value.id}/queues/${value.idQueque}/roster`;
        data = value.data;
        break;
      case "updateAgentStatus":
        url =
          urlBase + `/itpvoice/v2/${value.id}/agents/${value.user_id}/status`;
        data = value.data;
        break;
      case "updateCallflowDisturb":
        url = urlBase + `/itpvoice/v2/${value.id}/my-extension/callflow`;
        data = value.data;
        break;
      case "updateCallflowDisturbDashboard":
        url =
          urlBase +
          `/itpvoice/v2/${value.id}/my-extension/callflow/${value.callflow_id}`;
        data = value.data;
        break;
      case "updateVoicemailBox":
        url =
          urlBase +
          `/itpvoice/v2/${value.id}/voicemails/${value.voicemail_box_id}`;
        data = value.data;
        break;
      case "updateReadChat":
        url =
          urlBase +
          `/itpvoice/v2/${value.id}/my-extension/chat/sms/${value.number}/${value.thread}`;
        data = value.data;
        break;
      case "updateNote":
        url =
          urlBase + `/itpvoice/v2/${value.id}/callnotes/${value.callnote_id}`;
        data = value.data;
        break;
      case "updateContactGroups":
        url =
          urlBase +
          `/itpvoice/v2/${value.id}/my-extension/contact-groups/${value.group_id}`;
        data = value.data;
        break;
      case "timeRules":
        url = urlBase + `/itpvoice/v2/${value.id}/time-rules/${value.time_id}`;
        data = value.data;
        break;
      case "orderItemUpdate":
        url = urlBase + `/crm/orderitems/${value.order_item_id}`;
        data = value.data;
        break;
      case "QueuesDetailsMembers":
        url =
          urlBase +
          `/itpvoice/v2/${value.id}/queues/${value.queue_id}/members/${value.member_id}`;
        data = value.data;
        break;
      case "updateWebhooks":
        url = urlBase + `/itpvoice/v2/${value.id}/webhooks/${value.webhookId}`;
        data = value.data;
        break;
      default:
        return "error";
    }
    var config = {
      headers: {
        Authorization: "Bearer " + token.access_token.replace(/['"]+/g, ""),
      },
    };

    return axios.patch(url, data, config);
  } else {
    window.location.href = "./profile";
  }
}
