import React from 'react'
import { Card, Row, Col, Button } from "reactstrap"
import ButtonHover from '../../../../components/utils/ButtonHover';
import GetAppIcon from '@material-ui/icons/GetApp';
export default function FaxSelectedItem({ item, setModal, formatData }) {
    return (
        <Card body className="fax-content">

            <div className="px-3">
                <div className="w-100 d-flex justify-content-between pt-3">
                    <h3 className="text-capitalize fax-number">{item.from_callerid_name} {item.from_callerid_number}</h3>

                    <div className="d-flex fax-download">

                        <ButtonHover onClick={() => { setModal(item) }} className="pr-3 custom-pointer" ><GetAppIcon />  </ButtonHover>
                    </div>
                </div>
            </div>

            <div className=" p-3 mt-3 d-flex flex-wrap items-center justify-content-center" >
                <div className="col-6 p-2  " >
                    <div className="bg-primary-opacity overflow-hidden  rounded-pill p-2 d-flex justify-content-between align-items-center ">
                        <div className="bg-primary rounded-pill p-2  text-white d-flex justify-content-center align-items-center " style={{ fontWeight: "bold", fontSize: "13px" }}> From name: </div>
                        <span className="px-1 text-white">{item.from_callerid_name}</span>
                    </div>
                </div>
                <div className="col-6 p-2  " >


                    <div className="bg-primary-opacity overflow-hidden rounded-pill p-2 d-flex justify-content-between align-items-center ">
                        <div className="bg-primary rounded-pill p-2  text-white d-flex justify-content-center align-items-center " style={{ fontWeight: "bold", fontSize: "13px" }}> From number: </div>
                        <span className="px-1 text-white">{item.from_callerid_number}</span>
                    </div>
                </div>
                <div className="col-6 p-2  " >

                    <div className="bg-primary-opacity overflow-hidden rounded-pill p-2 d-flex justify-content-between align-items-center ">
                        <div className="bg-primary rounded-pill p-2  text-white d-flex justify-content-center align-items-center " style={{ fontWeight: "bold", fontSize: "13px" }}> To name: </div>
                        <span className="px-1 text-white">{item.to_callerid_name}</span>
                    </div>
                </div>

                <div className="col-6 p-2  " >

                    <div className="bg-primary-opacity overflow-hidden rounded-pill p-2 d-flex justify-content-between align-items-center ">
                        <div className="bg-primary rounded-pill p-2  text-white d-flex justify-content-center align-items-center " style={{ fontWeight: "bold", fontSize: "13px" }}> To number: </div>
                        <span className="px-1 text-white">{item.to_callerid_number}</span>
                    </div>
                </div>
                <div className="col-6 p-2  " >

                    <div className="bg-primary-opacity overflow-hidden rounded-pill p-2 d-flex justify-content-between align-items-center ">
                        <div className="bg-primary rounded-pill p-2  text-white d-flex justify-content-center align-items-center " style={{ fontWeight: "bold", fontSize: "13px" }}> Status: </div>
                        <span className="px-1 text-white">{item.status}</span>
                    </div>
                </div>
                <div className="col-6 p-2  " >

                    <div className="bg-primary-opacity overflow-hidden rounded-pill p-2 d-flex justify-content-between align-items-center ">
                        <div className="bg-primary rounded-pill p-2  text-white d-flex justify-content-center align-items-center " style={{ fontWeight: "bold", fontSize: "13px" }}> Date: </div>
                        <span className="px-1 text-white">{formatData(item.faxdate)}</span>
                    </div>
                </div>


            </div>







        </Card >
    )
}
