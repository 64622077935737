import React, { useEffect, useState } from 'react'
import toastr from "toastr";
import GetAppIcon from "@material-ui/icons/GetApp";
import petition_get from '../pages/petitions/petition_get'
export default function useFax() {

    const [loading, setLoading] = useState(false)

    toastr.options = {
        showDuration: 300,
    };
    const params = new URL(document.location).searchParams;
    const id = params.get("id");
    const [modal, setModal] = useState(false);
    const [modalDownload, setModalDownload] = useState(false);
    const [data, setData] = useState(null)
    const [dataTable, setDataTable] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)
    const [userData, setUserData] = useState(null)

    const [filterKeys] = useState(["userCallName", "userCallNumber", "direction", "duration_seconds", "unix_timestamp"])


    useEffect(() => {
        getFaxData();
        getUserData();
    }, []);


    let getUserData = () => {
        petition_get("userDetails", { id: id })
            .then(({ data: result }) => setUserData(result.result))
            .catch((error) => console.log(error.response));
    }



    const formatData = (val) => {
        let time = new Date(val);
        let timeZoneTemp = time.toLocaleString('en-US', { timeZone: userData?.timezone })
        return timeZoneTemp
    }


    const downloadPdf = async () => {


        setLoading(true)

        const token = JSON.parse(localStorage.getItem("authUser"));
        const json = require("../config.json")
        const urlBase = json.prod ? "https://api.itpscorp.com/portal/itpvoice/v2/" : "https://api.itpscorp.com/dev/portal/itpvoice/v2/"


        const downloadFile = async () =>
            fetch(`${urlBase}${id}/fax/${modalDownload.pk}/${modalDownload.pdf}?token=${token.access_token}`).then((res) => res.blob());
        const createURL = (blob) => URL.createObjectURL(blob);

        const blob = await downloadFile();
        const create = createURL(blob);

        let link = document.createElement("a");
        link.download = `${modalDownload.pdf}.pdf`
        link.href = create;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        setModalDownload(false);
        setLoading(false)

    }


    const onChangeSelectedItem = (item) => { setSelectedItem(item) }

    const getFaxData = () => {
        petition_get("fax", { id: id })
            .then(({ data: result }) => {

                setSelectedItem(result.result[0])
                setData(result.result)
                /*  setDataTable({
                     title: [
                         { title: "From name", key: "from_callerid_name" },
                         { title: "From number", key: "from_callerid_number" },
                         { title: "To name", key: "to_callerid_number" },
                         { title: "To Number", key: "to_callerid_name" },
                         { title: "Status", key: "status" },
                         { title: "Date", key: "faxdate" },
 
                     ],
                     content: [...result.result],
                     actions: {
                         title: "Actions",
                         content: [
                             {
                                 icon: <GetAppIcon />,  //Svg icon
                                 handleClick: (e) => { setModalDelete(e) },  //Function
                             },
                         ],
                     },
                     search: {
                         label: "Search Fax"
                     },
                     addButton: {
                         label: "Send Fax",
                         handleClick: () => { setModal(true) }
                     }
                 }) */
            })
            .catch((error) => console.log(error));
    }

    return { userData, formatData, onChangeSelectedItem, selectedItem, filterKeys, loading, data, getFaxData, modal, setModal, setModalDownload, modalDownload, downloadPdf }
}
