import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  Spinner,
} from "reactstrap";

//Import Images

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ModalAddImages from "./ModalAddImages"
import ModalViewImage from "./ModalViewImage"
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ModalUtilityAddContact from "../ModalUtility/ModalAddContact"
import toastr from "toastr";
import petition_get from "../../../petitions/petition_get"


import { scroller, Element } from "react-scroll";

export default function ContainerChat({
  Chat_Box_Username,
  search_Menu,
  toggleSearch,
  toggleOther,
  onChangeSearch,
  formSearch,
  onClickActive,
  other_Menu,
  divRef,
  setActiveSearch,
  activeSearch,
  PerfectScrollbar,
  messages,
  curMessage,
  setCurMessage,
  addMessage,
  loadingSend,
  setFormSearch,
  loadingChat,
  imgMessages,
  setImgMessages,
  deleteMessage,
  chatContact,
  setChatContact,
  setChats, chats, numberConnect
}) {



  const [modalImage, setModalImage] = useState(false)
  const [contactAddModal, setContactAddModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [reloadData, setReloadData] = useState(false)


  //Get Api_id
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const token = JSON.parse(localStorage.getItem("authUser"));


  const handleContact = () => {

    setContactAddModal(parseInt(Chat_Box_Username))
  }



  useEffect(() => {

    if (window.innerWidth < 850 && Chat_Box_Username) {
      scroller.scrollTo("chat", {
        duration: 500,
        smooth: true,
      });
    }
  }, [Chat_Box_Username])



  useEffect(() => {


    if (reloadData) {


      petition_get("contacts", { id })
        .then(({ data: result }) => {

          let contactSave = result.result
          let newChats = chats

          newChats.map(element => {
            let findContact = []
            if (element.participants[0].is_self === false || element.participants.length === 1) {
              contactSave.model_list.default_contacts.map(elementTwo => { if (parseInt(elementTwo.internal_number) === parseInt(element.participants[0].number) || parseInt(elementTwo.external_number) === parseInt(element.participants[0].number)) findContact.push(elementTwo) })
              contactSave.model_list.user_contacts.map(elementTwo => { let findNumber = elementTwo.numbers.find(elementThree => parseInt(elementThree.number) === parseInt(element.participants[0].number)); if (findNumber) findContact.push(elementTwo) })

            }
            else {
              contactSave.model_list.default_contacts.map(elementTwo => { if (parseInt(elementTwo.internal_number) === parseInt(element.participants[1].number) || parseInt(elementTwo.external_number) === parseInt(element.participants[1].number)) findContact.push(elementTwo) })
              contactSave.model_list.user_contacts.map(elementTwo => { let findNumber = elementTwo.numbers.find(elementThree => parseInt(elementThree.number) === parseInt(element.participants[1].number)); if (findNumber) findContact.push(elementTwo) })

            }

            if (findContact.length >= 1) element.contact = { ...findContact[0] }
          })

          setChatContact(reloadData)
          setReloadData(false)
          setChats(newChats)

        })
        .catch((error) => console.log(error))
    }

  }, [reloadData])


  const handleUsingDeskPhone = () => {

    if (Chat_Box_Username) {

      petition_get("callUsingDeskPhone", { id, number: Chat_Box_Username })
        .then(({ data: result }) => {
          toastr.success("Action completed", "Success");
        })
        .catch((error) => {
          toastr.error("Action Error", "Error");
          console.log(error)
        })


    }

  }


  const handleUsingWebPhone = () => { numberConnect(Chat_Box_Username) }


  return (
    <Card>
      <ModalUtilityAddContact setReloadData={setReloadData} modal={contactAddModal} setModal={setContactAddModal} />

      <ModalViewImage modal={modal} setModal={setModal} />
      <ModalAddImages setImgMessages={setImgMessages} imgMessages={imgMessages} modal={modalImage} setModal={setModalImage} />
      <div className="p-4 border-bottom ">
        <Row>
          <Col md="6" xs="9" >

            <div className="d-flex align-items-center">
              <h5 className="font-size-15 mb-0">{chatContact || Chat_Box_Username}
              </h5>
              {!chatContact && Chat_Box_Username && <div style={{ cursor: "pointer", marginLeft: "5px" }}> <AddCircleOutlineIcon onClick={() => { handleContact() }} style={{ fontSize: "1.8rem" }} /></div>}

            </div>

          </Col>
          <Col md="6" xs="3">
            <ul className="list-inline user-chat-nav text-right mb-0">
              {/* <li className="list-inline-item d-none d-sm-inline-block">
                <Dropdown isOpen={search_Menu} toggle={toggleSearch}>
                  <DropdownToggle className="btn nav-btn" tag="i">
                    <i className="bx bx-search-alt-2"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-md" right>
                    <Form className="p-3">
                      <FormGroup className="m-0">
                        <InputGroup>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Search ..."
                            aria-label="Recipient's username"
                            onChange={onChangeSearch}
                            value={formSearch}
                          />
                          <InputGroupAddon addonType="append">
                            <Button
                              color="primary"
                              type="submit"
                              onClick={onClickActive}
                            >
                              <i className="mdi mdi-magnify"></i>
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </FormGroup>
                    </Form>
                  </DropdownMenu>
                </Dropdown>
              </li> */}
              {messages.length > 0 && <li className="list-inline-item">
                <Dropdown isOpen={other_Menu} toggle={toggleOther}>
                  <DropdownToggle className="btn nav-btn" tag="i">
                    <i className="bx bx-phone-call"></i>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={handleUsingWebPhone} >
                      <div>   Using Web Phone  </div>
                    </DropdownItem>
                    <DropdownItem onClick={handleUsingDeskPhone}>
                      <div>   Using Desk Phone  </div>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </li>}

            </ul>
          </Col>
        </Row>
      </div>

      <div>
        <div className="chat-conversation p-3">
          <ul className="list-unstyled">
            <PerfectScrollbar
              containerRef={(ref) => {
                divRef.current = ref;
              }}
              style={{ height: "470px" }}
            >
              <li>
                <div className="chat-day-title">
                  <span className="title">Today</span>
                </div>
              </li>
              {loadingChat ? (
                <div className="text-center">
                  <Spinner
                    animation="grow"
                    style={{
                      margin: "10vh auto",
                      height: "10vh",
                      width: "10vh",
                    }}
                  />
                </div>
              ) : (
                <>
                  {" "}
                  {messages.map((message, i) => (
                    <li
                      key={i}
                      className={
                        message.message_participant !== Chat_Box_Username
                          ? "right"
                          : ""
                      }
                    >
                      {message.message_body.includes(activeSearch) && (
                        <div className="conversation-list">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              href="#"
                              className="btn nav-btn"
                              tag="i"
                            >
                              <i className="bx bx-dots-vertical-rounded"></i>
                            </DropdownToggle>
                            <DropdownMenu direction="right">
                              <DropdownItem onClick={() => { deleteMessage(message) }} href="#">Delete</DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          <div className="ctext-wrap" >
                            <div className="conversation-name">
                              {message.message_participant}
                            </div>
                            {message.message_mms_media
                              ? < img onClick={() => { setModal(`https://api.itpscorp.com/portal/itpvoice/${id}/my-extension/chat/media/${message.message_mms_media}?token=${token.access_token.replace(/['"]+/g, "")}`) }} className="img-fluid" style={{ maxWidth: "200px", height: "200px", cursor: "pointer" }} src={`https://api.itpscorp.com/portal/itpvoice/${id}/my-extension/chat/media/${message.message_mms_media}?token=${token.access_token.replace(/['"]+/g, "")}`} alt="message image" />
                              : <p>{message.message_body}</p>}

                            <p className="chat-time mb-0">
                              {message.message_status === "failed" ? <div style={{ color: "#f46a6a" }}><i className="bx bx-error-alt align-middle mr-1"></i> Error in Message  </div> : <>  {message.pending ? <i className="bx bx-time-five align-middle mr-1"></i> : <i className="bx bx-check-double align-middle mr-1"></i>}

                                {message.message_timestamp} </>}


                            </p>
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </>
              )}
              <div ref={divRef}></div>{" "}
            </PerfectScrollbar>
          </ul>
        </div>
        <div className="p-3 chat-input-section">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              addMessage(imgMessages, setImgMessages);
            }}
            action=""
          >
            <Row>
              <Col >
                <div className="position-relative d-flex align-items-center">
                  <input
                    type="text"
                    value={curMessage}
                    onChange={(e) => {
                      setCurMessage(e.target.value);
                    }}
                    className="form-control chat-input pr-0"
                    placeholder="Enter Message..."
                  />

                  <div style={{ position: "relative", cursor: "pointer" }}>

                    {imgMessages.length > 0 && <div className="notification-image">{imgMessages.length}</div>}
                    <AttachFileIcon onClick={() => { setModalImage(true) }} />
                  </div>
                </div>
              </Col>
              <Col className="col-lg-auto col-12 mt-3 mt-lg-0">
                <Element name="chat" className="d-flex  ">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-rounded chat-send w-md waves-effect waves-light text-right w-100"
                    disabled={loadingSend || (curMessage === "" && imgMessages.length === 0)}
                  >
                    <span className=" d-inline-block mr-2">Send</span>{" "}
                    {loadingSend ? (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <i className="mdi mdi-send"></i>
                    )}
                  </Button>
                </Element>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </Card>
  );
}
