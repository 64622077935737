import React, {useState, useEffect} from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import petition_get from "../../../../petitions/petition_get";
import classnames from "classnames";
import ReactTooltip from "react-tooltip";
import InfoIcon from "@material-ui/icons/Info";
import AssignedUserVoice from "./AssignedUser/AssignedUserVoice";
import AssignedUserTextMessage from "./AssignedUser/AssignedUserTextMessage";
import AssignedUserFax from "./AssignedUser/AssignedUserFax";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import toastr from "toastr";
export default function ModalUtility({
  modal,
  setModal,
  elementNumber,
  allUsers,
  setReloadDataEdit,
  defaultNumber,
}) {
  toastr.options = {
    showDuration: 300,
  };

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [loading, setLoading] = useState(false);

  const [customActiveTab, setCustomActiveTab] = useState("1");

  const [activeSubmit, setActiveSubmit] = useState(false);

  const [numberDetails, setNumberDetails] = useState(null);

  const [cname, setCname] = useState("");

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  }

  useEffect(() => {
    setNumberDetails(null);
    setCname(elementNumber.caller_id_name || "");
    petition_get("viewDetails", {id, phoneNumber: elementNumber.number})
      .then(({data: result}) => setNumberDetails(result.result))
      .catch((error) => console.log(error));
  }, [elementNumber]);

  const onSubmitActiveSubmit = () => {
    /*  if (elementNumber.used_by === "callflow" && elementNumber.callflowId)
      return toastr.error(
        "This number is used in one callflow, please change number in the callflow to update",
        "Error"
      ); */

    setActiveSubmit(true);
  };

  return (
    <>
      <Modal
        center
        isOpen={modal === false ? false : true}
        className="info modal-md"
      >
        <ModalHeader className="bg-soft-primary">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h3 className="text-primary">Edit Number</h3>
                <h6>{elementNumber ? elementNumber.number : null}</h6>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={phoneImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <Nav
            tabs
            className="nav-tabs-custom  flex-column flex-md-row  text-center  "
          >
            <NavItem className="pt-3 pt-md-0">
              <NavLink
                style={{cursor: "pointer"}}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                <span>Voice</span>
              </NavLink>
            </NavItem>

            <NavItem className="pt-3 pt-md-0">
              <NavLink
                style={{cursor: "pointer"}}
                className={classnames({
                  active: customActiveTab === "2",
                })}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                <span>Text Messaging</span>
              </NavLink>
            </NavItem>
            <NavItem className="pt-3 pt-md-0">
              <NavLink
                style={{cursor: "pointer"}}
                className={classnames({active: customActiveTab === "3"})}
                onClick={() => {
                  toggleCustom("3");
                }}
              >
                <span>Outbound CNAM</span>
              </NavLink>
            </NavItem>
            <NavItem className="pt-3 pt-md-0">
              <NavLink
                style={{cursor: "pointer"}}
                className={classnames({active: customActiveTab === "4"})}
                onClick={() => {
                  toggleCustom("4");
                }}
              >
                <span>Fax</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={customActiveTab}>
            <TabPane tabId="1" className="p-3">
              <AssignedUserVoice
                cname={cname}
                numberDetails={numberDetails}
                allUsers={allUsers}
                setReloadDataEdit={setReloadDataEdit}
                setLoading={setLoading}
                setModal={setModal}
                elementNumber={elementNumber}
                activeSubmit={activeSubmit}
                setActiveSubmit={setActiveSubmit}
              />
            </TabPane>
            <TabPane tabId="2" className="p-3">
              <AssignedUserTextMessage
                numberDetails={numberDetails}
                elementNumber={elementNumber}
                activeSubmit={activeSubmit}
                setActiveSubmit={setActiveSubmit}
                allUsers={allUsers}
              />
            </TabPane>
            <TabPane tabId="3" className="p-3">
              <label className="m-0 pr-2 d-flex" htmlFor="check1">
                Caller ID Name
                <InfoIcon
                  data-tip
                  data-for="text1"
                  style={{paddingLeft: "0.5rem", fontSize: "21px"}}
                />
              </label>
              <input
                value={cname}
                onChange={(e) => {
                  setCname(e.target.value);
                }}
                type="text"
                className="form-control"
              />
              <ReactTooltip id="text1">
                <div className="text-left" style={{fontSize: "10px"}}>
                  CNAM (Caller ID- NAME), attempts to display this
                  <br />
                  caller ID information to carriers when making an outbound
                  <br />
                  call. Please note, this can take a few weeks to process
                  <br />
                  and some carriers may take longer. There is a one-time
                  <br />
                  charge of $5 to process this change
                </div>
              </ReactTooltip>
            </TabPane>
            <TabPane tabId="4" className="p-3">
              <AssignedUserFax
                numberDetails={numberDetails}
                elementNumber={elementNumber}
                activeSubmit={activeSubmit}
                setActiveSubmit={setActiveSubmit}
                allUsers={allUsers}
              />
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loading}
            outline
            color="secondary"
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </Button>

          <Button
            disabled={loading}
            color="primary"
            className="pl-3 pr-3"
            onClick={onSubmitActiveSubmit}
          >
            {loading && (
              <Spinner
                style={{marginRight: "0.5em"}}
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
