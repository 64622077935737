import React, {useEffect, useState} from "react";

import petition_get from "../../../petitions/petition_get";
import CustomTable from "../../../../components/utils/CustomTable/CustomTable";
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum";

import toastr from "toastr";

import GetAppIcon from "@material-ui/icons/GetApp";

import ModalUtililyAdd from "./ModalUtility/ModalUtilityAdd";
import ModalUtilityDownload from "./ModalUtility/ModalUtilityDownload";

export default function MusicOnHold() {
  toastr.options = {showDuration: 300};
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [modalAdd, setModalAdd] = useState(false);
  const [modalDownload, setModalDownload] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [reload, setReload] = useState(false);
  const [dataTable, setDataTable] = useState(null);

  useEffect(() => {
    const petitions = () => {
      setReload(true);
      setDataTable(null);
      petition_get("musicOnHoldMedia", {
        id: id,
      })
        .then(({data: result}) => {
          let dataTableCopy = {
            title: [
              {title: "Name", key: "name"},
              /*      { title: "LANGUAGE", key: "language" }, */
            ],
            content: [...result.result],
            actions: {
              title: "DOWNLOAD",
              content: [
                {
                  icon: <GetAppIcon />, //Svg icon
                  handleClick: (e) => {
                    setModalDownload(e);
                  }, //Function
                },
              ],
            },
            search: {
              label: "Search Menu",
            },
            addButton: {
              label: "Add Media",
              handleClick: () => {
                setModalAdd(true);
              },
            },
          };
          setDataTable(dataTableCopy);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (!reload) petitions();
  }, [reload]);

  const download = async (element) => {
    setSpinner(true);

    const token = JSON.parse(localStorage.getItem("authUser"));
    const json = require("../../../../config.json");
    const urlBase = json.prod
      ? "https://api.itpscorp.com/portal/itpvoice/v2/"
      : "https://api.itpscorp.com/dev/portal/itpvoice/v2/";

    const downloadFile = async () =>
      fetch(
        `${urlBase}${id}/media/${element.pk}/${element.media_files[0].original_file}?token=${token.access_token}&&accept=audio/mpeg`
      ).then((res) => res.blob());
    const createURL = (blob) => URL.createObjectURL(blob);

    const blob = await downloadFile();
    console.log(blob);
    const create = createURL(blob);
    console.log(create);

    let link = document.createElement("a");
    link.download = `audio.mp3`;
    link.href = create;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();

    setSpinner(false);
    setModalDownload(false);
  };

  return (
    <>
      <ModalUtilityDownload
        modal={modalDownload}
        setModal={setModalDownload}
        download={download}
        loading={spinner}
      />
      <ModalUtililyAdd
        setReloadMusic={setReload}
        modal={modalAdd}
        setModal={setModalAdd}
      />
      <div className="page-content">
        <CustomBreadCrum
          title="Your Phone System"
          subtitle="Media / Audio File"
        />
        <CustomTable data={dataTable} />
      </div>
    </>
  );
}
