import React, {useState, useEffect} from "react";
import {
  Modal,
  ModalHeader,
  Row,
  Col,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
  Label,
  Input,
} from "reactstrap";
import phoneImg from "../../../../../../assets/images/phone-image.svg";

export default function AddKeyModals({
  modal,
  loading,
  setModal,
  updateWebhooks,
}) {
  const [form, setForm] = useState({
    name: "",
    url: "",
    http_method: "GET",
    hook: "USER_DIAL_HANGUP",
    retries: "1",
  });

  useEffect(() => {
    if (modal) {
      setForm({...modal});
    }
  }, [modal]);

  const onChange = (e) => setForm({...form, [e.target.name]: e.target.value});

  return (
    <Modal
      centered
      isOpen={modal === false ? false : true}
      className="info modal-md overflowActive"
    >
      <ModalHeader className="bg-soft-primary">
        <Row>
          <Col xs="9">
            <div className="text-primary p-3">
              <h3 className="text-primary">Add Webhooks</h3>
            </div>
          </Col>
          <Col xs="3" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <div style={{textAlign: "center"}}>
            <Spinner
              animation="grow"
              style={{
                margin: "10vh auto",
                height: "10vh",
                width: "10vh",
              }}
            />
          </div>
        ) : (
          <Row>
            <Col className=" col-12 mt-4">
              <Label htmlFor="number">Enter name</Label>
              <Input
                id="name"
                name="name"
                onChange={onChange}
                value={form.name}
                type="text"
              />
            </Col>
            <Col className=" col-12 mt-4">
              <Label htmlFor="number">Enter url</Label>
              <Input
                id="url"
                name="url"
                onChange={onChange}
                value={form.url}
                type="url"
              />
            </Col>
            <Col className=" col-12 mt-4">
              <Label htmlFor="number">Enter http metrod</Label>
              <select
                id="http_method"
                name="http_method"
                onChange={onChange}
                className="form-control"
                value={form.http_method}
              >
                <option value="GET"> GET </option>
                <option value="POST"> POST</option>
              </select>
            </Col>
            <Col className=" col-12 mt-4">
              <Label htmlFor="number">Enter Hook</Label>
              <select
                id="hook"
                name="hook"
                className="form-control"
                onChange={onChange}
                value={form.hook}
              >
                <option value="USER_DIAL_HANGUP"> USER_DIAL_HANGUP </option>
                <option value="USER_DIAL_NOANSWER"> USER_DIAL_NOANSWER</option>
                <option value="USER_DIAL"> USER_DIAL</option>
                <option value="QUEUE_MEMBER_DIAL"> QUEUE_MEMBER_DIAL</option>
                <option value="QUEUE_MEMBER_DIAL_HANGUP">
                  QUEUE_MEMBER_DIAL_HANGUP
                </option>
                <option value="QUEUE_HANGUP"> QUEUE_HANGUP</option>
		<option value="INCOMING_EXTERNAL_CALL"> Incoming External Call</option>
		<option value="SMS_RECEIVED"> Incoming Text Message</option>
                <option value="SMS_DELIVERED"> Delivered Text Message</option>
              </select>
            </Col>
            <Col className=" col-12 mt-4">
              <Label htmlFor="number">Enter retries</Label>
              <select
                id="retries"
                name="retries"
                onChange={onChange}
                value={form.retries}
                className="form-control"
              >
                <option value="1"> 1 </option>
                <option value="2"> 2</option>
                <option value="3"> 3</option>
              </select>
            </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={loading}
          outline
          color="secondary"
          onClick={() => {
            setModal(false);
          }}
        >
          Close
        </Button>

        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            updateWebhooks(form);
          }}
        >
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
}
