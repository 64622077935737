import React, { useState } from "react";
import { Col, Row, Label } from "reactstrap";
import Switch from "react-switch";
export default function AdvancedSettings({ form, setForm }) {

  const [switchOne, setSwitchOne] = useState(true);
  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  return (
    <div>
      <div className="col-12 ">
        <Label htmlFor="timeout" className="custom-pointer m-0 mt-4 p-0" > Timeout: </Label>
        <input
          value={form.timeout}
          onChange={onChange}
          id="timeout"
          type="number"
          name="timeout"
          className="form-control col-12 col-lg-6"
        />
      </div>
      <div className="col-12 mt-2 mt-lg-0">
        <Label htmlFor="ringinuse" className="custom-pointer m-0 mt-4 col-12 p-0" > Ring in Use: </Label>
        <Switch
          uncheckedIcon={<Offsymbol />}
          className="mr-1"
          checkedIcon={<OnSymbol />}
          onColor="#626ed4"
          onChange={() => {
            setForm({ ...form, ringinuse: form.ringinuse === "yes" ? "no" : "yes" });
          }}
          checked={form.ringinuse === "yes" ? true : false}
        />
      </div>
      {/*  <div className="col-12 ">
        <Label htmlFor="queue_youarenext" className="custom-pointer m-0 mt-4 p-0" > Queue You are Next: </Label>
        <input
          value={form.queue_youarenext}
          onChange={onChange}
          id="queue_youarenext"
          type="text"
          name="queue_youarenext"
          className="form-control col-12 col-lg-6"
        />
      </div> */}
      <div className="col-12 mt-2 mt-lg-0">
        <Label htmlFor="autopause " className="custom-pointer m-0 mt-4 col-12 p-0" > Auto Pause: </Label>
        <Switch
          uncheckedIcon={<Offsymbol />}
          className="mr-1"
          checkedIcon={<OnSymbol />}
          onColor="#626ed4"
          onChange={() => {
            setForm({ ...form, autopause: form.autopause === "yes" ? "no" : "yes" });
          }}
          checked={form.autopause === "yes" ? true : false}
        />
      </div>
      <div className="col-12 ">
        <Label htmlFor="strategy" className="custom-pointer m-0 mt-4 p-0" > strategy: </Label>
        <select
          value={form.strategy}
          onChange={onChange}
          id="strategy"
          type="number"
          name="strategy"
          className="form-control col-12 col-lg-6"
        >
          <option value="ringall">Ring all</option>
          <option value="leastrecent">Least Recent</option>
          <option value="fewestcalls">Fewest Calls</option>
          <option value="random">Random</option>
          <option value="rrmemory">RR Memory</option>
          <option value="linear">Linear</option>
          <option value="wrandom">Wrandom</option>
          <option value="rrordered">RR Ordered</option>
        </select>
      </div>
      <div className="col-12 mt-2 mt-lg-0">
        <Label htmlFor="joinempty " className="custom-pointer m-0 mt-4 col-12 p-0" > Join Empty: </Label>
        <Switch
          uncheckedIcon={<Offsymbol />}
          className="mr-1"
          checkedIcon={<OnSymbol />}
          onColor="#626ed4"
          onChange={() => {
            setForm({ ...form, joinempty: form.joinempty === "yes" ? "no" : "yes" });
          }}
          checked={form.joinempty === "yes" ? true : false}
        />
      </div>
      <div className="col-12 mt-2 mt-lg-0">
        <Label htmlFor="leavewhenempty" className="custom-pointer m-0 mt-4 col-12 p-0" > Leave When Empty: </Label>
        <Switch
          uncheckedIcon={<Offsymbol />}
          className="mr-1"
          checkedIcon={<OnSymbol />}
          onColor="#626ed4"
          onChange={() => {
            setForm({ ...form, leavewhenempty: form.leavewhenempty === "yes" ? "no" : "yes" });
          }}
          checked={form.leavewhenempty === "yes" ? true : false}
        />
      </div>
      <div className="col-12 mt-2 mt-lg-0">
        <Label htmlFor="queue_args" className="custom-pointer m-0 mt-4 col-12 p-0" > Ring instead of Playing Music: </Label>
        <Switch
          uncheckedIcon={<Offsymbol />}
          className="mr-1"
          checkedIcon={<OnSymbol />}
          onColor="#626ed4"
          onChange={() => {
            setForm({ ...form, queue_args: form.queue_args === "r" ? "" : "r" });
          }}
          checked={form.queue_args === "r" ? true : false}
        />
      </div>

    </div>
  );
}
