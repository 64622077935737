import React, { Component } from "react";
import {
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Steps, Hints } from "intro.js-react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import petition_get from "../../pages/petitions/petition_get";
import ModalUtil from "../../pages/Fiber/SelectFiberModalTwo";
import SelectFiberModal from "../../pages/Fiber/SelectFiberModal";
import classname from "classnames";
import { ServiceStyled } from "./Style/Style";
import Tilt from "react-tilt";
import ModalUtilityLegacy from "./ModalUtility/ModalUtilityLegacy";
import "../../assets/intro/index.css";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.state = {
      isSearch: false,
      typeServices: [],
      onlyFiber: [],
      onlyVoice: <h1>test</h1>,
      ModalVoice: false,
      ModalLegacy: false,
      SelectFiberModal: false,
      ModalUtil: false,
      ModalUtilLegacy: false,
      servicesITPVoice: [],
      servicesITPVoiceLegacy: [],
      selectedService: "",
      loading: false,
    };

    this.steps = [
      {
        title: "welcome",
        intro: "Hello World! 👋",
      },
      {
        element: ".billing",
        intro: "Billing Info 1",
      },
      {
        element: ".myServices",
        intro: "World step 2",
      },
      {
        element: ".profile",
        intro: "World step 3",
      },
      {
        element: ".myServices2",
        intro: "World step 4",
      },
    ];
  }
  SelectFiberModalClose = () => {
    this.setState({ SelectFiberModal: false });
  };
  ModalVoiceClose = () => {
    this.setState({ ModalVoice: false });
  };

  ModalUtilClose = () => {
    this.setState({ ModalUtil: false, selectedService: "" });
  };
  ModalUtilCloseLegacy = () => {
    this.setState({ ModalUtilLegacy: false, selectedService: "" });
  };
  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch });
  };
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }
  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  BillingOption() {
    return (
      <button
        onClick={() => {
          window.location.href = "/Billing";
        }}
        className="btn header-item waves-effect"
      >
        Billing
      </button>
    );
  }
  componentDidMount() {
    let services = [];
    let onlyFiber = [];
    let servicesITPVoice = [];
    let servicesITPVoiceLegacy = [];
    petition_get("services", "")
      .then(({ data: { result } }) => {
        result.forEach((element, i) => {
          if (
            services.find(
              (serv) => serv.category === element.product.category
            ) === undefined
          ) {
            if (
              element.product.category === "Cloud" ||
              element.product.category === "Phone" ||
              element.product.category === "Fiber SMB" ||
              element.product.category === "Fiber DIA" ||
              element.product.category === "Voice" ||
              element.product.category === "Voice Legacy"
            ) {
              services.push({
                category: element.product.category,
                pk: element.pk,
                api_id: element.api_id,
              });
              /*   services.push(element.product.category); */
            }
          }
          if (
            element.product.category === "Fiber SMB" ||
            element.product.category === "Fiber DIA"
          ) {
            onlyFiber.push(element);
          }
        });
        result.forEach((element, i) => {
          if (element.product.category === "Voice") {
            servicesITPVoice.push(element);
          }
          if (
            element.product.category === "Fiber SMB" ||
            element.product.category === "Fiber DIA"
          ) {
            onlyFiber.push(element);
          }
          if (element.product.category === "Voice Legacy") {
            servicesITPVoiceLegacy.push(element);
          }
        });

        if (servicesITPVoice.length === 1 && servicesITPVoiceLegacy.length === 0) {

          return window.location.href = "/DashboardUsers?id=" + servicesITPVoice[0].api_id;

        }


        this.setState({
          loading: true,
          typeServices: services,
          onlyFiber: onlyFiber,
          servicesITPVoice: servicesITPVoice,
          servicesITPVoiceLegacy: servicesITPVoiceLegacy,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  selectedVoice() {
    this.setState({
      ModalUtil: true,
      ModalVoice: true,
      selectedService: "itp_voice",
    });
  }
  selectedLegacy() {
    this.setState({
      ModalUtilLegacy: true,
      ModalLegacy: true,
      selectedService: "itp_voice_legacy",
    });
  }

  menuOptions() {
    return (
      <Card className="menuMyServicesTwo">
        <CardBody>
          {!this.state.loading ? (
            <div style={{ textAlign: "center" }}>
              <Spinner
                animation="grow"
                style={{
                  margin: "10vh auto",
                  height: "10vh",
                  width: "10vh",
                }}
              />{" "}
            </div>
          ) : (
            <Row className="no-gutters " style={{ justifyContent: "center" }}>
              <div
              // style={{
              //   display:
              //     this.state.typeServices.find(
              //       (serv) => serv.category === "Fiber SMB"
              //     ) !== undefined ||
              //     this.state.typeServices.find(
              //       (serv) => serv.category === "Fiber Dia"
              //     ) !== undefined
              //       ? null
              //       : "none",
              // }}
              >
                {/* <Link className="dropdown-icon-item" to="/fiberSMB">
                    <i
                      className="fas fa-network-wired"
                      onClick={() => {}}
                      style={{ fontSize: "1.5em" }}
                    ></i>
                    <span>Fiber Internet</span>
                  </Link> */}

                <ServiceStyled className="">
                  <Link
                    style={{ border: "none", width: "auto", padding: "0" }}
                    className="dropdown-icon-item"
                    onClick={() => {
                      // this.setState({ SelectFiberModal: true });
                      if (
                        this.state.typeServices.find(
                          (serv) => serv.category === "Fiber SMB"
                        ) !== undefined ||
                        this.state.typeServices.find(
                          (serv) => serv.category === "Fiber Dia"
                        ) !== undefined
                      ) {
                        this.setState({ ModalUtil: true });
                      } else {
                      }
                    }}
                  >
                    <div className="glass-image">
                      {" "}
                      <img
                        src={require("../../assets/images/fiber.jpg")}
                        alt="dropbox"
                      />
                    </div>
                    <Tilt options={{ max: 0 }} className="data Tilt">
                      {(this.state.typeServices.find(
                        (serv) => serv.category === "Fiber SMB"
                      ) === undefined ||
                        this.state.typeServices.find(
                          (serv) => serv.category === "Fiber Dia"
                        ) !== undefined) && (
                          <div className="get-out"> Order Now </div>
                        )}
                      <p className="Tilt-inner">
                        <span> Fiber Internet</span>
                      </p>
                    </Tilt>{" "}
                  </Link>
                </ServiceStyled>
              </div>
              {/* <div
          style={{
            display:
              this.state.typeServices.find(
                (serv) => serv.category === "Phone"
              ) !== undefined
                ? null
                : "none",
          }}
        >
          <ServiceStyled className="">
            <Link
              style={{border: "none", width: "auto", padding: "0"}}
              className="dropdown-icon-item"
              to="/Phone"
            >
              <div className="glass-image">
                {" "}
                <img
                  src={require("../../assets/images/voice.png")}
                  alt="dropbox"
                />
              </div>
              <Tilt options={{max: 0}} className="data Tilt">
                <p className="Tilt-inner">
                  <span>Phone </span>
                </p>
              </Tilt>{" "}
            </Link>
          </ServiceStyled>
        </div> */}
              {/* <Row className="no-gutters"> */}
              <div
              // style={{
              //   display:
              //     this.state.typeServices.find(
              //       (serv) => serv.category === "Cloud"
              //     ) !== undefined
              //       ? null
              //       : "none",
              // }}
              >
                <ServiceStyled className="">
                  <Link
                    style={{ border: "none", width: "auto", padding: "0" }}
                    className="dropdown-icon-item"
                    onClick={() => {
                      // this.setState({ SelectFiberModal: true });
                      if (
                        this.state.typeServices.find(
                          (serv) => serv.category === "Cloud"
                        ) !== undefined
                      ) {
                        window.location.href = "/Cloud";
                      } else {
                      }
                    }}
                  >
                    <div className="glass-image">
                      {" "}
                      <img
                        src={require("../../assets/images/cloud.jpg")}
                        alt="dropbox"
                      />
                    </div>
                    <Tilt options={{ max: 0 }} className="data Tilt">
                      {this.state.typeServices.find(
                        (serv) => serv.category === "Cloud"
                      ) === undefined && (
                          <div className="get-out"> Order Now </div>
                        )}
                      <p className="Tilt-inner">
                        <span>Cloud </span>
                      </p>
                    </Tilt>{" "}
                  </Link>
                </ServiceStyled>
              </div>
              {this.state.typeServices.map((serv) => {
                if (serv.category === "Voice") {
                  return (
                    <div
                      key={serv.pk}
                    // style={{
                    //   display:
                    //     this.state.typeServices.find(
                    //       (serv) => serv.category === "Voice"
                    //     ) !== undefined
                    //       ? null
                    //       : "none",
                    // }}
                    >
                      {" "}
                      <ServiceStyled className="">
                        <Link
                          style={{ border: "none", width: "auto", padding: "0" }}
                          className="dropdown-icon-item"
                          // to={`/ITPVoice?id=${serv.api_id}`}
                          onClick={() => {
                            if (
                              this.state.typeServices.find(
                                (serv) => serv.category === "Voice"
                              ) !== undefined
                            ) {
                              if (this.state.servicesITPVoice.length === 1) {
                                window.location.href =
                                  "/DashboardUsers?id=" +
                                  this.state.servicesITPVoice[0].api_id;
                              } else {
                                this.selectedVoice();
                              }
                            }
                          }}
                        >
                          <div className="glass-image">
                            {" "}
                            <img
                              src={require("../../assets/images/voice.png")}
                              alt="dropbox"
                            />
                          </div>
                          <Tilt options={{ max: 0 }} className="data Tilt">
                            {this.state.typeServices.find(
                              (serv) => serv.category === "Voice"
                            ) === undefined && (
                                <div className="get-out"> Order Now </div>
                              )}

                            <p className="Tilt-inner">
                              <span>ITP Voice </span>
                            </p>
                          </Tilt>{" "}
                        </Link>
                      </ServiceStyled>
                    </div>
                  );
                }
              })}

              {this.state.typeServices.find(
                (serv) => serv.category === "Voice Legacy"
              ) !== undefined && (
                  <div>
                    <ServiceStyled className="">
                      <Link
                        style={{ border: "none", width: "auto", padding: "0" }}
                        className="dropdown-icon-item"
                        //  to={`/DashboardITPVoiceLegacy?id=prueba`}
                        onClick={() => {
                          if (
                            this.state.typeServices.find(
                              (serv) => serv.category === "Voice Legacy"
                            ) !== undefined
                          ) {
                            if (this.state.servicesITPVoiceLegacy.length === 1) {
                              window.location.href =
                                "/DashboardITPVoiceLegacy?id=" +
                                this.state.servicesITPVoiceLegacy[0].pk;
                            } else {
                              this.selectedLegacy();
                            }
                          } else {
                          }
                        }}
                      >
                        <div className="glass-image">
                          {" "}
                          <img
                            src={require("../../assets/images/voice.png")}
                            alt="dropbox"
                          />
                        </div>
                        <Tilt options={{ max: 0 }} className="data Tilt">
                          <p className="Tilt-inner">
                            <span>ITP Voice Legacy </span>
                          </p>
                        </Tilt>{" "}
                      </Link>
                    </ServiceStyled>
                  </div>
                )}
            </Row>
          )}
        </CardBody>
      </Card>
    );
  }

  render() {
    return (
      <React.Fragment>
        {/* <Steps enabled={true} steps={this.steps} initialStep={0} /> */}
        <SelectFiberModal
          success_msg={this.state.SelectFiberModal}
          title={"test"}
          onCancel={this.SelectFiberModalClose}
        />
        <ModalUtil
          modal={this.state.ModalUtil}
          spinner={false}
          toggle={this.ModalUtilClose}
          cancelButton={"Close"}
          selectedService={this.state.selectedService}
          modalbody={
            this.state.ModalVoice === true
              ? this.state.servicesITPVoice
              : this.state.onlyFiber
          }
        />

        <ModalUtilityLegacy
          modal={this.state.ModalUtilLegacy}
          spinner={false}
          close={this.ModalUtilCloseLegacy}
          selectedService={this.state.selectedService}
          modalbody={this.state.servicesITPVoiceLegacy}
        />
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Home" breadcrumbItem="Home" />
            {this.menuOptions()}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
