import styled from "styled-components";

export const GraphContainer = styled.div`
  border: 2px solid rgba(79, 84, 92, 0.48);
  border-radius: 5px;
`;

export const GraphContent = styled.div`
  position: relative;
  overflow: hidden;
`;

export const GraphTopbarContainer = styled.div`
  height: 50px;

  background: #323232;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;
export const GraphTopbarItem = styled.div`
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`;

export const GraphSidebarContainer = styled.div`
  position: fixed;
  bottom: 20px;

  right: ${(props) => (props.activeSidebar ? "20px" : "-330px")};
  transition: 0.5s all;
  width: 300px;
  padding-bottom: 20px;
  border-radius: 15px;
  background: #323232;

  box-shadow: 0px 0px 10px 1px rgb(255, 255, 255, 0.5);
`;

export const GraphSidebarClose = styled.button`
  border: none;
  outline: none;
  position: absolute;
  background: transparent;
  color: gray;
  top: 5px;
  right: 10px;
  font-size: 20px;
`;
export const GraphSidebarTitle = styled.h3`
  padding-top: 20px;
  text-align: center;
`;
