import React, { useEffect, useState } from 'react'
import { Row, Col, CustomInput, Button, Table } from "reactstrap"
import petition_post from "../../../petitions/petition_post";
import toastr from "toastr";


export default function TimeDate({ activeSubmitTimeDate, setLoading, dataTimeDateId, setModal, setReloadData }) {

    toastr.options = { showDuration: 300 };
    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    useEffect(() => {
        if (activeSubmitTimeDate.activeSubmitTimeDate) {
            activeSubmitTimeDate.setActiveSubmitTimeDate(false)
            if (form.name.trim() === "") toastr.error("Name is Required", "Error");
            /*             else if (form.start_date.trim() === "") toastr.error("Start Date is Required", "Error");
                        else if (form.end_date.trim() === "") toastr.error("End Date is Required", "Error"); */
            else if (form.start_time.trim() === "") toastr.error("Start Time is Required", "Error");
            else if (form.end_time.trim() === "") toastr.error("End Time is Required", "Error");

            else handleSubmit()

        }

    }, [activeSubmitTimeDate.activeSubmitTimeDate])

    const [form, setForm] = useState({
        name: "",
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        start_day_of_week: 0,
        end_day_of_week: 4,
    })

    const handleChange = (e) => { setForm({ ...form, [e.target.name]: e.target.value }) }


    const handleSubmit = async () => {

        let newData = {
            name: form.name,
            start_hour: parseInt(form.start_time.split(":")[0]),
            start_minute: parseInt(form.start_time.split(":")[1]),
            start_month: parseInt(form.start_date.split("-")[1]),
            start_month_day: parseInt(form.start_date.split("-")[2]),
            start_month_year: parseInt(form.start_date.split("-")[0]),
            end_hour: parseInt(form.end_time.split(":")[0]),
            end_minute: parseInt(form.end_time.split(":")[1]),
            end_month: parseInt(form.end_date.split("-")[1]),
            end_month_day: parseInt(form.end_date.split("-")[2]),
            end_month_year: parseInt(form.end_date.split("-")[0]),
            start_day_of_week: parseInt(form.start_day_of_week),
            end_day_of_week: parseInt(form.end_day_of_week),
            timegroup_id: dataTimeDateId
        }


        if (!newData.end_month) delete newData.end_month
        if (!newData.end_month_day) delete newData.end_month_day
        if (!newData.end_month_year) delete newData.end_month_year
        if (!newData.start_month) delete newData.start_month
        if (!newData.start_month_day) delete newData.start_month_day
        if (!newData.start_month_year) delete newData.start_month_year


        petition_post("timeRules", { id, data: newData })
            .then(({ data: result }) => {
                setLoading(false);
                setModal(false);
                toastr.success("Action completed", "Success");
                setReloadData(true);

            })
            .catch((error) => console.log(error));
    }

    const wDaysOptions = [
        { label: "Monday", value: 0 },
        { label: "Tuesday", value: 1 },
        { label: "Wednesday", value: 2 },
        { label: "Thursday", value: 3 },
        { label: "Friday", value: 4 },
        { label: "Saturday", value: 5 },
        { label: "Sunday", value: 6 },
    ]

    return (
        <Row>
            <Col className="col-12">
                <label htmlFor="name"  >
                    Name:
                </label>
                <input
                    onChange={handleChange}
                    name="name"
                    value={form.name}
                    className="form-control"
                    type="text"
                />
            </Col>
            <Col className="col-12 pt-3">
                <label htmlFor="start_day_of_week" > Start Week : </label>
                <select
                    onChange={handleChange}
                    name="start_day_of_week"
                    value={form.start_day_of_week}
                    className="form-control"
                    type="date"
                >
                    {wDaysOptions.map(element => <option value={element.value} >{element.label}</option>)}
                </select>
            </Col>
            <Col className="col-12 pt-3">
                <label htmlFor="end_day_of_week" > End Week : </label>
                <select
                    onChange={handleChange}
                    name="end_day_of_week"
                    value={form.end_day_of_week}
                    className="form-control"
                    type="date"
                >
                    {wDaysOptions.map(element => <option value={element.value} >{element.label}</option>)}
                </select>
            </Col>
            <Col className="col-12 pt-3">
                <label htmlFor="start_date" > Start Date : </label>
                <input
                    onChange={handleChange}
                    name="start_date"
                    value={form.start_date}
                    className="form-control"
                    type="date"
                />
            </Col>
            <Col className="col-12 pt-3">
                <label htmlFor="end_date" > End Date : </label>
                <input
                    onChange={handleChange}
                    name="end_date"
                    value={form.end_date}
                    className="form-control"
                    type="date"
                />
            </Col>

            <Col className="col-12 pt-3 ">
                <label htmlFor="start_time" className="m-0 pr-4" > Time: </label>
                <div className="d-flex align-items-center">
                    <input
                        onChange={handleChange}
                        name="start_time"
                        disabled={form.check}
                        value={form.start_time}
                        className="form-control"
                        type="time"
                    />
                    <label htmlFor="end_time" className="m-0 px-2">  To: </label>
                    <input
                        onChange={handleChange}
                        disabled={form.check}
                        name="end_time"
                        value={form.end_time}
                        className="form-control"
                        type="time"
                    />
                    <div className="pl-2 d-flex align-items-center">
                        <CustomInput
                            onClick={(e) => { setForm({ ...form, checked: e.target.checked, start_time: "00:00", end_time: "23:59" }) }}
                            checked={form.checked}
                            type="checkbox"
                            className="input-check"
                            id={"exampleCustomCheckbox"}
                        />
                        <label htmlFor="" className="m-0 px-2"> All Day</label>
                    </div>
                </div>

            </Col>
        </Row>
    )
}
