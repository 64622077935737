import React, {useState, useEffect} from "react";
import {SidebarItem} from "../../components/Index";
import {Button} from "reactstrap";
import styled from "styled-components";
import petition_get from "../../../../pages/petitions/petition_get";
import Users from "./Menu/User";
import RingGroup from "./Menu/RingGroup";
import TimeConditionals from "./Menu/TimeConditionals";
import CallFlowNumber from "./Menu/CallFlowNumber";
import Queues from "./Menu/Queues";
import CallFlowMenu from "./Menu/CallFlowMenu";
import Media from "./Menu/Media";
import ModalInformation from "./Menu/ModalInformation";
import ExternalNumber from "./Menu/ExternalNumber";
import Directory from "./Menu/Directory";
import PlayMedia from "./Menu/PlayMedia";

import EditIcon from "@material-ui/icons/Edit";

import MenuIcon from "@material-ui/icons/Menu";
import TimeConditionalIcon from "@material-ui/icons/AvTimer";
import DisplaySettingsIcon from "@material-ui/icons/Settings";
import VoicemailIcon from "@material-ui/icons/Voicemail";
import PersonIcon from "@material-ui/icons/Person";
import CallIcon from "@material-ui/icons/Call";
import RouteIcon from "@material-ui/icons/Map";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import ButtonHover from "../../../../components/utils/ButtonHover";

import infoImage from "../../../../assets/images/info.svg";

import EditNodo from "./Menu/EditNodo";
import {first} from "lodash";
import Voicemail from "./Menu/Voicemail";
const StartItemStyle = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0.5rem auto;
  border-radius: 5px;
  font-size: 18px;
  background: white;
  padding: 0 0.3rem;
  color: rgba(135, 145, 173, 1);
  cursor: pointer;
`;

export const CallFlowOption = ({
  selectNode,
  setSelectNode,
  setDataCallFlow,
  updateCallFlow,
  state,
  listUsers,
  refChart,
  activeEdit,
  setActiveEdit,
  dataCallFlow,
  setActiveOptions,
  form,
  setFirstModal,
  deleteNode,
  listOfUpdateDevices,
  setListOfUpdateDevices,
}) => {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [queues, setQueues] = useState([]);
  const [timeGroups, setTimeGroups] = useState([]);
  const [ringGroup, setRingGroup] = useState([]);
  const [voicemail, setVoicemail] = useState([]);
  const [timeZone, setTimeZone] = useState([]);
  const [details, setDetails] = useState(null);
  const [callFlowNumber, setCallFlowNumber] = useState([]);
  const [mediaSounds, setMediaSounds] = useState([]);
  const [listMenu, setListMenu] = useState([]);
  const [listCallflows, setListCallflows] = useState([]);
  const [directory, setDirectory] = useState([]);
  const [modal, setModal] = useState(false);

  const startPoint = (value) => {
    return {
      [`port_1_${value}`]: {
        id: `port_1_${value}`,
        type: "left",
        properties: {
          linkColor: "white",
        },
      },
      [`port_2_${value}`]: {
        id: `port_2_${value}`,
        type: "right",
        properties: {
          linkColor: "white",
        },
      },
    };
  };

  const [selectOption, setSelectOption] = useState(null);

  useEffect(() => {
    if (selectOption) return setActiveOptions(true);
    setActiveOptions(false);
  }, [selectOption]);

  useEffect(() => {
    setSelectOption(null);
  }, [selectNode]);

  useEffect(() => {
    petition_get("ringGroup", {id})
      .then(({data: result}) => setRingGroup(result.result))
      .catch((error) => console.log(error));

    petition_get("Queues", {id})
      .then(({data: result}) => setQueues(result.result))
      .catch((error) => console.log(error));
    petition_get("timeGroups", {id})
      .then(({data: result}) => {
        let tempPetitionTimeRules = [];

        let tempTimeGroups = [];

        result.result.map((element) =>
          tempPetitionTimeRules.push(
            petition_get("timeRules", {id, query: element.pk}).then(
              (result) =>
                result.data.result.length > 0 &&
                tempTimeGroups.push({
                  ...element,
                  id: element.pk.toString(),
                  pk: element.pk.toString(),
                })
            )
          )
        );

        Promise.all(tempPetitionTimeRules)
          .then((result) => setTimeGroups(tempTimeGroups))
          .catch((err) => console.log(err));
      })
      .catch((error) => console.log(error));

    petition_get("timeZome")
      .then(({data: result}) => setTimeZone(result.result))
      .catch((error) => console.log(error));
    petition_get("voicemail-v2", {id})
      .then(({data: result}) => setVoicemail(result.result))
      .catch((error) => console.log(error));

    petition_get("musicOnHoldMedia", {id})
      .then(({data: result}) => setMediaSounds(result.result))
      .catch((error) => console.log(error));

    petition_get("getMenu", {id})
      .then(({data: result}) => setListMenu(result.result))
      .catch((error) => console.log(error));

    petition_get("allCallflows", {id})
      .then(({data: result}) => setListCallflows(result.result))
      .catch((error) => console.log(error));

    petition_get("directory", {id})
      .then(({data: result}) => setDirectory(result.result.data))
      .catch((error) => console.log(error));
  }, []);

  const updateAction = (event) => {
    //Reset Select Option of node in chart
    setSelectOption(null);

    //Get new node
    const idNode = Object.keys(refChart.current.state.nodes)[
      Object.keys(refChart.current.state.nodes).length - 1
    ];
    //Get last node
    const idNode2 = Object.keys(refChart.current.state.nodes)[
      Object.keys(refChart.current.state.nodes).length - 2
    ];

    const newNode = refChart.current.state.nodes[idNode];

    //Filter with selectNode or default last node
    const firstNode = selectNode
      ? selectNode
      : refChart.current.state.nodes[idNode2];

    const linkNodeCopy = Object.keys(refChart.current.state.links);
    const findLink = linkNodeCopy.find(
      (element) =>
        refChart.current.state.links[element].from.nodeId === firstNode.id
    );
    if (
      (findLink &&
        firstNode.type.split("_")[0] !== "Time" &&
        firstNode.type.split("_")[0] !== "Menu") ||
      firstNode.flowSelected === "voicemail"
    ) {
      const copyOfDeleteNodes = refChart.current.state;
      delete copyOfDeleteNodes.nodes[newNode.id];
      refChart.current.setState(copyOfDeleteNodes);
      return;
    }

    const typeOfNode = newNode.type.split("_")[0];
    setSelectNode(newNode);
    let data = {};
    let getIdCallflow;
    let endpoints = [];
    switch (typeOfNode) {
      case "Media":
        data = {
          children: {},
          data: {
            id: details.pk,
            nodeFatherId: firstNode.id,
            nodeId: newNode.id,
            /*   endless_playback: false,
              answer: true,
              terminators: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"] */
          },
          module: "play",
        };
        break;
      case "Menu":
        data = {
          children: {},
          data: {
            /* timeout: parseInt(details.timeout), */
            nodeFatherId: firstNode.id,
            nodeId: newNode.id,
            media_id: details.mediaId,
            retries: details.retries,
            max_digits: details.max_digits,
            timeout_retries: details.timeout_retries,
            timeout: parseInt(details.timeout || "8000"),
            direct_dialing: details.direct_dialing,
          },
          module: "menu",
        };
        break;
      case "Time":
        data = {
          children: {},
          data: {
            rules: [],
            timezone: details.timezone,
            nodeFatherId: firstNode.id,
            nodeId: newNode.id,
          },
          module: "timecondition",
        };

        if (details.timezone === "default") delete data.data.timezone;
        break;

      case "ring":
        details.users.map((element) =>
          endpoints.push({
            delay: 0,
            endpoint_type: "user",
            id: element.id,
            timeout: 20,
          })
        );
        data = {
          children: {},
          data: {
            endpoints,
            name: details.name,
            timeout: parseInt(details.timeout),
            strategy: details.strategy,
            repeats: details.repeats,
            ignore_forward: details.ignore_forward,
            nodeFatherId: firstNode.id,
            nodeId: newNode.id,
          },
          module: "ring_group",
        };
        break;
      case "UserCallflow":
        getIdCallflow = listCallflows.find(
          (element) => element.owner_id === details.pk.toString()
        );

        if (details.flow === "voicemail") {
          data = {
            children: {},
            data: {
              id: getIdCallflow.pk,
              idUser: details.pk,
              idCallflow: getIdCallflow.id,
              nodeFatherId: firstNode.id,
              nodeId: newNode.id,
              flow: details.flow,
            },

            module: "callflow",
          };
        } else {
          data = {
            children: {},
            data: {
              id: details.pk,
              idUser: details.pk,
              idCallflow: getIdCallflow.id,
              timeout: details.timeout,
              can_call_self: details.checked,
              nodeFatherId: firstNode.id,
              nodeId: newNode.id,
              flow: details.flow,
            },

            module: "user",
          };
        }

        break;
      case "queues":
        data = {
          children: {},
          data: {
            id: details.pk,
            timeout: details.timeout,
            nodeFatherId: firstNode.id,
            nodeId: newNode.id,
          },

          module: "queue",
        };
        break;
      case "voicemail":
        console.log("voicemail test");
        data = {
          children: {},
          data: {
            id: details.pk,
            timeout: details.timeout,
            nodeFatherId: firstNode.id,
            nodeId: newNode.id,
          },

          module: "voicemail",
        };
        break;
      case "externalNumber":
        data = {
          children: {},
          to_number: details.number,
          dial_timeout: details.timeout,
          data: {
            id: `Create_${details.number}`,
            dial_timeout: details.timeout,
            destination: details.number,
            confirm_call: details.require_keypress,
            keep_original_callerid: details.keep_caller_id,
            nodeFatherId: firstNode.id,
            nodeId: newNode.id,
          },

          module: "externaltransfer",
        };
        break;
      case "directory":
        data = {
          children: {},
          data: {
            /* id: `${details.directory}`, */
            nodeFatherId: firstNode.id,
            nodeId: newNode.id,
          },

          module: "directory",
        };
        break;
      default:
        break;
    }

    let copiaState = refChart.current.state;
    const getFirstNodePortData = Object.keys(firstNode.ports);
    const getFirstNodePort =
      firstNode.ports[getFirstNodePortData[getFirstNodePortData.length - 1]];

    copiaState.links = {
      ...copiaState.links,
      [`link_${newNode.id}`]: {
        id: `link_${newNode.id}`,
        from: {nodeId: firstNode.id, portId: getFirstNodePort.id},
        to: {nodeId: newNode.id, portId: `port_1_${newNode.type}`},
      },
    };

    //Save Route of Childrens
    if (firstNode.type.split("_")[0] === "Callflow") {
      copiaState.nodes[newNode.id].childrenUrl = "";
    } else {
      const childrenParam = details.timeGroups || details.menuOption || "_";
      copiaState.nodes[newNode.id].childrenUrl =
        firstNode.childrenUrl +
        `${
          firstNode.childrenUrl === ""
            ? `children.${childrenParam}`
            : `.children.${childrenParam}`
        }`;
    }

    const urlChildren = copiaState.nodes[newNode.id].childrenUrl;

    //Save Type of TimeGroup
    if (details.timeGroups) {
      if (details.timeGroups === "_") {
        copiaState.nodes[newNode.id].timegroup = "All other time";
      } else {
        console.log(timeGroups);
        console.log(details);
        const findOne = timeGroups.find(
          (element) => element.id === details.timeGroups
        );
        copiaState.nodes[newNode.id].timegroup = findOne.name;
      }
    }

    //Save Type of Menu
    if (details.menuOption) {
      let MenuOptions = [
        {id: "1"},
        {id: "2"},
        {id: "3"},
        {id: "4"},
        {id: "5"},
        {id: "6"},
        {id: "7"},
        {id: "8"},
        {id: "9"},
        {id: "0"},
        {id: "timeout"},
        {id: "invalid"},
      ];
      const findOne = MenuOptions.find(
        (element) => element.id === details.menuOption
      );
      copiaState.nodes[newNode.id].menuOption = findOne.id;
    }

    if (details.flow) copiaState.nodes[newNode.id].flowSelected = details.flow;

    updateCallFlow(data, copiaState, urlChildren);
  };

  const optionsMenu = [
    /*  { title: "Media", value: "media"}, */
    {title: "Menu", value: "callFlowMenu", icon: <MenuIcon />},
    {
      title: "Time Conditions",
      value: "time_conditions",
      icon: <TimeConditionalIcon />,
    },
    {title: "Queues", value: "queues", icon: <DisplaySettingsIcon />},
    /*  { title: "Ring Groups", value: "ring_groups" }, */
    {title: "Voicemail", value: "voicemail", icon: <VoicemailIcon />},
    {title: "Users", value: "users", icon: <PersonIcon />},
    {
      title: "Send Call to External Number",
      value: "externalNumber",
      icon: <CallIcon />,
    },
    {title: "Directory", value: "directory", icon: <RouteIcon />},
    {title: "Play Media", value: "playmedia", icon: <PlayArrowIcon />},
  ];

  return (
    <div style={{fontSize: "1rem", textAlign: "center"}}>
      {activeEdit ? (
        <EditNodo
          listOfUpdateDevices={listOfUpdateDevices}
          setListOfUpdateDevices={setListOfUpdateDevices}
          listUsers={listUsers}
          setActiveEdit={setActiveEdit}
          setDataCallFlow={setDataCallFlow}
          timeZone={timeZone}
          dataCallFlow={dataCallFlow}
          activeEdit={activeEdit}
          refChart={refChart}
          deleteNode={deleteNode}
          listCallflows={listCallflows}
          mediaSounds={mediaSounds}
        />
      ) : (
        <>
          {/* <ModalInformation modal={modal} setModal={setModal} /> */}
          {/* <img
            onClick={() => {
              setModal(true);
            }}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "-20px",
              top: "2px",
            }}
            src={infoImage}
            alt=""
          /> */}
          <div style={{position: "relative"}}>
            <h3 className="py-2 text-center" style={{color: "white"}}>
              {!selectOption && (
                <div
                  onClick={() => {
                    setFirstModal(true);
                  }}
                  style={{cursor: "pointer"}}
                  className="d-flex justify-content-center"
                >
                  {form.nameCallflow}{" "}
                  <ButtonHover style={{margin: "0"}}>
                    <EditIcon />
                  </ButtonHover>{" "}
                </div>
              )}
              {selectOption === "media" && "Media"}
              {selectOption === "callFlowMenu" && "Menu"}
              {selectOption === "callFlowNumber" && "Numbers"}
              {selectOption === "time_conditions" && "Time Condition"}
              {selectOption === "users" && "Users"}
              {selectOption === "ring_groups" && "Ring Group"}
              {selectOption === "queues" && "Queue"}
              {selectOption === "voicemail" && "Voicemail"}
              {selectOption === "externalNumber" &&
                "Send Call to External Number"}
              {selectOption === "directory" && "Directory"}
              {selectOption === "playmedia" && "Play Media"}
            </h3>
          </div>
          {!selectOption && (
            <div className="row">
              {optionsMenu.map((element) => (
                <div className="col-12 px-2">
                  <Button
                    className="w-100  callflow-button"
                    onClick={() => {
                      setSelectOption(element.value);
                    }}
                  >
                    {element.icon}
                    <span>{element.title} </span>
                  </Button>
                </div>
              ))}
            </div>
          )}
          {selectOption === "externalNumber" && (
            <ExternalNumber
              dataCallFlow={dataCallFlow}
              selectNode={selectNode}
              timeGroups={timeGroups}
              mediaSounds={mediaSounds}
              updateAction={updateAction}
              state={state}
              startPoint={startPoint}
              StartItemStyle={StartItemStyle}
              setDetails={setDetails}
            />
          )}
          {selectOption === "media" && (
            <Media
              dataCallFlow={dataCallFlow}
              selectNode={selectNode}
              timeGroups={timeGroups}
              mediaSounds={mediaSounds}
              updateAction={updateAction}
              state={state}
              startPoint={startPoint}
              StartItemStyle={StartItemStyle}
              setDetails={setDetails}
            />
          )}
          {selectOption === "callFlowMenu" && (
            <CallFlowMenu
              dataCallFlow={dataCallFlow}
              selectNode={selectNode}
              timeGroups={timeGroups}
              updateAction={updateAction}
              state={state}
              startPoint={startPoint}
              mediaSounds={mediaSounds}
              StartItemStyle={StartItemStyle}
              listUsers={listUsers}
              setDetails={setDetails}
              details={details}
            />
          )}
          {selectOption === "callFlowNumber" && (
            <CallFlowNumber
              setSelectOption={setSelectOption}
              callFlowNumber={callFlowNumber}
              setCallFlowNumber={setCallFlowNumber}
              dataCallFlow={dataCallFlow}
              setDataCallFlow={setDataCallFlow}
            />
          )}
          {selectOption === "time_conditions" && (
            <TimeConditionals
              dataCallFlow={dataCallFlow}
              selectNode={selectNode}
              timeGroups={timeGroups}
              updateAction={updateAction}
              state={state}
              startPoint={startPoint}
              StartItemStyle={StartItemStyle}
              timeZone={timeZone}
              setDetails={setDetails}
            />
          )}
          {selectOption === "users" && (
            <Users
              dataCallFlow={dataCallFlow}
              selectNode={selectNode}
              timeGroups={timeGroups}
              updateAction={updateAction}
              state={state}
              startPoint={startPoint}
              StartItemStyle={StartItemStyle}
              listUsers={listUsers}
              setDetails={setDetails}
            />
          )}
          {selectOption === "ring_groups" && (
            <RingGroup
              dataCallFlow={dataCallFlow}
              selectNode={selectNode}
              timeGroups={timeGroups}
              listUsers={listUsers}
              updateAction={updateAction}
              state={state}
              startPoint={startPoint}
              StartItemStyle={StartItemStyle}
              ringGroup={ringGroup}
              setDetails={setDetails}
            />
          )}
          {selectOption === "queues" && (
            <Queues
              dataCallFlow={dataCallFlow}
              selectNode={selectNode}
              timeGroups={timeGroups}
              updateAction={updateAction}
              state={state}
              startPoint={startPoint}
              StartItemStyle={StartItemStyle}
              queues={queues}
              setDetails={setDetails}
            />
          )}
          {selectOption === "voicemail" && (
            <Voicemail
              dataCallFlow={dataCallFlow}
              selectNode={selectNode}
              timeGroups={timeGroups}
              updateAction={updateAction}
              state={state}
              startPoint={startPoint}
              StartItemStyle={StartItemStyle}
              voicemail={voicemail}
              setDetails={setDetails}
            />
          )}
          {selectOption === "directory" && (
            <Directory
              dataCallFlow={dataCallFlow}
              selectNode={selectNode}
              timeGroups={timeGroups}
              mediaSounds={mediaSounds}
              updateAction={updateAction}
              state={state}
              startPoint={startPoint}
              StartItemStyle={StartItemStyle}
              setDetails={setDetails}
              directory={directory}
            />
          )}
          {selectOption === "playmedia" && (
            <PlayMedia
              dataCallFlow={dataCallFlow}
              selectNode={selectNode}
              timeGroups={timeGroups}
              mediaSounds={mediaSounds}
              updateAction={updateAction}
              state={state}
              startPoint={startPoint}
              StartItemStyle={StartItemStyle}
              setDetails={setDetails}
            />
          )}
        </>
      )}
    </div>
  );
};
