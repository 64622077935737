import React, {useState, useEffect} from "react";
import CustomForm from "../../../../../../components/utils/CustomForm";
import petition_get from "../../../../../petitions/petition_get";
export default function Directory({
  handleDeleteModule,
  handleSubmitModule,
  defaultOption,
}) {
  const [customForm, setCustomForm] = useState(null);

  const {data} = defaultOption || {data: {}};

  const formConfig = {
    defaultValues: {},
  };

  useEffect(() => {
    setCustomForm("onlySubmit");
  }, []);

  return (
    <div>
      <CustomForm
        activeDelete={!data.new ? handleDeleteModule : null}
        onSubmit={handleSubmitModule}
        customForm={customForm}
        formConfig={formConfig}
      />
    </div>
  );
}
