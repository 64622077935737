import timezonesData2 from "./Timezone.json"
import moment from "moment-timezone"
import petition_get from "../../pages/petitions/petition_get"
const _t = (s, timezonesData) => {
    if (timezonesData.Reacti18n !== void 0 && timezonesData.Reacti18n[s]) {
        return timezonesData.Reacti18n[s];
    }
    return s;
};



export default function getTimezoneList(timezonesData) {


    let selectorOptions = [{ value: "", label: "Default" }]

    moment.tz.names()
        .filter(tz => {
            if (!timezonesData) return timezonesData2.timezones.includes(tz)
            return timezonesData.includes(tz)
        })
        .reduce((memo, tz) => {
            memo.push({
                name: tz,
                offset: moment.tz(tz).utcOffset()
            });

            return memo;
        }, [])
        .sort((a, b) => {
            return a.offset - b.offset
        })
        .reduce((memo, tz) => {
            const timezone = tz.offset ? moment.tz(tz.name).format('Z') : '';
            selectorOptions.push({ value: tz.name, label: `(GMT${timezone}) ${_t(tz.name, !timezonesData ? timezonesData2.timezones : timezonesData)}` })

        }, "");

    return selectorOptions

}