import {
  SIP,
  SIP_SUCCESS,
  SIP_USER,
  USERAGENT_END,
  USERAGENT_START,
  DISCONNECTED_PHONE
} from "./actionsTypes";

export const sip = (phone) => {
  return {
    type: SIP,
    payload: phone,
  };
};

export const sipSuccess = (info, info2) => {
  return {
    type: SIP_SUCCESS,
    payload: { userAgent: info, result: info2 },
  };
};

export const sipUser = (info) => {
  return {
    type: SIP_USER,
    payload: info,
  };
};
export const userAgentStart = () => {
  return {
    type: USERAGENT_START,
  };
};
export const userAgentEnd = () => {
  return {
    type: USERAGENT_END,
  };
};

export const disconnected = () => {
  return {
    type: DISCONNECTED_PHONE,
  };
};
