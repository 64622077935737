import React, {useState} from "react";
import {useEffect} from "react";
import {useForm} from "react-hook-form";
import {Controller} from "react-hook-form";
import {CustomInput} from "reactstrap";
export default function CustomForm({
  customClick,
  customForm = [],
  formConfig,
  onSubmit,
  activeDelete,
}) {
  const [form, setForm] = useState({});
  const [step, setStep] = useState(1);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: {errors},
    getValues,
  } = useForm(formConfig || {});

  useEffect(() => {
    let intervalId = setInterval(() => {
      setForm(getValues());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (customForm === "onlySubmit")
    return (
      <>
        <div className="px-4">
          <button onClick={onSubmit} className="btn btn-primary col-12 mt-4 ">
            Save
          </button>
          {activeDelete && (
            <button
              onClick={activeDelete}
              type="submit"
              className="btn btn-danger col-12 mt-2"
            >
              Delete
            </button>
          )}
        </div>
      </>
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="px-4">
      {customForm?.map((fieldForm) => {
        if (fieldForm.type === "button")
          return (
            <button
              className="w-100 callflow-button"
              onClick={() => customClick(fieldForm.name)}
            >
              {fieldForm.icon} {fieldForm.label}
            </button>
          );

        if (
          fieldForm.visible &&
          form[fieldForm.visible.key] !== fieldForm.visible.value
        )
          return <> </>;

        return (
          <div>
            <label
              className={`mt-4 mb-0 col-12 pl-0 text-left ${
                errors[fieldForm.name] && "input-error-form"
              }`}
              htmlFor={fieldForm.name}
            >
              {fieldForm.label}:
            </label>

            {fieldForm.type === "text" && (
              <input
                {...register(fieldForm.name, {required: true})}
                className={`form-control ${
                  errors[fieldForm.name] && "form-control-error"
                } `}
                type="text"
              />
            )}
            {fieldForm.type === "number" && (
              <input
                {...register(fieldForm.name, {required: true})}
                className={`form-control ${
                  errors[fieldForm.name] && "form-control-error"
                } `}
                type="text"
              />
            )}
            {fieldForm.type === "select" && (
              <select
                {...register(fieldForm.name, {required: true})}
                className={`form-control ${
                  errors[fieldForm.name] && "form-control-error"
                } `}
                type="text"
              >
                <option value="">Select option</option>
                {fieldForm.options.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            )}
            {fieldForm.type === "check" && (
              <input
                {...register(fieldForm.name, {required: false})}
                className="form-control form-control-error"
                type="checkbox"
                className="input-check"
              />
            )}
          </div>
        );
      })}

      {customForm?.length > 0 && customForm[0]?.type !== "button" && (
        <button type="submit" className="btn btn-primary col-12 mt-4">
          Save
        </button>
      )}
      {activeDelete && (
        <button
          onClick={activeDelete}
          type="submit"
          className="btn btn-danger col-12 mt-2"
        >
          Delete
        </button>
      )}
    </form>
  );
}
