import React, {useEffect, useState, useRef} from "react";
import PhoneInput from "react-phone-input-2";
import Autocomplete from "react-autocomplete-select";
import {Label, Button, Card} from "reactstrap";

import toastr from "toastr";
export default function SidebarNewMessage({
  addThreeList,
  contacts,
  setChatSelectedPk,
}) {
  const [inputNumber, setInputNumber] = useState("+1 ");
  const [options, setOptions] = useState([]);

  const [countryCode, setCountryCode] = useState("+1 ");

  useEffect(() => {
    if (contacts) {
      let findContacts = [];

      let findNumber = inputNumber
        .split(" ")
        [inputNumber.split(" ").length - 1].toLowerCase();

      contacts.map((element) => {
        element.numbers.map(
          (elementTwo) =>
            elementTwo.number.includes(findNumber) &&
            elementTwo.label === "Mobile" &&
            findContacts.push(
              `${countryCode}${element.name} +${elementTwo.number}`
            )
        );
      });
      contacts.map((element) => {
        if (element.name.toLowerCase().includes(findNumber)) {
          let findMobileNumber = element.numbers.find(
            (elementTwo) => elementTwo.label === "Mobile"
          );
          if (findMobileNumber) {
            let findExistRecomendation = findContacts.find(
              (elementThree) =>
                elementThree.number ===
                ` ${element.name} ${findMobileNumber.number}`
            );
            if (!findExistRecomendation)
              findContacts.push(
                `${countryCode}${element.name} +${findMobileNumber.number}`
              );
          }
        }
      });
      if (inputNumber === "") return setOptions([]);

      let reduceValues = findContacts.reduce((result, current) => {
        return result.includes(current) ? result : [...result, current];
      }, []);
      setOptions(reduceValues);
    }
  }, [inputNumber]);

  const inputAutocompleteRef = useRef();

  const handleSubmitNumber = () => {
    let number = inputNumber.split(" ")[inputNumber.split(" ").length - 1];

    if (`${number.substring(0, countryCode.length - 1)} ` === countryCode)
      addThreeList(number);
    else addThreeList(`${countryCode.split(" ")[0]}${number}`);

    setInputNumber("");
    setChatSelectedPk("");
  };

  const handleChangeInput = (value) => {
    if (value === `${countryCode.substring(0, countryCode.length - 1)}`) return;
    setInputNumber(value);
  };

  return (
    <div className="slideAddNumber">
      <Label>Enter Name or Phone Number </Label>

      <div className="d-flex ">
        <div className="inputFlag">
          <PhoneInput
            country={"us"}
            value={""}
            onChange={(e) => {
              setCountryCode(`+${e} `);
              setInputNumber(`+${e} `);
            }}
          />
        </div>

        <div style={{width: "90%"}}>
          <Autocomplete
            inputRef={inputAutocompleteRef}
            searchPattern={"containsString"}
            placeholder=""
            maxOptionsLimit={20}
            searchEnabled={true}
            getItemValue={(item) => {
              return item;
            }}
            optionsJSX={(value) => <span>{value}</span>}
            itemsData={options}
            onChange={handleChangeInput}
            inputJSX={(props) => (
              <input {...props} value={inputNumber} className="form-control" />
            )}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <Button
          disabled={inputNumber === ""}
          className="mt-2"
          color="primary"
          onClick={handleSubmitNumber}
        >
          {" "}
          Open chat{" "}
        </Button>
      </div>
    </div>
  );
}
