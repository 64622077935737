import React, {useState, useEffect} from "react";
import {Button, CustomInput} from "reactstrap";
import {SidebarItem} from "../../../components/Index";
import {get} from "lodash";
import ReactTooltip from "react-tooltip";
import InfoIcon from "@material-ui/icons/Info";
export default function Menu({
  setDetails,
  details,
  updateAction,
  state,
  startPoint,
  StartItemStyle,
  timeGroups,
  selectNode,
  dataCallFlow,
  mediaSounds,
}) {
  const [mediaSoundSelected, setMediaSoundSelected] = useState(false);

  const [activeForm, setActiveForm] = useState(false);

  const [activeTimeGroups, setActiveTimeGroups] = useState(false);
  const [selectTimeGroup, setSelectTimeGroup] = useState(null);
  const [defaultValue, setDefaultValue] = useState(true);
  const [timeGroupsValues, setTimeGroupsValues] = useState([]);

  const [activeMenu, setActiveMenu] = useState(false);
  const [menuOptions, setMenuOptions] = useState(false);
  const [selectMenu, setSelectMenu] = useState(null);

  const [form, setForm] = useState({
    retries: "1",
    timeout_retries: "3",
    max_digits: "4",
    timeout: "8000",
    direct_dialing: false,
  });

  const onChangeAutocompleteMenu = (e) => {
    setSelectMenu(e.target.value);
  };

  const onChangeAutocomplete = (e) => {
    const findOne = mediaSounds.find(
      (element) => element.pk === parseInt(e.target.value)
    );
    if (findOne) {
      setMediaSoundSelected(findOne.name);
      setActiveForm(findOne.pk);
      setDetails({...details, mediaId: findOne.pk});
    }
  };

  const onChangeAutocompleteTimeGroup = (e) => {
    setSelectTimeGroup(e.target.value);
  };

  useEffect(() => {
    setDetails({id: "Menu", timeGroups: selectTimeGroup});
  }, [selectTimeGroup]);
  useEffect(() => {
    setDetails({id: "Menu", menuOption: selectMenu});
  }, [selectMenu]);

  useEffect(() => {
    if (selectNode && selectNode.type.split("_")[0] === "Time") {
      setDefaultValue(true);

      let values;
      if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
      else {
        values = get(dataCallFlow.flow, selectNode.childrenUrl).children;
      }

      const timeGroupsKeys = Object.keys(values);
      const newTimeGroups = [];
      timeGroups.map((element) => {
        const findOne = timeGroupsKeys.find(
          (elementTwo) => elementTwo === element.id
        );
        if (!findOne) {
          newTimeGroups.push(element);
        }
      });

      if (values._) {
        setDefaultValue(false);
        setSelectTimeGroup(newTimeGroups.length > 0 ? newTimeGroups[0].id : "");
      } else {
        setSelectTimeGroup("_");
      }
      setTimeGroupsValues(newTimeGroups);
      setActiveTimeGroups(false);
    } else if (selectNode && selectNode.type.split("_")[0] === "Menu") {
      let MenuOptions = [
        {id: "1"},
        {id: "2"},
        {id: "3"},
        {id: "4"},
        {id: "5"},
        {id: "6"},
        {id: "7"},
        {id: "8"},
        {id: "9"},
        {id: "0"},
        {id: "timeout"},
        {id: "invalid"},
      ];
      let values;
      if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
      else values = get(dataCallFlow.flow, selectNode.childrenUrl).children;

      const MenuKeys = Object.keys(values);
      const newMenu = [];
      MenuOptions.map((element) => {
        const findOne = MenuKeys.find(
          (elementTwo) => elementTwo === element.id
        );
        if (!findOne) {
          newMenu.push(element);
        }
      });
      setSelectMenu(newMenu[0].id);
      setMenuOptions(newMenu);
      setActiveMenu(true);
      setActiveTimeGroups(true);
    } else {
      setActiveTimeGroups(true);
    }
  }, []);

  return (
    <>
      {activeTimeGroups ? (
        <>
          {activeMenu ? (
            <>
              {" "}
              <select
                onChange={onChangeAutocompleteMenu}
                className="form-control"
                list="datalistOptions"
                id="exampleDataList"
                placeholder="Type to search..."
              >
                {menuOptions.map((element, i) => (
                  <option key={i} value={element.id}>
                    {element.id}
                  </option>
                ))}
              </select>
              {selectMenu && (
                <Button
                  onClick={async () => {
                    setActiveMenu(false);
                  }}
                  className=" mt-3 w-100"
                  color="primary"
                >
                  Create
                </Button>
              )}{" "}
            </>
          ) : (
            <>
              <div>
                <label className="mt-2 mb-0 col-12 pl-0 text-left" htmlFor="">
                  Select Media
                </label>
                <select
                  onChange={onChangeAutocomplete}
                  className="form-control"
                  list="datalistOptions"
                  id="exampleDataList"
                  placeholder="Type to search..."
                >
                  {!activeForm && <option value="">Select option</option>}
                  {mediaSounds.map((element, i) => (
                    <option key={i} value={element.pk}>
                      {element.name}
                    </option>
                  ))}
                </select>
                <label className="mt-2 mb-0 col-12 pl-0 text-left" htmlFor="">
                  Invalid Attempts
                  <InfoIcon
                    data-tip
                    data-for="text0"
                    style={{paddingLeft: "0.5rem", fontSize: "21px"}}
                  />
                </label>

                <ReactTooltip id="text0">
                  <div className="text-left" style={{fontSize: "10px"}}>
                    This is the number of attempts a user has when entering an
                    <br />
                    invalid option. Once they have reached the number of
                    <br />
                    attempts, they will be routed to the 'Invalid Destination'
                    <br />
                    if the menu has one set. If the menu does not have one set,
                    <br />
                    the call will be terminated.
                    <br />
                  </div>
                </ReactTooltip>
                <select
                  onChange={(e) => {
                    setForm({...form, retries: e.target.value});
                    setDetails({
                      ...details,
                      ...form,
                      retries: e.target.value,
                    });
                  }}
                  value={form.retries}
                  className="form-control"
                  list="datalistOptions"
                  id="exampleDataList"
                  placeholder="Type to search..."
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>

                <label className="mt-2 mb-0 col-12 pl-0 text-left" htmlFor="">
                  Timeout retries
                </label>

                <select
                  onChange={(e) => {
                    setForm({...form, timeout_retries: e.target.value});
                    setDetails({
                      ...details,
                      ...form,
                      timeout_retries: e.target.value,
                    });
                  }}
                  value={form.timeout_retries}
                  className="form-control"
                  list="datalistOptions"
                  id="exampleDataList"
                  placeholder="Type to search..."
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                </select>
                <label className="mt-2 mb-0 col-12 pl-0 text-left" htmlFor="">
                  Max digits
                  <InfoIcon
                    data-tip
                    data-for="text1"
                    style={{paddingLeft: "0.5rem", fontSize: "21px"}}
                  />
                </label>
                <ReactTooltip id="text1">
                  <div className="text-left" style={{fontSize: "10px"}}>
                    Amount of seconds before replaying message or going to
                    <br />
                    'timeout' destination if number of retries has exceeded
                    <br /> the maximum amount
                  </div>
                </ReactTooltip>
                <select
                  onChange={(e) => {
                    setForm({...form, max_digits: e.target.value});
                    setDetails({
                      ...details,
                      ...form,
                      max_digits: e.target.value,
                    });
                  }}
                  value={form.max_digits}
                  className="form-control"
                  list="datalistOptions"
                  id="exampleDataList"
                  placeholder="Type to search..."
                >
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                </select>
                <label className="mt-2 mb-0 col-12 pl-0 text-left" htmlFor="">
                  Timeout (Seconds)
                  <InfoIcon
                    data-tip
                    data-for="text2"
                    style={{paddingLeft: "0.5rem", fontSize: "21px"}}
                  />
                </label>
                <ReactTooltip id="text2">
                  <div className="text-left" style={{fontSize: "10px"}}>
                    Allows to shorten the delay after a user dials
                    <br />a number
                  </div>
                </ReactTooltip>
                <select
                  onChange={(e) => {
                    setForm({...form, timeout: e.target.value});
                    setDetails({
                      ...details,
                      ...form,
                      timeout: e.target.value,
                    });
                  }}
                  value={form.timeout}
                  className="form-control"
                  list="datalistOptions"
                  id="exampleDataList"
                  placeholder="Type to search..."
                >
                  <option value={"2000"}>2</option>
                  <option value={"2500"}>2.5</option>
                  <option value={"3000"}>3</option>
                  <option value={"3500"}>3.5</option>
                  <option value={"4000"}>4</option>
                  <option value={"4500"}>4.5</option>
                  <option value={"5000"}>5</option>
                  <option value={"5500"}>5.5</option>
                  <option value={"6000"}>6</option>
                  <option value={"6500"}>6.5</option>
                  <option value={"7000"}>7</option>
                  <option value={"7500"}>7.5</option>
                  <option value={"8000"}>8</option>
                  <option value={"8500"}>8.5</option>
                  <option value={"9000"}>9</option>
                  <option value={"9500"}>9.5</option>
                  <option value={"10000"}>10</option>
                </select>

                <div className="d-flex mt-3">
                  <CustomInput
                    onChange={(e) => {
                      setForm({...form, direct_dialing: e.target.checked});
                      setDetails({
                        ...details,
                        ...form,
                        direct_dialing: e.target.checked,
                      });
                    }}
                    checked={form.direct_dialing}
                    type="checkbox"
                    className="input-check"
                    name=""
                    id="check2"
                  />
                  <label className="m-0 d-flex text-left" htmlFor="check2">
                    Direct Extension Dialing
                    <InfoIcon
                      data-tip
                      data-for="text10"
                      style={{paddingLeft: "0.5rem", fontSize: "21px"}}
                    />
                  </label>
                  <ReactTooltip id="text10">
                    <div className="text-left" style={{fontSize: "10px"}}>
                      - Allows dialing of extension numbers within the menu
                    </div>
                  </ReactTooltip>
                </div>
              </div>

              {activeForm && (
                <SidebarItem
                  updateAction={updateAction}
                  state={state}
                  label={`Menu`}
                  type={`Menu_1`}
                  ports={startPoint(`Menu_1`)}
                  ItemStyle={StartItemStyle}
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          <select
            onChange={onChangeAutocompleteTimeGroup}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            placeholder="Type to search..."
          >
            {defaultValue && <option value="_">All Other Times</option>}
            {selectTimeGroup === "" && (
              <option value="">You have no more options </option>
            )}
            {timeGroupsValues.map((element, i) => (
              <option key={i} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>

          {selectTimeGroup && (
            <Button
              disabled={selectTimeGroup === ""}
              onClick={async () => {
                setActiveTimeGroups(true);
              }}
              className=" mt-3 w-100"
              color="primary"
            >
              Create
            </Button>
          )}
        </>
      )}
    </>
  );
}
