import React, {useState, useEffect} from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  CustomInput,
  Table,
} from "reactstrap";
import Switch from "react-switch";
import toastr from "toastr";
import petition_patch from "../../../../petitions/petition_patch";
import petition_post from "../../../../petitions/petition_post";
import petition_get from "../../../../petitions/petition_get";
import petition_delete from "../../../../petitions/petition_delete";
import ButtonHover from "../../../../../components/utils/ButtonHover";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TimeGroupFormEdit from "../TimeGroupFormEdit";
export default function ModalUtilityEdit({
  modal,
  setModal,
  setReloadData,
  dataTimeDate,
  dataTimeDateId,
}) {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [activeSubmitTimeGroup, setActiveSubmitTimeGroup] = useState(false);

  const [loading, setLoading] = useState(false);
  const [formTimeGroup, setFormTimeGroup] = useState({
    name: "",
  });
  const [formTimeDate, setFormTimeDate] = useState({
    name: "",
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    start_day_of_week: 0,
    end_day_of_week: 4,
  });
  const handleChangeGroup = (e) => {
    setFormTimeGroup({...formTimeGroup, [e.target.name]: e.target.value});
  };

  const handleChange = (e) => {
    setFormTimeDate({...formTimeDate, [e.target.name]: e.target.value});
  };

  const addZeroString = (value) => {
    if (value < 10) return `0${value}`;

    return value;
  };

  useEffect(() => {
    if (modal) {
      if (dataTimeDate) {
        console.log(modal);

        setFormTimeDate({
          name: modal.name,
          start_date: `${modal.start_month_year}-${addZeroString(
            modal.start_month
          )}-${addZeroString(modal.start_month_day)}`,
          end_date: `${modal.end_month_year}-${addZeroString(
            modal.end_month
          )}-${addZeroString(modal.end_month_day)}`,
          start_time: `${addZeroString(modal.start_hour)}:${addZeroString(
            modal.start_minute
          )}`,
          end_time: `${addZeroString(modal.end_hour)}:${addZeroString(
            modal.end_minute
          )}`,
          start_day_of_week: modal.start_day_of_week,
          end_day_of_week: modal.end_day_of_week,
        });
      } else {
        setFormTimeGroup({name: modal.name});
      }
    }
  }, [modal]);

  const wDaysOptions = [
    {label: "Monday", value: 0},
    {label: "Tuesday", value: 1},
    {label: "Wednesday", value: 2},
    {label: "Thursday", value: 3},
    {label: "Friday", value: 4},
    {label: "Saturday", value: 5},
    {label: "Sunday", value: 6},
  ];

  const handleSubmit = async () => {
    if (dataTimeDate) {
      let newData = {
        name: formTimeDate.name,
        start_hour: parseInt(formTimeDate.start_time.split(":")[0]),
        start_minute: parseInt(formTimeDate.start_time.split(":")[1]),
        start_month: parseInt(formTimeDate.start_date.split("-")[1]),
        start_month_day: parseInt(formTimeDate.start_date.split("-")[2]),
        start_month_year: parseInt(formTimeDate.start_date.split("-")[0]),
        end_hour: parseInt(formTimeDate.end_time.split(":")[0]),
        end_minute: parseInt(formTimeDate.end_time.split(":")[1]),
        end_month: parseInt(formTimeDate.end_date.split("-")[1]),
        end_month_day: parseInt(formTimeDate.end_date.split("-")[2]),
        end_month_year: parseInt(formTimeDate.end_date.split("-")[0]),
        start_day_of_week: parseInt(formTimeDate.start_day_of_week),
        end_day_of_week: parseInt(formTimeDate.end_day_of_week),
        timegroup_id: formTimeDate.timegroup_id,
      };

      if (!newData.end_month) delete newData.end_month;
      if (!newData.end_month_day) delete newData.end_month_day;
      if (!newData.end_month_year) delete newData.end_month_year;
      if (!newData.start_month) delete newData.start_month;
      if (!newData.start_month_day) delete newData.start_month_day;
      if (!newData.start_month_year) delete newData.start_month_year;

      petition_patch("timeRules", {id, data: newData, time_id: modal.pk})
        .then(({data: result}) => {
          setLoading(false);
          setModal(false);
          toastr.success("Action completed", "Success");
          setReloadData(true);
        })
        .catch((error) => {
          setLoading(false);
          toastr.error("Action failed", "Error");
        });
    } else {
      setActiveSubmitTimeGroup(true);
      /*  setLoading(true);
 
 
       petition_get("timeGroupsDetails", { id: id, time_group_id: modal.id })
         .then(({ data: result }) => {
 
           console.log(result.result)
           petition_patch("timeGroup", {
             id,
             data: { ...result.result, name: formTimeGroup.name },
             time_id: modal.id,
           })
             .then(({ data: result }) => {
               setLoading(false);
               setModal(false);
               toastr.success("Action completed", "Success");
               setReloadData(true);
             })
             .catch((error) => {
               console.log(error);
               toastr.error("Action failed", "Error");
             });
         })
         .catch((error) => { });
  */
    }
  };

  return (
    <Modal centered={true} isOpen={modal ? true : false}>
      <ModalHeader>
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">
                Edit {dataTimeDate ? "Time Date" : "Time Group"}
              </h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>

      <ModalBody>
        {dataTimeDate ? (
          <Row>
            <Col className="col-12">
              <label htmlFor="name">Name:</label>
              <input
                onChange={handleChange}
                name="name"
                value={formTimeDate.name}
                className="form-control"
                type="text"
              />
            </Col>
            <Col className="col-12 pt-3">
              <label htmlFor="start_day_of_week"> Start Week : </label>
              <select
                onChange={handleChange}
                name="start_day_of_week"
                value={formTimeDate.start_day_of_week}
                className="form-control"
                type="date"
              >
                {wDaysOptions.map((element) => (
                  <option value={element.value}>{element.label}</option>
                ))}
              </select>
            </Col>
            <Col className="col-12 pt-3">
              <label htmlFor="end_day_of_week"> End Week : </label>
              <select
                onChange={handleChange}
                name="end_day_of_week"
                value={formTimeDate.end_day_of_week}
                className="form-control"
                type="date"
              >
                {wDaysOptions.map((element) => (
                  <option value={element.value}>{element.label}</option>
                ))}
              </select>
            </Col>
            <Col className="col-12 pt-3">
              <label htmlFor="start_date"> Start Date : </label>
              <input
                onChange={handleChange}
                name="start_date"
                value={formTimeDate.start_date}
                className="form-control"
                type="date"
              />
            </Col>
            <Col className="col-12 pt-3">
              <label htmlFor="end_date"> End Date : </label>
              <input
                onChange={handleChange}
                name="end_date"
                value={formTimeDate.end_date}
                className="form-control"
                type="date"
              />
            </Col>

            <Col className="col-12 pt-3 ">
              <label htmlFor="start_time" className="m-0 pr-4">
                {" "}
                Time:{" "}
              </label>
              <div className="d-flex align-items-center">
                <input
                  onChange={handleChange}
                  name="start_time"
                  disabled={formTimeDate.check}
                  value={formTimeDate.start_time}
                  className="form-control"
                  type="time"
                />
                <label htmlFor="end_time" className="m-0 px-2">
                  {" "}
                  To:{" "}
                </label>
                <input
                  onChange={handleChange}
                  disabled={formTimeDate.check}
                  name="end_time"
                  value={formTimeDate.end_time}
                  className="form-control"
                  type="time"
                />
                <div className="pl-2 d-flex align-items-center">
                  <CustomInput
                    onClick={(e) => {
                      setFormTimeDate({
                        ...formTimeDate,
                        checked: e.target.checked,
                        start_time: "00:00",
                        end_time: "23:59",
                      });
                    }}
                    checked={formTimeDate.checked}
                    type="checkbox"
                    className="input-check"
                    id={"exampleCustomCheckbox"}
                  />
                  <label htmlFor="" className="m-0 px-2">
                    {" "}
                    All Day
                  </label>
                </div>
              </div>
            </Col>
          </Row>
        ) : (
          <TimeGroupFormEdit
            setLoading={setLoading}
            modal={modal}
            setModal={setModal}
            setReloadData={setReloadData}
            activeSubmitTimeGroup={{
              setActiveSubmitTimeGroup,
              activeSubmitTimeGroup,
            }}
          />
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          outline
          disabled={loading}
          color="secondary"
          onClick={() => {
            setModal(false);
            setFormTimeGroup({name: ""});
            setFormTimeDate({
              name: "",
              cycle: "",
              time_window_start: "",
              time_window_stop: "",
              days: [""],
              start_date: "",
              month: "",
              ordinal: "",
              interval: "",
              time_group_id: "",
              enabled: "",
              wdays: [],
              check: false,
            });
          }}
        >
          Cancel
        </Button>
        <Button disabled={loading} color="primary" onClick={handleSubmit}>
          {loading && (
            <Spinner
              style={{marginRight: "0.5em"}}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
