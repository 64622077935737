import React, { useState, useEffect } from "react";
import { Container, Card, CardBody } from "reactstrap";
import petition_get from "../../../../pages/petitions/petition_get";
import petition_post from "../../../../pages/petitions/petition_post";
import { OrderNewNumbersStyled } from "./Style/Style";

import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import NavbarTitle from "./NavbarTitle/NavbarTitle";
import Steps from "./Steps/Steps";
import Filter from "./Filters/Filter";
import FilterTwo from "./Filters/FilterTwo";
import { useHistory } from "react-router-dom";
import FilterThree from "./Filters/FilterThree";
import FilterFour from "./Filters/FilterFour";

export default function AddUsers() {
  //For api_id search
  const history = useHistory();
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  /***********/

  //States
  //Steps
  const [step, setStep] = useState(1);
  //Services
  const [data, setData] = useState({});
  //Products
  const [products, setProducts] = useState([]);

  //Recommend Extension 
  const [recommedExtension, setRecommendExtension] = useState(null)
  /********/

  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    petition_get("products", "?category=ITP Voice Hardware&unlimit=true")
      .then(({ data: result }) => {
        let promises = [];
        result.result.model_list.forEach((element, i) => {
          promises.push(petition_get("products", "/" + element.pk));
        });
        Promise.all(promises)
          .then((values) => {
            setProducts(values);
          })
          .catch((reason) => {
            console.log(reason);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    petition_get("services", "")
      .then(({ data: result }) => {
        let findService = result.result.filter(service => service.item_type === "itp_voice")
        console.log("service", findService)
        if (findService) setData({ ...data, prices: findService[0].metadata });

      })
      .catch((error) => {
        console.log(error);
      });


    petition_get("itpvoiceUsers", { id: id })
      .then(({ data: result }) => {
        console.log(result.result)
        let lastExtension = parseInt(result.result[0].presence_id)
        result.result.map(element => {

          if (parseInt(element.presence_id) > lastExtension) {
            lastExtension = parseInt(element.presence_id)
          }
        })
        setRecommendExtension((lastExtension + 1).toString())
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <React.Fragment>
      <OrderNewNumbersStyled>
        <div className="page-content">
          <Container fluid>
            <div
              className="d-flex"
              style={{ alignItems: "center", paddingBottom: "2.5rem" }}
            >
              <h3 style={{ margin: "0" }}>Phone Numbers</h3>
              <span>
                <DoubleArrowIcon className="icon-arrow" />
              </span>
              <h4
                style={{
                  color: "#a6b0cf",
                  fontWeight: "400",
                  margin: "0",
                  fontSize: "15px",
                  paddingTop: "7px",
                }}
              >
                Add Users
              </h4>
            </div>

            <Card>
              <CardBody>
                <NavbarTitle
                  data={data}
                  setData={setData}
                  setStep={setStep}
                  step={step}
                />
                <hr style={{ marginTop: "0", height: "3px" }} />
                <Steps step={step} />

                {step === 1 && (
                  <>
                    {" "}
                    <Filter
                      recommedExtension={recommedExtension}
                      setStep={setStep}
                      id={id}
                      setData={setData}
                      data={data}
                    />
                  </>
                )}

                {step === 2 && (
                  <FilterTwo
                    step={step}
                    setStep={setStep}
                    setData={setData}
                    data={data}
                  />
                )}

                {step === 3 && (
                  <FilterThree
                    step={step}
                    setStep={setStep}
                    setData={setData}
                    data={data}
                    products={products}
                  />
                )}
                {step === 4 && (
                  <FilterFour
                    step={step}
                    setStep={setStep}
                    setData={setData}
                    data={data}
                    apiId={id}
                  />
                )}
              </CardBody>
            </Card>
          </Container>
        </div>
      </OrderNewNumbersStyled>
    </React.Fragment>
  );
}
