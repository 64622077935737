import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  CustomInput,
  Spinner,
} from "reactstrap";

import phoneImg from "../../../../assets/images/phone-image.svg";
import petition_patch from "../../../petitions/petition_patch";
export default function FavoritesModal({
  modal,
  setModal,
  users,
  queues,
  refreshAll,
  accountData
}) {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectOption, setSelectOption] = useState("");

  const handleCheck = (element) => {
    const findOne = selectedUsers.find((elementTwo) => elementTwo === element.pk);

    let newSelectedUsers;
    if (findOne) {
      newSelectedUsers = selectedUsers.filter(
        (elementTwo) => elementTwo !== element.pk
      );
    } else {
      newSelectedUsers = selectedUsers;
      newSelectedUsers.push(element.pk);
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handleCheckQueues = (element) => {
    const findOne = selectedQueues.find(
      (elementTwo) => elementTwo === element.id
    );

    let newSelectedQueues;
    if (findOne) {
      newSelectedQueues = selectedQueues.filter(
        (elementTwo) => elementTwo !== element.id
      );
    } else {
      newSelectedQueues = selectedQueues;
      newSelectedQueues.push(element.id);
    }

    setSelectedQueues(newSelectedQueues);
  };

  const savefavorite = () => {
    if (selectOption === "user") {
      setLoading(true);
      petition_patch("editCards", {
        id: id,
        data: { operator_panel: { panel_settings: { ...accountData.operator_panel.panel_settings, favorite_extensions: selectedUsers } } },
      })
        .then(({ data: result }) => {
          setSelectedUsers([]);
          setSelectedQueues([]);
          refreshAll();
          setTimeout(() => {
            setLoading(false);

            setModal(false);
          }, 1000);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.response);
        });
    }
    if (selectOption === "queues") {
      setLoading(true);
      petition_patch("editCards", {
        id: id,
        data: { operator_panel: { panel_settings: { ...accountData.operator_panel.panel_settings, favorite_queues: selectedQueues } } },
      })
        .then(({ data: result }) => {
          setSelectedUsers([]);
          setSelectedQueues([]);
          refreshAll();
          setTimeout(() => {
            setLoading(false);

            setModal(false);
          }, 1000);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.response);
        });
    }
  };

  return (
    <Modal centered={true} isOpen={modal} className="info ">
      <ModalHeader className="bg-soft-primary">
        <Row>
          <Col xs="9">
            <div className="text-primary p-3">
              <h3 className="text-primary">Favorites Users</h3>
            </div>
          </Col>
          <Col xs="3" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <div>
          <label htmlFor="">Select Type</label>
          <select
            value={selectOption}
            onChange={(e) => {
              setSelectedUsers([]);
              setSelectOption(e.target.value);
            }}
            className="form-control"
            name=""
            id=""
          >
            <option value="">Select One</option>
            <option value="user">Users</option>
          </select>
        </div>

        {selectOption === "user" && (
          <Table className="mt-4" hover size={"md"}>
            <thead className="thead-light">
              <tr>
                <th className="text-center">
                  Users</th>
              </tr>
            </thead>

            <tbody>
              {users &&
                users.map((element, i) => {
                  let flag = false;
                  const findOne = selectedUsers.find(
                    (elementTwo) =>
                      elementTwo.presence_id === element.presence_id
                  );

                  if (findOne) flag = true;

                  return (
                    <tr className="border py-3 text-center d-flex pl-2" key={i}>
                      <CustomInput
                        onClick={(e) => {
                          handleCheck(element);
                        }}
                        value={flag}
                        type="checkbox"
                        className="input-check"
                        name=""
                        id={`check-${i}`}
                      />
                      <label htmlFor={`check-${i}`}> {element.first_name} {element.last_name} {" "}
                        {element.presence_id}</label>

                    </tr>
                  );
                })}
            </tbody>
          </Table>
        )}
        {selectOption === "queues" && (
          <Table className="mt-4" hover size={"md"}>
            <thead className="thead-light">
              <tr>
                <th className="text-center">Queues</th>
              </tr>
            </thead>

            <tbody>
              {queues &&
                queues.map((element, i) => {
                  let flag = false;
                  const findOne = selectedQueues.find(
                    (elementTwo) =>
                      elementTwo.presence_id === element.presence_id
                  );

                  if (findOne) flag = true;

                  return (
                    <tr className="border py-3 text-center d-flex pl-2" key={i}>
                      <CustomInput
                        onClick={(e) => {
                          handleCheckQueues(element);
                        }}
                        value={flag}
                        type="checkbox"
                        className="input-check"
                        name=""
                        id={`check-${i}`}
                      />
                      {element.name}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          disabled={loading}
          color="secondary"
          onClick={() => {
            setModal(false);
            setSelectedUsers([]);
            setSelectedQueues([]);
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={loading}
          color="primary"
          onClick={() => {
            savefavorite();
          }}
        >
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
