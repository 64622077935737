import React, { useState, useRef, useEffect } from "react";
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum";
import { Button } from "reactstrap"
//Modal
import ModalUtility from "./ModalUtility/ModalUtility";
import ModalUtilityDownload from "./ModalUtility/ModalUtilityDownload";
import Loading from "../../../../components/utils/Loading";
import SidebarComponent from "../../../../components/SidebarComponent/SidebarComponent";
import useFax from "../../../../hooks/useFax";
import imgProfile from "../../../../assets/images/imgProfile.png"

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import FaxSelectedItem from "./FaxSelectedItem";

import { Steps, Hints } from "intro.js-react";

import tutorial from "../../../../pages/Utility/tutorialJsons"

import introJs from 'intro.js';

export default function Fax() {

  const refStep = useRef()
  const [tutorial, setActiveTutorial] = useState(0)



  /*   useEffect(() => {
  
  
      switch (tutorial) {
  
        case 2: setModal(true);
  
  
      }
  
    }, [tutorial]) */



  const { formatData, onChangeSelectedItem, selectedItem, filterKeys, loading, data, getFaxData, modal, setModal, setModalDownload, userData, modalDownload, downloadPdf } = useFax()



  const DataItem = ({ item, index }) => {



    return (<>  <hr className="w-100" style={{ margin: "10px 0 0 0" }} />

      <div onClick={() => { onChangeSelectedItem(item) }} className={`col-12 callhistory-item d-flex pt-2 ${selectedItem.pk === item.pk && "callhistory-item-active fax-item"}`}>
        <div className="d-flex align-items-center  mr-3 h-100">
          <img src={imgProfile} className="rounded-circle avatar-sm" alt="" />
        </div>
        <div className="w-100 d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-between w-100">
            <h5 className="text-truncate font-size-14 mb-0">
              {item.from_callerid_name}
            </h5>
            <p className="text-truncate mb-0">
              {formatData(item.faxdate)}
            </p>
          </div>
          <p className="text-truncate mb-0">
            <ArrowUpwardIcon className={`arrowCall ${item.direction === "Inbound" ? "rotateInbound" : "rotateOutbound"}`} />  {item.from_callerid_number}
          </p>
        </div>
      </div>

    </>)
  }


  return (
    <React.Fragment>
      <ModalUtility getFaxData={getFaxData} modal={modal} setModal={setModal} />
      <ModalUtilityDownload downloadPdf={downloadPdf} loading={loading} modal={modalDownload} setModal={setModalDownload} />
      <div className="page-content">
        <CustomBreadCrum refStep={refStep} title="My Extensions" subtitle="Fax" tutorial="tutorialFax" activeTutorial={setActiveTutorial} />

        <div className="mb-3 flex justify-content-end align-items-end">

          <Button className="fax-send" color="primary" onClick={() => { setModal(true); }}>
            Send Fax
          </Button>

        </div>

        {data ?
          <div className="row fax-select">

            {data.length > 0
              ? <>
                <div className="col-12 col-lg-5 "><SidebarComponent loading={loading} filterKeys={filterKeys} data={data} DataItem={DataItem} /></div>
                <div className="col-12 col-lg-7 pt-5 pt-lg-0 "> {selectedItem && <FaxSelectedItem formatData={formatData} setModal={setModalDownload} item={selectedItem} />} </div>
              </>
              : <h3 className=" text-center w-100">You do not have any fax at the moment. Make some Fax!</h3>
            }
          </div>
          : <Loading />}
      </div>
    </React.Fragment>
  );
}
