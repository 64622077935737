import React, {useState, useEffect} from "react";

import petition_get from "../pages/petitions/petition_get";
import toastr from "toastr";

const useCallHistory = (props) => {
  toastr.options = {
    showDuration: 300,
  };

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [data, setData] = useState(null);
  const [users, setUsers] = useState(null);
  const [contacts, setContacts] = useState(null);

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [reloadData, setReloadData] = useState(true);
  const [filterKeys] = useState([
    "userCallName",
    "userCallNumber",
    "direction",
    "duration_seconds",
    "unix_timestamp",
  ]);
  const [nextStartKey, setNextStartKey] = useState(null);

  useEffect(() => {
    if (props) {
      setUserData(props);
    }
  }, [props]);
  useEffect(() => {
    if (reloadData) {
      /* setUserData(props); */
      setReloadData(false);
      setData(null);

      getListUsers();
      getListContact();
      getUserData();
    }
  }, [reloadData]);

  useEffect(() => {
    if (users && contacts && userData) {
      getListCallHistory({pagination: true});
    }
  }, [users, contacts, userData]);

  let getUserData = () => {
    petition_get("userDetails", {id: id})
      .then(({data: result}) => setUserData(result.result))
      .catch((error) => console.log(error.response));
  };

  let getListUsers = () => {
    petition_get("itpvoiceUsers", {id: id})
      .then(({data: result}) => setUsers(result.result))
      .catch((error) => console.log(error));
  };

  let getListContact = () => {
    petition_get("contacts", {id})
      .then(({data: result}) => setContacts(result.result))
      .catch((error) => console.log(error));
  };

  const formatData = (val) => {
    let time = new Date(val * 1000);
    let timeZoneTemp = time.toLocaleString("en-US", {
      timeZone: userData.timezone,
    });
    return timeZoneTemp;
  };

  const filterData = (number, filter) => {
    setNextStartKey(null);
    getListCallHistory({number, filter, key: true});
  };

  let getListCallHistory = async ({number, filter, pagination, key}) => {
    let tempKey = key ? null : nextStartKey;

    if (tempKey === "notPage") return;

    try {
      setLoading(true);

      let result = await petition_get("callHistoryInteraction", {
        id,
        query: number
          ? `paginate=true&page_size=50${
              tempKey ? `&next_start_key=${tempKey}&` : "&"
            }${filter || "src"}=${number}`
          : `paginate=true&page_size=10${
              tempKey && pagination ? `&start_key=${tempKey}` : ""
            }`,
      });

      setNextStartKey(result.data.result.next_start_key || "notPage");

      //Find Contacts of call numbers
      let newData = [];

      if (Object.keys(result.data.result).length === 0) {
        setLoading(false);
        setData([]);
        return; /* toastr.error("No call history available", "Error") */
      }
      let ObjectCalls = Object.keys(result.data.result);

      ObjectCalls.map((elementKey, i) => {
        let element = result.data.result[elementKey];
        if (
          element.hangup_cause === "NORMAL_TEMPORARY_FAILURE" ||
          element.hangup_cause === "INCOMPATIBLE_DESTINATION"
        )
          return;

        let fromUserData = users.filter(
          (elementTwo) => elementTwo.pk === element.from_user_id
        );
        let toUserData = users.filter(
          (elementTwo) => elementTwo.pk === element.to_user_id
        );

        let fromNumber = fromUserData[0]?.presence_id || element.src;
        let toNumber = toUserData[0]?.presence_id || element.dst;

        let userCall = {name: "", number: ""};

        let findContact = [];

        contacts.model_list.default_contacts.map((elementTwo) => {
          if (
            elementTwo.internal_number === fromNumber ||
            elementTwo.external_number === fromNumber
          )
            findContact.push(elementTwo);
        });
        contacts.model_list.user_contacts.map((elementTwo) => {
          let findNumber = elementTwo.numbers.find(
            (elementThree) => elementThree.number === fromNumber
          );
          if (findNumber) findContact.push(elementTwo);
        });

        contacts.model_list.default_contacts.map((elementTwo) => {
          if (
            elementTwo.internal_number === toNumber ||
            elementTwo.external_number === toNumber
          )
            findContact.push(elementTwo);
        });
        contacts.model_list.user_contacts.map((elementTwo) => {
          let findNumber = elementTwo.numbers.find(
            (elementThree) => elementThree.number === toNumber
          );
          if (findNumber) findContact.push(elementTwo);
        });

        findContact = findContact.filter(
          (elementTwo) =>
            elementTwo.numbers ||
            elementTwo.internal_number !== userData.presence_id
        );
        if (findContact.length === 0)
          userCall.number =
            toNumber === userData.presence_id ? fromNumber : toNumber;
        else
          userCall = {
            number: toNumber === userData.presence_id ? fromNumber : toNumber,
            name: findContact[0].name,
          };

        newData.push({
          direction:
            fromNumber !== userData.presence_id ? "Inbound" : "Outbound",
          result: [
            {
              ...element,
              media_recordings: element.media_recordings || [],
              interaction_id: elementKey,
              id: elementKey,
            },
          ],
          unix_timestamp: formatData(element.calldate),
          duration_seconds: new Date((element.billsec || 0) * 1000)
            .toISOString()
            .substr(
              (element.billsec || 0) < 3600 ? 14 : 11,
              (element.billsec || 0) < 3600 ? 5 : 8
            ),
          duration: element.billsec || 0,
          durationReplay: element.duration,
          durationReplayPlay: new Date(element.duration * 1000)
            .toISOString()
            .substr(
              element.duration < 3600 ? 14 : 11,
              element.duration < 3600 ? 5 : 8
            ),
          userCallName:
            fromNumber !== userData.presence_id
              ? `${userCall.name || element.clid.split('"')[1]}`
              : "",
          userCallNumber:
            fromNumber !== userData.presence_id ? fromNumber : toNumber,
          contactData: findContact.length > 0 && findContact[0],
          from: fromNumber,
          to: toNumber,
          interaction_id: elementKey,
          id: elementKey,
          disposition: element.disposition,
          call_recording_filename: element.call_recording_filename,
        });
      });
      if (!selectedItem && newData.length > 0)
        setSelectedItem({...newData[0], index: 0});
      if (selectedItem && !pagination)
        setSelectedItem({
          ...newData[selectedItem.index],
          index: selectedItem.index,
        });

      if (data) {
        setData(pagination ? [...data, ...newData] : newData);
      } else {
        setData(newData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("CallHistoryError", error);
      /*      if (error.response.status === 404) setError404(true) */
    }
  };

  const onChangeSelectedItem = (item) => {
    setSelectedItem(item);
  };

  const downloadCall = async (value) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(value);

        const token = JSON.parse(localStorage.getItem("authUser"));
        const json = require("../config.json");
        const urlBase = json.prod
          ? "https://api.itpscorp.com/portal/itpvoice/v2/"
          : "https://api.itpscorp.com/dev/portal/itpvoice/v2/";

        const downloadFile = async () =>
          fetch(
            `${urlBase}${id}/my-extension/cdr/${value.result[0].pk}/${value.call_recording_filename}?token=${token.access_token}`
          ).then((res) => res.blob());
        const createURL = (blob) => URL.createObjectURL(blob);

        const blob = await downloadFile();
        const create = createURL(blob);

        let link = document.createElement("a");
        link.download = `${value.result[0].interaction_id}.mp3`;
        link.href = create;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    userData,
    data,
    loading,
    selectedItem,
    onChangeSelectedItem,
    setReloadData,
    downloadCall,
    filterKeys,
    filterData,
    getListCallHistory,
  };
};

export default useCallHistory;
