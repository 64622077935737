import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import * as qs from "query-string";

export default class ModalUtil extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.props.toggle;
    this.childFunction = this.props.childFunction;
    this.acceptButton = this.props.acceptButton;
    this.cancelButton = this.props.cancelButton;
    this.urlData = qs.parse(window.location.search);
    this.title = this.props.title;
    this.state = {
      modal: this.props.modal,
      spinner: this.props.spinner,
      disabledAccept: true,
      modalbody: [],
      selectedService: this.props.selectedService,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        disabledAccept: this.props.disabledAccept,
        spinner: this.props.spinner,
        modal: this.props.modal,
        modalbody: this.props.modalbody,
        selectedService: this.props.selectedService,
      });
    }
  }

  spinner() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "20em",
        }}
      >
        <Spinner animation="grow" style={{ height: "10vh", width: "10vh" }} />
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        <div>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            size="lg"
            backdrop="static"
          >
            <ModalHeader>
              {this.state.spinner ? "Loading" : this.title}
            </ModalHeader>
            <ModalBody>
              {this.state.spinner ? (
                this.spinner()
              ) : (
                <Table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>
                        {this.state.selectedService === "itp_voice"
                          ? "api_id"
                          : "Direccion"}
                      </th>
                      <th>Select</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.modalbody.sort().map((element, i) => (
                      <tr key={i}>
                        <td>{element.product.name}</td>
                        <td>
                          {this.state.selectedService === "itp_voice"
                            ? element.api_id
                            : element.product.name}
                        </td>
                        <td>
                          {this.state.selectedService === "itp_voice" ? (
                            this.urlData.id === element.api_id ? (
                              <Link
                                to={{
                                  pathname: "/DashboardUsers",
                                  search: "?id=" + element.api_id,
                                }}
                              >
                                View
                              </Link>
                            ) : (
                              <Link
                                onClick={() => {
                                  window.location.href = window.location.origin + "/DashboardUsers?id=" + element.api_id
                                }}
                              >
                                View
                              </Link>
                            )
                          ) : (
                            "View"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </ModalBody>
            <ModalFooter>
              {this.state.spinner ? null : (
                <>
                  <Button color="danger" onClick={this.toggle}>
                    {this.cancelButton}
                  </Button>{" "}
                </>
              )}
            </ModalFooter>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
