import React, { useState, useEffect } from 'react';
import petition_get from '../pages/petitions/petition_get'
import petition_delete from '../pages/petitions/petition_delete'
import petition_post from '../pages/petitions/petition_post'
import petition_patch from '../pages/petitions/petition_patch'
import toastr from "toastr";
import SettingsIcon from '@material-ui/icons/Settings';
export default function useFeatureCode() {

    const [reloadData, setReloadData] = useState(true)
    const [dataTable, setDataTable] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)

    const [modal, setModal] = useState(false)

    const [dataFeatureCodes, setDataFeatureCodes] = useState(null)

    const [featuresCallflow, setFeaturesCallflow] = useState({ intercomCode: null, parkCode: null, parkPick: null })


    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    toastr.options = {
        showDuration: 300,
    };


    const handleSwitch = (e, dataFeatures, parkPick) => {

        let newData = []
        dataFeatures.map(element => newData.push({ ...element, switch: element.pk === e.pk ? !element.switch : element.switch }))

        setDataFeatureCodes(newData)
        setDataTable({
            title: [
                { title: "Feature Code", key: "feature_code" },
                { title: "Name", key: "name" },
                { title: "Description", key: "description" },
            ],
            content: newData,
            actions: {
                title: "Enabled",
                content: [
                    {
                        switch: true,
                        keySwitch: "switch",
                        handleClick: (e) => { handleSwitch(e, newData, parkPick) },
                    },
                    {

                        component: (e) => { if (e.icon && e.switch && parkPick?.length > 0) return (<div onClick={() => { setModal({ ...e, featureCodeTimeout: featuresCallflow.parkCode }) }} style={{ cursor: "pointer" }} className=" mx-2 h-100 d-flex align-items-center"><SettingsIcon /> </div>) },
                        type: "component",
                    },
                ],
            },
        })
    }

    useEffect(() => {
        if (reloadData) {



            petition_get("allCallflows", { id })
                .then(({ data: result }) => {

                    let findIntercomCode = result.result.filter(element => element.featurecode?.name === "intercom");
                    let findParkCode = result.result.filter(element => element.featurecode?.name === "park_call");
                    let findParkPickCode = result.result.filter(element => element.featurecode?.name === "park_pickup");

                    let saveAllCallflowsFeactures = result.result.filter(element => (element.type === "featureCode" && (element.featurecode?.name !== "intercom" && element.featurecode?.name !== "park_pickup" && element.featurecode?.name !== "park_call")));


                    const dataFeatureCodesIntern = [

                        { pk: 0, switch: findIntercomCode.length > 0 ? true : false, feature_code: "*0", name: "Intercom", description: "Allows for calling an extension and automatically answering the call" },
                        { pk: 1, icon: true, switch: findParkCode.length > 0 ? true : false, feature_code: "*70", name: "Park Call", description: "Place a call in one of the available parking slots automatically" }

                    ]

                    saveAllCallflowsFeactures.map(element => dataFeatureCodesIntern.push({ ...element, feature_code: element.numbers, description: "Feature Code", switch: element.enabled ? true : false, updateEnable: true }))
                    setDataTable({
                        title: [
                            { title: "Feature Code", key: "feature_code" },
                            { title: "Name", key: "name" },
                            { title: "Description", key: "description" },
                        ],
                        content: dataFeatureCodesIntern,
                        actions: {
                            title: "Enabled",
                            content: [
                                {
                                    switch: true,
                                    keySwitch: "switch",
                                    handleClick: (e) => { handleSwitch(e, dataFeatureCodesIntern, findParkPickCode) },
                                },
                                {

                                    component: (e) => { if (e.icon && e.switch && findParkPickCode.length > 0) return (<div onClick={() => { setModal({ ...e, featureCodeTimeout: featuresCallflow.parkCode }) }} style={{ cursor: "pointer" }} className=" mx-2 h-100 d-flex align-items-center"><SettingsIcon /> </div>) },
                                    type: "component",
                                },
                            ],
                        },
                    })

                    setFeaturesCallflow({ intercomCode: findIntercomCode, parkCode: findParkCode, parkPick: findParkPickCode })
                    setDataFeatureCodes(dataFeatureCodesIntern)
                    setReloadData(false);
                })
                .catch((error) => setReloadData(false));


        }
    }, [reloadData]);



    const onHandleSave = async () => {

        try {

            setLoading(true)

            if (featuresCallflow.intercomCode.length > 0 && !dataFeatureCodes[0].switch) await petition_delete("callFlow", { id, callflow_id: featuresCallflow.intercomCode[0].pk }).catch(err => { throw err })
            else if (!featuresCallflow.intercomCode.length > 0 && dataFeatureCodes[0].switch) {

                await petition_post("callflow", {
                    id, data: {
                        callflow_object: {
                            type: "featureCode",
                            flow: {
                                children: {},
                                data: { action: "compose" },
                                module: "intercom"
                            },
                            featurecode: { name: "intercom", number: "0" },
                            patterns: ["^\\*0([0-9]*)$"],
                            metadata: { itpvoice: { name: "intercom" } }

                        },
                        type: "featureCode",
                        numbers: [],
                    }
                })
                    .catch((error) => { throw error });

            }

            if (featuresCallflow.parkCode.length > 0 && !dataFeatureCodes[1].switch) {

                await petition_delete("callFlow", { id, callflow_id: featuresCallflow.parkCode[0].pk }).catch(err => { throw err })
                if (featuresCallflow.parkPick.length > 0) featuresCallflow.parkPick.map(element => petition_delete("callFlow", { id, callflow_id: element.pk }).catch(err => { throw err }))

            }
            else if (!featuresCallflow.parkCode.length > 0 && dataFeatureCodes[1].switch) {


                await petition_post("callflow", {
                    id, data: {
                        callflow_object: {
                            flow: {
                                children: {},
                                data: {
                                    action: "park",
                                    timeout: "120"
                                },
                                module: "callparking"
                            },
                            featurecode: { name: "park_call", number: "70" },
                            type: "featureCode",
                        },
                        type: "featureCode",

                        numbers: ["70"],
                    }
                })
                    .catch((err) => { throw err });

                let objectDataCallflow = (slot) => {
                    return {
                        id, data: {
                            callflow_object: {
                                flow: {
                                    data: {
                                        action: "pickup",
                                        slot_number: `${slot}`,
                                    },
                                    module: "callparking",
                                    children: {}
                                },
                                featurecode: { name: "park_pickup", number: "70" },
                                type: "featureCode",
                            },
                            type: "featureCode",
                            numbers: [`${slot}`],
                        }
                    }
                }

                await petition_post("callparking", { id, data: { slots: [71, 72, 73, 74, 75] } }).catch((err) => { throw err });

                await petition_post("callflow", objectDataCallflow("71")).catch((err) => { throw err });
                await petition_post("callflow", objectDataCallflow("72")).catch((err) => { throw err });
                await petition_post("callflow", objectDataCallflow("73")).catch((err) => { throw err });
                await petition_post("callflow", objectDataCallflow("74")).catch((err) => { throw err });
                await petition_post("callflow", objectDataCallflow("75")).catch((err) => { throw err });


            }

            let allPetitionFeatureCodes = []

            dataTable.content.map(element => {

                if (element.updateEnable) {

                    allPetitionFeatureCodes.push(petition_patch("callflowEdit", {
                        id, callflow_id: element.pk, data: {
                            callflow_object: {
                                flow: element.flow,
                                enabled: element.switch
                            },
                            numbers: element.numbers, name: element.name || "",
                        }
                    }))

                }


            })

            Promise.all(allPetitionFeatureCodes).then(() => {
                toastr.success("Action completed", "Success");
                setLoading(false)
                setReloadData(true)
            }).catch((err) => { throw err });




        } catch (error) {
            console.log("testing2", error)
            toastr.error("Action Error", "Error")
        }


    }


    const updateParkPickup = (pickSlots, timeout) => {

        setLoading(true)

        let slotsObject = []
        for (let i = 1; i <= parseInt(pickSlots); i++) { slotsObject.push(70 + i) }




        petition_patch("callflowEdit", {
            id, data: {
                callflow_object: {
                    flow: {
                        children: {},
                        data: {
                            action: "park",
                            timeout: timeout
                        },
                        module: "callparking"
                    },
                    featurecode: { name: "park_call", number: "70" },
                    type: "featureCode",
                },
                type: "featureCode",

                numbers: ["70"],
            },
            callflow_id: featuresCallflow.parkCode[0].pk
        })
            .catch((err) => { throw err });


        if (featuresCallflow.parkPick.length > 0) {

            let allDelete = []



            featuresCallflow.parkPick.map(element => allDelete.push(petition_delete("callFlow", { id, callflow_id: element.pk }).catch(err => { throw err })))

            Promise.all(allDelete).then(result => {



                let objectDataCallflow = (slot) => {
                    return {
                        id, data: {
                            callflow_object: {

                                flow: {
                                    data: {
                                        action: "pickup",
                                        slot_number: `${slot}`,
                                    },
                                    module: "callparking",
                                    children: {}
                                },
                                type: "featureCode",
                                featurecode: { name: "park_pickup", number: "70" },

                            },
                            type: "featureCode",

                            numbers: [`${slot}`],
                        }, callflow_id: featuresCallflow.parkPick[0].pk
                    }
                }


                let updatearray = []

                slotsObject.map(element => {
                    updatearray.push(petition_post("callflow", objectDataCallflow(element)))
                })
                Promise.all(updatearray)
                    .then(result => { setModal(false); setReloadData(true); setLoading(false) })
                    .catch((err) => { toastr.error("Action Error", "Error") });

                petition_post("callparking", { id, data: { slots: slotsObject } })
                    .then(result => { setModal(false); toastr.success("Action completed", "Success"); })
                    .catch((err) => { toastr.error("Action Error", "Error") });

            }).catch(err => { toastr.error("Action Error", "Error") })






        }

    }



    return { dataTable, loading, onHandleSave, modal, setModal, loadingModal, featuresCallflow, updateParkPickup };
}
