import React, { useState, useEffect, useRef } from "react";

import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import CustomTable from "../../../../../components/utils/CustomTable/CustomTable"
import ButtonHover from "../../../../../components/utils/ButtonHover";
import petition_get from "../../../../petitions/petition_get";
import useCallRecord from "../../../../../hooks/useCallRecord"
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from '@material-ui/icons/Pause';
import GetAppIcon from '@material-ui/icons/GetApp';
import json from "../../../../../config.json"
export default function ModalUtility({ modal, setModal, timezone }) {

  const [data, setData] = useState(null)

  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [dataTable, setDataTable] = useState(null)

  const [urlBase] = useState(json.prod ? "https://api.itpscorp.com/portal/itpvoice/v2/" : "https://api.itpscorp.com/dev/portal/itpvoice/v2/")
  const [token] = useState(JSON.parse(localStorage.getItem("authUser")))

  const CallRecording = ({ item }) => {
    const refAudio = useRef()

    const { getUrlVoiceAudio, time, playActive, onClickButtonPlay, onChangeTime } = useCallRecord(urlBase, token, refAudio, item)


    if (!item.call_recording_filename) return "Not recorded"
    return (<><div className="d-flex align-items-center" style={{ width: "250px" }}>
      <audio
        className="d-none"
        src=""
        controls
        type="audio/mpeg"
        preload="true"
        ref={refAudio}
        onTimeUpdate={(e) => { onChangeTime({ e, item, refAudio }) }}
      />
      <div style={{ width: "10%" }}> <div onClick={() => { onClickButtonPlay(refAudio, item.pk, item) }} className="bg-primary button-play mr-2  "> {playActive === item.pk ? <PauseIcon style={{ color: "white" }} /> : <PlayArrowIcon style={{ color: "white" }} />}   </div></div>
      <div className="barRange ml-4">  <div style={{ width: `${time.timeProgress >= 100 || !time.timeProgress ? "0" : time.timeProgress}%` }} className="barRangePositionBackground">. </div>  </div>
      {refAudio.current && !isNaN(refAudio.current.duration) && <div className="containerTime"> {time.timeSecond || "00:00"} / {refAudio.current && !isNaN(refAudio.current.duration) ? new Date(refAudio.current.duration * 1000).toISOString().substr(refAudio.current.duration < 3600 ? 14 : 11, refAudio.current.duration < 3600 ? 5 : 8) : ""}  </div>}
    </div></>)
  }



  const downloadCall = async (value) => {

    return new Promise(async (resolve, reject) => {

      try {

        console.log(value)

        const token = JSON.parse(localStorage.getItem("authUser"));
        const urlBase = json.prod ? "https://api.itpscorp.com/portal/itpvoice/v2/" : "https://api.itpscorp.com/dev/portal/itpvoice/v2/"


        const downloadFile = async () =>
          fetch(`${urlBase}${id}/my-extension/cdr/${value.pk}/${value.uniqueid}?token=${token.access_token}&&accept=audio/mpeg`).then((res) => res.blob());
        const createURL = (blob) => URL.createObjectURL(blob);

        const blob = await downloadFile();
        const create = createURL(blob);

        let link = document.createElement("a");
        link.download = `${value.call_recording_filename}.mp3`
        link.href = create;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();

        resolve(true)
      } catch (error) {
        reject(error)
      }
    })
  }


  const formatData = (val) => {

    let time = new Date(parseInt(val) * 1000);
    let timeZoneTemp = time.toLocaleString('en-US', { timeZone: timezone })
    return timeZoneTemp
  }




  useEffect(() => {

    if (modal) {

      setData(null)
      setDataTable(null)

      let query = ""

      let newTimeStart = new Date(modal.timeStart)
      let newTimeEnd = new Date(modal.timeEnd)

      if (modal.type === "number") query = `?callee_id_number=${modal.number}`
      else query = `?caller_id_name=${modal.user}&callee_id_name=${modal.user}&start_date=${newTimeStart.getTime() / 1000}&end_date=${newTimeEnd.getTime() / 1000}`

      petition_get("callReportQuery", { id, query })
        .then(({ data: res }) => {
          let newDate = []


          Object.values(res.result).map(element => {
            let fromNumber = element.src
            let toNumber = element.dst
            let saveDurationCall = new Date((element.billsec) * 1000).toISOString().substr((element.billsec - element.billing_seconds) < 3600 ? 14 : 11, (element.billsec - element.billing_seconds) < 3600 ? 5 : 8)

            let datetime = formatData((element.calldate).split(".")[0])
            newDate.push({ ...element, datetime, ringTime: element.duration, fromNumber, toNumber, saveDurationCall })
          })


          setDataTable({

            title: [
              { title: "Date", key: "datetime" },
              { title: "From", key: "fromNumber" },
              { title: "To", key: "toNumber" },
              { title: "Billable Time", key: "billsec" },
              { title: "Ring Time", key: "ringTime" },
              { title: "Disposition", key: "disposition" },
            ],
            content: newDate,
            actions: {
              title: "Actions",
              content: [
                {
                  component: (e) => { return (<CallRecording item={e} />) },
                  type: "component",

                },
                {

                  component: (e) => { if (e.call_recording_filename) return (<div onClick={() => { downloadCall(e) }} className=" mx-2 h-100 d-flex align-items-center"><GetAppIcon /> </div>) },
                  type: "component",
                },
              ],
            },
          })

        })
        .catch((error) => console.log("Error", error));

    }




  }, [modal])



  return (
    <Modal size={"xl"} isOpen={modal} style={{ marginTop: "10rem" }} className=" info">
      <ModalHeader className=" bg-soft-primary">
        {" "}
        <Row>
          <Col className="col-10">
            <div className="text-primary p-3">
              <h3 className="text-primary"> Call Details</h3>
            </div>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody style={{ padding: "4rem 0 0 0rem" }}>
        <CustomTable data={dataTable} />
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            setModal(false)
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}
