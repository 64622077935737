import React, {useEffect, useState} from "react";
import {get, set} from "lodash";
import {CustomInput, Button} from "reactstrap";
import InfoIcon from "@material-ui/icons/Info";
import ReactTooltip from "react-tooltip";
import {Multiselect} from "multiselect-react-dropdown";
import petition_get from "../../../../../pages/petitions/petition_get";
import petition_patch from "../../../../../pages/petitions/petition_patch";

export default function EditNodo({
  listOfUpdateDevices,
  setListOfUpdateDevices,
  listUsers,
  activeEdit,
  setActiveEdit,
  dataCallFlow,
  timeZone,
  setDataCallFlow,
  refChart,
  deleteNode,
  mediaSounds,
}) {
  const [form, setForm] = useState({});
  const [finalDataSave, setFinalDataSave] = useState(null);
  const [usersOptions, setUsersOptions] = useState(
    listUsers.map((element) => ({
      name: `${element.first_name} ${element.last_name}`,
      id: element.id,
    }))
  );
  const [firstOptions, setFirstOptions] = useState([]);
  const filterTypeNodo = activeEdit.type.split("_");
  const [saveDevice, setSaveDevice] = useState(null);

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  useEffect(() => {
    let finalData;
    if (activeEdit.childrenUrl === "") finalData = dataCallFlow.flow;
    else finalData = get(dataCallFlow.flow, activeEdit.childrenUrl);
    setFinalDataSave(finalData);
    console.log(finalData);
    switch (filterTypeNodo[0]) {
      case "Time":
        setForm({timezone: activeEdit.type.replace("Time_", "")});
        break;
      case "UserCallflow":
        setForm({
          ...finalData.data,
        });

        break;
      case "Menu":
        setForm({
          ...finalData.data,
        });

        break;
      case "ring":
        let listUsersCopy = [];
        listUsers.map((element) => {
          const findOne = finalData.data.endpoints.find(
            (elementTwo) => elementTwo.id === element.id
          );
          if (findOne)
            listUsersCopy.push({
              name: `${element.first_name} ${element.last_name}`,
              id: element.id,
            });
        });

        setFirstOptions(listUsersCopy);
        setForm({
          name: finalData.data.name,
          strategy: finalData.data.strategy,
          timeout: finalData.data.timeout,
          users: listUsersCopy,
          repeats: finalData.data.repeats,
          ignore_forward: finalData.data.ignore_forward,
        });

        break;

      case "externalNumber":
        if (finalData.data.id.split("_").length > 1) {
          let destination = finalData.data.id.split("_")[1];

          const {keep_original_callerid, confirm_call, dial_timeout} =
            finalData.data;
          setForm({
            destination,
            keep_original_callerid,
            confirm_call,
            dial_timeout,
          });
        } else {
          /*  petition_get("getDevicesDetails", { id, deviceId: finalData.data.id })
             .then(({ data: result }) => {
 
 
               setSaveDevice(result.result)
               const { require_keypress, keep_caller_id, timeout } = finalData.data
               setForm({
                 number: result.result.call_forward.number,
                 require_keypress,
                 keep_caller_id,
                 timeout
               })
 
             })
             .catch((error) => {
               console.log(error);
             }); */

          petition_get("getDevicesDetails", {id, deviceId: finalData.data.id})
            .then(({data: result}) => {
              setSaveDevice(result.result);
              const {require_keypress, keep_caller_id, timeout, number} =
                finalData.data;
              setForm({
                number: number || result.result.call_forward.number,
                require_keypress,
                keep_caller_id,
                timeout,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }

        break;
      default:
        break;
    }
  }, [activeEdit]);

  const onChangeForm = (e) => {
    switch (filterTypeNodo[0]) {
      case "Time":
        setForm({timezone: e.target.value});
        break;
      case "UserCallflow":
        setForm({...form, [e.target.name]: e.target.value});
        break;
      case "ring":
        setForm({...form, [e.target.name]: e.target.value});

        break;
      case "Menu":
        setForm({...form, [e.target.name]: e.target.value});

        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    let data;
    switch (filterTypeNodo[0]) {
      case "Time":
        data = {...finalDataSave.data, timezone: form.timezone};

        //Actualizando el nodo
        const copyNodes2 = refChart.current.state;
        copyNodes2.nodes[
          finalDataSave.data.nodeId
        ].type = `Time_${form.timezone}`;

        refChart.current.setState(copyNodes2);
        break;
      case "UserCallflow":
        data = {
          ...finalDataSave.data,
          id:
            form.flow === "voicemail"
              ? finalDataSave.data.idCallflow
              : finalDataSave.data.idUser,
          flow: form.flow,
          timeout: form.timeout,
          can_call_self: form.can_call_self,
        };
        finalDataSave.module = form.flow === "voicemail" ? "callflow" : "user";
        break;
      case "Menu":
        data = {
          ...finalDataSave.data,
          media_id: parseInt(form.media_id),
          retries: form.retries,
          max_digits: form.max_digits,
          timeout_retries: form.timeout_retries,
          direct_dialing: form.direct_dialing,
          timeout: form.timeout,
        };

        break;
      case "ring":
        let endpoints = [];
        form.users.map((element) =>
          endpoints.push({
            delay: 0,
            endpoint_type: "user",
            id: element.id,
            timeout: 20,
          })
        );

        data = {
          ...finalDataSave.data,
          endpoints,
          name: form.name,
          strategy: form.strategy,
          timeout: form.timeout,
          ignore_forward: form.ignore_forward,
          repeats: form.repeats,
        };

        break;
      case "externalNumber":
        if (finalDataSave.data.id.split("_").length > 1) {
          data = {
            ...finalDataSave.data,
            id: `Create_${form.destination}`,
            dial_timeout: form.dial_timeout,
            destination: form.destination,
            confirm_call: form.confirm_call,
            keep_original_callerid: form.keep_original_callerid,
          };
        } else {
          //Actualizar la data de nodo
          data = {
            ...finalDataSave.data,
            require_keypress: form.require_keypress,
            keep_caller_id: form.keep_caller_id,
            timeout: form.timeout,
            number: form.number,
          };

          //Guardando la lista de devices que se deben actualizar al guardar el callflow, ya que si se actualiza en el momento puede que el usuario no guarde el callflow
          let copyListOfUpdateDevices = [];

          let findFlag = false;

          listOfUpdateDevices.map((element) => {
            if (element.id === finalDataSave.data.id) {
              findFlag = true;
              copyListOfUpdateDevices.push({
                id: element.id,
                call_forward: {
                  ...saveDevice.call_forward,
                  require_keypress: form.require_keypress,
                  keep_caller_id: form.keep_caller_id,
                  enabled: true,
                  number: form.number,
                },
              });
            } else {
              copyListOfUpdateDevices.push({
                id: element.id,
                call_forward: element.call_forward,
              });
            }
          });

          if (!findFlag)
            copyListOfUpdateDevices.push({
              id: finalDataSave.data.id,
              call_forward: {
                ...saveDevice.call_forward,
                require_keypress: form.require_keypress,
                keep_caller_id: form.keep_caller_id,
                enabled: true,
                number: form.number,
              },
            });

          setListOfUpdateDevices(copyListOfUpdateDevices);
        }

        //Actualizando el nodo
        const copyNodes = refChart.current.state;
        copyNodes.nodes[
          finalDataSave.data.nodeId
        ].type = `externalNumber_${form.destination}`;

        refChart.current.setState(copyNodes);
        break;
      default:
        break;
    }
    const dataCallFlowCopy = dataCallFlow;

    if (activeEdit.childrenUrl === "") {
      dataCallFlowCopy.flow = {
        children: finalDataSave.children,
        data,
        module: finalDataSave.module,
      };
    } else {
      set(dataCallFlowCopy.flow, activeEdit.childrenUrl, {
        children: finalDataSave.children,
        data,
        module: finalDataSave.module,
      });
    }

    setDataCallFlow(dataCallFlowCopy);
    setActiveEdit(false);

    if (form.flow && finalDataSave) {
      const copyNodes = refChart.current.state;
      copyNodes.nodes[finalDataSave.data.nodeId].flowSelected = data.flow;
      refChart.current.setState(copyNodes);

      let nodeSelected = copyNodes.nodes[finalDataSave.data.nodeId];
      if (form.flow === "voicemail" && finalDataSave.data.flow === "custom") {
        const newArrayLinks = Object.keys(copyNodes.links);
        newArrayLinks.find((element) => {
          if (copyNodes.links[element].from.nodeId === nodeSelected.id) {
            deleteNode(copyNodes.nodes[copyNodes.links[element].to.nodeId]);
          }
        });
      }
    }
  };

  const handleChangeCheck = (e) => {
    setForm({...form, users: e});
  };

  return (
    <>
      <p style={{fontSize: "1rem", textAlign: "center"}}>Edit Node</p>

      {filterTypeNodo[0] === "Menu" && (
        <div>
          <label className="mt-2 mb-0 col-12 pl-0 text-left" htmlFor="">
            Select Media
          </label>
          <select
            onChange={(e) => setForm({...form, media_id: e.target.value})}
            value={form.media_id?.toString()}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            placeholder="Type to search..."
          >
            {mediaSounds.map((element, i) => (
              <option key={i} value={element.pk}>
                {element.name}
              </option>
            ))}
          </select>
          <label className="mt-2 mb-0 col-12 pl-0 text-left" htmlFor="">
            Invalid Attempts
            <InfoIcon
              data-tip
              data-for="text0"
              style={{paddingLeft: "0.5rem", fontSize: "21px"}}
            />
          </label>

          <ReactTooltip id="text0">
            <div className="text-left" style={{fontSize: "10px"}}>
              This is the number of attempts a user has when entering an
              <br />
              invalid option. Once they have reached the number of
              <br />
              attempts, they will be routed to the 'Invalid Destination'
              <br />
              if the menu has one set. If the menu does not have one set,
              <br />
              the call will be terminated.
              <br />
            </div>
          </ReactTooltip>

          <select
            onChange={(e) => {
              setForm({...form, retries: e.target.value});
            }}
            value={form.retries}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            placeholder="Type to search..."
          >
            <option value={0}>0</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
          </select>
          <label className="mt-2 mb-0 col-12 pl-0 text-left" htmlFor="">
            Timeout retries
          </label>

          <select
            onChange={(e) => {
              setForm({...form, timeout_retries: e.target.value});
            }}
            value={form.timeout_retries}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            placeholder="Type to search..."
          >
            <option value={0}>0</option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
          </select>
          <label className="mt-2 mb-0 col-12 pl-0 text-left" htmlFor="">
            Max digits
            <InfoIcon
              data-tip
              data-for="text1"
              style={{paddingLeft: "0.5rem", fontSize: "21px"}}
            />
          </label>
          <ReactTooltip id="text1">
            <div className="text-left" style={{fontSize: "10px"}}>
              Amount of seconds before replaying message or going to
              <br />
              'timeout' destination if number of retries has exceeded
              <br /> the maximum amount
            </div>
          </ReactTooltip>
          <select
            onChange={(e) => {
              setForm({...form, max_digits: e.target.value});
            }}
            value={form.max_digits}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            placeholder="Type to search..."
          >
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
            <option value={7}>7</option>
            <option value={8}>8</option>
          </select>
          <label className="mt-2 mb-0 col-12 pl-0 text-left" htmlFor="">
            Timeout (Seconds)
            <InfoIcon
              data-tip
              data-for="text2"
              style={{paddingLeft: "0.5rem", fontSize: "21px"}}
            />
          </label>
          <ReactTooltip id="text2">
            <div className="text-left" style={{fontSize: "10px"}}>
              Allows to shorten the delay after a user dials
              <br />a number
            </div>
          </ReactTooltip>
          <select
            onChange={(e) => {
              setForm({...form, timeout: e.target.value});
            }}
            value={form.timeout?.toString()}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            placeholder="Type to search..."
          >
            <option value={"2000"}>2</option>
            <option value={"2500"}>2.5</option>
            <option value={"3000"}>3</option>
            <option value={"3500"}>3.5</option>
            <option value={"4000"}>4</option>
            <option value={"4500"}>4.5</option>
            <option value={"5000"}>5</option>
            <option value={"5500"}>5.5</option>
            <option value={"6000"}>6</option>
            <option value={"6500"}>6.5</option>
            <option value={"7000"}>7</option>
            <option value={"7500"}>7.5</option>
            <option value={"8000"}>8</option>
            <option value={"8500"}>8.5</option>
            <option value={"9000"}>9</option>
            <option value={"9500"}>9.5</option>
            <option value={"10000"}>10</option>
          </select>

          <div className="d-flex mt-3">
            <CustomInput
              onChange={(e) => {
                setForm({...form, direct_dialing: e.target.checked});
              }}
              checked={form.direct_dialing}
              type="checkbox"
              className="input-check"
              name=""
              id="check2"
            />
            <label className="m-0 d-flex text-left" htmlFor="check2">
              Direct Extension Dialing
              <InfoIcon
                data-tip
                data-for="text10"
                style={{paddingLeft: "0.5rem", fontSize: "21px"}}
              />
            </label>
            <ReactTooltip id="text10">
              <div className="text-left" style={{fontSize: "10px"}}>
                - Allows dialing of extension numbers within the menu
              </div>
            </ReactTooltip>
          </div>
        </div>
      )}
      {filterTypeNodo[0] === "Time" && (
        <>
          <select
            onChange={onChangeForm}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            value={form.timezone}
            placeholder="Type to search..."
          >
            <option value="default">Default</option>
            {timeZone.map((element, i) => (
              <option key={i} value={element}>
                {element}
              </option>
            ))}
          </select>
        </>
      )}
      {filterTypeNodo[0] === "UserCallflow" && (
        <>
          <div>
            <div className=" pt-3">
              <label className="m-0 w-100 text-left" htmlFor="flow">
                If the number is not available:
              </label>

              <select
                onChange={onChangeForm}
                name="flow"
                value={form.flow}
                id="flow"
                type="number"
                className="form-control"
              >
                <option value="voicemail">Go to User`s Voicemail</option>
                <option value="custom">Custom option</option>
              </select>
            </div>
            {form.flow === "custom" && (
              <>
                {" "}
                <div className=" pt-3">
                  <label className="m-0 w-100 text-left" htmlFor="timeout">
                    Ring Time:
                  </label>
                  <input
                    onChange={onChangeForm}
                    name="timeout"
                    value={form.timeout}
                    id="timeout"
                    type="number"
                    className="form-control"
                  />
                </div>
                <div className="d-flex pt-3" style={{alignItems: "center"}}>
                  <label className="m-0 pr-2" htmlFor="exampleCustomCheck">
                    Allow call themselves:
                  </label>
                  <CustomInput
                    onChange={(e) => {
                      setForm({...form, can_call_self: e.target.checked});
                    }}
                    type="checkbox"
                    checked={form.can_call_self}
                    className="input-check"
                    name=""
                    id="exampleCheck1"
                  />
                </div>{" "}
              </>
            )}
          </div>
        </>
      )}

      {filterTypeNodo[0] === "externalNumber" && (
        <div>
          <label
            style={{textAlign: "left", width: "100%"}}
            htmlFor="externalNumber"
          >
            External Number:
          </label>
          <input
            onChange={(e) => {
              setForm({...form, destination: e.target.value});
            }}
            className="form-control"
            value={form.destination}
            id="externalNumber"
            type="number"
          />
          <div className="pt-4">
            <label style={{textAlign: "left", width: "100%"}} htmlFor="timeout">
              Timeout:
            </label>
            <input
              onChange={(e) => {
                setForm({...form, dial_timeout: e.target.value});
              }}
              className="form-control"
              value={form.dial_timeout}
              id="dial_timeout"
              type="number"
            />
          </div>
          <div className="d-flex mt-3">
            <CustomInput
              onChange={(e) => {
                setForm({...form, confirm_call: e.target.checked});
              }}
              checked={form.confirm_call}
              type="checkbox"
              className="input-check"
              name=""
              id="check1"
            />
            <label className="m-0 pr-2 d-flex" htmlFor="check1">
              Confirm Call?
              <InfoIcon
                data-tip
                data-for="text1"
                style={{paddingLeft: "0.5rem", fontSize: "21px"}}
              />
            </label>
            <ReactTooltip id="text1">
              <div className="text-left" style={{fontSize: "10px"}}>
                When receiving a forwarded call, user will
                <br />
                be required to press 1 to answer the call.
                <br />
                This is useful when you are forwarding calls
                <br />
                to a cellphone and want to avoid connecting
                <br />
                to the Cell Phone's voicemail.
              </div>
            </ReactTooltip>
          </div>
          <div className="d-flex mt-3">
            <CustomInput
              onChange={(e) => {
                setForm({...form, keep_original_callerid: e.target.checked});
              }}
              checked={form.keep_original_callerid}
              type="checkbox"
              className="input-check"
              name=""
              id="check2"
            />
            <label className="m-0 d-flex text-left" htmlFor="check2">
              Kepp Caller's Caller ID?
              <InfoIcon
                data-tip
                data-for="text2"
                style={{paddingLeft: "0.5rem", fontSize: "21px"}}
              />
            </label>
            <ReactTooltip id="text2">
              <div className="text-left" style={{fontSize: "10px"}}>
                - If enabled, this will attempt to show the
                <br />
                Caller's Caller ID on the forwarded phone.
                <br />
                - If disabled, the caller ID displayed
                <br />
                will be the system default
              </div>
            </ReactTooltip>
          </div>
        </div>
      )}

      {filterTypeNodo[0] === "ring" && (
        <div>
          <div className=" pt-3">
            <label className="m-0 pr-2 text-left" htmlFor="name">
              Name:
            </label>
            <input
              onChange={onChangeForm}
              name="name"
              value={form.name}
              id="name"
              type="text"
              className="form-control"
            />
          </div>
          <div className=" pt-3">
            <label className="m-0 pr-2 text-left" htmlFor="strategy">
              Strategy:
            </label>
            <select
              value={form.strategy}
              onChange={onChangeForm}
              className="form-control"
              name="strategy"
              id="strategy"
            >
              <option value="">---Select One---</option>
              <option value="single">Single</option>
              <option value="simultaneous">Simultaneous</option>
            </select>
          </div>{" "}
          <div className=" pt-3">
            <label className=" text-left m-0 pr-2" htmlFor="timeout">
              Number of Seconds To Ring:
            </label>
            <input
              onChange={onChangeForm}
              name="timeout"
              value={form.timeout}
              id="timeout"
              type="number"
              className="form-control"
            />
          </div>
          <div className=" pt-3">
            <label className="m-0 pr-2 text-left" htmlFor="strategy">
              Ignore Call Forwarding:
            </label>
            <select
              value={form.ignore_forward}
              onChange={onChangeForm}
              className="form-control"
              name="ignore_forward"
              id="ignore_forward"
            >
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>
          </div>
          <div className=" pt-3">
            <label className="m-0 pr-2 text-left" htmlFor="repeats">
              Repeats:
            </label>
            <input
              onChange={onChangeForm}
              name="repeats"
              value={form.repeats}
              id="repeats"
              type="number"
              className="form-control"
            />
          </div>
          <div className=" pt-3">
            <label className=" text-left m-0 pr-2" htmlFor="repeats">
              Members
            </label>
            <Multiselect
              options={[...usersOptions]}
              onSelect={handleChangeCheck}
              onRemove={handleChangeCheck}
              selectedValues={firstOptions || []}
              displayValue="name"
              placeholder="Start Typing..."
            />
          </div>
        </div>
      )}

      <Button
        onClick={async () => {
          handleSubmit();
        }}
        className=" mt-3 w-100"
        color="primary"
      >
        Save
      </Button>
    </>
  );
}
