import React, { useRef, useState } from 'react'
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from '@material-ui/icons/GetApp';
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from '@material-ui/icons/Pause';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ButtonHover from '../../../../components/utils/ButtonHover';
import { Card } from "reactstrap"
import ModalUtilityDelete from "./ModalUtility/ModalUtilityDelete"
import ModalUtilityDownload from "./ModalUtility/ModalUtilityDownload"
export default function VoicemailDetails({ item, reloadData, getUrlVoiceAudio, deleteVoicemail, downloadVoicemail }) {

    const [playActive, setPlayActive] = useState(false)
    const [time, setTime] = useState(0)
    const [modalDelete, setModalDelete] = useState(false);
    const [modalDownload, setModalDownload] = useState(false);

    const [message, setMessage] = useState(null);
    let refAudio = useRef()



    const onChangeTime = (e) => {

        setTime({ timeProgress: (e.target.currentTime * 100) / parseInt(item.length / 1000), timeSecond: new Date(e.target.currentTime * 1000).toISOString().substr(e.target.currentTime < 3600 ? 14 : 11, e.target.currentTime < 3600 ? 5 : 8) });
        if (refAudio.current && e.target.currentTime === refAudio.current.duration) {
            setTime({ timeProgress: 0, timeSecond: 0 });
            setPlayActive(false)
        }
    }

    const onClickButtonPlay = async () => {
        if (!playActive) await refAudio.current.play();
        else await refAudio.current.pause();

        setPlayActive(!playActive)
    }



    const activeModalDelete = (e) => {
        setModalDelete(e);
        setMessage("Are you sure you want to delete this voice message?");
    };

    const activeModalDownload = (e) => {
        setModalDownload(e);
        setMessage("Are you sure you want to download this voice message?");
    };




    return (
        <Card body>

            <ModalUtilityDelete
                modal={modalDelete}
                setModal={setModalDelete}

                message={message}
                deleteVoicemail={deleteVoicemail}
            />
            <ModalUtilityDownload
                modal={modalDownload}
                setModal={setModalDownload}
                message={message}
                downloadVoicemail={downloadVoicemail}
            />

            <div className="px-3">


                <div className="w-100 d-flex justify-content-between pt-3">

                    <div>

                        <h3>{item.caller_id_name}</h3>

                    </div>

                    <div className="d-flex">
                        <ButtonHover onClick={() => { activeModalDelete(item.pk) }} className="pr-3 custom-pointer" ><DeleteIcon /></ButtonHover>
                        <ButtonHover onClick={() => { activeModalDownload(item.pk) }} className="pr-3 custom-pointer" ><GetAppIcon />  </ButtonHover>
                    </div>
                </div>


                <div className="border rounded p-3 mt-3 d-flex flex-wrap" style={{ lineHeight: "30px" }}>

                    <div className=" d-flex align-items-center"> <span className="px-3"></span>  <AccessTimeIcon /> <span className="px-1">{item.duration_seconds}</span>   </div>
                    <div className=" d-flex align-items-center"> <span className="px-3">|</span> From :  <span className="px-1">{item.caller_id_number}</span>   </div>
                    <div className=" d-flex align-items-center"> <span className="px-3">|</span>  <span className="px-1">{item.timestampFormat}</span>   </div>
                </div>

                <div className="border border-2 containerAudioTag  p-3  mt-4">
                    <audio
                        className="d-none"
                        src={getUrlVoiceAudio(item.pk)}
                        controls
                        type="audio/mpeg"
                        preload="true"
                        ref={refAudio}
                        onTimeUpdate={onChangeTime}
                    />
                    <div style={{ width: "10%" }}> <div onClick={onClickButtonPlay} className="bg-primary button-play mr-2  "> {playActive ? <PauseIcon style={{ color: "white" }} /> : <PlayArrowIcon style={{ color: "white" }} />}   </div></div>
                    <div className="barRange">  <div style={{ width: `${time.timeProgress <= 100 ? time.timeProgress : 0}%` }} className="barRangePositionBackground">. </div>  </div>
                    <div className="containerTime"> {time.timeSecond || "00:00"} /  {item.duration_seconds}  </div>
                </div>

            </div>

        </Card>
    )
}
