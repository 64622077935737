import styled from "styled-components";

export const StyledTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  width: 100%;
  height: 100%;

  cursor: pointer;
`;

export const StyledText = styled.pre`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: ${({width}) => width};
  height: ${({height}) => height};
  min-height: 50;
  margin: 0px;
  color: ${({theme}) => theme.TEXT_NORMAL};
`;

export const StyledForeignObject = styled.foreignObject`
  overflow: visible;
  pointer-events: none;
  * {
    font-family: "Roboto Mono", monospace;
  }
  &.searched {
    border: 2px solid ${({theme}) => theme.TEXT_POSITIVE};
    border-radius: 2px;
  }
  .highlight {
    background-color: rgba(255, 0, 255, 0.5);
    filter: hue-rotate();
  }
  .renderVisible {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 100%;
    height: 100%;

    cursor: pointer;
  }
`;

export const StyledKey = styled.span`
  font-weight: 500;
  color: ${(props) =>
    props.activeColor ? (props.parent ? "orange" : "green") : "#5c87ff"};
`;

export const StyledRow = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 auto;
  text-align: ${(props) => (props.custom ? "center" : "left")};
  display: flex;
  flex-direction: ${(props) => (props.custom ? "column" : "row")};
  align-items: ${(props) => (props.custom ? "center" : "flex-start")};
  height: 100%;
  margin: 0px;
  width: ${({width}) => `${width - 20}px`};
  p {
    opacity: 0.7;
    margin: 0px;
  }
`;

export const StyledButton = styled.button`
  border: 2px solid #5c87ff;
  width: 20px;
  height: 20px;
  background: #2b2c3e;
  border-radius: 50%;
  position: absolute;
  bottom: -10px;
  left: ${(props) => (props.positionX ? `${props.positionX - 10}px` : "10px")};
  z-index: 1000;
  margin: 0 auto;
  cursor: pointer;
`;
