import React, {useEffect, useState} from "react";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import classnames from "classnames";
import {
  Container,
  Card,
  Row,
  Col,
  Label,
  Button,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import toastr from "toastr";
import petition_get from "../../../petitions/petition_get";
import petition_patch from "../../../petitions/petition_patch";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

import SelectSearch from "react-select-search";

import {findAllByTestId} from "@testing-library/react";
import Switch from "react-switch";
import ReactTooltip from "react-tooltip";
import InfoIcon from "@material-ui/icons/Info";

import FeacturesCodes from "./OptionsTab/FeacturesCodes";
import Keys from "../../API/Keys/Keys";
import Webhooks from "./OptionsTab/Webhooks/Webhooks";
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum";

// import { Autocomplete } from "@material-ui/lab/Autocomplete";

export default function GeneralSettings() {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [accountDetails, setAccountDetails] = useState(null);
  const [data, setData] = useState([]);
  const [allTimezone, setAllTimezone] = useState([]);
  const [timeZone, setTimeZone] = useState(null);
  const [music, setMusic] = useState(null);
  const [change, setChange] = useState(false);
  const [max_number_of_users, setMax_number_of_users] = useState("");
  const [saveMax_number_of_users, setSaveMax_number_of_users] = useState("");
  const [current_number_of_users, setCurrent_number_of_users] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [promise, setPromise] = useState(false);
  const [loadingOne, setLoadingOne] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [loadingThree, setLoadingThree] = useState(false);
  const [defaultCallerId, setDefaultCallerId] = useState(null);
  const [defaultNumber, setDefaultNumber] = useState({});
  const [defaultName, setDefaultName] = useState(null);
  const [allNumbers, setAllNumbers] = useState(null);
  const [maxConnect, setMaxConnect] = useState("3");
  const [switchOne, setSwitchOne] = useState(true);
  const [switchTwo, setSwitchTwo] = useState(false);
  const [switchThree, setSwitchThree] = useState(false);
  const [loadingMaxConnect, setLoadingMaxConnect] = useState(false);
  const [loadingRecordCall, setLoadingRecordCall] = useState(false);

  const fuzzySearch = (options) => {
    return (value) => {
      if (!value.length) {
        return options;
      }

      let newArrayOptions = options.filter((element) =>
        element.value.includes(value)
      );

      return newArrayOptions;
    };
  };

  toastr.options = {
    showDuration: 300,
  };

  function toggleCustom(tab) {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  }

  const onChangeFormNumber = (e) => {
    setDefaultNumber(e);
  };

  const onChangeFormName = (e) => {
    setDefaultName(e.target.value);
  };

  useEffect(() => {
    let petitions = async () => {
      let start_key = 0;
      let tempDefaultOutboundNumber = null;
      await petition_get("accountDetails", {id: id})
        .then(({data: result}) => {
          setAccountDetails(result.result);
          setTimeZone(result.result.time_zone || null);
          setMax_number_of_users(result.result.max_number_of_users);
          setSaveMax_number_of_users(result.result.max_number_of_users);
          setCurrent_number_of_users(result.result.current_number_of_users);
          setMusic(result.result.moh_media_id);

          setDefaultCallerId(
            result.result.default_outbound_callerid_name || true
          );
          setDefaultNumber(
            result.result.default_outbound_callerid_number
              ? {
                  name: result.result.default_outbound_callerid_number,
                  value: result.result.default_outbound_callerid_number,
                }
              : ""
          );
          setDefaultName(
            result.result.default_outbound_callerid_name
              ? result.result.default_outbound_callerid_name
              : ""
          );
          if (result.result.default_outbound_callerid_number)
            tempDefaultOutboundNumber =
              result.result.default_outbound_callerid_number;
          if (result.result.max_connect_failures === -1) {
            setSwitchOne(false);
            setMaxConnect("0");
          } else if (result.result.max_connect_failures) {
            setSwitchOne(true);
            setMaxConnect(result.result.max_connect_failures.toString());
          }

          /*   if (result.result.data.call_recording) {
              setSwitchTwo(result.result.data.call_recording.account.any.onnet.enabled)
              setSwitchThree(result.result.data.call_recording.account.any.offnet.enabled)
            } */

          setLoadingOne(true);
        })
        .catch((error) => {
          console.log(error);
        });
      petition_get("musicOnHoldMediaAll", {
        id: id,
        search: "pagination=false",
      })
        .then(({data: result}) => {
          setData(result.result);
          setLoadingTwo(true);
        })
        .catch((error) => {
          console.log(error);
        });
      petition_get("timeZome")
        .then(({data: result}) => {
          setAllTimezone(result.result);
          setLoadingThree(true);
        })
        .catch((error) => {
          console.log(error);
        });

      petition_get("view", {id: id})
        .then(({data: result}) => {
          let preAllData = [];
          result.result.map((element) =>
            preAllData.push({name: element.number, value: element.number})
          );

          let findUsedNumber = result.result.find(
            (element) => element.number === tempDefaultOutboundNumber
          );

          if (!findUsedNumber)
            preAllData.push({
              name: tempDefaultOutboundNumber,
              value: tempDefaultOutboundNumber,
            });

          preAllData.push({name: "Empty", value: ""});

          setAllNumbers(preAllData);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    petitions();
  }, [promise]);

  const save = () => {
    let promises = [];
    setDisabledButton(true);
    let dataSend = {
      time_zone: timeZone,
      max_number_of_users,
      music_on_hold_id: parseInt(music),
    };
    if (dataSend.max_number_of_users !== saveMax_number_of_users) {
      if (dataSend.max_number_of_users > saveMax_number_of_users)
        dataSend.action = "upgrade";
      else dataSend.action = "downgrade";
    }
    promises.push(
      petition_patch("updateAccountSettings", {id: id, data: dataSend})
    );
    Promise.all(promises)
      .then((values) => {
        setChange(false);
        toastr.success("Success", "Success");
        setDisabledButton(false);
      })
      .catch((reason) => {
        console.log(reason);
        toastr.error("Error", "Error");
        setDisabledButton(false);
      });
  };

  const saveCallerID = () => {
    setDisabledButton(true);
    let dataNumber = {
      default_outbound_callerid_name: defaultName,
      default_outbound_callerid_number: defaultNumber.value,
    };
    petition_patch("updateAccountSettings", {
      id: id,
      data: dataNumber,
    })
      .then((values) => {
        setChange(false);
        toastr.success("Success", "Success");
        setDisabledButton(false);
      })
      .catch((reason) => {
        console.log(reason);
        toastr.error("Error", "Error");
        setDisabledButton(false);
      });
  };

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{marginTop: "1.5em"}}>No</p>
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        <p style={{marginTop: "1.5em"}}>Yes</p>
      </div>
    );
  };

  const updateMaxConnect = () => {
    setLoadingMaxConnect(true);
    petition_patch("updateAccountSettings", {
      id: id,
      data: {max_connect_failures: !switchOne ? -1 : parseInt(maxConnect)},
    })
      .then((values) => {
        setLoadingMaxConnect(false);
        toastr.success("Success", "Success");
      })
      .catch((reason) => {
        setLoadingMaxConnect(false);
        toastr.error("Error", "Error");
      });
  };

  return (
    <>
      <div className="page-content">
        <CustomBreadCrum title="Phone System" subtitle="General Settings" />

        {loadingOne &&
        loadingTwo &&
        loadingThree &&
        defaultCallerId &&
        allNumbers ? (
          <>
            <Nav
              tabs
              className="nav-tabs-custom  flex-column flex-md-row  text-center  "
            >
              <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{cursor: "pointer"}}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1");
                  }}
                >
                  <span>General</span>
                </NavLink>
              </NavItem>
              <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{cursor: "pointer"}}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2");
                  }}
                >
                  <span>Caller ID</span>
                </NavLink>
              </NavItem>
              <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{cursor: "pointer"}}
                  className={classnames({
                    active: customActiveTab === "5",
                  })}
                  onClick={() => {
                    toggleCustom("5");
                  }}
                >
                  <span>Feature Codes</span>
                </NavLink>
              </NavItem>
              <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{cursor: "pointer"}}
                  className={classnames({
                    active: customActiveTab === "6",
                  })}
                  onClick={() => {
                    toggleCustom("6");
                  }}
                >
                  <span>Api Keys</span>
                </NavLink>
              </NavItem>
              <NavItem className="pt-3 pt-md-0">
                <NavLink
                  style={{cursor: "pointer"}}
                  className={classnames({
                    active: customActiveTab === "7",
                  })}
                  onClick={() => {
                    toggleCustom("7");
                  }}
                >
                  <span> Webhooks </span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={customActiveTab}>
              <TabPane tabId="1" className="p-3">
                <Row>
                  <Col
                    className="col-12 row d-flex mt-5"
                    style={{alignItems: "center"}}
                  >
                    <Col className="col-12 col-md-3 ">
                      <Label style={{margin: "0"}}>Time Zone</Label>
                    </Col>
                    <Col className="mt-3 mt-md-0 col-12 col-md-6  col-lg-5">
                      <select
                        className="form-control"
                        name=""
                        id=""
                        value={timeZone}
                        onChange={(e) => {
                          setTimeZone(e.target.value);
                        }}
                      >
                        {allTimezone.map((element, i) => (
                          <option key={i} value={element}>
                            {element}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Col>
                  <Col
                    className="  col-12 row d-flex mt-4"
                    style={{alignItems: "center"}}
                  >
                    <Col className="col-12 col-md-3">
                      <Label style={{margin: "0"}}>SIP Realm / Domain:</Label>
                    </Col>
                    <Col className="mt-3 mt-md-0 col-12 col-md-6  col-lg-5">
                      <p className="m-0">{accountDetails.realm}</p>
                    </Col>
                  </Col>
                  <Col
                    className=" col-12 row  d-flex mt-4"
                    style={{alignItems: "center"}}
                  >
                    <Col className="col-12 col-md-3">
                      <Label style={{margin: "0"}}>Max Number of Users</Label>
                    </Col>
                    <Col className="mt-3 mt-md-0 col-12 col-md-6 d-flex col-lg-5">
                      <input
                        style={{width: "40px"}}
                        type="text"
                        className="form-control"
                        // placeholder="10"
                        value={max_number_of_users}
                        onChange={(e) => {
                          setMax_number_of_users(e.target.value);
                        }}
                        disabled={!change}
                      />
                      <Button
                        className="btn  ml-4"
                        onClick={() => {
                          setChange(!change);
                          if (change) {
                            setMax_number_of_users(
                              accountDetails.max_number_of_users
                            );
                          }
                        }}
                        color={!change ? "primary" : "danger"}
                      >
                        {!change ? "Change" : "Cancel"}
                      </Button>
                    </Col>
                  </Col>
                  <Col
                    className=" col-12 row d-flex mt-4"
                    style={{alignItems: "center"}}
                  >
                    <Col className="col-12 col-md-3">
                      <Label style={{margin: "0"}}>
                        Current Number of Users
                      </Label>
                    </Col>
                    <Col className="mt-3 mt-md-0 col-12 col-md-6 d-flex col-lg-5">
                      <input
                        style={{width: "40px"}}
                        type="text"
                        className="form-control"
                        value={current_number_of_users}
                        onChange={(e) =>
                          setCurrent_number_of_users(e.target.value)
                        }
                        disabled={true}
                      />
                    </Col>
                  </Col>
                  <Col
                    className=" col-12 row d-flex mt-4"
                    style={{alignItems: "center"}}
                  >
                    <Col className="col-12 col-md-3">
                      <Label style={{margin: "0"}}>
                        Default Music on Hold:
                      </Label>
                    </Col>
                    <Col className="mt-3 mt-md-0 col-12 col-md-6 d-flex col-lg-5">
                      <select
                        className="form-control"
                        name=""
                        id=""
                        value={music}
                        onChange={(e) => setMusic(e.target.value)}
                      >
                        {data.map((element, i) => (
                          <option key={i} value={element.pk}>
                            {element.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Col>
                  <Col className="col-12  text-right mt-4">
                    <button
                      className="btn btn-primary ml-4 pl-4 pr-4 font-size-16"
                      onClick={() => save()}
                      disabled={disabledButton}
                    >
                      {disabledButton && (
                        <Spinner
                          style={{marginRight: "0.5em"}}
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Save
                    </button>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2" className="p-3">
                <Row>
                  <Col
                    className="col-12 row d-flex mt-5"
                    style={{alignItems: "center"}}
                  >
                    <Col className="col-12 col-md-3 ">
                      <Label style={{margin: "0"}}>Default Name:</Label>
                    </Col>
                    <Col className="mt-3 mt-md-0 col-12 col-md-6  col-lg-5">
                      <input
                        type="text"
                        className="form-control"
                        value={defaultName}
                        onChange={onChangeFormName}
                      />
                    </Col>
                  </Col>
                  <Col
                    className="col-12 row d-flex mt-5"
                    style={{alignItems: "center"}}
                  >
                    <Col className="col-12 col-md-3 ">
                      <Label style={{margin: "0"}}>Default Number:</Label>
                    </Col>

                    <Col className="mt-3 mt-md-0 col-12 col-md-6  col-lg-5">
                      <SelectSearch
                        placeholder="Start Typing..."
                        search={true}
                        onChange={(e) => {
                          setDefaultNumber({value: e});
                        }}
                        options={allNumbers}
                        filterOptions={fuzzySearch}
                        value={defaultNumber !== "" ? defaultNumber.value : ""}
                        name="language"
                      />
                    </Col>
                  </Col>
                  <Col className="col-12  text-right mt-4">
                    <button
                      className="btn btn-primary ml-4 pl-4 pr-4 font-size-16"
                      onClick={() => saveCallerID()}
                      disabled={!defaultNumber}
                    >
                      {disabledButton && (
                        <Spinner
                          style={{marginRight: "0.5em"}}
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Save
                    </button>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3" className="p-3">
                <Row>
                  <Col className="col-12 pt-3">
                    <label
                      htmlFor="time_window_start"
                      className="m-0 pr-4  w-100 mb-2"
                    >
                      Log Users Out of Queues Automatically
                      <InfoIcon
                        data-tip
                        data-for="text2"
                        style={{paddingLeft: "0.5rem", fontSize: "21px"}}
                      />
                    </label>
                    <ReactTooltip id="text2">
                      <div className="text-left" style={{fontSize: "10px"}}>
                        Enable this option if you wish to remove Users
                        <br />
                        from a Queue when they fail to answer a phone a
                        <br />
                        certain number of times specified below.
                      </div>
                    </ReactTooltip>
                    <Switch
                      uncheckedIcon={<Offsymbol />}
                      className="mr-1"
                      checkedIcon={<OnSymbol />}
                      onColor="#626ed4"
                      onChange={() => {
                        setSwitchOne(!switchOne);
                      }}
                      height={24}
                      width={48}
                      checked={switchOne}
                    />
                  </Col>
                  {switchOne && (
                    <Col className="col-12 col-md-6 pt-3">
                      <label htmlFor="start_date">
                        Number of Failed Calls To Log Out
                      </label>
                      <div className="d-flex">
                        <input
                          onChange={(e) => {
                            setMaxConnect(e.target.value);
                          }}
                          value={maxConnect}
                          className="form-control"
                          type="number"
                        />
                      </div>
                    </Col>
                  )}
                  <Col className="col-12  text-right mt-4">
                    <button
                      className="btn btn-primary ml-4 pl-4 pr-4 font-size-16"
                      onClick={() => updateMaxConnect()}
                      disabled={loadingMaxConnect}
                    >
                      {loadingMaxConnect && (
                        <Spinner
                          style={{marginRight: "0.5em"}}
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      Save
                    </button>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="5" className="p-3">
                {accountDetails && (
                  <FeacturesCodes accountDetails={accountDetails} />
                )}
              </TabPane>
              <TabPane tabId="6" className="p-3">
                <Keys />
              </TabPane>
              <TabPane tabId="7" className="p-3">
                <Webhooks />
              </TabPane>
            </TabContent>
          </>
        ) : (
          <div style={{textAlign: "center"}}>
            <Spinner
              animation="grow"
              style={{margin: "10vh auto", height: "10vh", width: "10vh"}}
            />
          </div>
        )}
      </div>
    </>
  );
}
