import React, { useState, useEffect } from 'react'
import { Card, Table, CustomInput, Button } from "reactstrap"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ButtonHover from "../../../../components/utils/ButtonHover";
import petition_get from "../../../petitions/petition_get";
import toastr from "toastr";
import Loading from "../../../../components/utils/Loading"
export default function SelectCallReports({ setStep, users, setDataReport }) {

    toastr.options = {
        showDuration: 300,
    };
    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const [selected, setSelected] = useState([]);
    const [available, setAvailable] = useState([]);

    const [checkAll, setCheckAll] = useState({
        available: true,
        selected: true,
    });

    const [timeframe, setTimeframe] = useState({
        minTimeframeStart: "",
        minTimeframeEnd: "",
        maxTimeframeStart: "",
        maxTimeframeEnd: "",
        timeStart: "",
        timeEnd: "",
    });

    const handleAllCheck = (e) => {
        if (e.name === "available") {
            setCheckAll({ ...checkAll, available: e.checked });
            const newAvailable = [];
            available.map((element) => {
                element.check = e.checked;
                newAvailable.push(element);
            });
            setAvailable(newAvailable);
        } else {
            setCheckAll({ ...checkAll, selected: e.checked });
            const newSelected = [];

            selected.map((element) => {
                element.check = e.checked;
                newSelected.push(element);
            });
            setSelected(newSelected);
        }
    };

    const handleCheck = (value) => {

        if (value.id) {
            if (value.type === "selected") {
                let allSelected = selected.map((element, i) => {
                    if (element.id === value.id) {
                        element.check = !element.check;
                    }
                    return element;
                });
                setSelected(allSelected);
            } else {
                let allAvailable = available.map((element, i) => {
                    if (element.id === value.id) {
                        element.check = !element.check;
                    }
                    return element;
                });
                setAvailable(allAvailable);
            }
        }
    };



    const left = () => {
        setCheckAll({ available: false, selected: false });
        let allAvailable = available;
        let allSelected = [];

        selected.forEach((element, i) => {
            if (element.check) {
                element.check = false;
                allAvailable.push(element);
                // allSelected.splice( i-1, 1 );
            } else {
                allSelected.push(element);
            }
            if (selected.length === i + 1) {
                allAvailable.map((element) => {
                    element.check = false;
                });
                setAvailable(allAvailable);
                setSelected(allSelected);
            }
        });
    };
    const right = () => {
        setCheckAll({ available: false, selected: false });
        let allAvailable = [];
        let allSelected = selected;

        available.forEach((element, i) => {
            if (element.check) {
                element.check = false;
                allSelected.push(element);
                // allSelected.splice( i-1, 1 );
            } else {
                allAvailable.push(element);
            }
            if (available.length === i + 1) {
                allSelected.map((element) => {
                    element.check = false;
                });
                setAvailable(allAvailable);
                setSelected(allSelected);
            }
        });
    };

    const onChangeDate = (e) => {
        if (e.target.name === "timeStart") {
            let newTimeEnd;
            if (
                e.target.value.split("-")[1] === timeframe.timeEnd.split("-")[1] &&
                parseInt(e.target.value.split("-")[2]) >
                parseInt(timeframe.timeEnd.split("-")[2])
            ) {
                newTimeEnd = e.target.value;
            } else if (
                parseInt(e.target.value.split("-")[1]) >
                parseInt(timeframe.timeEnd.split("-")[1])
            ) {
                newTimeEnd = e.target.value;
            } else {
                newTimeEnd = timeframe.timeEnd;
            }

            setTimeframe({
                ...timeframe,
                [e.target.name]: e.target.value,
                timeEnd: newTimeEnd,
                minTimeframeEnd: e.target.value,
            });
        } else {
            setTimeframe({ ...timeframe, [e.target.name]: e.target.value });
        }
    };


    useEffect(() => {

        const dateTimeframe = new Date();
        const getTimeStart = `${dateTimeframe.getFullYear()}-${dateTimeframe.getMonth() + 1 < 10
            ? `0${dateTimeframe.getMonth() + 1}`
            : `${dateTimeframe.getMonth() + 1}`
            }-${dateTimeframe.getDate() < 10
                ? `0${dateTimeframe.getDate() - 1}`
                : `${dateTimeframe.getDate() - 1}`
            }`;
        const getTimeEnd = `${dateTimeframe.getFullYear()}-${dateTimeframe.getMonth() + 1 < 10
            ? `0${dateTimeframe.getMonth() + 1}`
            : `${dateTimeframe.getMonth() + 1}`
            }-${dateTimeframe.getDate() < 10
                ? `0${dateTimeframe.getDate()}`
                : `${dateTimeframe.getDate()}`
            }`;

        const getMinDateTimeFrameStart = `${dateTimeframe.getFullYear()}-${dateTimeframe.getMonth() < 10
            ? `0${dateTimeframe.getMonth()}`
            : `${dateTimeframe.getMonth()}`
            }-${dateTimeframe.getDate() < 10
                ? `0${dateTimeframe.getDate()}`
                : `${dateTimeframe.getDate()}`
            }`;
        const getMinDateTimeFrameEnd = getTimeStart;

        const getMaxDateTimeFrameStart = `${dateTimeframe.getFullYear()}-${dateTimeframe.getMonth() + 1 < 10
            ? `0${dateTimeframe.getMonth() + 1}`
            : `${dateTimeframe.getMonth() + 1}`
            }-${dateTimeframe.getDate() < 10
                ? `0${dateTimeframe.getDate()}`
                : `${dateTimeframe.getDate()}`
            }`;
        const getMaxDateTimeFrameEnd = `${dateTimeframe.getFullYear()}-${dateTimeframe.getMonth() + 1 < 10
            ? `0${dateTimeframe.getMonth() + 1}`
            : `${dateTimeframe.getMonth() + 1}`
            }-${dateTimeframe.getDate() < 10
                ? `0${dateTimeframe.getDate()}`
                : `${dateTimeframe.getDate()}`
            }`;

        setTimeframe({
            ...timeframe,
            timeStart: getTimeStart,
            timeEnd: getTimeEnd,
            minTimeframeStart: getMinDateTimeFrameStart,
            minTimeframeEnd: getMinDateTimeFrameEnd,
            maxTimeframeStart: getMaxDateTimeFrameStart,
            maxTimeframeEnd: getMaxDateTimeFrameEnd,
        });




        /*        petition_get("callReport", { id: id })
                   .then(({ data: result }) => {
                     
                       setData(result.result);
                       setDataFilter(result.result); 
                     
                   })
                   .catch((error) => {
                       setLoading(false);
                       console.log(error.response);
                       if (error.response.status === 404) {
                           setError404(true);
                       }
                   }); */


    }, [])


    useEffect(() => {

        if (users) setSelected(users);

    }, [users])

    if (!users) return <Loading />


    const handleSubmit = () => {

        if (timeframe.timeStart === "" || timeframe.timeEnd === "") {
            return toastr.error("Select Timeframe", "Error");
        }

        let filterSelected = selected.filter(element => element.check)
        setDataReport({ selected: filterSelected, timeframe })

    }



    return (
        <Card body >
            <div className="row">
                <div className="col-12 col-md-6">
                    <Table hover className="table mb-0">
                        <thead className="thead-light">
                            <th style={{ textAlign: "center" }}>
                                <CustomInput
                                    onChange={(e) => {
                                        handleAllCheck(e.target);
                                    }}
                                    type="checkbox"
                                    className="input-check"
                                    name="selected"
                                    id="GeneralSelected"
                                    checked={checkAll.selected}
                                />
                            </th>
                            <th style={{ textAlign: "center" }}>Selected</th>
                        </thead>
                        <tbody className="border">
                            {selected.map((element, i) => (
                                <tr key={i}>
                                    <th style={{ textAlign: "center" }}>
                                        <CustomInput
                                            onChange={(e) => {
                                                handleCheck({
                                                    id: element.id,
                                                    evento: e.target.checked,
                                                    type: "selected",
                                                });
                                            }}
                                            type="checkbox"
                                            className="input-check"
                                            name=""
                                            id={element.id}
                                            checked={element.check}
                                        />
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                        <label
                                            htmlFor={element.id}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {element.first_name +
                                                " " +
                                                element.last_name}
                                        </label>
                                    </th>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="col-12 col-md-6 p-2 border">
                    <h3>Select Timeframe</h3>

                    <div className="mt-3">
                        <label htmlFor="">Start Date:</label>

                        <input
                            type="date"
                            name="timeStart"
                            onChange={onChangeDate}
                            value={timeframe.timeStart}
                            /*  min={timeframe.minTimeframeStart}
                             max={timeframe.maxTimeframeStart} */
                            className="form-control"
                            id=""
                        />
                    </div>
                    <div className="mt-3">
                        <label htmlFor="">End Date:</label>

                        <input
                            type="date"
                            name="timeEnd"
                            onChange={onChangeDate}
                            value={timeframe.timeEnd}
                            /*  min={timeframe.minTimeframeEnd}
                             max={timeframe.maxTimeframeEnd} */
                            className="form-control"
                            id=""
                        />
                    </div>
                </div>
            </div>
            <div className="text-right pt-2">
                <Button
                    color="primary"
                    onClick={handleSubmit}
                >
                    Run Report
                </Button>
            </div>

        </Card>
    )
}
