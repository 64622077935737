import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  CardTitle,
  Button,
  Table,
  FormGroup,
  Input,
  Label,
  CardBody,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../../../components/Common/Breadcrumb";

//Icons
import PhoneIcon from "@material-ui/icons/Phone";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import UserDetails from "./UserDetails/UserDetails";
import CallSettings from "./CallSettings/CallSettings";
import FaxSettings from "./FaxSettings/FaxSettings";
import petition_get from "../../../../petitions/petition_get";
import petition_patch from "../../../../petitions/petition_patch";

export default function SettingsAndDevices({ setEditUser, infoUser, setDeleteUser, defaultTimeZone }) {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [selector, setSelector] = useState(1);
  const [myDevices, setMyDevices] = useState([]);
  const [accountDetails, setAccountDetails] = useState([]);

  useEffect(() => {
    petition_get("devicesUsersEdit", { id: id, owner_id: infoUser.pk })
      .then(({ data: result }) => {
        let filterDevices = result.result.filter(element => element.device_type !== "web_phone" && element.device_type !== "app_phone")

        setMyDevices(filterDevices);
      })
      .catch((error) => {
        console.log(error.response);
      });
    petition_get("accountDetails", { id: id })
      .then(({ data: result }) => {
        setAccountDetails(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onClickCard = (type) => {
    setSelector(type);
  };

  return (
    <React.Fragment>
      <Row>

        <Col sm="12" className="mb-2">
          <Button color="danger" onClick={() => setEditUser(false)}>
            Back
          </Button>
        </Col>

        <Col md={3}>
          <Card
            onClick={() => {
              onClickCard(1);
            }}
            body
            className={`${selector === 1 && "bg-primary"}`}
            style={{ cursor: "pointer" }}
          >
            {" "}
            <PersonOutlineOutlinedIcon
              style={{
                margin: "0 auto",
                fontSize: "4rem",
                color: `${selector === 1 ? "white" : "inherit"}`,
              }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: "1rem",
                color: `${selector === 1 ? "white" : "inherit"}`,
              }}
            >
              User Details
            </p>
          </Card>
          <Card
            style={{ cursor: "pointer" }}
            body
            onClick={() => {
              onClickCard(2);
            }}
            className={`${selector === 2 && "bg-primary"}`}
          >
            <PhoneIcon
              style={{
                margin: "0 auto",
                fontSize: "4rem",
                color: `${selector === 2 ? "white" : "inherit"}`,
              }}
            />
            <p
              style={{
                textAlign: "center",
                fontSize: "1rem",
                color: `${selector === 2 ? "white" : "inherit"}`,
              }}
            >
              Call Settings
            </p>
          </Card>
          {/* <Card
            style={{ cursor: "pointer" }}
            body
            onClick={() => {
              onClickCard(3);
            }}
            className={`${selector === 3 && "bg-primary"}`}
          >
            <i
              className="fas fa-fax"
              style={{
                margin: "0 auto",
                fontSize: "4rem",
                color: `${selector === 3 ? "white" : "inherit"}`,
              }}
            ></i>
            <p
              style={{
                textAlign: "center",
                fontSize: "1rem",
                color: `${selector === 3 ? "white" : "inherit"}`,
              }}
            >
              Fax Settings
            </p>
          </Card> */}
        </Col>
        {/* /////////////////////////////////// Diego */}
        <Col md={9}>
          {selector === 1 && (
            <>
              <UserDetails
                infoUser={infoUser}
                id={id}
                setEditUser={setEditUser}
                setDeleteUser={setDeleteUser}
                defaultTimeZone={defaultTimeZone}
              />
            </>
          )}
          {selector === 2 && (
            <>
              <CallSettings
                infoUser={infoUser}
                myDevices={myDevices}
                id={id}
                accountDetails={accountDetails}
              />
            </>
          )}
          {/* {selector === 3 && <FaxSettings />} */}
        </Col>
      </Row>
    </React.Fragment>
  );
}
