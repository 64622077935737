import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

// reactstrap
import {Row, Col, Dropdown, DropdownToggle, DropdownMenu} from "reactstrap";

//Images
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logo from "../../assets/images/itpFiber/itp_white.png";
import logoLight from "../../assets/images/itpFiber/itp_white.png";
import logoLightSvg from "../../assets/images/itpFiber/itp-logo-black.png";
import logoDark from "../../assets/images/itpFiber/itp-logo-black.png";

//petitions
import petition_get from "../../pages/petitions/petition_get";

import ModalUtilityLegacy from "../../pages/Home/ModalUtility/ModalUtilityLegacy";

// Redux Store
import {
  changeLayout,
  changeSidebarTheme,
  changeTopbarTheme,
  toggleRightSidebar,
  changeLayoutWidth,
  showRightSidebarAction,
} from "../../store/actions";
import SelectFiberModal from "../../pages/Fiber/SelectFiberModal";

//i18n
import {withNamespaces} from "react-i18next";
import ModalUtil from "../../pages/Fiber/SelectFiberModalTwo";
import ModalVoice from "../utils/modal";

class Header extends Component {
  constructor(props) {
    super(props);
    // this.history = useHistory();
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
    this.state = {
      isSearch: false,
      typeServices: [],
      onlyFiber: [],
      onlyVoice: <h1>test</h1>,
      ModalVoice: false,
      ModalLegacy: false,
      ModalUtilLegacy: false,

      SelectFiberModal: false,
      ModalUtil: false,
      servicesITPVoice: [],
      selectedService: "",
      servicesITPVoiceLegacy: [],
    };
  }

  SelectFiberModalClose = () => {
    this.setState({SelectFiberModal: false});
  };
  ModalVoiceClose = () => {
    this.setState({ModalVoice: false});
  };

  ModalUtilClose = () => {
    this.setState({ModalUtil: false, selectedService: ""});
  };
  ModalUtilCloseLegacy = () => {
    this.setState({ModalUtilLegacy: false, selectedService: ""});
  };
  componentDidMount() {
    let services = [];
    let onlyFiber = [];
    let servicesITPVoice = [];
    let servicesITPVoiceLegacy = [];
    petition_get("services", "")
      .then(({data: {result}}) => {
        result.forEach((element, i) => {
          if (
            services.find(
              (serv) => serv.category === element.product.category
            ) === undefined
          ) {
            if (
              element.product.category === "Cloud" ||
              element.product.category === "Phone" ||
              element.product.category === "Fiber SMB" ||
              element.product.category === "Fiber DIA" ||
              element.product.category === "Voice" ||
              element.product.category === "Voice Legacy"
            ) {
              services.push({
                category: element.product.category,
                pk: element.pk,
                api_id: element.api_id,
              });
              /*   services.push(element.product.category); */
            }
          }
          if (
            element.product.category === "Fiber SMB" ||
            element.product.category === "Fiber DIA"
          ) {
            onlyFiber.push(element);
          }
        });
        result.forEach((element, i) => {
          if (element.product.category === "Voice") {
            servicesITPVoice.push(element);
          }
          if (
            element.product.category === "Fiber SMB" ||
            element.product.category === "Fiber DIA"
          ) {
            onlyFiber.push(element);
          }
          if (element.product.category === "Voice Legacy") {
            servicesITPVoiceLegacy.push(element);
          }
        });
        this.setState({
          typeServices: services,
          onlyFiber: onlyFiber,
          servicesITPVoice: servicesITPVoice,
          servicesITPVoiceLegacy: servicesITPVoiceLegacy,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  toggleSearch = () => {
    this.setState({isSearch: !this.state.isSearch});
  };
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  BillingOption() {
    return (
      <button
        onClick={() => {
          window.location.href = "/Billing";
        }}
        className="step-billing btn header-item waves-effect"
      >
        Billing
      </button>
    );
  }

  menuOptions() {
    return (
      <Dropdown
        className=" d-lg-inline-block ml-1"
        isOpen={this.state.socialDrp}
        toggle={() => {
          this.setState({socialDrp: !this.state.socialDrp});
        }}
      >
        <DropdownToggle
          className="btn header-item waves-effect menuMyServices"
          caret
          tag="button"
        >
          {this.props.t("My Services")}{" "}
          {/* <i className="mdi mdi-chevron-down"></i> */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg" right>
          <div className="px-lg-2">
            <Row className="no-gutters ">
              {}
              <Col
                style={{
                  display:
                    this.state.typeServices.find(
                      (serv) => serv.category === "Fiber SMB"
                    ) !== undefined ||
                    this.state.typeServices.find(
                      (serv) => serv.category === "Fiber Dia"
                    ) !== undefined
                      ? null
                      : "none",
                }}
              >
                {/* <Link className="dropdown-icon-item" to="/fiberSMB">
                    <i
                      className="fas fa-network-wired"
                      onClick={() => {}}
                      style={{ fontSize: "1.5em" }}
                    ></i>
                    <span>Fiber Internet</span>
                  </Link> */}
                <Link
                  className="dropdown-icon-item"
                  onClick={() => {
                    // this.setState({ SelectFiberModal: true });
                    this.setState({ModalUtil: true});
                  }}
                >
                  <i
                    className="fas fa-network-wired"
                    style={{fontSize: "1.5em"}}
                  ></i>
                  <span>Fiber Internet</span>
                </Link>
              </Col>
              <Col
                style={{
                  display:
                    this.state.typeServices.find(
                      (serv) => serv.category === "Phone"
                    ) !== undefined
                      ? null
                      : "none",
                }}
              >
                <Link className="dropdown-icon-item" to="/Phone">
                  <i
                    className="fas fa-phone"
                    onClick={() => {}}
                    style={{fontSize: "1.5em"}}
                  ></i>
                  <span>Phone</span>
                </Link>
              </Col>
              {/* <Row className="no-gutters"> */}
              <Col
                style={{
                  display:
                    this.state.typeServices.find(
                      (serv) => serv.category === "Cloud"
                    ) !== undefined
                      ? null
                      : "none",
                }}
              >
                <Link className="dropdown-icon-item" to="/Cloud">
                  <i
                    className="fab fa-mixcloud"
                    onClick={() => {}}
                    style={{fontSize: "1.5em"}}
                  ></i>
                  <span>Cloud</span>
                </Link>
              </Col>
              {/* <Col>
                <Link className="dropdown-icon-item" to="/Billing">
                 //<img src={dropbox} alt="dropbox" /> 
                  <i
                    className="fas fa-book"
                    onClick={() => {}}
                    style={{fontSize: "1.5em"}}
                  ></i>
                  <span>My ITP</span>
                </Link>
              </Col> */}
              {this.state.typeServices.map((serv) => {
                if (serv.category === "Voice") {
                  return (
                    <Col
                      key={serv.pk}
                      style={{
                        display:
                          this.state.typeServices.find(
                            (serv) => serv.category === "Voice"
                          ) !== undefined
                            ? null
                            : "none",
                      }}
                    >
                      <Link
                        className="dropdown-icon-item"
                        // to={`/ITPVoice?id=${serv.api_id}`}
                        onClick={() => {
                          if (this.state.servicesITPVoice.length === 1) {
                            window.location.href =
                              "/DashboardUsers?id=" +
                              this.state.servicesITPVoice[0].api_id;
                          } else {
                            this.selectedVoice();
                          }
                        }}
                      >
                        {/* <img src={dropbox} alt="dropbox" /> */}
                        <i
                          className="fas fa-book"
                          onClick={() => {}}
                          style={{fontSize: "1.5em"}}
                        ></i>
                        <span>ITP Voice </span>
                        <span>{`(${serv.pk})`}</span>
                      </Link>
                    </Col>
                  );
                }
              })}
              {this.state.typeServices.map((serv) => {
                if (serv.category === "Voice Legacy") {
                  return (
                    <Col
                      key={serv.pk}
                      style={{
                        display:
                          this.state.typeServices.find(
                            (serv) => serv.category === "Voice Legacy"
                          ) !== undefined
                            ? null
                            : "none",
                      }}
                    >
                      <Link
                        className="dropdown-icon-item"
                        // to={`/ITPVoice?id=${serv.api_id}`}
                        onClick={() => {
                          if (this.state.servicesITPVoiceLegacy.length === 1) {
                            window.location.href =
                              "/DashboardITPVoiceLegacy?id=" +
                              this.state.servicesITPVoiceLegacy[0].pk;
                          } else {
                            this.selectedLegacy();
                          }
                        }}
                      >
                        {/* <img src={dropbox} alt="dropbox" /> */}
                        <i
                          className="fas fa-book"
                          onClick={() => {}}
                          style={{fontSize: "1.5em"}}
                        ></i>
                        <span>ITP Voice Legacy</span>
                        <span>{`(${serv.pk})`}</span>
                      </Link>
                    </Col>
                  );
                }
              })}
            </Row>
          </div>
        </DropdownMenu>
      </Dropdown>
    );
  }

  selectedVoice() {
    this.setState({
      ModalUtil: true,
      ModalVoice: true,
      selectedService: "itp_voice",
    });
  }

  selectedLegacy() {
    this.setState({
      ModalUtilLegacy: true,
      ModalLegacy: true,
      selectedService: "itp_voice_legacy",
    });
  }

  render() {
    return (
      <React.Fragment>
        <SelectFiberModal
          success_msg={this.state.SelectFiberModal}
          title={"test"}
          onCancel={this.SelectFiberModalClose}
        />
        <ModalUtil
          modal={this.state.ModalUtil}
          spinner={false}
          toggle={this.ModalUtilClose}
          cancelButton={"Close"}
          selectedService={this.state.selectedService}
          modalbody={
            this.state.ModalVoice === true
              ? this.state.servicesITPVoice
              : this.state.onlyFiber
          }
        />

        <ModalUtilityLegacy
          modal={this.state.ModalUtilLegacy}
          spinner={false}
          close={this.ModalUtilCloseLegacy}
          selectedService={this.state.selectedService}
          modalbody={this.state.servicesITPVoiceLegacy}
        />
        {/* <ModalVoice
        modal={this.state.ModalVoice} modalbody={this.state.onlyVoice}  title={"test"} spinner={false}
        /> */}
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/Home" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="33" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="33" />
                  </span>
                </Link>

                <Link to="/Home" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="33" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="33" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>

            <div className="d-flex">
              {this.BillingOption()}
              {this.menuOptions()}{" "}
              {/* <div className="dropdown d-none d-lg-inline-block ml-1">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div> */}
              {/* <LanguageDropdown />

            

              <NotificationDropdown /> */}
              <ProfileMenu />
              {/*   <div className="dropdown d-inline-block">
                <button
                  onClick={this.toggleRightbar}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div> */}
              {/*   <div className="dropdown d-inline-block">
                <button
                  onClick={() => { this.props.showRightSidebarAction(!this.props.showRightSidebar); }}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle waves-effect" >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div> */}
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const {layoutType} = state.Layout;
  return {layoutType};
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleRightSidebar,
  changeTopbarTheme,
  changeSidebarTheme,
})(withNamespaces()(Header));
