import React from 'react'
import SidebarComponent from '../../../../components/SidebarComponent/SidebarComponent'
import CustomBreadCrum from '../../../../components/utils/CustomBreadCrum/CustomBreadCrum'
import Loading from '../../../../components/utils/Loading'
import useVoicemail from '../../../../hooks/useVoicemail'

import imgProfile from "../../../../assets/images/imgProfile.png"
import VoicemailDetails from './VoicemailDetails'

export default function Voicemail() {

  const { data, onChangeSelectedItem, selectedItem, reloadData, getUrlVoiceAudio, deleteVoicemail, downloadVoicemail } = useVoicemail()


  const DataItem = ({ item, index }) => {
    return (<>  <hr className="w-100" style={{ margin: "10px 0 0 0" }} />
      <div onClick={() => { onChangeSelectedItem({ ...item, index }) }} className={`col-12 callhistory-item d-flex pt-2 ${selectedItem.pk === item.pk && "callhistory-item-active"}`}>
        <div className="d-flex align-items-center  mr-3 h-100">
          <div className="rounded-circle avatar-sm bg-secondary d-flex justify-content-center align-items-center text-black" style={{ textTransform: "uppercase", color: "black", fontSize: "20px", fontWeight: "700" }} >
            {item.cutName}
          </div>
        </div>
        <div className="w-100 d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-between w-100">
            <h5 className="text-truncate font-size-14 mb-0">
              {item.caller_id_name}
            </h5>
            <p className="text-truncate mb-0">
              {item.timestampFormat}
            </p>
          </div>
          <p className="text-truncate mb-0">
            {item.caller_id_number}
          </p>
        </div>
      </div>

    </>)

  }

  return (
    <div className="page-content px-5">
      <CustomBreadCrum title="My Extensions" subtitle="Voicemail" />
      {data ? <div className="row">

        {data.length > 0
          ? <>
            <div className="col-12 col-lg-5 "><SidebarComponent filterKeys={["caller_id_name", "caller_id_number", "timestampFormat"]} data={data} DataItem={DataItem} /></div>
            <div className="col-12 col-lg-7 pt-5 pt-lg-0 "> {selectedItem && <VoicemailDetails getUrlVoiceAudio={getUrlVoiceAudio} deleteVoicemail={deleteVoicemail} downloadVoicemail={downloadVoicemail} item={selectedItem} reloadData={reloadData} />} </div>   </>
          : <h3 className="text-center w-100">You do not have any voicemail at the moment. Make some calls!</h3>
        }
      </div> : <Loading />}


    </div>
  )
}

