import React, { useState, useEffect } from "react";
import phoneImg from "../../../../../../../../assets/images/phone-image.svg";

import petition_get from "../../../../../../../../pages/petitions/petition_get";
import petition_patch from "../../../../../../../../pages/petitions/petition_patch";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Badge,
  Spinner,
} from "reactstrap";

import Switch from "react-switch";
import toastr from "toastr"
export default function ModalUtility({ modal, setModal }) {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [switchOne, setSwitchOne] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [error, setError] = useState(false);
  const [form, setForm] = useState({
    prefix: "",
  });

  const onChange = (e) => {
    if (e.target.value !== "") {
      setSwitchOne(true);
    }

    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSubmitForm = (e) => {
    setLoading(true);

    if (!switchOne) {
      setForm({ ...form, callerid_prefix: "" });
    }

    petition_patch("viewNumberDetails", {
      id: id,
      number: modal,
      data: switchOne ? { callerid_prefix: form.callerid_prefix } : "",
    })
      .then(({ data: result }) => {
        setLoading(false);
        setError(false);
        toastr.success("Action completed", "Success");
        setModal(false);
      })
      .catch((error) => {
        console.log(error);
        toastr.error("Action failed", "Error");

        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    const petitions = async () => {
      petition_get("viewNumberDetails", { id: id, number: modal })
        .then(({ data: result }) => {

          setSwitchOne(result.result.callerid_prefix === "" ? false : true);
          setForm({ ...form, callerid_prefix: result.result.callerid_prefix });
          setLoadingTwo(true);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (modal !== false) petitions();
    else setForm({ callerid_prefix: "" });
  }, [modal]);

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  return (
    <Modal isOpen={modal === false ? false : true} className="info">
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Edit Number</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {loadingTwo ? (
          <Row>
            <Col className="col-12">Basic Information</Col>
            <Col className="col-12 mt-4">
              <Switch
                uncheckedIcon={<Offsymbol />}
                className="mr-1"
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                onChange={() => {
                  setSwitchOne(!switchOne);
                }}
                checked={switchOne}
              />
              <span> Add Caller ID Prefix to Incoming Calls</span>
            </Col>
            <Col className="mt-3 mb-5">
              <form action="">
                <Label>Enter Prefix</Label>
                <Input
                  onChange={onChange}
                  value={form.callerid_prefix}
                  name="callerid_prefix"
                  type="text"
                />
              </form>
            </Col>

            <Col className="col-12 text-center">
              {" "}
              {error && (
                <Badge color="danger" style={{ padding: "0.5rem" }}>
                  Update error
                </Badge>
              )}
            </Col>
          </Row>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Spinner
              animation="grow"
              style={{
                margin: "10vh auto",
                height: "10vh",
                width: "10vh",
              }}
            />{" "}
          </div>
        )}
      </ModalBody>{" "}
      <ModalFooter>
        <Button
          outline
          color="secondary"
          onClick={() => {
            setModal(false);
          }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={() => {
            onSubmitForm();
          }}
        >
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
