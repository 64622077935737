import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  CardTitle,
  Button,
  Badge,
  Spinner,
  Input,
  Label,
  CardBody,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import petition_post from "../../../../../petitions/petition_post";
import petition_patch from "../../../../../petitions/petition_patch";
import petition_get from "../../../../../petitions/petition_get";
import toastr from "toastr";

export default function UserDetails({
  id,
  infoUser,
  setEditUser,
  setDeleteUser,
  defaultTimeZone,
}) {
  const [allTimeZone, setAllTimeZone] = useState([""]);

  toastr.options = {
    showDuration: 300,
  };

  //States
  //Load[ing Next
  const [loading, setLoading] = useState(false);
  const [loadingGet, setLoadingGet] = useState(true);
  //Validate Email
  const [emailExist, setEmailExist] = useState(false);

  const [emailExistValid, setEmailExistValid] = useState(false);
  //Validate Extension
  const [extensionExist, setExtensionExist] = useState(false);
  const [existTimeZone, setExistTimeZone] = useState(null);

  const [dataUser, setData] = useState(null)

  //Form data
  const [form, setForm] = useState({
    first_name: infoUser.first_name,
    last_name: infoUser.last_name,
    presence_id: infoUser.presence_id,
    email: infoUser.email,
    priv_level: infoUser.priv_level,
    // timeZone: infoUser.timeZone ? infoUser.timeZone : defaultTimeZone,
  });

  useEffect(() => {
    console.log(infoUser);
    petition_get("timeZome")
      .then(({ data: result }) => {
        setAllTimeZone(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    petition_get("viewCallSettingsUsers", { id, user_id: infoUser.pk })
      .then(({ data: result }) => {
        setLoadingGet(false);
        setData(result.result)
        setExistTimeZone(result.result.time_zone);
        setForm({
          ...form,
          ["time_zone"]: result.result.time_zone
            ? result.result.time_zone
            : defaultTimeZone,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [infoUser]);

  const onChangeForm = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    if (e.target.name === "email") {
      setEmailExist(false);
    }
    if (e.target.name === "presence_id") {
      setExtensionExist(false);
    }
  };

  const edit = () => {

    let newData = { ...dataUser, ...form, }
    if (!newData.callforward_keep_caller_caller_id) delete newData.callforward_keep_caller_caller_id
    if (!newData.callforward_queue_calls) delete newData.callforward_queue_calls
    delete newData.account_id

    let data = [
      {
        id: id,
        user_id: infoUser.pk,
      },
      { ...newData },
    ];

    setLoading(true);
    petition_patch("editUser", data)
      .then(async ({ data: result }) => {

        //Update Callflow extension 
        await petition_get("allCallflows", { id })
          .then(({ data: result }) => {


            let getResult = result.result.find(element => element.owner_id === dataUser.pk.toString())



            petition_get("callflowsDetails", { id, callflow: getResult.pk })
              .then(({ data: result }) => {
                let numbers = result.result.numbers.filter(elementTwo => elementTwo !== infoUser.presence_id)
                numbers.push(form.presence_id)

                petition_patch("callflowEdit", { id, callflow_id: getResult.pk, data: { numbers, name: `${form.first_name} ${form.last_name}` }, }).catch((error) => console.log(error));

              })
              .catch((error) => console.log(error));

          })
          .catch((error) => console.log(error));

        //Update voicemail extension 
        await petition_get("voicemail-box-details", { id, idVoicemail: result.result.voicemail_box_id })
          .then(({ data: result }) => {


            console.log("voicemail", { ...result.result, name: `${form.first_name} ${form.last_name}`, mailbox: form.extension, })
            petition_patch("updateVoicemailBox", {
              id: id,
              voicemail_box_id: result.result.pk,
              data: { ...result.result, name: `${form.first_name} ${form.last_name}`, mailbox: form.presence_id, },
            }).catch((error) => {
              console.log(error);
            });
          })
          .catch((error) => {
            console.log(error);
          });


        toastr.success("Action completed", "Success");

        setLoading(false);
        setEditUser(false);
        setDeleteUser(true);
      })
      .catch((error) => {
        console.log(error);
        toastr.error("Action failed", "Error");
      });
  };

  // function timeZonesSelect() {
  //   let options = [];
  //   let i = 0;
  //   for (const element in TimeZone) {
  //     options.push(
  //       <option key={i} value={`${element}`}>
  //         {`${element}: ${TimeZone[element]}`}
  //       </option>
  //     );
  //     i++;
  //   }
  //   return options;
  // }
  return (
    <React.Fragment>
      <Card body>
        <CardTitle className="mt-4">User Details </CardTitle>
        {loadingGet ? (
          <CardBody>
            <div style={{ textAlign: "center" }}>
              <Spinner
                animation="grow"
                style={{
                  margin: "10vh auto",
                  height: "10vh",
                  width: "10vh",
                }}
              />{" "}
            </div>
          </CardBody>
        ) : (
          <CardBody>
            <Row>
              <Col
                sm="12"
                md="12"
                lg="2"
                xl="2"
                className="mb-sm-2 mb-lg-0"
                className="mb-sm-2 mb-lg-0"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="first_name"
                  style={{ padding: "0", margin: "0" }}
                >
                  First Name
                </label>
              </Col>
              <Col sm="12" md="6" lg="5" xl="5">
                <input
                  onChange={onChangeForm}
                  value={form.first_name}
                  name="first_name"
                  id="first_name"
                  type="text"
                  placeholder="First Name"
                  className="form-control"
                  disabled={emailExistValid}
                />
              </Col>
              <Col
                sm="3"
                className="mt-2 ml-0 ml-md-2 mt-md-0"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Badge
                  color="info"
                  style={{
                    marginLeft: "2em",
                    display: emailExistValid ? "initial" : "none",
                  }}
                >
                  A User with this email was found. First name and Last name was
                  updated.
                </Badge>
              </Col>
            </Row>
            <br />
            <Row>
              <Col
                sm="12"
                md="12"
                lg="2"
                xl="2"
                className="mb-sm-2 mb-lg-0"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="last_name"
                  style={{ padding: "0", margin: "0" }}
                >
                  Last Name
                </label>
              </Col>
              <Col sm="12" md="6" lg="5" xl="5">
                <input
                  onChange={onChangeForm}
                  value={form.last_name}
                  name="last_name"
                  id="last_name"
                  type="text"
                  placeholder="Last Name"
                  className="form-control"
                  disabled={emailExistValid}
                />
              </Col>
              <Col
                sm="3"
                className="mt-2 ml-0 ml-md-2 mt-md-0"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Badge
                  color="info"
                  style={{
                    marginLeft: "2em",
                    display: emailExistValid ? "initial" : "none",
                  }}
                >
                  A User with this email was found. First name and Last name was
                  updated.
                </Badge>
              </Col>
            </Row>
            <br />
            <Row>
              <Col
                sm="12"
                md="12"
                lg="2"
                xl="2"
                className="mb-sm-2 mb-lg-0"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label
                  htmlFor="presence_id"
                  style={{ padding: "0", margin: "0" }}
                >
                  Extension Number
                </label>
              </Col>
              <Col
                sm="12"
                md="5"
                lg="4"
                xl="2"
                className="mb-sm-2 mb-lg-0"
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  onChange={onChangeForm}
                  value={form.presence_id}
                  name="presence_id"
                  id="presence_id"
                  type="number"
                  placeholder="Extension Number"
                  className="form-control input-extension"
                />
              </Col>

              <Col
                sm="3"
                className="mt-2 ml-0 ml-md-2 mt-md-0"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Badge
                  color="danger"
                  style={{
                    // marginLeft: "2em",
                    display: extensionExist ? "initial" : "none",
                  }}
                >
                  Extension Already In Use !
                </Badge>
              </Col>
            </Row>
            <br />
            <Row>
              <Col
                sm="12"
                md="12"
                lg="2"
                xl="2"
                className="mb-sm-2 mb-lg-0"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label htmlFor="email" style={{ padding: "0", margin: "0" }}>
                  Email
                </label>
              </Col>
              <Col sm="12" md="6" lg="5" xl="5">
                <input
                  disabled={true}
                  onChange={onChangeForm}
                  value={form.email}
                  name="email"
                  id="email"
                  type="email"
                  placeholder="cengroba@gmail.com"
                  className="form-control"
                />
              </Col>
              <Col
                sm="3"
                className="mt-2 ml-0 ml-md-2 mt-md-0"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Badge
                  color="danger"
                  style={{
                    marginLeft: "2em",
                    display: emailExist ? "initial" : "none",
                  }}
                >
                  Email Already In Use !
                </Badge>
              </Col>
            </Row>
            <br />
            <Row>
              <Col
                sm="12"
                md="12"
                lg="2"
                xl="2"
                className="mb-sm-2 mb-lg-0"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label htmlFor="select" style={{ padding: "0", margin: "0" }}>
                  Zone Time
                </label>
              </Col>
              <Col sm="12" md="6" lg="5" xl="5">
                {/* <TimezoneSelect
              value={selectedTimezone}
              onChange={setSelectedTimezone}
              style={{background:"red"}}
              // className="form-control"
            /> */}
                <select
                  onChange={onChangeForm}
                  value={form.time_zone}
                  name="time_zone"
                  id="select"
                  className="form-control"
                >
                  {existTimeZone ? null : (
                    <option value={defaultTimeZone}>
                      Use Default TimeZone
                    </option>
                  )}
                  {allTimeZone.map((element, i) => (
                    <option key={i} value={element}>
                      {element}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <br />
            <Row>
              <Col
                sm="12"
                md="12"
                lg="2"
                xl="2"
                className="mb-sm-2 mb-lg-0"
                style={{ display: "flex", alignItems: "center" }}
              >
                <label htmlFor="select" style={{ padding: "0", margin: "0" }}>
                  Type
                </label>
              </Col>
              <Col sm="12" md="6" lg="5" xl="5">
                <select
                  onChange={onChangeForm}
                  value={form.priv_level}
                  name="priv_level"
                  id="select"
                  className="form-control"
                >
                  <option value="selected">Select one...</option>
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
              </Col>
            </Row>
            <br />
            <Row>
              <Col sm="12" style={{ textAlign: "right" }}>
                <Button
                  color="primary"
                  disabled={
                    form.first_name === "" ||
                      form.last_name === "" ||
                      form.presence_id === "" ||
                      form.email === "" ||
                      form.priv_level === "selected"
                      ? true
                      : false || loading
                  }
                  onClick={() => edit()}
                >
                  {loading && (
                    <Spinner
                      style={{ marginRight: "0.5em" }}
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Edit
                </Button>
              </Col>
            </Row>
          </CardBody>
        )}
      </Card>
    </React.Fragment>
  );
}
