import React, { useState, useEffect } from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import {
    Button,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Input,
    Badge,
    Spinner,
} from "reactstrap";

import Switch from "react-switch";
export default function ModalUtilityDelete({
    modal,
    setModal,
    downloadPdf,
    loading,
}) {
    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    return (
        <Modal
            centered
            isOpen={modal === false ? false : true}
            className="info modal-md"
        >
            <ModalHeader className="bg-soft-primary">
                {" "}
                <Row>
                    <Col xs="7">
                        <div className="text-primary p-3">
                            <h3 className="text-primary">{modal.from_callerid_name}</h3>
                        </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                        <img src={phoneImg} alt="" className="img-fluid" />
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <p className="text-center" style={{ fontSize: "1.2rem" }}>
                    Are you sure you want to download this Fax?
                </p>
            </ModalBody>{" "}
            <ModalFooter>
                <Button
                    outline
                    disabled={loading}
                    color="secondary"
                    onClick={() => {
                        setModal(false);
                    }}
                >
                    Cancel
                </Button>

                <Button
                    color="primary"
                    disabled={loading}
                    onClick={downloadPdf}
                >
                    {loading && (
                        <Spinner
                            style={{ marginRight: "0.5em" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                    Download
                </Button>

            </ModalFooter>
        </Modal>
    );
}
