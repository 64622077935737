import React from "react";
import {Label, Node, NodeProps, Port} from "reaflow";
import ObjectNode from "./ObjectNode";
import TextNode from "./TextNode";

const baseLabelStyle = {
  fill: "transparent",
  stroke: "transparent",
  strokeWidth: 0,
};

const CustomNode = (nodeProps) => {
  const {properties} = nodeProps;

  return (
    <Node
      {...nodeProps}
      label={<Label style={baseLabelStyle} />}
      port={
        <Port
          onClick={(e, node) => {
            nodeProps.setActiveSidebar({
              text: {module: "createModule", node},
            });
          }}
          rx={100}
          ry={100}
        />
      }
    >
      {({width, height, x, y}) => {
        if (properties.text instanceof Object) {
          const entries = Object.entries(properties.text);

          return (
            <ObjectNode
              {...properties}
              value={entries}
              width={width}
              height={height}
              positionX={nodeProps.ports[0]?.x}
              x={x}
              y={y}
            />
          );
        }

        return (
          <TextNode
            isParent={properties.data.isParent}
            value={properties.text}
            width={width}
            height={height}
            x={x}
            y={y}
          />
        );
      }}
    </Node>
  );
};

export default CustomNode;
