import React, { useState } from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";


import TimeDate from "../TimeDate"


import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,

  Spinner,

} from "reactstrap";
import TimeGroupForm from "../TimeGroupForm";

export default function ModalUtility({
  modal,
  setModal,
  dataTimeDate,
  dataTimeDateId,
  setReloadData,
  viewTimeDate
}) {



  const [loading, setLoading] = useState(false);

  const [activeSubmitTimeDate, setActiveSubmitTimeDate] = useState(false)
  const [activeSubmitTimeGroup, setActiveSubmitTimeGroup] = useState(false)


  const onSubmitForm = (e) => {
    if (dataTimeDate) setActiveSubmitTimeDate(true)
    else setActiveSubmitTimeGroup(true)
  };




  return (
    <Modal centered={true} isOpen={modal} className="info ">
      <ModalHeader className="bg-soft-primary">

        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Add {dataTimeDate ? "Time Date" : "Time Group"}</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        {dataTimeDate
          ? <TimeDate setLoading={setLoading} setModal={setModal} setReloadData={setReloadData} dataTimeDateId={dataTimeDateId} activeSubmitTimeDate={{ setActiveSubmitTimeDate, activeSubmitTimeDate }} />
          : modal && <TimeGroupForm viewTimeDate={viewTimeDate} setLoading={setLoading} setModal={setModal} setReloadData={setReloadData} activeSubmitTimeGroup={{ setActiveSubmitTimeGroup, activeSubmitTimeGroup }} />
        }
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          color="secondary"
          disabled={loading}
          onClick={() => {
            setModal(false);

          }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={() => {
            onSubmitForm();
          }}
          disabled={loading}
        >
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
