import React, { useEffect, useState } from "react";
import {
    Button,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Spinner
} from "reactstrap";
import ReactTooltip from 'react-tooltip';
import InfoIcon from '@material-ui/icons/Info';
import phoneImg from "../../../../../../assets/images/verification-img.png";
import classnames from "classnames";
import Switch from "react-switch";

import petition_patch from "../../../../../petitions/petition_patch"
import toastr from "toastr";

import Loading from "../../../../../../components/utils/Loading"
import DisableCallWaiting from "./OptionsTab/DisableCallWaiting"
export default function FavoritesModal({
    modal,
    setModal,
    changeAudioSelected,
    changeAudioSelectedInput,
    loading,
    setLoading,
    calling,
    id,
    deviceId,
    reloadData,
    setReloadData,
    deviceData,
    volume,
    setVolume,
    data,
    users,
    userStatusProfile,
    setUserStatusProfile
}) {

    toastr.options = {
        showDuration: 300,
    };


    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [switchOne, setSwitchOne] = useState(false)
    const [switchTwo, setSwitchTwo] = useState(false)

    const onChangeAudio = (e) => { changeAudioSelected(e.target.value) }

    const onChangeAudioInput = (e) => { changeAudioSelectedInput(e.target.value) }




    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                No
            </div>
        );
    };
    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                Yes
            </div>
        );
    };






    const handleSubmit = () => {
        if (calling === 1 && !loading) {
            setLoading(true)
            petition_patch("deviceEdit", { id, device_id: deviceId, data: { itpvoice_metadata: { ...deviceData.itpvoice_metadata, web_phone_enabled: !switchOne } } })
                .then(({ data: result }) => {
                    setReloadData(!reloadData)
                    setSwitchOne(!switchOne)
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                    console.log(error);
                });

        }
    }

    const handleSubmitCallWaiting = () => {
        if (calling === 1 && !loading) {
            setLoading(true)
            petition_patch("deviceEdit", { id, device_id: deviceId, data: { itpvoice_metadata: { ...deviceData.itpvoice_metadata, enableCallWaiting: !switchTwo } } })
                .then(({ data: result }) => {
                    setReloadData(!reloadData)
                    setSwitchTwo(!switchTwo)
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                    console.log(error);
                });

        }
    }


    useEffect(() => {
        if (modal && deviceData) {

            setVolume(userStatusProfile?.user_volume_preference || "30")

            setSwitchOne(deviceData.itpvoice_metadata.web_phone_enabled)
            return setSwitchTwo(deviceData.itpvoice_metadata.enableCallWaiting ? true : false)

        }
    }, [modal])


    const updateVolumen = (volumenUpdate) => {


        petition_patch("userDetails", { id, data: { user_volume_preference: volumenUpdate } })
            .then(async ({ data: result }) => { setUserStatusProfile({ ...userStatusProfile, user_volume_preference: volumenUpdate }) })
            .catch((error) => toastr.error("Action failed", "Error"));

    }

    return (
        <Modal centered={true} isOpen={modal} className="info ">
            <ModalHeader className="bg-soft-primary">
                <Row>
                    <Col xs="9">
                        <div className="text-primary p-3">
                            <h3 className="text-primary">Audio</h3>
                        </div>
                    </Col>
                    <Col xs="3" className="align-self-end">
                        <img src={phoneImg} alt="" className="img-fluid" />
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>

                <Nav
                    tabs
                    className="nav-tabs-custom  flex-column flex-md-row  text-center  "
                >
                    <NavItem className="pt-3 pt-md-0">
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: customActiveTab === "1",
                            })}
                            onClick={() => {
                                setCustomActiveTab("1");
                            }}
                        >
                            <span>General</span>
                        </NavLink>
                    </NavItem>
                    <NavItem className="pt-3 pt-md-0">
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: customActiveTab === "2",
                            })}
                            onClick={() => {
                                setCustomActiveTab("2");
                            }}
                        >
                            <span>Audio</span>
                        </NavLink>
                    </NavItem>
                    <NavItem className="pt-3 pt-md-0">
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: customActiveTab === "3",
                            })}
                            onClick={() => {
                                setCustomActiveTab("3");
                            }}
                        >
                            <span>Enable Call Waiting</span>
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={customActiveTab}>
                    <TabPane tabId="1" className="p-3">
                        {loading ? <Loading />
                            : (
                                <>
                                    <div >
                                        <label className="w-100" htmlFor="">
                                            <span className="pr-2">
                                                {switchOne ? "Enable Web Phone" : "Disable Web Phone"}
                                            </span>
                                            <InfoIcon
                                                data-tip data-for='text1'
                                                style={{ fontSize: "1.2rem" }}
                                            />
                                            <ReactTooltip id="text1" >
                                                <div className="text-left" style={{ fontSize: "10px" }}>

                                                    This will prevent the web phone from placing
                                                    <br />
                                                    or receiving calls. Useful in the event you
                                                    <br />
                                                    wish to use your physical phone instead of
                                                    <br />
                                                    the web phone.
                                                </div>
                                            </ReactTooltip>
                                        </label>
                                        <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            className="mr-1"
                                            checkedIcon={<OnSymbol />}
                                            onColor="#626ed4"
                                            onChange={handleSubmit}
                                            checked={switchOne}
                                        />
                                    </div>


                                </>
                            )}

                    </TabPane>
                    <TabPane tabId="2" className="p-3">
                        <div>
                            <label htmlFor="">
                                Inputs Audio:
                            </label>
                            <select onChange={onChangeAudioInput} disabled={false} className="form-control">
                                {data.input.map((element, i) => <option key={i} value={element.deviceId}> {element.label} </option>
                                )} </select>
                        </div>

                        <br />
                        <br />

                        <div>
                            <label htmlFor="">
                                Outputs Audio:
                            </label>
                            <select onChange={onChangeAudio} className="form-control">
                                {data.output.map((element, i) => <option key={i} value={element.deviceId}> {element.label} </option>
                                )} </select>
                        </div>

                        <div className="mt-5">
                            <label className="w-100" htmlFor="">
                                Ringer Volume:
                            </label>
                            <input
                                type="range"
                                onChange={(e) => {
                                    setVolume(e.target.value)
                                    updateVolumen(parseInt(e.target.value))
                                }}
                                value={volume}
                                color="primary"
                                style={{
                                    height: "3px",
                                    padding: "0",
                                    margin: "0",
                                    width: "200px",
                                }}
                            />
                        </div>
                    </TabPane>
                    <TabPane tabId="3" className="p-3">

                        <DisableCallWaiting switchTwo={switchTwo} setSwitchTwo={setSwitchTwo} handleSubmitCallWaiting={handleSubmitCallWaiting} />
                    </TabPane>
                </TabContent>

            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    color="secondary"
                    onClick={() => {
                        setModal(false);
                    }}
                >
                    Close
                </Button>

            </ModalFooter>
        </Modal >
    );
}
