import React, {Component} from "react";
import ReactApexChart from "react-apexcharts";

class DashedLine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "Calls",
          data: this.props.dataX,
        },
      ],
      options: {
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },

        colors: ["#556ee6", "#34c38f"],
        chart: {
          events: {
            beforeZoom: function (ctx) {
              // we need to clear the range as we only need it on the iniital load.
              ctx.w.config.xaxis.range = undefined;
            },
          },
        },
        xaxis: {
          type: "date",
          categories: this.props.dataY,
          range: 10,
        },
        grid: {
          borderColor: "#f1f1f1",
        },
        tooltip: {
          x: {
            format: "dd/MM/yy",
          },
        },
      },
    };
  }

  // componentDidUpdate(prevProps) {
  //     if (this.props.data !== prevProps.data) {
  //         if (this.props.data != null) {
  //             this.setState({
  //                 series: {
  //                     name: 'Calls',
  //                     data: Object.values(this.props.data)
  //                 },
  //                 options: {
  //                     dataLabels: {
  //                         enabled: false
  //                     },
  //                     stroke: {
  //                         curve: 'smooth',
  //                         width: 3,
  //                     },

  //                     colors: ['#556ee6', '#34c38f'],
  //                     xaxis: {
  //                         type: 'datetime',
  //                         categories: ["2018-09-19T00:00:00", "2018-09-19T01:30:00", "2018-09-19T02:30:00", "2018-09-19T03:30:00", "2018-09-19T04:30:00", "2018-09-19T05:30:00", "2018-09-19T06:30:00"],
  //                         // categories: Object.keys(this.props.data),
  //                     },
  //                     grid: {
  //                         borderColor: '#f1f1f1',
  //                     },
  //                     tooltip: {
  //                         x: {
  //                             format: 'dd/MM/yy'
  //                         },
  //                     }
  //                 }
  //             })

  //         }

  //     }
  // }

  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height="350"
        />
      </React.Fragment>
    );
  }
}

export default DashedLine;
