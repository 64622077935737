import React, {useEffect, useState} from "react";
import petition_get from "../pages/petitions/petition_get";
import petition_delete from "../pages/petitions/petition_delete";
import petition_patch from "../pages/petitions/petition_patch";
import petition_post from "../pages/petitions/petition_post";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import AddWebhooksModal from "../pages/ITPVoice/YourPhoneSystem/GeneralSettings/OptionsTab/Webhooks/AddWebhooksModal";
import EditWebhooksModal from "../pages/ITPVoice/YourPhoneSystem/GeneralSettings/OptionsTab/Webhooks/EditWebhooksModal";
import DeleteWebhooksModal from "../pages/ITPVoice/YourPhoneSystem/GeneralSettings/OptionsTab/Webhooks/DeleteWebhooksModal";
import toastr from "toastr";
export default function useWebhooks() {
  toastr.options = {
    showDuration: 300,
  };

  const [data, setData] = useState(null);
  const [dataTable, setDataTable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  //Modal
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const getWebhooks = () => {
    setDataTable(null);
    petition_get("getWebhooks", {id})
      .then(({data: result}) => {
        setDataTable({
          title: [
            {title: "Name", key: "name"},
            {title: "Url", key: "url"},
            {title: "Type", key: "http_method"},
            {title: "Hook", key: "hook"},
          ],
          content: result.result,
          actions: {
            title: "Actions",
            content: [
              {
                icon: <DeleteIcon />, //Svg icon
                handleClick: (e) => {
                  setModalDelete(e.pk);
                }, //Function
              },
              {
                icon: <EditIcon />, //Svg icon
                handleClick: (e) => {
                  setModalEdit(e);
                }, //Function
              },
            ],
          },
          search: {
            label: "Search",
          },
          addButton: {
            label: "Add Webhook",
            handleClick: () => {
              setModalAdd(true);
            },
          },
        });
      })
      .catch((error) => console.log("error", error));
  };

  const addWebhooks = (form) => {
    if (validateForm(form)) return;
    setLoading(true);

    petition_post("addWebhooks", {id, data: form})
      .then(({data: result}) => {
        getWebhooks();
        setModalAdd(false);
        toastr.success("Action completed", "Success");
      })
      .catch((error) => {
        toastr.error("Action failed", "Error");
      })
      .finally(() => setLoading(false));
  };

  const updateWebhooks = (form) => {
    if (validateForm(form)) return;
    setLoading(true);

    petition_patch("updateWebhooks", {id, webhookId: form.pk, data: form})
      .then(({data: result}) => {
        getWebhooks();
        setModalEdit(false);
        toastr.success("Action completed", "Success");
      })
      .catch((error) => {
        toastr.error("Action failed", "Error");
      })
      .finally(() => setLoading(false));
  };

  const deleteWebhooks = () => {
    setLoading(true);

    petition_delete("deleteWebhooks", {id, webHookId: modalDelete})
      .then(({data: result}) => {
        getWebhooks();
        setModalDelete(false);
        toastr.success("Action completed", "Success");
      })
      .catch((error) => {
        toastr.error("Action failed", "Error");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getWebhooks();
  }, []);

  const getAddWebhooksModal = () => {
    return (
      <AddWebhooksModal
        error={error}
        loading={loading}
        modal={modalAdd}
        setModal={setModalAdd}
        addWebhooks={addWebhooks}
      />
    );
  };
  const getUpdateWebhooksModal = () => {
    return (
      <EditWebhooksModal
        error={error}
        loading={loading}
        modal={modalEdit}
        setModal={setModalEdit}
        updateWebhooks={updateWebhooks}
      />
    );
  };
  const getDeleteWebhooksModal = () => {
    return (
      <DeleteWebhooksModal
        loading={loading}
        modal={modalDelete}
        setModal={setModalDelete}
        deleteWebhook={deleteWebhooks}
      />
    );
  };

  const isUrl = (url) => {
    var regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(url);
  };

  const validateForm = (form) => {
    setError({});
    let tempError = {};

    if (form.name.trim() === "") tempError.name = true;
    else if (form.url.trim() === "" || !isUrl(form.url)) tempError.url = true;

    setError(tempError);

    if (tempError.name) {
      toastr.error("Field name error", "Error");
      return true;
    } else if (tempError.url) {
      toastr.error("Field url error", "Error");
      return true;
    }

    return false;
  };

  return {
    data,
    dataTable,
    getAddWebhooksModal,
    getUpdateWebhooksModal,
    getDeleteWebhooksModal,
  };
}
