import React from "react";
import ButtonHover from "../utils/ButtonHover";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
export default function BackOptionButton({
  menuOptions,
  activeOptions,
  activeEdit,
  setActiveMenu,
  setActiveEdit,
}) {
  if (menuOptions.length > 1 || activeOptions || activeEdit)
    return (
      <div
        style={{position: "absolute", zIndex: "200"}}
        className="text-left  w-100"
      >
        <ButtonHover
          style={{margin: "0"}}
          outline
          color="secondary"
          onClick={() => {
            setActiveMenu(false);
            setActiveEdit();
          }}
        >
          <ArrowBackIosIcon />
        </ButtonHover>
      </div>
    );

  return <> </>;
}
