import React from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

import phoneImg from "../../../../../assets/images/verification-img.png";

export default function FavoritesModal({modal, setModal, numbers}) {
  return (
    <Modal centered={true} isOpen={modal} className="info ">
      <ModalHeader className="bg-soft-primary">
        <Row>
          <Col xs="9">
            <div className="text-primary p-3">
              <h3 className="text-primary">Numbers</h3>
            </div>
          </Col>
          <Col xs="3" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Table className="mt-4" hover size={"md"}>
          <thead className="thead-light">
            <tr>
              <th className="text-center">Numbers</th>
            </tr>
          </thead>
          {console.log("test", numbers)}

          <tbody>
            {numbers?.map((element, i) => (
              <tr className="border py-3 text-center d-flex pl-2" key={i}>
                {element.number}
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          color="secondary"
          onClick={() => {
            setModal(false);
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
