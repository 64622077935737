import React from "react";
import ButtonHover from "../utils/ButtonHover";

export default function ZoomButtons({zoomChart}) {
  return (
    <>
      <div
        className="border"
        style={{
          boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.09)",
          borderRadius: "10px",
          background: "white",
          position: "absolute",
          top: "10px",
          left: "-80px",
        }}
      >
        <ButtonHover
          onClick={() => zoomChart(true)}
          style={{width: "35px", color: "black"}}
        >
          +
        </ButtonHover>
        <div style={{borderBottom: "1px solid rgba(0,0,0,.3)"}}></div>
        <ButtonHover
          onClick={() => zoomChart(false)}
          style={{width: "35px", color: "black"}}
        >
          -
        </ButtonHover>
      </div>
    </>
  );
}
