import React, { useState, useEffect } from 'react'
import CustomTable from "../../../../../components/utils/CustomTable/CustomTable"
import petition_post from "../../../../petitions/petition_post";
import petition_get from "../../../../petitions/petition_get";
import SelectNumberReport from "../SelectNumberReport"
import DescriptionIcon from "@material-ui/icons/Description";
import ModalUtility from "../ModalUtility/ModalUtility";
export default function NumerReports({ timezone }) {

    const [dataTable, setDataTable] = useState(null)
    const [distribution, setDistribution] = useState("all")

    const [dataReport, setDataReport] = useState("")

    const [numbers, setNumbers] = useState(null)


    const params = new URL(document.location).searchParams;
    const id = params.get("id");
    const [modal, setModal] = useState(false)


    useEffect(() => {

        if (dataReport) {
            setDataTable(null)

            let numbers = [];
            dataReport.selected.forEach((element) => numbers.push(element.number));

            let newTimeStart = new Date(dataReport.timeframe.timeStart)
            let newTimeEnd = new Date(dataReport.timeframe.timeEnd)


            petition_post("numberCallReports", {
                id: id,
                data: { numbers },
                query: `?start_date=${newTimeStart.getTime() / 1000}&end_date=${newTimeEnd.getTime() / 1000}`,
            })
                .then(({ data: res }) => {
                    setDataTable({

                        title: [
                            { title: "Number", key: "number" },
                            { title: "Total", key: "total_calls" },
                            { title: "Completed", key: "completed" },
                            { title: "Missed", key: "missed" },
                            { title: "% Missed", key: "missed_percentage" },
                            { title: "Duration", key: "duration" },
                            { title: "% Duration", key: "duration_percentage" },
                            { title: "Avg Duration", key: "avg_duration" },
                            { title: "Incoming", key: "incoming" },
                        ],
                        content: res.result,
                        actions: {
                            title: "Actions",
                            content: [
                                {
                                    icon: <DescriptionIcon />, //Svg icon
                                    handleClick: (e) => { setModal({ ...e, type: "number", timeStart: dataReport.timeframe.timeStart, timeEnd: dataReport.timeframe.timeEnd }) }, //Function
                                },
                            ],
                        },
                    })

                })
                .catch((error) => console.log(error));

        }
    }, [dataReport])

    useEffect(() => {

        petition_get("view", { id })
            .then(({ data: res }) => {
                let numbers = []
                res.result.map((element) => { numbers.push({ check: true, number: element.number, id: element.pk }) });
                setNumbers(numbers);
            })
            .catch((error) => console.log("Error", error));

    }, [])

    return (
        <div>
            <ModalUtility timezone={timezone} setModal={setModal} modal={modal} />
            <SelectNumberReport numbers={numbers} setDataReport={setDataReport} />
            {dataReport && <CustomTable data={dataTable} />}
        </div>
    )
}
