import React, {useState} from "react";
import {GraphTopbarContainer, GraphTopbarItem} from "./styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";
import FileDownloadIcon from "@material-ui/icons/GetApp";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import RedoIcon from "@material-ui/icons/Redo";
export default function GraphTopBar({setLayout, setModal, createCallflow}) {
  const [options] = useState([
    {
      type: "zoomIn",
      title: "Zoom in",
      icon: <AddIcon color="red" />,
      handleClick: () => {},
    },
    {
      type: "zoomOut",
      title: "Zoom out",
      icon: <RemoveIcon />,
      handleClick: () => {},
    },
    {
      type: "backZoom",
      title: "Back zoom",
      icon: <CenterFocusWeakIcon />,
      handleClick: () => {},
    },
    {
      type: "download",
      title: "Download",
      icon: <FileDownloadIcon />,
      handleClick: () => {},
    },
    {
      type: "viewJson",
      title: "View json",
      icon: <ZoomOutMapIcon />,
      handleClick: () => {
        setModal(true);
      },
    },
    {
      type: "rotate",
      title: "Rotate",
      icon: <RedoIcon />,
      handleClick: () => {
        setLayout((prev) => {
          if (prev === 4) return 0;
          return prev + 1;
        });
      },
    },
  ]);

  return (
    <GraphTopbarContainer>
      {options.map((option) => (
        <GraphTopbarItem onClick={option.handleClick}>
          {option.icon}
        </GraphTopbarItem>
      ))}
      <button onClick={createCallflow} className="btn btn-submit">
        Save
      </button>
      <button onClick={createCallflow} className="btn btn-submit">
        Back
      </button>
    </GraphTopbarContainer>
  );
}
