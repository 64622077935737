import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Container, Row, Col} from "reactstrap";
import json from "../../config.json";
//Import Images
import error from "../../assets/images/error-img.png";

class Pages401 extends Component {
  render() {
    const token = JSON.parse(localStorage.getItem("authUser"));

    console.log(token);

    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <h1 className="display-2 font-weight-medium">
                    4
                    <i className="bx bx-buoy bx-spin text-primary display-3"></i>
                    1
                  </h1>
                  <h4 className="text-uppercase">
                    You currently do not have a User Created within ITP Voice.
                    Please click below to access your profile.{" "}
                  </h4>
                  <div className="mt-5 text-center">
                    <a
                      href={
                        json.prod
                          ? `https://portal.itp247.com/login?token=${token?.access_token.replace(
                              /['"]+/g,
                              ""
                            )}&&redirect=profile`
                          : `https://dev.portal.itp247.com/login?token=${token?.access_token.replace(
                              /['"]+/g,
                              ""
                            )}&&redirect=profile`
                      }
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Back to Home
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="8" xl="6">
                <div>
                  <img src={error} alt="" className="img-fluid" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Pages401;
