import React, { useState, useEffect } from "react";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import petition_get from "../../../../petitions/petition_get";
import CustomTable from "../../../../../components/utils/CustomTable/CustomTable";
import CustomBreadCrum from "../../../../../components/utils/CustomBreadCrum/CustomBreadCrum";
import DescriptionIcon from "@material-ui/icons/Description";
import { Spinner } from "reactstrap";
//Modals
import DevicesModal from "./Modals/DevicesModal";
export default function SipRegistration() {
  //Get api_id
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  //States
  const [data, setData] = useState([]);
  const [dataTable, setDataTable] = useState(null);
  const [devicesModal, setDevicesModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDevices, setLoadingDevices] = useState(false);

  //Open Modal Devices
  const handleOpenDevicesModal = (element) => {
    setDevicesModal(element);
  };

  //Get Table Data
  useEffect(() => {
    setLoading(true);
    petition_get("sipRegistration", { id: id })
      .then(({ data: result }) => {
        setDataTable({
          title: [
            { title: "Presence Id", key: "presence_id" },
            { title: "First Name", key: "first_name" },
            { title: "Last Name", key: "last_name" },
            /*             {title: "Devices", key: "devices_registered"}, */
          ],
          content: [...result.result],
          actions: {
            title: "Devices",
            content: [
              {
                icon: <DescriptionIcon />,
                handleClick: handleOpenDevicesModal,
              },
            ],
          },
        });
        setLoading(false);
        setData(result.result);
      })
      .catch((error) => { });
  }, []);

  return (
    <>
      <DevicesModal
        modal={devicesModal}
        setModal={setDevicesModal}
        loading={loadingDevices}
      />
      <div className="page-content">

        <div className="padding-page">

          <CustomBreadCrum title="Advanced" subtitle="Sip Registrations" />
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <Spinner
                animation="grow"
                style={{
                  margin: "10vh auto",
                  height: "10vh",
                  width: "10vh",
                }}
              />{" "}
            </div>
          ) : (
            <div className="p-3">
              {dataTable && <CustomTable size="md" data={dataTable} />}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
