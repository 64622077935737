import React, {useState, useEffect} from "react";
import phoneImg from "../../../../../assets/images/phone-image.svg";
import petition_get from "../../../../petitions/petition_get";
import petition_patch from "../../../../petitions/petition_patch";
import petition_delete from "../../../../petitions/petition_delete";
import petition_post from "../../../../petitions/petition_post";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import classnames from "classnames";

import BasicSettings from "./NavEdit/BasicSettings";
import Members from "./NavEdit/Members";
import toastr from "toastr";
import AdvancedSettings from "./NavEdit/AdvancedSettings";

export default function ModalUtility({
  modal,
  setModal,
  id,
  listUsers,
  setReloadData,
  reloadData,
}) {
  toastr.options = {
    showDuration: 300,
  };
  const [loading, setLoading] = useState(false);
  const [add, setAdd] = useState(false);
  const [form, setForm] = useState({
    name: "",
    description: "",
  });
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [listMembers, setMembers] = useState([]);
  const [saveMembers, setSaveMembers] = useState([]);

  const onChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value});
  };

  const onSubmitForm = async (e) => {
    setLoading(true);

    let sendInfo = {
      data: {
        queue_name: form.queue_name,
        queue_description: form.queue_description,
        timeout: form.timeout,
        ringinuse: form.ringinuse || "no",
        queue_youarenext: form.queue_youarenext || "",
        autopause: form.autopause || "no",
        strategy: form.strategy || "ringall",
        joinempty: form.joinempty || "yes",
        leavewhenempty: form.leavewhenempty || "no",
        queue_args: form.queue_args || "",
      },
      id: id,
      idQueque: modal.pk,
    };
    await petition_patch("QueuesDetails", sendInfo)
      .then(({data: result}) => {
        setReloadData(true);
        let petitionMembers = [];
        let existListMembers = [];

        listMembers.map((element) => {
          if (element.pk) existListMembers.push(element);
          else
            petitionMembers.push(
              petition_post("createQueueMember", {
                id,
                queue_id: modal.pk,
                data: element,
              })
            );
        });

        saveMembers.map((element) => {
          let findExistMember = existListMembers.find(
            (elementTwo) => elementTwo.pk === element.pk
          );
          if (findExistMember)
            petitionMembers.push(
              petition_patch("QueuesDetailsMembers", {
                id,
                queue_id: modal.pk,
                member_id: element.pk,
                data: findExistMember,
              })
            );
          else
            petitionMembers.push(
              petition_delete("deleteQueueMember", {
                id,
                queue_id: modal.pk,
                member_id: element.pk,
              })
            );
        });

        Promise.all(petitionMembers)
          .then(({data: result}) => {
            setModal(false);

            setLoading(false);
            toastr.success("Action Success", "Success");
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            toastr.error("Action failed", "Error");
          });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toastr.error("Action failed", "Error");
      });
  };

  //Custom Navigation
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  useEffect(() => {
    if (modal) {
      setForm(modal);

      petition_get("queueMembers", {id, queue_id: modal.pk})
        .then(({data: result}) => {
          setMembers(result.result);
          setSaveMembers(result.result);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [modal]);

  return (
    <Modal
      size="lg"
      centered={true}
      isOpen={modal ? true : false}
      className="info "
    >
      <ModalHeader className="bg-soft-primary">
        {" "}
        <Row>
          <Col xs="10">
            <div className="text-primary p-3">
              <h4 className="text-primary">Edit Queues</h4>
            </div>
          </Col>
          <Col xs="2" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Nav
          tabs
          className="nav-tabs-custom  flex-column flex-md-row  text-center  "
        >
          <NavItem className="pt-3 pt-md-0">
            <NavLink
              style={{cursor: "pointer"}}
              className={classnames({
                active: customActiveTab === "1",
              })}
              onClick={() => {
                toggleCustom("1");
              }}
            >
              <span>Basic Settings</span>
            </NavLink>
          </NavItem>
          <NavItem className="pt-3 pt-md-0">
            <NavLink
              style={{cursor: "pointer"}}
              className={classnames({
                active: customActiveTab === "2",
              })}
              onClick={() => {
                toggleCustom("2");
              }}
            >
              <span>Members</span>
            </NavLink>
          </NavItem>
          <NavItem className="pt-3 pt-md-0">
            <NavLink
              style={{cursor: "pointer"}}
              className={classnames({
                active: customActiveTab === "3",
              })}
              onClick={() => {
                toggleCustom("3");
              }}
            >
              <span>Advanced Settings</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={customActiveTab}>
          <TabPane tabId="1" className="p-3">
            <BasicSettings
              onChange={onChange}
              form={form}
              setForm={setForm}
              setModal={setModal}
            />
          </TabPane>
          <TabPane tabId="2" className="p-3">
            <Members
              listUsers={listUsers}
              list={listMembers}
              setList={setMembers}
              setAdd={setAdd}
            />
          </TabPane>
          <TabPane tabId="3" className="p-3">
            <AdvancedSettings form={form} setForm={setForm} />
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          color="secondary"
          onClick={() => {
            if (add) {
              return setAdd(false);
            }
            setCustomActiveTab("1");
            setModal(false);
          }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={() => {
            onSubmitForm();
          }}
          disabled={loading}
        >
          {loading && (
            <Spinner
              style={{marginRight: "0.5em"}}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
