import React, {useState, useEffect} from "react";
import CustomForm from "../../../../../../components/utils/CustomForm";
import petition_get from "../../../../../petitions/petition_get";
export default function Voicemail({
  handleDeleteModule,
  handleSubmitModule,
  defaultOption,
}) {
  const [customForm, setCustomForm] = useState(null);

  const {data} = defaultOption || {data: {}};

  const formConfig = {
    defaultValues: {
      mediaId: data.id || "",
    },
  };

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  useEffect(() => {
    petition_get("musicOnHoldMedia", {id})
      .then(({data: result}) =>
        setCustomForm([
          {
            type: "select",
            options: result.result.map((play) => ({
              value: play.pk,
              label: play.name,
            })),
            label: "Media File",
            name: "mediaId",
          },
        ])
      )
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <CustomForm
        activeDelete={!data.new ? handleDeleteModule : null}
        onSubmit={handleSubmitModule}
        customForm={customForm}
        formConfig={formConfig}
      />
    </div>
  );
}
