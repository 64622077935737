import React, { useState } from 'react'
import phoneImg from "../../../../../assets/images/phone-image.svg";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import toastr from "toastr";
import petition_get from "../../../../petitions/petition_get";
import petition_delete from "../../../../petitions/petition_delete";
export default function ModalUtilityDelete({ dataTimeDate, setReloadData, modal, setModal }) {

  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [loading, setLoading] = useState(false)


  const handleDelete = async () => {
    setLoading(true)





    if (dataTimeDate) {

      petition_delete("deleteTimeRules", { id, time_id: modal.pk })
        .then(({ data: result }) => {


          setLoading(false);
          setModal(false);
          toastr.success("Action completed", "Success");
          return setReloadData(true);

        })
        .catch((error) => console.log(error));
    } else {
      petition_get("timeRules", { id: id, query: modal.id })
        .then(({ data: result }) => {

          /*    let petitions = []
             modal.itpvoice_metadata.callflows.map(element => petitions.push(petition_delete("callFlow", { id, callflow_id: element })))
   
             Promise.all(petitions).then(result => {
               setLoading(false);
               setModal(false);
               toastr.success("Action completed", "Success");
               setReloadData(true);
             }).catch(error => {
               setLoading(false)
               toastr.error("Action failed", "Error");
             }) */


          let petitions = []
          result.result.map(element => petitions.push(petition_delete("deleteTimeRules", { id, time_id: element.pk })))

          if (modal.forced_disable_code_callflow_id) petitions.push(petition_delete("callFlow", { id, callflow_id: modal.forced_disable_code_callflow_id }))
          if (modal.forced_enable_code_callflow_id) petitions.push(petition_delete("callFlow", { id, callflow_id: modal.forced_enable_code_callflow_id }))
          if (modal.forced_reset_code_callflow_id) petitions.push(petition_delete("callFlow", { id, callflow_id: modal.forced_reset_code_callflow_id }))


          Promise.all(petitions).then(result => {
            petition_delete("timeGroup", { id, time_id: modal.id })
              .then(({ data: result }) => {
                setLoading(false);
                setModal(false);
                toastr.success("Action completed", "Success");
                setReloadData(true);
              })
              .catch((error) => {
                setLoading(false)
                console.log(error);
                toastr.error("Action failed", "Error");
              });
          }).catch(error => {
            setLoading(false)
            toastr.error("Action failed", "Error");
          })

        })
        .catch((error) => { });

    }
  }

  return (
    <Modal centered={true} isOpen={modal ? true : false} >
      <ModalHeader>
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h3 className="text-primary">Delete {dataTimeDate ? "Time Date" : "Time Group"}</h3>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={phoneImg} alt="" className="img-fluid" />
          </Col>
        </Row></ModalHeader>

      <ModalBody> {dataTimeDate ? "Are you sure you want to delete this time date? " : "Are you sure you want to delete this time group"} </ModalBody>

      <ModalFooter>       <Button
        outline
        disabled={loading}

        color="secondary"
        onClick={() => {
          setModal(false);
        }}
      >
        Cancel
      </Button>

        <Button
          disabled={loading}
          color="danger"
          onClick={() => {
            handleDelete()
          }}
        >
          {loading && (
            <Spinner
              style={{ marginRight: "0.5em" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Delete
        </Button> </ModalFooter>
    </Modal>
  )
}
