import React, {useEffect, useState} from "react";

import {Container, Card, Table, Badge, Spinner} from "reactstrap";
import {useHistory} from "react-router-dom";

import petition_get from "../../../petitions/petition_get";
import petition_patch from "../../../petitions/petition_patch";
import petition_delete from "../../../petitions/petition_delete";

import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import GetAppIcon from "@material-ui/icons/GetApp";
import Tools from "./Tools/Tools";
import ButtonHover from "../../../../components/utils/ButtonHover";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import ModalUtilityEdit from "./ModalUtility/ModalUtilityEdit";
import ModalUtilityDelete from "./ModalUtility/ModalUtilityDelete";
import ModalUtilityAdd from "./ModalUtility/ModalUtilityAdd";

import toastr from "toastr";

import CustomTable from "../../../../components/utils/CustomTable/CustomTable";

import {get, set} from "lodash";
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum";
import GraphCallflow from "./GraphCallflow/GraphCallflow";

export default function Callflows() {
  toastr.options = {
    showDuration: 300,
  };

  const [modalDelete, setModalDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingPetition, setLoadingPetition] = useState(false);
  const [data, setData] = useState([]);
  const [reloadData, setReloadData] = useState(true);
  const [dataTable, setDataTable] = useState(null);

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const deleteCallFlows = async () => {
    setLoading(true);
    let saveCallFlowDetails;
    let flowDevice2;
    let filterNodesDelete = [];

    await petition_get("callflowsDetails", {id, callflow: modalDelete.pk})
      .then(({data: result}) => {
        saveCallFlowDetails = result.result;
      })
      .catch((error) => {
        console.log(error);
      });

    petition_delete("callFlow", {id, callflow_id: modalDelete.pk})
      .then(({data: result}) => {
        if (saveCallFlowDetails.dataChart) {
          setLoading(false);
          toastr.success("Action completed", "Success");
          setReloadData(true);
          setModalDelete(false);
        } else {
          setLoading(false);
          toastr.success("Action completed", "Success");
          setReloadData(true);
          setModalDelete(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toastr.error("Action failed", "Error");
      });
  };

  const activeDeleteModal = (e) => {
    if (
      e.type === "mainUserCallflow" ||
      e.type === "QueueCallflow" ||
      e.type === "ring_group" ||
      e.type === "TemporalRouteCallflow"
    ) {
      return toastr.warning("Cannot delete this Callflow", "Warning");
    }
    setModalDelete(e);
  };
  const activeEditModal = (e) => {
    if (e.flow.route) setModalEdit(e);
    else {
      let tempJson = e;

      Object.keys(e.dataChart.nodes).map((node) => {
        if (e.dataChart.nodes[node].childrenUrl) {
          let getData = get(tempJson.flow, e.dataChart.nodes[node].childrenUrl);
          set(tempJson.flow, e.dataChart.nodes[node].childrenUrl, {
            route: `flow.${e.dataChart.nodes[node].childrenUrl}`,
            ...getData,
          });
        }
      });
      tempJson.flow.route = "flow";
      setModalEdit(tempJson);
    }
  };

  useEffect(() => {
    if (reloadData) {
      petition_get("allCallflows", {id})
        .then(({data: result}) => {
          let tempResult = [];
          result.result.map(
            (element) =>
              element.type === "CustomByUser" &&
              tempResult.push({
                ...element,
                numbers: element.numbers.map((elementTwo) => elementTwo),
                numbersText:
                  element.numbers.length > 0
                    ? element.numbers.map((elementTwo) => elementTwo)
                    : "Not assigned numbers",
              })
          );

          setDataTable({
            title: [
              {title: "Name", key: "name"},
              {title: "Numbers", key: "numbersText"},
            ],
            content: [...tempResult],
            actions: {
              title: "Actions",
              content: [
                {
                  icon: <DeleteIcon />, //Svg icon
                  handleClick: activeDeleteModal, //Function
                },
                {
                  icon: <EditIcon />, //Svg icon
                  handleClick: activeEditModal, //Function
                },
              ],
            },
            search: {
              label: "Search Menu",
            },
            addButton: {
              label: "Add Callflow",
              handleClick: () => {
                setModalAdd(true);
              },
            },
          });
          setData(tempResult);
          setLoadingPetition(true);
          setReloadData(false);
        })
        .catch((error) => {
          console.log(error);
          setReloadData(false);
        });
    }
  }, [reloadData]);

  const reload = () => {
    setModalAdd(false);
    setModalEdit(false);
    setReloadData(true);
  };

  return (
    <>
      {/* {modalEdit && (
        <ModalUtilityEdit
          modal={modalEdit}
          loading={loading}
          setModal={setModalEdit}
          setReloadData={setReloadData}
        />
      )} */}

      <ModalUtilityDelete
        modal={modalDelete}
        loading={loading}
        setModal={setModalDelete}
        deleteCallFlows={deleteCallFlows}
      />
      {/* {modalAdd && (
        <ModalUtilityAdd
          setReloadData={setReloadData}
          modal={modalAdd}
          loading={loading}
          setModal={setModalAdd}
        />
      )} */}

      <div className="page-content pt-0">
        <CustomBreadCrum
          removePadding
          title="Your Phone System"
          subtitle="Callflows"
        />
        {!modalAdd && !modalEdit && (
          <CustomTable responsive={true} data={dataTable} />
        )}

        {(modalAdd || modalEdit) && (
          <GraphCallflow reload={reload} modal={modalEdit} />
        )}
      </div>
    </>
  );
}
