import React, {useEffect, useState} from "react";
import {
  Container,
  Table,
  CustomInput,
  Row,
  Col,
  Button,
  Spinner,
  Card,
  CardBody,
} from "reactstrap";

import {useHistory} from "react-router-dom";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AddIcon from "@material-ui/icons/Add";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styled from "styled-components";
import toastr from "toastr";

import NavbarTitle from "../NavbarTitle/NavbarTitle";
import petition_get from "../../../../petitions/petition_get";
import petition_delete from "../../../../petitions/petition_delete";

import ButtonHover from "../../../../../components/utils/ButtonHover";

//Modal
import ModalUtility from "../ModalUtility/ModalUtility";

import Deleted from "../../../../../components/utils/Deleted";
import CustomTable from "../../../../../components/utils/CustomTable/CustomTable";

export default function ListAllUsers({
  data,
  setData,
  allData,
  setAllData,
  loading,
  setLoading,
  editUser,
  setEditUser,
  setInfoUser,
  setDeleteUser,
}) {
  toastr.options = {
    showDuration: 300,
  };
  const history = useHistory();
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  //   const [data, setData] = useState(null);
  //   const [allData, setAllData] = useState(null);
  const cantPagination = 10;
  const [pagination, setPagination] = useState(cantPagination);
  const [modal, setModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [message, setMessage] = useState("");
  const [searchFilter, setSearchFilter] = useState("");

  const [dataTable, setDataTable] = useState(null);

  useEffect(() => {
    if (!data) return;
    setDataTable({
      title: [
        {title: "FIRST NAME", key: "first_name"},
        {title: "LAST NAME", key: "last_name"},
        {title: "EMAIL", key: "email"},
        {title: "EXTENSION NUMBER", key: "presence_id"},
        {title: "TYPE", key: "priv_level"},
      ],
      content: [...data],
      actions: {
        title: "Actions",
        content: [
          {
            icon: <DeleteIcon />, //Svg icon
            handleClick: (e) => {
              setModal(e.pk);
              setMessage(
                "Are you sure you wish to delete this User? All user information including Voicemails will be removed."
              );
            }, //Function
          },
          {
            icon: <EditIcon />, //Svg icon
            handleClick: (e) => {
              setEditUser(true);
              setInfoUser(e);
            }, //Function
          },
        ],
      },
      search: {
        label: "Search Users",
      },
      addButton: {
        label: "Add User",
        handleClick: () => {
          history.push(`/AddUsers?id=${id}`);
        },
      },
    });
  }, [data]);

  function search(val) {
    setSearchFilter(val);
  }

  const onClickNext = () => {
    if (pagination < data.length) {
      setPagination(pagination + cantPagination);
    }
  };

  const onClickBack = () => {
    if (pagination > cantPagination) {
      setPagination(pagination - cantPagination);
    }
  };

  const deleteUser = () => {
    setSpinner(true);
    petition_delete("deleteUser", {api_id: id, user_id: modal})
      .then(({data: result}) => {
        const newData = data.filter((user) => user.id !== modal);
        setDeleteUser(true);
        setData(newData);
        setModal(false);
        toastr.success("Action completed", "Success");
        setSpinner(false);
      })
      .catch((error) => {
        console.log(error.response);
        toastr.error("Action failed", "Error");
        setMessage("error");
        setSpinner(false);
      });
  };

  return (
    <>
      <ModalUtility
        setModal={setModal}
        modal={modal}
        deleteUser={deleteUser}
        message={message}
        spinner={spinner}
      />
      <CustomTable data={dataTable} />
    </>
  );
}
