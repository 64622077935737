import React, { useEffect, useState } from "react";

import {
    Button,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Table,
    CustomInput
} from "reactstrap";
import phoneImg from "../../../../../assets/images/phone-image.svg";

import CustomTable from "../../../../../components/utils/CustomTable/CustomTable"

import { v4 as uuidv4 } from 'uuid';
import toastr from "toastr";
import petition_get from "../../../../petitions/petition_get"

export default function ModalUtilityEdit({ modal, setModal, loading, featuresCallflow, updateParkPickup }) {

    const [parkingSlot, setParkingSlot] = useState("5")
    const [timeout, setTimeout] = useState(null)
    const [dataTable, setDataTable] = useState(null)

    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    useEffect(() => {


        if (featuresCallflow.parkPick && modal) {

            setDataTable(null)
            let codeList = []
            setTimeout(featuresCallflow.parkCode[0].flow.data.timeout)
            featuresCallflow.parkPick.map(element => codeList.push({ code: element.numbers[0] }))
            setDataTable({
                title: [
                    { title: "Parking Slots", key: "code" },
                ],
                content: codeList,
            });
            setParkingSlot(featuresCallflow.parkPick.length)

        }
    }, [featuresCallflow, modal])



    return (
        <Modal centered={true} isOpen={modal ? true : false}>
            <ModalHeader className="bg-soft-primary">

                <Row>
                    <Col xs="7">
                        <div className="text-primary p-3">
                            <h3 className="text-primary">Edit Call Pickup</h3>
                        </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                        <img src={phoneImg} alt="" className="img-fluid" />
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>

                <div className="row px-2 mb-3">

                    <label htmlFor="parkin">Park Timeout:</label>
                    <input type="text" className="form-control col-12" value={timeout} onChange={(e) => { setTimeout(e.target.value) }} />
                </div>


                <label htmlFor="parkin">Parking Slots:</label>
                <select value={parkingSlot} className="form-control" onChange={(e) => { setParkingSlot(e.target.value) }} name="" id="parkin">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>

                </select>

                <CustomTable bordered={true} data={dataTable} />




            </ModalBody>

            <ModalFooter>

                <Button
                    outline
                    disabled={loading}
                    color="secondary"
                    onClick={() => { setModal(false) }}
                >
                    Cancel
                </Button>
                <Button
                    disabled={loading}
                    color="primary"
                    onClick={() => { updateParkPickup(parkingSlot, timeout) }}
                >
                    {loading && (
                        <Spinner
                            style={{ marginRight: "0.5em" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
}
