import React, {useState, useEffect} from "react";
import {Button, CustomInput} from "reactstrap";
import ReactTooltip from "react-tooltip";
import InfoIcon from "@material-ui/icons/Info";
import {SidebarItem} from "../../../components/Index";
import {get} from "lodash";
export default function ExternalNumber({
  setDetails,
  details,
  updateAction,
  state,
  startPoint,
  StartItemStyle,
  timeGroups,
  selectNode,
  dataCallFlow,
}) {
  const [form, setForm] = useState({
    number: "",
    require_keypress: false,
    keep_caller_id: true,
    timeout: "30",
  });

  const [activeForm, setActiveForm] = useState(false);
  const [activeTimeGroups, setActiveTimeGroups] = useState(false);
  const [selectTimeGroup, setSelectTimeGroup] = useState(null);
  const [defaultValue, setDefaultValue] = useState(true);
  const [timeGroupsValues, setTimeGroupsValues] = useState([]);

  const [activeMenu, setActiveMenu] = useState(false);
  const [menuOptions, setMenuOptions] = useState(false);
  const [selectMenu, setSelectMenu] = useState(null);

  const onChangeAutocompleteMenu = (e) => {
    setSelectMenu(e.target.value);
  };
  const onChangeAutocompleteTimeGroup = (e) => {
    setSelectTimeGroup(e.target.value);
  };

  useEffect(() => {
    setDetails({timeGroups: selectTimeGroup});
  }, [selectTimeGroup]);
  useEffect(() => {
    setDetails({menuOption: selectMenu});
  }, [selectMenu]);

  useEffect(() => {
    if (selectNode && selectNode.type.split("_")[0] === "Time") {
      setDefaultValue(true);

      let values;
      if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
      else {
        values = get(dataCallFlow.flow, selectNode.childrenUrl).children;
      }

      const timeGroupsKeys = Object.keys(values);
      const newTimeGroups = [];
      timeGroups.map((element) => {
        const findOne = timeGroupsKeys.find(
          (elementTwo) => elementTwo === element.id
        );
        if (!findOne) {
          newTimeGroups.push(element);
        }
      });

      if (values._) {
        setDefaultValue(false);
        setSelectTimeGroup(newTimeGroups.length > 0 ? newTimeGroups[0].id : "");
      } else {
        setSelectTimeGroup("_");
      }
      setTimeGroupsValues(newTimeGroups);
      setActiveTimeGroups(false);
    } else if (selectNode && selectNode.type.split("_")[0] === "Menu") {
      let MenuOptions = [
        {id: "1"},
        {id: "2"},
        {id: "3"},
        {id: "4"},
        {id: "5"},
        {id: "6"},
        {id: "7"},
        {id: "8"},
        {id: "9"},
        {id: "0"},
        {id: "timeout"},
        {id: "invalid"},
      ];
      let values;
      if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
      else values = get(dataCallFlow.flow, selectNode.childrenUrl).children;

      const MenuKeys = Object.keys(values);
      const newMenu = [];
      MenuOptions.map((element) => {
        const findOne = MenuKeys.find(
          (elementTwo) => elementTwo === element.id
        );
        if (!findOne) {
          newMenu.push(element);
        }
      });
      setSelectMenu(newMenu[0].id);
      setMenuOptions(newMenu);
      setActiveMenu(true);
      setActiveTimeGroups(true);
    } else {
      setActiveTimeGroups(true);
    }
  }, []);

  const handleSubmit = () => {
    setActiveForm(form.number);
    setDetails({
      menuOption: selectMenu,
      ...form,
      timeGroups: selectTimeGroup,
    });
  };

  return (
    <>
      {activeTimeGroups ? (
        <>
          {activeMenu ? (
            <>
              {" "}
              <select
                onChange={onChangeAutocompleteMenu}
                className="form-control"
                list="datalistOptions"
                id="exampleDataList"
                placeholder="Type to search..."
              >
                {menuOptions.map((element, i) => (
                  <option key={i} value={element.id}>
                    {element.id}
                  </option>
                ))}
              </select>
              {selectMenu && (
                <Button
                  onClick={async () => {
                    setActiveMenu(false);
                  }}
                  className=" mt-3 w-100"
                  color="primary"
                >
                  Create
                </Button>
              )}{" "}
            </>
          ) : (
            <>
              <div>
                <label
                  style={{textAlign: "left", width: "100%"}}
                  htmlFor="externalNumber"
                >
                  External Number:
                </label>
                <input
                  onChange={(e) => {
                    setForm({...form, number: e.target.value});
                  }}
                  className="form-control"
                  value={form.number}
                  id="externalNumber"
                  type="number"
                />
                <div className="pt-4">
                  <label
                    style={{textAlign: "left", width: "100%"}}
                    htmlFor="timeout"
                  >
                    Timeout:
                  </label>
                  <input
                    onChange={(e) => {
                      setForm({...form, timeout: e.target.value});
                    }}
                    className="form-control"
                    value={form.timeout}
                    id="timeout"
                    type="number"
                  />
                </div>
                <div className="d-flex mt-3">
                  <CustomInput
                    onChange={(e) => {
                      setForm({...form, require_keypress: e.target.checked});
                    }}
                    checked={form.require_keypress}
                    type="checkbox"
                    className="input-check"
                    name=""
                    id="check1"
                  />
                  <label className="m-0 pr-2 d-flex" htmlFor="check1">
                    Confirm Call?
                    <InfoIcon
                      data-tip
                      data-for="text1"
                      style={{paddingLeft: "0.5rem", fontSize: "21px"}}
                    />
                  </label>
                  <ReactTooltip id="text1">
                    <div className="text-left" style={{fontSize: "10px"}}>
                      When receiving a forwarded call, user will
                      <br />
                      be required to press 1 to answer the call.
                      <br />
                      This is useful when you are forwarding calls
                      <br />
                      to a cellphone and want to avoid connecting
                      <br />
                      to the Cell Phone's voicemail.
                    </div>
                  </ReactTooltip>
                </div>
                <div className="d-flex mt-3">
                  <CustomInput
                    onChange={(e) => {
                      setForm({...form, keep_caller_id: e.target.checked});
                    }}
                    checked={form.keep_caller_id}
                    type="checkbox"
                    className="input-check"
                    name=""
                    id="check2"
                  />
                  <label className="m-0 d-flex text-left" htmlFor="check2">
                    Kepp Caller's Caller ID?
                    <InfoIcon
                      data-tip
                      data-for="text2"
                      style={{paddingLeft: "0.5rem", fontSize: "21px"}}
                    />
                  </label>
                  <ReactTooltip id="text2">
                    <div className="text-left" style={{fontSize: "10px"}}>
                      - If enabled, this will attempt to show the
                      <br />
                      Caller's Caller ID on the forwarded phone.
                      <br />
                      - If disabled, the caller ID displayed
                      <br />
                      will be the system default
                    </div>
                  </ReactTooltip>
                </div>
                <Button
                  onClick={handleSubmit}
                  className=" mt-3 w-100"
                  color="primary"
                >
                  Create
                </Button>
              </div>

              {activeForm && (
                <SidebarItem
                  updateAction={updateAction}
                  state={state}
                  label={`externalNumber`}
                  type={`externalNumber_${activeForm}`}
                  ports={startPoint(`externalNumber_${activeForm}`)}
                  ItemStyle={StartItemStyle}
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          <select
            onChange={onChangeAutocompleteTimeGroup}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            placeholder="Type to search..."
          >
            {defaultValue && <option value="_">All Other Times</option>}
            {selectTimeGroup === "" && (
              <option value="">You have no more options </option>
            )}
            {timeGroupsValues.map((element, i) => (
              <option key={i} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>

          {selectTimeGroup && (
            <Button
              disabled={selectTimeGroup === ""}
              onClick={async () => {
                setActiveTimeGroups(true);
              }}
              className=" mt-3 w-100"
              color="primary"
            >
              Create
            </Button>
          )}
        </>
      )}
    </>
  );
}
