import React, {useEffect, useState, useRef} from "react";
import {FlowChartWithState} from "@mrblenny/react-flow-chart";
import {Page, Sidebar, SidebarItem, MenuOption} from "./components/Index";
import {chartSimple} from "./misc/exampleChartState.js";
import {Card, Button} from "reactstrap";
import {NumbersOption, UsersOption, CallFlowOption} from "./optionsComponents";
import styled from "styled-components";
import petition_get from "../../pages/petitions/petition_get";
import petition_post from "../../pages/petitions/petition_post";
import petition_patch from "../../pages/petitions/petition_patch";
import petition_delete from "../../pages/petitions/petition_delete";
import ButtonHover from "../utils/ButtonHover";
import {get, set} from "lodash";
import toastr from "toastr";

import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";

import ReactTooltip from "react-tooltip";
import ZoomButtons from "./ZoomButtons";
import BackOptionButton from "./BackOptionButton";

const StartPoint = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props && props.selectNode ? "white" : "rgba(86, 95, 121, 1)"};
  color: ${(props) =>
    props && props.selectNode ? "rgba(86, 95, 121, 1)" : "white"};
  border-radius: 5px;
  height: 50px;
  cursor: pointer;
  padding: 0 1rem;
  font-size: 16px;
  transition: 2s all;
  .btn-left {
    color: ${(props) =>
      props && props.selectNode ? "rgba(86, 95, 121, 1)" : "white"} !important;
    position: absolute;
    bottom: 5px;
    left: 5px;
    cursor: pointer;
    width: 30px !important;
    padding: 0 0.1rem;
    background: transparent;
    border: none;
    box-shadow: none;
  }
  .btn-right {
    border: none;
    width: 30px !important;
    color: ${(props) =>
      props && props.selectNode ? "rgba(86, 95, 121, 1)" : "white"} !important;
    position: absolute;
    bottom: 5px;
    right: 5px;
    cursor: pointer;
    padding: 0 0.1rem;
    background: transparent;
    box-shadow: none;
  }
`;

const CanvasOuterCustom = styled.div`
  position: relative;
  background-size: 10px 10px;
  background-color: rgba(67, 76, 103, 1);
  width: 100%;
  border-radius: 5px;
  height: 80vh;
  overflow: hidden;
  cursor: not-allowed;
`;

export default function FlowChart({
  dataChart,
  chartSimpleData,
  setGetData,
  getMainCallFlow,
  activeSave,
  setActiveSave,
  setModal,
  setReloadData,
  data,
  form,
  setFirstModal,
}) {
  toastr.options = {
    showDuration: 300,
  };
  const {menuOptions} = dataChart;
  const [selectNode, setSelectNode] = useState(null);
  const [activeMenu, setActiveMenu] = useState(false);
  const [queues, setQueues] = useState([]);
  const [activeOptions, setActiveOptions] = useState(false);
  const [listUsers, setListUsers] = useState([]);
  const [activeEdit, setActiveEdit] = useState();
  const [listOfUpdateDevices, setListOfUpdateDevices] = useState([]);
  const [dataCallFlow, setDataCallFlow] = useState({
    contact_list: {exclude: false},
    numbers: [],
    patterns: [],
    name: "",
    flow: {},
  });
  const refChart = useRef();

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const deleteNode = (node) => {
    setActiveEdit();
    let newState = refChart.current.state;

    //Nodes
    const newArrayLinks = Object.keys(newState.links);

    let nodeId = [`${node.id}`];
    let deleteNodes = [`${node.id}`];
    let deleteLinks = [];
    let flag = true;

    newArrayLinks.find((element) => {
      if (newState.links[element].to.nodeId === nodeId[0]) {
        deleteLinks.push(newState.links[element].id);
      }
    });

    while (flag) {
      let findOne = [];
      nodeId.map((elementThree) => {
        newArrayLinks.map((element) => {
          if (newState.links[element].from.nodeId === elementThree) {
            findOne.push(element);
          }
        });
      });

      console.log(findOne);

      if (findOne.length > 0) {
        console.log(findOne);
        nodeId = [];
        findOne.map((elementThree) => {
          deleteLinks.push(newState.links[elementThree].id);
          deleteNodes.push(newState.links[elementThree].to.nodeId);

          nodeId.push(newState.links[elementThree].to.nodeId);
        });
      } else {
        flag = false;
      }
    }
    let getNodes = {};
    const arrayNodes = Object.keys(newState.nodes);

    arrayNodes.map((element) => {
      const findNode = deleteNodes.filter(
        (elementTwo) => elementTwo === newState.nodes[element].id
      );

      if (findNode.length === 0) {
        getNodes = {...getNodes, [element]: newState.nodes[element]};
      }
    });
    const arrayLinks = Object.keys(newState.links);
    let getLinks = {};

    arrayLinks.map((element) => {
      const findNode = deleteLinks.filter(
        (elementTwo) => elementTwo === newState.links[element].id
      );
      if (findNode.length === 0) {
        getLinks = {...getLinks, [element]: newState.links[element]};
      }
    });

    newState.links = getLinks;
    newState.nodes = getNodes;

    refChart.current.setState(newState);

    let dataCallFlowCopy = dataCallFlow;

    if (Object.keys(getNodes).length === 1) {
      dataCallFlowCopy.flow = {};
    } else {
      console.log(node.childrenUrl);
      const parseChildren = node.childrenUrl.split(".");
      let newChildrenUrl = "";
      parseChildren.map((element, i) => {
        if (i < parseChildren.length - 1) {
          newChildrenUrl +=
            element + `${i === parseChildren.length - 2 ? "" : "."}`;
        }
      });

      const getChildrens = get(dataCallFlowCopy.flow, newChildrenUrl);

      const childrenAccess = parseChildren[parseChildren.length - 1];

      delete getChildrens[childrenAccess];
      set(dataCallFlowCopy.flow, newChildrenUrl, getChildrens);
    }
    setDataCallFlow(dataCallFlowCopy);
  };

  const editNode = (node) => {
    setActiveMenu("Callflow");
    setActiveEdit(node);
  };

  const NodeCustom = React.forwardRef(
    ({node, children, ...otherProps}, ref) => {
      switch (node.type.split("_")[0]) {
        case `User`:
          return (
            <StartPoint ref={ref} {...otherProps}>
              {`${node.type.split("_")[0]} `} : {`${node.type.split("_")[1]}`}
            </StartPoint>
          );
        case `externalNumber`:
          return (
            <StartPoint
              selectNode={selectNode && node.id === selectNode.id}
              onClick={() => {
                if (activeEdit) setActiveEdit();
                setSelectNode(node);
              }}
              ref={ref}
              {...otherProps}
            >
              <div className="d-flex" style={{flexDirection: "column"}}>
                <button
                  className="btn-left"
                  onClick={() => {
                    deleteNode(node);
                    setTimeout(() => {
                      if (activeEdit) setActiveEdit();
                      setSelectNode(null);
                    }, 100);
                  }}
                >
                  <CloseIcon />
                </button>
                <button
                  className="btn-right"
                  onClick={() => {
                    editNode(node);
                  }}
                >
                  <EditIcon />
                </button>
                External Number: {`${node.type.split("_")[1]}`} <br />
                {node.menuOption && `Menu Option: ${node.menuOption}`}
                {node.timegroup && `Time Group: ${node.timegroup}`}
              </div>
            </StartPoint>
          );
        case `UserCallflow`:
          return (
            <StartPoint
              selectNode={selectNode && node.id === selectNode.id}
              onClick={() => {
                if (activeEdit) setActiveEdit();
                setSelectNode(node);
              }}
              ref={ref}
              {...otherProps}
            >
              <div className="d-flex" style={{flexDirection: "column"}}>
                <button
                  className="btn-left"
                  onClick={() => {
                    deleteNode(node);
                    setTimeout(() => {
                      if (activeEdit) setActiveEdit();
                      setSelectNode(null);
                    }, 100);
                  }}
                >
                  <CloseIcon />
                </button>
                <button
                  className="btn-right"
                  onClick={() => {
                    editNode(node);
                  }}
                >
                  <EditIcon />
                </button>
                User: {`${node.type.split("_")[1]}`} <br />
                {node.menuOption && `Menu Option: ${node.menuOption}`}
                {node.timegroup && `Time Group: ${node.timegroup}`}
              </div>
            </StartPoint>
          );

        case `Number`:
          return (
            <StartPoint ref={ref} {...otherProps}>
              {`${node.type.split("_")[0]} `} : {`${node.type.split("_")[1]}`}
            </StartPoint>
          );
        case `Callflow`:
          return (
            <StartPoint ref={ref} {...otherProps}>
              <div className="d-flex" style={{flexDirection: "column"}}>
                CALL FLOW (
                {form.addNumbers.length > 1 ? (
                  <>
                    <div data-tip data-for="textNumber">
                      {form.addNumbers[0].number.number}...
                    </div>
                    <ReactTooltip id="textNumber">
                      <div className="text-center" style={{fontSize: "13px"}}>
                        {form.addNumbers.map(
                          (element) => `${element.number.number},`
                        )}
                      </div>
                    </ReactTooltip>
                  </>
                ) : (
                  form.addNumbers.length >= 1 &&
                  form.addNumbers[0].number.number
                )}
                )
              </div>
            </StartPoint>
          );
        case `Time`:
          return (
            <StartPoint
              selectNode={selectNode && node.id === selectNode.id}
              onClick={() => {
                if (activeEdit) setActiveEdit();
                setSelectNode(node);
              }}
              ref={ref}
              {...otherProps}
            >
              <div className="d-flex" style={{flexDirection: "column"}}>
                <button
                  className="btn-left"
                  onClick={() => {
                    deleteNode(node);
                    setTimeout(() => {
                      if (activeEdit) setActiveEdit();
                      setSelectNode(null);
                    }, 100);
                  }}
                >
                  <CloseIcon />
                </button>
                <button
                  className="btn-right"
                  onClick={() => {
                    editNode(node);
                  }}
                >
                  <EditIcon />
                </button>
                Time : {`${node.type.split("_")[1]}`}
                <br />
                {node.menuOption && `Menu Option: ${node.menuOption}`}
                {node.timegroup && `Time Group: ${node.timegroup}`}
              </div>
            </StartPoint>
          );
        case `Media`:
          return (
            <StartPoint
              selectNode={selectNode && node.id === selectNode.id}
              onClick={() => {
                if (activeEdit) setActiveEdit();
                setSelectNode(node);
              }}
              ref={ref}
              {...otherProps}
            >
              <div className="d-flex" style={{flexDirection: "column"}}>
                <button
                  className="btn-left"
                  onClick={() => {
                    deleteNode(node);
                    setTimeout(() => {
                      if (activeEdit) setActiveEdit();
                      setSelectNode(null);
                    }, 100);
                  }}
                >
                  <CloseIcon />
                </button>
                Play Media: {`${node.type.split("_")[1]}`}
                <br />
                {node.menuOption && `Menu Option: ${node.menuOption}`}
                {node.timegroup && `Time Group: ${node.timegroup}`}
              </div>
            </StartPoint>
          );
        case `Menu`:
          return (
            <StartPoint
              selectNode={selectNode && node.id === selectNode.id}
              onClick={() => {
                if (activeEdit) setActiveEdit();
                setSelectNode(node);
              }}
              ref={ref}
              {...otherProps}
            >
              <div className="d-flex" style={{flexDirection: "column"}}>
                <button
                  className="btn-left"
                  onClick={() => {
                    deleteNode(node);
                    setTimeout(() => {
                      if (activeEdit) setActiveEdit();
                      setSelectNode(null);
                    }, 100);
                  }}
                >
                  <CloseIcon />
                </button>
                <button
                  className="btn-right"
                  onClick={() => {
                    editNode(node);
                  }}
                >
                  <EditIcon />
                </button>
                Menu
                <br />
                {node.menuOption && `Menu Option: ${node.menuOption}`}
                {node.timegroup && `Time Group: ${node.timegroup}`}
              </div>
            </StartPoint>
          );
        case `ring`:
          return (
            <StartPoint
              selectNode={selectNode && node.id === selectNode.id}
              onClick={() => {
                if (activeEdit) setActiveEdit();
                setSelectNode(node);
              }}
              ref={ref}
              {...otherProps}
            >
              <div className="d-flex" style={{flexDirection: "column"}}>
                <button
                  className="btn-left"
                  onClick={() => {
                    deleteNode(node);
                    setTimeout(() => {
                      if (activeEdit) setActiveEdit();
                      setSelectNode(null);
                    }, 100);
                  }}
                >
                  <CloseIcon />
                </button>
                <button
                  className="btn-right"
                  onClick={() => {
                    editNode(node);
                  }}
                >
                  <EditIcon />
                </button>
                Ring Group : {`${node.type.split("_")[1]}`}
                <br />
                {node.menuOption && `Menu Option: ${node.menuOption}`}
                {node.timegroup && `Time Group: ${node.timegroup}`}
              </div>
            </StartPoint>
          );
        case `queues`:
          let findQueue = queues.find(
            (queue) => queue.pk.toString() === node.type.split("_")[1]
          );

          let name;
          if (findQueue) name = findQueue.queue_name;
          else name = `${node.type.split("_")[1]} (pk)`;
          return (
            <StartPoint
              selectNode={selectNode && node.id === selectNode.id}
              onClick={() => {
                if (activeEdit) setActiveEdit();
                setSelectNode(node);
              }}
              ref={ref}
              {...otherProps}
            >
              <div className="d-flex" style={{flexDirection: "column"}}>
                <button
                  className="btn-left"
                  onClick={() => {
                    deleteNode(node);
                    setTimeout(() => {
                      if (activeEdit) setActiveEdit();
                      setSelectNode(null);
                    }, 100);
                  }}
                >
                  <CloseIcon />
                </button>
                Queues : {name}
                <br />
                {node.menuOption && `Menu Option: ${node.menuOption}`}
                {node.timegroup && `Time Group: ${node.timegroup}`}
              </div>
            </StartPoint>
          );
        case `voicemail`:
          return (
            <StartPoint
              selectNode={selectNode && node.id === selectNode.id}
              onClick={() => {
                if (activeEdit) setActiveEdit();
                setSelectNode(node);
              }}
              ref={ref}
              {...otherProps}
            >
              <div className="d-flex" style={{flexDirection: "column"}}>
                <button
                  className="btn-left"
                  onClick={() => {
                    deleteNode(node);
                    setTimeout(() => {
                      if (activeEdit) setActiveEdit();
                      setSelectNode(null);
                    }, 100);
                  }}
                >
                  <CloseIcon />
                </button>
                Voice Mail : {`${node.type.split("_")[1]}`}
                <br />
                {node.menuOption && `Menu Option: ${node.menuOption}`}
                {node.timegroup && `Time Group: ${node.timegroup}`}
              </div>
            </StartPoint>
          );
        case `directory`:
          return (
            <StartPoint
              selectNode={selectNode && node.id === selectNode.id}
              onClick={() => {
                if (activeEdit) setActiveEdit();
                setSelectNode(node);
              }}
              ref={ref}
              {...otherProps}
            >
              <div className="d-flex" style={{flexDirection: "column"}}>
                <button
                  className="btn-left"
                  onClick={() => {
                    deleteNode(node);
                    setTimeout(() => {
                      if (activeEdit) setActiveEdit();
                      setSelectNode(null);
                    }, 100);
                  }}
                >
                  <CloseIcon />
                </button>
                Directory : {`${node.type.split("_")[1]}`}
                <br />
                {node.menuOption && `Menu Option: ${node.menuOption}`}
                {node.timegroup && `Time Group: ${node.timegroup}`}
              </div>
            </StartPoint>
          );
        default:
          return (
            <StartPoint ref={ref} {...otherProps}>
              {children}
            </StartPoint>
          );
      }
    }
  );

  useEffect(() => {
    if (data) {
      setDataCallFlow({
        contact_list: data.contact_list,
        numbers: data.numbers,
        patterns: data.patterns,
        name: data.name,
        flow: data.flow,
      });
    }
    petition_get("itpvoiceUsers", {id})
      .then(({data: result}) => {
        setListUsers(result.result);
      })
      .catch((error) => {
        console.log(error);
      });
    petition_get("Queues", {id})
      .then(({data: result}) => setQueues(result.result))
      .catch((error) => console.log(error));
  }, []);

  const updateState = (value) => {
    refChart.current.setState(value);
    if (setGetData) {
      setGetData(value);
    }
  };

  const updateCallFlow = (data, copiaState, urlChildren) => {
    refChart.current.setState(copiaState);
    const dataCallFlowCopy = dataCallFlow;
    if (dataCallFlowCopy.flow.children) {
      set(dataCallFlowCopy.flow, urlChildren, {...data});
    } else {
      dataCallFlowCopy.flow = {...data};
    }

    console.log(dataCallFlowCopy);
    setDataCallFlow(dataCallFlowCopy);
  };

  const updateDevices = (listOfDeleteDevices) => {
    return new Promise((resolve) => {
      let petitions = [];
      listOfUpdateDevices.map((element) => {
        const findOneDevice = listOfDeleteDevices.find(
          (elementTwo) => elementTwo === element.id
        );

        if (!findOneDevice) {
          petitions.push(
            petition_patch("deviceEditCallflow", {
              id,
              deviceId: element.id,
              data: {call_forward: element.call_forward},
            })
          );
        }
      });

      Promise.all(petitions)
        .then((result) => resolve("ready"))
        .catch((err) => resolve("ready"));
    });
  };

  useEffect(() => {
    let petitions = async () => {
      if (activeSave) {
        if (activeEdit) {
          toastr.error("Save your changes", "Error");
          return setActiveSave(false);
        }

        let dataCallFlowCopy = dataCallFlow;

        let numbers = [];
        form.addNumbers.map(
          (element) =>
            form.addNumbers.length >= 1 &&
            element.number.number.split("_")[0] !== "nonum" &&
            numbers.push(element.number.number)
        );

        dataCallFlowCopy.name = form.nameCallflow;
        dataCallFlowCopy.numbers = numbers;

        if (data) {
          let saveCallFlowDetails;

          await petition_get("callflowsDetails", {id, callflow: data.pk})
            .then(({data: result}) => {
              saveCallFlowDetails = result.result;
            })
            .catch((error) => {
              console.log(error);
            });

          petition_patch("callflowEdit", {
            id,
            data: {
              numbers: dataCallFlowCopy.numbers,
              name: dataCallFlowCopy.name,
              callflow_object: {
                flow: dataCallFlowCopy.flow,
                type: "CustomByUser",
                dataChart: refChart.current.state,
              },
            },
            callflow_id: data.pk,
          })
            .then(({data: result}) => {
              toastr.success("Action completed", "Success");
              setModal(false);
              setReloadData(true);
              setActiveSave(false);
            })
            .catch((error) => {
              setActiveSave(false);
              toastr.error("Action Error", "Error");
            });
        } else {
          petition_post("callflow", {
            id,
            data: {
              numbers: dataCallFlowCopy.numbers,
              type: "CustomByUser",
              name: dataCallFlowCopy.name,
              callflow_object: {
                flow: dataCallFlowCopy.flow,
                type: "CustomByUser",
                dataChart: refChart.current.state,
              },
            },
          })
            .then(async ({data: result}) => {
              toastr.success("Action completed", "Success");
              setModal(false);
              setReloadData(true);
              setActiveSave(false);
            })
            .catch((error) => {
              setActiveSave(false);
              toastr.error(error.response.data.error.error_message, "Error");
            });
        }
      }
    };
    petitions();
  }, [activeSave]);

  const filterPort = (value) => {
    const findLinks = [];
    Object.keys(refChart.current.state.links).map((element) => {
      if (
        refChart.current.state.links[element].to.portId === value.toPortId ||
        refChart.current.state.links[element].from.portId === value.toPortId ||
        refChart.current.state.links[element].to.portId === value.fromPortId ||
        refChart.current.state.links[element].from.portId === value.fromPortId
      ) {
        findLinks.push(element);
      }
    });
    if (findLinks.length > 1) return false;

    return true;
  };

  useEffect(() => {
    if (menuOptions.length === 1) {
      setActiveMenu(menuOptions[0]);
    }
  }, [activeMenu]);

  const zoomChart = (active) => {
    let values = refChart.current.state;

    if (active) values.scale = values.scale + 0.05;
    else values.scale = values.scale - 0.05;
    refChart.current.setState(values);
  };

  return (
    <Page>
      <FlowChartWithState
        ref={refChart}
        initialValue={chartSimpleData || chartSimple}
        Components={{
          NodeInner: NodeCustom,
          CanvasOuter: CanvasOuterCustom,
        }}
        config={{
          validateLink: (value) => {
            const result = filterPort(value);
            return result;
          },
        }}
      />

      <Sidebar
        className="px-4"
        style={{
          position: "absolute",
          right: "5px",
          top: "25px",
          background: "transparent",
        }}
      >
        {/* <ZoomButtons zoomChart={zoomChart} /> */}

        <BackOptionButton
          menuOptions={menuOptions}
          activeOptions={activeOptions}
          activeEdit={activeEdit}
          setActiveMenu={setActiveMenu}
          setActiveEdit={setActiveEdit}
        />

        {activeMenu === "Callflow" && (
          <CallFlowOption
            listOfUpdateDevices={listOfUpdateDevices}
            setListOfUpdateDevices={setListOfUpdateDevices}
            setActiveOptions={setActiveOptions}
            selectNode={selectNode}
            setSelectNode={setSelectNode}
            activeEdit={activeEdit}
            setActiveEdit={setActiveEdit}
            refChart={refChart}
            updateCallFlow={updateCallFlow}
            listUsers={listUsers}
            state={refChart.current.state}
            dataCallFlow={dataCallFlow}
            setDataCallFlow={setDataCallFlow}
            setFirstModal={setFirstModal}
            form={form}
            deleteNode={deleteNode}
          />
        )}
      </Sidebar>
    </Page>
  );
}
