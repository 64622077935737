import React, { Component } from 'react';
import { Container,CardTitle,Card,Table,Badge,Button } from "reactstrap";
import petition_get from "../petitions/petition_get";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

export default class Orders extends Component {
    constructor(props){
        super(props)
        this.state = {
            data:[],
        }
    }
    componentDidMount(){
        petition_get("orders","")
        .then(({ data: { result } }) => {
            this.setState({
              data: result,
            });
          })
          .catch((error) => {
            console.log(error);
          });
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Orders" breadcrumbItem="Orders" />
                        <Card body>
                  <CardTitle className="mt-0">
                    Orders
                  </CardTitle>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>ID</th>
                          <th style={{ textAlign: "center" }}>NAME</th>
                          <th style={{ textAlign: "center" }}>
                            STATUS
                          </th>
                          {/* <th style={{maxWidth:"5em"}}>PAYMENT STATUS</th> */}
                          <th style={{ textAlign: "center" }}>CREATED</th>
                          <th style={{ textAlign: "center" }}>VIEW</th>
                        </tr>
                      </thead>

                      <tbody>
                  
                        {this.state.data.map((element, i) => (
                            <tr
                              key={i}
                            //   style={{ display: i < 5 ? null : "none" }}
                            >
                              <th style={{ textAlign: "center" }}>
                                {element.pk}
                              </th>
                              <th style={{ textAlign: "center" }}>
                                {element.name}
                              </th>
                              {/* <th style={{display:"flex",justifyContent:"center",alignItems:"center"}}> */}
                              <th style={{ textAlign: "center" }}>
                                <h5>
                                  <Badge color="primary">
                                    {element.status}
                                  </Badge>
                                </h5>
                              </th>
                              <th style={{ textAlign: "center" }}>
                                {element.date_created}
                              </th>
                              <th style={{ textAlign: "center" }}>
                                <Button color="primary">View</Button>
                              </th>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Card>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}