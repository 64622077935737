import React, {useState, useEffect} from "react";
import petition_get from "../pages/petitions/petition_get";
import petition_delete from "../pages/petitions/petition_delete";
import GetDateLocal from "../components/utils/GetDateLocal";
import json from "../config.json";
import toastr from "toastr";
import {reject} from "lodash";
export default function useVoicemail() {
  toastr.options = {
    showDuration: 300,
  };

  const [data, setData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [userData, setUserData] = useState(null);
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  useEffect(() => {
    getUserData();
  }, []);

  let getUserData = () => {
    petition_get("userDetails", {id: id})
      .then(({data: result}) => setUserData(result.result))
      .catch((error) => console.log(error.response));
  };

  const formatData = (val) => {
    let time = new Date(val);
    let timeZoneTemp = time.toLocaleDateString("en-US", {
      timeZone: userData.timezone,
    });
    let timeZoneTempTime = time.toLocaleTimeString("en-US", {
      timeZone: userData.timezone,
    });

    return (
      time.getFullYear() +
      "-" +
      (time.getMonth() + 1) +
      "-" +
      time.getDate() +
      " " +
      timeZoneTempTime
    );
  };

  const [urlBase] = useState(
    json.prod
      ? "https://api.itpscorp.com/portal/itpvoice/v2/"
      : "https://api.itpscorp.com/dev/portal/itpvoice/v2/"
  );
  const [token] = useState(JSON.parse(localStorage.getItem("authUser")));

  useEffect(() => {
    if (userData) {
      getVoicemailList();
    }
  }, [userData]);

  const getVoicemailList = () => {
    petition_get("voicemailMessages", {id: id})
      .then(({data: result}) => {
        const newDefault = [
          ...result.result.map((element) => {
            element.timestampFormat = formatData(element.usertime);
            element.cutName =
              element.callerid.split("/")[0].split('"')[1].split(" ")[0][0] ||
              "";
            element.duration_seconds = new Date(element.duration)
              .toISOString()
              .substr(
                element.duration < 3600 ? 14 : 11,
                element.duration < 3600 ? 5 : 8
              );
            element.caller_id_name = element.callerid
              .split("/")[0]
              .split('"')[1];
            element.caller_id_number = element.callerid
              .split("/")[0]
              .split('"')[2]
              .split("<")[1]
              .split(">")[0];
            return element;
          }),
        ];

        if (newDefault.length > 0) setSelectedItem(newDefault[0]);
        setData(newDefault);
      })
      .catch((error) => console.log("error", error));
  };
  const onChangeSelectedItem = (item) => {
    setSelectedItem(item);
  };

  const reloadData = () => {
    getVoicemailList();
  };

  const downloadVoicemail = async (element) => {
    return new Promise(async (resolve, reject) => {
      try {
        const downloadFile = async () =>
          fetch(
            urlBase +
              id +
              "/my-extension/voicemail-messages/" +
              element +
              "?token=" +
              token.access_token
          ).then((res) => res.blob());

        const createURL = (blob) => URL.createObjectURL(blob);

        const blob = await downloadFile();
        const create = createURL(blob);

        let link = document.createElement("a");
        link.download = "file.mp3";
        link.href = create;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();

        resolve(true);
      } catch (error) {
        reject(false);
      }
    });
  };

  const getUrlVoiceAudio = (media_id) => {
    return `${urlBase}${id}/my-extension/voicemail-messages/${media_id}?token=${token.access_token}`;
  };

  const deleteVoicemail = (media_id) => {
    return new Promise((resolve, reject) => {
      petition_delete("deleteVoicemail", {id: id, media_id})
        .then(({data: result}) => {
          reloadData();
          toastr.success("Action completed", "Success");
          resolve(true);
        })
        .catch((error) => {
          toastr.error("Action failed", "Error");
          reject(true);
        });
    });
  };

  return {
    data,
    selectedItem,
    onChangeSelectedItem,
    reloadData,
    downloadVoicemail,
    getUrlVoiceAudio,
    deleteVoicemail,
  };
}
