import {CanvasDirection, NodeData, EdgeData} from "reaflow";

import {parser} from "./json-editor-parser";

const toString = (value: string | object) => {
  const isObject = value instanceof Object;

  if (isObject) {
    const entries = Object.entries(value);
    const stringObj = entries.map((val) => [val[0], String(val[1])]);

    return Object.fromEntries(stringObj);
  }

  return String(value);
};

const filterCallflows = (elements) => {
  //Filter Nodes
  let filterElements = [];

  let updateEdges = [];

  Object.keys(elements).map((element) => {
    if (
      elements[element].text !== "flow" &&
      elements[element].text !== "_" &&
      elements[element].text !== "children" &&
      elements[element].text !== "data"
    ) {
      filterElements.push(elements[element]);
    } else {
      let findEdge = Object.keys(elements).find(
        (elementTwo) => elements[elementTwo].from === elements[element].id
      );

      if (findEdge)
        updateEdges.push({
          from: elements[element].id,
          to: elements[findEdge].to,
        });
    }
  });

  let filterElements2 = [];

  filterElements.map((element) => {
    let findEdge = updateEdges.find((edge) => edge.from === element.from);
    if (!findEdge) filterElements2.push(element);
  });

  let filterElements3 = [];

  filterElements2.map((element) => {
    let findEdge = updateEdges.find((edge) => edge.from === element.to);
    if (findEdge && findEdge.to)
      filterElements3.push({
        id: `e${element.from}-${findEdge.to}`,
        from: element.from,
        to: findEdge.to,
      });
    else if (findEdge && !findEdge.to) {
    } else filterElements3.push(element);
  });

  return filterElements3;
};
const filterCallflows2 = (elements) => {
  //Filter Nodes
  let filterElements = [];

  let updateEdges = [];

  Object.keys(elements).map((element) => {
    if (
      JSON.stringify(elements[element].text) !== "{}" &&
      !elements[element]?.text?.nodeFatherId &&
      !elements[element]?.text?.id
    ) {
      filterElements.push(elements[element]);
    } else {
      let findEdge = Object.keys(elements).find(
        (elementTwo) => elements[elementTwo].from === elements[element].id
      );
      let filterEdge = Object.keys(elements).filter(
        (elementTwo) => elements[elementTwo].from === elements[element].id
      );

      if (findEdge)
        updateEdges.push({
          from: elements[element].id,
          to: elements[findEdge].to,
          another: filterEdge.map((edge) => elements[edge].to),
        });
      else
        updateEdges.push({
          from: elements[element].id,
          to: null,
        });
    }
  });

  let filterElements2 = [];

  filterElements.map((element) => {
    let findEdge = updateEdges.find((edge) => edge.from === element.from);

    if (!findEdge) filterElements2.push(element);
  });

  let filterElements3 = [];

  filterElements2.map((element) => {
    let findEdge = updateEdges.find((edge) => edge.from === element.to);

    if (findEdge && findEdge.to)
      findEdge.another.map((edge) =>
        filterElements3.push({
          id: `e${element.from}-${edge}`,
          from: element.from,
          to: edge,
        })
      );
    else if (findEdge && !findEdge.to) {
    } else filterElements3.push(element);
  });

  return filterElements3;
};

export function getEdgeNodes(graph, isExpanded = true) {
  const elements = parser(graph);

  let nodes = [];
  let edges = [];

  let newElements = filterCallflows(elements);

  let newElements2 = filterCallflows2(newElements);

  for (let i = 0; i < newElements2.length; i++) {
    const el = newElements2[i];

    if (isNode(el)) {
      const text = renderText(el.text);
      const lines = text.split("\n");
      const lineLengths = lines
        .map((line) => line.length)
        .sort((a, b) => a - b);
      const longestLine = lineLengths.reverse()[0];

      const height = lines.length * 17.8 < 30 ? 40 : lines.length * 17.8;

      let filterEdge = newElements2.find((element) => element.from === el.id);

      if (el.text.module === "voicemail") filterEdge = true;

      nodes.push({
        hasChildren:
          filterEdge &&
          el.text.module !== "menu" &&
          el.text.module !== "timecondition"
            ? true
            : false,
        id: el.id,
        text: toString(el.text),
        data: {
          isParent: el.parent,
        },
        width: 180,
        height: el.text.route || el.text.container === "main" ? 100 : height,
        ports:
          !filterEdge ||
          el.text?.module === "menu" ||
          el.text?.module === "timecondition"
            ? [
                {
                  currentModule: el.text.module || "",
                  route: `${el.text.route || ""}`,
                  uuid: `${el.id}`,
                  id: `${el.id}-from`,
                  width: 20,
                  height: 20,
                  className: "customAdd",
                  side: "SOUTH",
                  text: "+ ",
                },
              ]
            : [],
      });
    } else {
      edges.push(el);
    }
  }

  return {
    nodes,
    edges,
  };
}

export function getNextLayout(layout: CanvasDirection) {
  switch (layout) {
    case "RIGHT":
      return "DOWN";

    case "DOWN":
      return "LEFT";

    case "LEFT":
      return "UP";

    default:
      return "RIGHT";
  }
}

function renderText(value: string | object) {
  if (value instanceof Object) {
    let temp = "";
    const entries = Object.entries(value);

    if (Object.keys(value).every((val) => !isNaN(+val))) {
      return Object.values(value).join("");
    }

    entries.forEach((entry) => {
      temp += `${entry[0]}: ${String(entry[1])}\n`;
    });

    return temp;
  }

  return value;
}

function isNode(element: NodeData | EdgeData) {
  if ("text" in element) return true;
  return false;
}
