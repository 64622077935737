import React, {useState, useEffect} from "react";
import FlowChart from "../../../../../components/FlowChart/FlowChart";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
  CustomInput,
} from "reactstrap";
import petition_get from "../../../../petitions/petition_get";

import {v4 as uuidv4} from "uuid";
import toastr from "toastr";
export default function ModalUtilityDelete({
  setReloadData,
  modal,
  setModal,
  loading,
}) {
  toastr.options = {
    showDuration: 300,
  };
  const params = new URL(document.location).searchParams;
  const id = params.get("id");
  const [getData, setGetData] = useState(null);
  const [firstModal, setFirstModal] = useState(true);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [activeInputNumber, setActiveInputNumber] = useState(false);
  const [reload, setReload] = useState(false);

  const [data, setData] = useState(null);
  const [allData, setAllData] = useState(null);

  const [form, setForm] = useState({
    nameCallflow: "",
    inputNumber: "",
    addNumbers: [],
    selectedNumbers: [],
  });
  const [activeSave, setActiveSave] = useState(false);
  const dataChart = {
    menuOptions: ["Callflow"],
  };

  const chartSimpleData = {
    offset: {
      x: 0,
      y: 0,
    },
    scale: 1,
    nodes: {
      node1: {
        id: "node1",
        type: `Callflow_1`,
        position: {
          x: 300,
          y: 300,
        },
        ports: {
          portAdd_1: {
            id: "portAdd_1",
            type: "right",
            properties: {
              value: "yes",
            },
          },
        },
      },
    },
    links: {},
    selected: {},
    hovered: {},
  };

  const handleCheck = (element) => {
    const findOne = selectedUsers.find(
      (elementTwo) => elementTwo.number.number === element.number.number
    );

    let newSelectedUsers;
    if (findOne) {
      newSelectedUsers = selectedUsers.filter(
        (elementTwo) => elementTwo.number.number !== element.number.number
      );
    } else {
      newSelectedUsers = selectedUsers;
      newSelectedUsers.push(element);
    }

    setSelectedUsers(newSelectedUsers);
    setReload(!reload);
  };

  const handleCheckAll = () => {
    if (selectedUsers.length === 0) return setSelectedUsers(data);
    setSelectedUsers([]);
  };

  useEffect(() => {
    if (getData) {
      Object.keys(getData.links).map((element) => {
        if (
          getData.links[element].from.nodeId === "node1" ||
          getData.links[element].to.nodeId === "node1"
        ) {
          return getData.links[element];
        }
      });
    }
  }, [getData]);

  useEffect(() => {
    petition_get("view", {id: id})
      .then(({data: result}) => {
        const numbersCopy = [];
        result.result.map(
          (element) => !element.used_by && numbersCopy.push({number: element})
        );
        setData(numbersCopy);
        setAllData(numbersCopy);
      })
      .catch((error) => {
        console.log(error);
        // setLoading(true);
      });
  }, []);

  const onChangeForm = (e) => {
    setForm({...form, [e.target.name]: e.target.value});
  };

  const handleAddNumber = () => {
    //If the Number is repeated
    if (data.find((element) => element.number.number === form.inputNumber)) {
      toastr.error("This Number is Repeated", "Error");
      setActiveInputNumber(false);
      return setForm({...form, inputNumber: ""});
    }

    if (form.inputNumber.trim() !== "") {
      let generateId = uuidv4();

      //push number in data
      setData([
        ...data,
        {delete: true, number: {number: form.inputNumber}, id: generateId},
      ]);

      //push number in selectnumbers
      setSelectedUsers([
        ...selectedUsers,
        {delete: true, number: {number: form.inputNumber}, id: generateId},
      ]);

      //reset
      setForm({...form, inputNumber: ""});
      setActiveInputNumber(false);
    }
  };

  const saveFirstModal = () => {
    console.log(selectedUsers);

    if (form.nameCallflow.trim() === "")
      return toastr.error("Need Call flow Name", "Error");
    if (selectedUsers.length === 0)
      return toastr.error("Need One Number", "Error");

    setForm({...form, addNumbers: selectedUsers});
    setFirstModal(false);
  };

  return (
    <Modal size="xl" centered={true} isOpen={modal ? true : false}>
      <ModalBody>
        {firstModal && (
          <>
            <h3 className="text-center pb-3" style={{opacity: "0.7"}}>
              {" "}
              CallFlow{" "}
            </h3>
            <p className="text-left mb-2"> Please set a callflow name. </p>

            <input
              value={form.nameCallflow}
              onChange={onChangeForm}
              type="text"
              name="nameCallflow"
              id=""
              className="form-control col-12 col-md-3 mb-5"
            />

            <p className="text-left">
              {" "}
              Please select phone number or unique extension number in the
              system that triggers this callflow.{" "}
            </p>
            {data && (
              <Table className="mt-4" hover size={"md"}>
                <thead className="thead-light">
                  <tr>
                    <th className="text-center ">
                      <CustomInput
                        onClick={(e) => {
                          handleCheckAll();
                        }}
                        type="checkbox"
                        className="input-check"
                        name=""
                        id={`check-th`}
                      />
                    </th>

                    <th className="text-center "> Number </th>
                  </tr>
                </thead>

                <tbody>
                  {data.map((element, i) => {
                    let flag = false;
                    const findOne = selectedUsers.find(
                      (elementTwo) =>
                        elementTwo.number.number === element.number.number
                    );
                    if (findOne) flag = true;
                    return (
                      <>
                        <tr>
                          <th className=" py-3 text-center  pl-2" key={i}>
                            <CustomInput
                              onClick={(e) => {
                                handleCheck(element);
                              }}
                              checked={flag}
                              type="checkbox"
                              className="input-check"
                              name=""
                              id={`check-${i}`}
                            />
                          </th>

                          <th className="text-center ">
                            {" "}
                            {element.number.number}{" "}
                          </th>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </Table>
            )}

            {activeInputNumber ? (
              <div className="d-flex text-left">
                <input
                  value={form.inputNumber}
                  onChange={onChangeForm}
                  type="number"
                  name="inputNumber"
                  id=""
                  className="form-control col-12 col-md-3 mr-2"
                />
                <Button
                  disabled={loading}
                  color="primary"
                  onClick={handleAddNumber}
                >
                  Save
                </Button>
              </div>
            ) : (
              <Button
                outline
                disabled={loading}
                color="secondary"
                onClick={() => {
                  setActiveInputNumber(true);
                }}
              >
                Add Number
              </Button>
            )}
          </>
        )}
        <div style={{display: firstModal ? "none" : "inherit"}}>
          <FlowChart
            form={form}
            setFirstModal={setFirstModal}
            setReloadData={setReloadData}
            setModal={setModal}
            activeSave={activeSave}
            setActiveSave={setActiveSave}
            dataChart={dataChart}
            setGetData={setGetData}
            chartSimpleData={chartSimpleData}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          outline
          disabled={loading || activeSave}
          color="secondary"
          onClick={() => {
            setModal(false);
            setSelectedUsers([]);
            setData(allData);
            setForm({
              nameCallflow: "",
              inputNumber: "",
              addNumbers: [],
              selectedNumbers: [],
            });
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={loading || activeSave}
          color="primary"
          onClick={() => {
            if (firstModal) return saveFirstModal();
            setActiveSave(true);
          }}
        >
          {(loading || activeSave) && (
            <Spinner
              style={{marginRight: "0.5em"}}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          {firstModal ? "Next" : "Save"}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
