import React, {useState} from "react";
import {ResizableBox} from "react-resizable";

import "../styles/resizable.css";
export default function CallParkingSlot({parkingCall}) {
  return (
    <>
      <ResizableBox
        height={(parkingCall.length / 4) * 150}
        minConstraints={[250, 200]}
      >
        <div className="containerGlass mt-3">
          <div className="area">
            <ul className="circles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>

          <div className="glass glassDisplay">
            {parkingCall.map((element) => (
              <div className={`glassItem`}>
                <div
                  className={`glassItemType ${
                    element.call_id ? "glassItem-incall" : "glassItem-call"
                  }  `}
                ></div>
                <h5>{element.slot_number}</h5>
                <p>{element.callerid_num}</p>
              </div>
            ))}
          </div>
        </div>
      </ResizableBox>
    </>
  );
}
