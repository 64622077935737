import React, {useEffect, useState} from "react";
import {Container} from "reactstrap";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import {useHistory} from "react-router-dom";

import petition_get from "../../../petitions/petition_get";

import List from "./Lists/List";
import EditUser from "./EditUser/EditUser";
import CustomBreadCrum from "../../../../components/utils/CustomBreadCrum/CustomBreadCrum";

export default function ListAllUsers() {
  const history = useHistory();
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [data, setData] = useState(null);
  const [allData, setAllData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [infoUser, setInfoUser] = useState({});
  const [defaultTimeZone, setDefaultTimeZone] = useState(null);

  useEffect(() => {
    petition_get("accountDetails", {id})
      .then(({data: result}) => {
        setDefaultTimeZone(result.result.data.timezone);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    petition_get("itpvoiceUsers", {id: id})
      .then(({data: result}) => {
        setData(result.result);
        setAllData(result.result);
        setLoading(true);
        setDeleteUser(false);
      })
      .catch((error) => {
        console.log(error);
        setDeleteUser(true);
        setLoading(true);
      });
  }, [deleteUser]);

  return (
    <>
      <div className="page-content">
        <CustomBreadCrum title="Users" subtitle="Users" />
        {editUser ? (
          <EditUser
            id={id}
            setEditUser={setEditUser}
            infoUser={infoUser}
            setDeleteUser={setDeleteUser}
            defaultTimeZone={defaultTimeZone}
          />
        ) : (
          <List
            data={data}
            setData={setData}
            data={allData}
            setAllData={setAllData}
            loading={loading}
            setLoading={setLoading}
            editUser={editUser}
            setEditUser={setEditUser}
            setInfoUser={setInfoUser}
            setDeleteUser={setDeleteUser}
          />
        )}
      </div>
    </>
  );
}
