import React, { useState, useEffect } from "react";
import verificationImg from "../../../../assets/images/verification-img.png";
import {
    Button,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Badge,
    Card
} from "reactstrap";


import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from "@material-ui/icons/Delete";
import PhoneInput from 'react-phone-input-2'

import petition_patch from "../../../petitions/petition_patch";
import toastr from "toastr";

import ModalUtilityDelete from "./ModalUtility/ModalUtilityDelete";
import ButtonHover from "../../../../components/utils/ButtonHover";
export default function ModalUtility({
    item,
    reloadData,
}) {
    toastr.options = {
        showDuration: 300,
    };
    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const [form, setForm] = useState({
        name: "",
        numbers: [],
        number: "",
        labelNumber: "",
        emails: [],
        email: "",
        labelEmail: "",
        notes: "",
    });

    const [addNumber, setAddNumber] = useState(false)
    const [addEmail, setAddEmail] = useState(false)

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [modalDelete, setModalDelete] = useState(false);


    useEffect(() => {
        if (item) {
            setAddNumber(false)
            setAddEmail(false)
            setForm({
                ...form,
                name: item.name,
                numbers: item.numbers,
                emails: item.emails,
                notes: item.notes,
            });
        }
    }, [item]);

    const onChangeForm = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmitForm = async (e) => {
        setLoading(true);
        setError(false);
        await petition_patch("updateContactList", {
            id: id,
            data: form,
            contact_id: item.pk,
        })
            .then(({ data: result }) => {

                setLoading(false);
                reloadData()
                toastr.success("Action completed", "Success");
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setError(true);
                toastr.error("Action failed", "Error");
            });
    };


    const handleAddNumber = () => {
        if (form.number.trim() === "") return toastr.error("Action failed", "Complete Number Field");
        if (form.labelNumber.trim() === "") return toastr.error("Action failed", "Complete Label Number Field");

        let findMobileType = form.numbers.find(element => element.label === "Mobile")
        if (findMobileType && form.labelNumber === "Mobile") return toastr.error("Action failed", "Only one number of type Mobile");

        let findNumber = form.numbers.find(element => element.number === `+${form.number}`)
        if (!findNumber) {
            setForm({ ...form, numbers: [...form.numbers, { number: `+${form.number}`, label: form.labelNumber }], number: "", labelNumber: "" })
        }
        else {
            setForm({ ...form, number: "", labelNumber: "" })
        }
        setAddNumber(false)
    }


    const handleAddEmail = () => {
        if (form.email.trim() === "") return toastr.error("Action failed", "Complete Number Field");
        if (form.labelEmail.trim() === "") return toastr.error("Action failed", "Complete Label Number Field");

        let findEmail = form.emails.find(element => element.email === form.email)
        if (!findEmail) setForm({ ...form, emails: [...form.emails, { email: form.email, label: form.labelEmail }], email: "", labelEmail: "" })
        else setForm({ ...form, email: "", labelEmail: "" })

        setAddEmail(false)
    }



    const handleDeleteNumber = (number) => {

        let numbers = form.numbers.filter(element => element.number !== number)

        setForm({ ...form, numbers })

    }

    const handleDeleteEmail = (email) => {

        let emails = form.emails.filter(element => element.email !== email)

        setForm({ ...form, emails })

    }

    return (
        <Card body>

            <ModalUtilityDelete
                setModal={setModalDelete}
                modal={modalDelete}

                reloadData={reloadData}
            />



            <div className="d-flex justify-content-between">

                <h3>Details</h3>
                <ButtonHover className="m-0" onClick={() => { setModalDelete(item.pk) }}><DeleteIcon /></ButtonHover>

            </div>

            <Row>
                <Col
                    sm="12"
                    className="mb-sm-2 mb-lg-0"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <label
                        htmlFor="name"
                        style={{ paddingBottom: "0.5rem", margin: "0" }}
                    >
                        Name
                    </label>
                </Col>
                <Col sm="12">
                    <input
                        onChange={onChangeForm}
                        value={form.name}
                        name="name"
                        id="name"
                        type="text"
                        className="form-control"
                    />

                </Col>
            </Row>
            <br />


            <Row>
                <Col
                    sm="12"
                    className="mb-sm-2 mb-lg-0"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <label
                        htmlFor="external_number"
                        style={{ paddingBottom: "0.5rem", margin: "0" }}
                    >
                        Numbers
                    </label>
                </Col>
                <Col
                    sm="12"
                    className="mb-sm-2 mb-lg-0"

                >
                    {form.numbers && form.numbers.map(element =>
                        <div className="d-flex justify-content-between">
                            <p>{element.label} - {element.number}</p>
                            <div style={{ cursor: "pointer" }} onClick={() => { handleDeleteNumber(element.number) }}>

                                <HighlightOffIcon />
                            </div>
                        </div>
                    )}
                    {addNumber &&
                        <div>
                            <div className="d-flex">
                                <PhoneInput
                                    country={'us'}
                                    value={form.number}
                                    onChange={(e) => { setForm({ ...form, number: e }) }}
                                />
                                <select onChange={onChangeForm}
                                    value={form.labelNumber}
                                    name="labelNumber"
                                    id="labelNumber"
                                    className="form-control ml-2">

                                    <option value="">Select One</option>
                                    <option value="Home">Home</option>
                                    <option value="Mobile">Mobile</option>
                                    <option value="Work">Work</option>
                                    <option value="Others">Others</option>
                                </select>

                            </div>


                            <Button className="mt-2" color="primary" onClick={() => { handleAddNumber() }}   >
                                Save
                            </Button>
                        </div>

                    }

                    <p style={{ paddingTop: "10px", cursor: "pointer", color: "#556ee6" }} onClick={() => { setAddNumber(true) }}> + Add Number </p>
                </Col>
            </Row>

            <br />
            <Row>
                <Col
                    sm="12"
                    className="mb-sm-2 mb-lg-0"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <label
                        htmlFor="external_number"
                        style={{ paddingBottom: "0.5rem", margin: "0" }}
                    >
                        Emails
                    </label>
                </Col>
                <Col
                    sm="12"
                    className="mb-sm-2 mb-lg-0"

                >
                    {form.emails && form.emails.map(element =>
                        <div className="d-flex justify-content-between">
                            <p><p>{element.label} - {element.email}</p></p>
                            <div style={{ cursor: "pointer" }} onClick={() => { handleDeleteEmail(element.email) }}>

                                <HighlightOffIcon />
                            </div>
                        </div>
                    )}
                    {addEmail &&
                        <div >
                            <div className="d-flex">
                                <input
                                    onChange={onChangeForm}
                                    value={form.email}
                                    name="email"
                                    id="email"
                                    type="text"
                                    className="form-control"
                                />

                                <select onChange={onChangeForm}
                                    value={form.labelEmail}
                                    name="labelEmail"
                                    id="labelEmail"
                                    className="form-control ml-2">

                                    <option value="">Select One</option>
                                    <option value="Particular">Particular</option>
                                    <option value="Work">Work</option>
                                    <option value="Others">Others</option>
                                </select>


                            </div>

                            <Button className="mt-2" color="primary" onClick={() => { handleAddEmail() }}   >
                                Save
                            </Button>
                        </div>

                    }

                    <p style={{ paddingTop: "10px", cursor: "pointer", color: "#556ee6" }} onClick={() => { setAddEmail(true) }}> + Add Email </p>
                </Col>
            </Row>

            <br />

            <Row>
                <Col
                    sm="12"
                    className="mb-sm-2 mb-lg-0"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <label
                        htmlFor="notes"
                        style={{ paddingBottom: "0.5rem", margin: "0" }}
                    >
                        Notes:
                    </label>
                </Col>
                <Col sm="12">
                    <textarea
                        onChange={onChangeForm}
                        value={form.notes}
                        name="notes"
                        id="notes"
                        type="text"
                        className="form-control"
                    />
                </Col>
            </Row>
            <div className="text-right pt-3">

                <Button
                    disabled={loading}
                    color="primary"
                    onClick={onSubmitForm}
                >
                    {loading && (
                        <Spinner
                            style={{ marginRight: "0.5em" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                    Update
                </Button>
            </div>
        </Card>

    );
}
