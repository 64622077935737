import React, { useEffect, useState } from "react";
import {
    Container,
    Table,
    CustomInput,
    Button,
    Row,
    Col,
    Card,
    Spinner,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import petition_get from "../../../../petitions/petition_get";
import petition_delete from "../../../../petitions/petition_delete";
import petition_patch from "../../../../petitions/petition_patch";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";

import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import AddIcon from "@material-ui/icons/Add";
import styled from "styled-components";
import ButtonHover from "../../../../../components/utils/ButtonHover";
import EditIcon from "@material-ui/icons/Edit";

import ModalUtilityPortRequests from "../ModalUtility/ModalUtilityPortRequests"

import { v4 as uuidv4 } from 'uuid';
import toastr from "toastr";
const ContainerPagination = styled.div`
  padding: 20px;
  margin: 0;
  .btn-page {
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #a6b0cf;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    margin: 0 5px;
    svg {
      color: #a6b0cf;
    }
    &:focus {
      outline: none;
    }
  }
  .active {
    background: #536be1;
  }
`;

const Pages = ({ keyNumbers, setPagination, pagination, cantPagination }) => {
    let page = [];

    for (let i = 0; i < Math.trunc(keyNumbers); i++) {
        page.push(i);
    }

    const onClickPage = (index) => {
        index = (index + 1) * cantPagination;
        setPagination(index);
    };

    return (
        <>
            {page.map((pag, index) => {
                if (
                    (index + 1 >= pagination / cantPagination - 2 &&
                        index + 1 <= pagination / cantPagination) ||
                    (index + 1 <= pagination / cantPagination + 2 &&
                        index + 1 >= pagination / cantPagination)
                ) {
                    return (
                        <button
                            className={`btn-page ${pagination / cantPagination === index + 1 && "active"
                                } `}
                            onClick={() => {
                                onClickPage(index);
                            }}
                            key={index}
                        >
                            {" "}
                            {index + 1}{" "}
                        </button>
                    );
                }
            })}
        </>
    );
};

export default function TabAllNumbers({ setUpdate }) {
    toastr.options = {
        showDuration: 300,
    };

    const history = useHistory();
    const params = new URL(document.location).searchParams;
    const id = params.get("id");

    const cantPagination = 10;
    const [numbers, setNumbers] = useState([]);
    const [keyNumbers, setKeyNumbers] = useState(null);
    const [AllData, setAllData] = useState(null);
    const [pagination, setPagination] = useState(cantPagination);
    const [reloadDataEdit, setReloadDataEdit] = useState(true);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingTwo, setLoadingTwo] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingDisconnect, setLoadingDisconnect] = useState(false);
    const [modal, setModal] = useState(false);

    const [elementNumber, setElementNumber] = useState(null);
    const [allUsers, setAllUsers] = useState(null);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectCheckbox, setSelectCheckbox] = useState([]);
    const [inputSearch, setInputSearch] = useState("");
    const [allColflows, setAllColflows] = useState(null);
    const [defaultNumber, setDefaultNumber] = useState({});
    const [viewCallSettingsUsersAll, setViewCallSettingsUsersAll] = useState(null);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    useEffect(() => {
        if (reloadDataEdit) {
            setLoadingTwo(false);
            setLoadingData(false);
            setReloadDataEdit(false);
            setAllColflows(null);
            setViewCallSettingsUsersAll(null);

            const petition = async () => {

                let saveUsers, saveCallflows = null;

                petition_get("accountDetails", { id: id })
                    .then(({ data: result }) => setDefaultNumber(result.result.default_outbound_callerid_number ? { name: result.result.default_outbound_callerid_number, value: result.result.default_outbound_callerid_number } : ""))
                    .catch((error) => console.log(error));

                await petition_get("itpvoiceUsers", { id: id })
                    .then(({ data: result }) => {
                        setAllUsers(result.result);
                        saveUsers = result.result
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                await petition_get("allCallflows", { id: id })
                    .then(({ data: result }) => {
                        saveCallflows = result.result
                        setAllColflows(result.result);
                    })
                    .catch((error) => {
                        console.log(error);
                    });


                await petition_get("portRequests", { id: id })
                    .then(({ data: result }) => {

                        console.log("testing", result.result)

                        setLoadingData(true);
                        setLoadingTwo(true)
                        setPagination(10);
                        setNumbers(result.result)
                    })
                    .catch((error) => console.log(error))

            }

            petition()


            petition_get("viewCallSettingsUsersAll", { id: id })
                .then(({ data: result }) => {
                    setViewCallSettingsUsersAll(result.result);
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }, [reloadDataEdit]);





    const onClickNext = () => {
        if (pagination < keyNumbers.length) {
            setPagination(pagination + cantPagination);
        }
    };

    const onClickBack = () => {
        if (pagination > cantPagination) {
            setPagination(pagination - cantPagination);
        }
    };



    return (
        <>
            <ModalUtilityPortRequests setReloadDataEdit={setReloadDataEdit} modal={modal} setModal={setModal} />
            <div >
                {!loadingTwo ? (
                    <div style={{ textAlign: "center" }}>
                        <Spinner
                            animation="grow"
                            style={{
                                margin: "10vh auto",
                                height: "10vh",
                                width: "10vh",
                            }}
                        />
                    </div>
                ) : (
                    <Card body>
                        {numbers.length !== 0 || inputSearch.trim() !== "" ? (
                            <>
                                <Row>
                                    <Col style={{ positio: "relative" }} className="col-12   col-md-5">
                                        <input
                                            id="search"
                                            type="text"
                                            className="form-control"
                                            style={{ width: "100%" }}
                                            placeholder={"Search Numbers"}
                                            onChange={(e) => {
                                                setInputSearch(e.target.value);
                                            }}
                                        />
                                        <label

                                            className="icon-search"
                                            htmlFor="search"
                                        >

                                            <SearchIcon />
                                        </label>
                                    </Col>
                                </Row>

                                <Table hover responsive className=" table mb-0 mt-4">
                                    <thead className="thead-light">
                                        <tr style={{ backgroun: "#2a3042" }}>
                                            <th>Number</th>
                                            <th>Status</th>
                                            <th>Porting Status</th>
                                            <th>FOC Date</th>
                                            <th style={{ textAlign: "center" }}>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {numbers
                                            ? numbers.map((number, index) => {
                                                if (index < pagination && index >= pagination - cantPagination) {
                                                    return (
                                                        <tr key={index}>
                                                            <th> {number.metadata.did} </th>
                                                            <th> {number.provision_status}</th>
                                                            <th> {number.provision_result}</th>
                                                            <th> {number.porting_actual_foc_date}</th>
                                                            <th style={{ display: "flex", justifyContent: "center", }}>
                                                                <Button color="primary" onClick={() => { setModal(number) }}  >
                                                                    Update
                                                                </Button>
                                                            </th>
                                                        </tr>
                                                    );
                                                }
                                            })
                                            : null}
                                    </tbody>
                                </Table>{" "}
                            </>
                        ) : (
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "2rem",
                                    fontWeight: "200",
                                    margin: "0",
                                }}
                            >
                                {" "}
                                You currently do not have any phone numbers
                            </p>
                        )}

                        <ContainerPagination>
                            {numbers && numbers.length > cantPagination && (
                                <div className="col-12">
                                    <button className="btn-page" onClick={onClickBack}>
                                        {" "}
                                        <ArrowBackIosIcon />{" "}
                                    </button>
                                    <Pages
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        cantPagination={cantPagination}
                                        keyNumbers={
                                            (numbers.length / cantPagination) % 1 === 0
                                                ? numbers.length / cantPagination
                                                : numbers.length / cantPagination + 1
                                        }
                                    />
                                    <button className="btn-page" onClick={onClickNext}>
                                        {" "}
                                        <ArrowForwardIosIcon />
                                    </button>
                                </div>
                            )}
                        </ContainerPagination>
                    </Card>
                )}

            </div>
        </>
    );
}
