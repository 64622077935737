import React, {useState, useEffect} from "react";
import CustomForm from "../../../../../../components/utils/CustomForm";
import MenuIcon from "@material-ui/icons/Menu";
import TimeConditionalIcon from "@material-ui/icons/AvTimer";
import DisplaySettingsIcon from "@material-ui/icons/Group";
import VoicemailIcon from "@material-ui/icons/Voicemail";
import PersonIcon from "@material-ui/icons/Person";
import CallIcon from "@material-ui/icons/Call";
import RouteIcon from "@material-ui/icons/Map";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
export default function Queue({activeSidebar, setActiveSidebar}) {
  const [customForm, setCustomForm] = useState(null);

  const formConfig = {
    defaultValues: {
      queueId: "",
    },
  };

  useEffect(() => {
    setCustomForm([
      {
        type: "button",
        label: "External Transfer",
        name: "externaltransfer",
        icon: <CallIcon />,
      },
      {
        type: "button",
        label: "Menu",
        name: "menu",
        icon: <MenuIcon />,
      },
      {
        type: "button",
        label: "Users",
        name: "user",
        icon: <PersonIcon />,
      },
      {
        type: "button",
        label: "Voicemails",
        name: "voicemail",
        icon: <VoicemailIcon />,
      },
      {
        type: "button",
        label: "Play",
        name: "play",
        icon: <PlayArrowIcon />,
      },

      {
        type: "button",
        label: "Time Conditions",
        name: "timecondition",
        icon: <TimeConditionalIcon />,
      },
      {
        type: "button",
        label: "Queues",
        name: "queue",
        icon: <DisplaySettingsIcon />,
      },
      {
        type: "button",
        label: "Directory",
        name: "directory",
        icon: <RouteIcon />,
      },
    ]);
  }, []);

  return (
    <div>
      <CustomForm
        customClick={(e) => {
          setActiveSidebar({
            text: {module: e, node: activeSidebar.text.node},
          });
        }}
        customForm={customForm}
        formConfig={formConfig}
      />
    </div>
  );
}
