import React, {useState, useEffect} from "react";
import CustomForm from "../../../../../../components/utils/CustomForm";
import petition_get from "../../../../../petitions/petition_get";
export default function Voicemail({
  handleDeleteModule,
  handleSubmitModule,
  defaultOption,
}) {
  const [customForm, setCustomForm] = useState(null);

  const {data} = defaultOption || {data: {}};

  const formConfig = {
    defaultValues: {
      flow: data?.flow || "callflow",
      userId: data?.idUser || "",
      timeout: data?.timeout || "20",
      can_call_self: data?.can_call_self || false,
    },
  };

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  useEffect(() => {
    petition_get("itpvoiceUsers", {id})
      .then(({data: result}) => {
        setCustomForm([
          {
            type: "select",
            options: result.result.map((user) => ({
              value: user.pk,
              label: user.email,
            })),
            label: "User",
            name: "userId",
          },
          {
            type: "select",
            options: [
              {label: "Go to User's Voicemail", value: "callflow"},
              {label: "Custom option", value: "custom"},
            ],
            label: "If the number is not available",
            name: "flow",
          },
          {
            type: "number",
            label: "Ring time",
            name: "timeout",
            visible: {key: "flow", value: "custom"},
          },
          {
            type: "check",
            label: "Allow call themselves",
            name: "can_call_self",
            visible: {key: "flow", value: "custom"},
          },
        ]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <CustomForm
        activeDelete={!data.new ? handleDeleteModule : null}
        onSubmit={handleSubmitModule}
        customForm={customForm}
        formConfig={formConfig}
      />
    </div>
  );
}
