import {
  DID_PRICE,
  DID_PRICE_LEGACY,
  WEB_PHONE_OPTION,
  APP_PK,
  NUMBER_CONNECT
} from "./actionTypes";

export const didPrice = (payload) => ({
  type: DID_PRICE,
  payload: payload,
});
export const didPriceLegacy = (payload) => ({
  type: DID_PRICE_LEGACY,
  payload: payload,
});
export const webPhoneChangeOption = (payload) => ({
  type: WEB_PHONE_OPTION,
  payload: payload,
});
export const appPK = (payload) => ({
  type: APP_PK,
  payload: payload,
});
export const numberConnect = (payload) => ({
  type: NUMBER_CONNECT,
  payload: payload,
});
