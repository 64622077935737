import styled, {keyframes} from "styled-components";
import image from "../../../../assets/images/telefon2.png";

const phone = keyframes`

    0% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    10% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    20% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    30% {
        transform: rotate(-25deg) scale(1) skew(1deg)
    }
    40% {
        transform: rotate(25deg) scale(1) skew(1deg)
    }
    50% {
        transform: rotate(0) scale(1) skew(1deg)
    }
    100% {
        transform: rotate(0) scale(1) skew(1deg)
    }


`;

const ContainerPhoneStyle = styled.div`
  .phone {
    &-container {
      height: 500px;

      box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.7);
      width: 250px;
      border-radius: 1.5rem;
      overflow: hidden;
      margin: 0;
      margin-right: 3rem;
      position: fixed;
      top: 90px;
      right: -30px;
      z-index: 100;
      transition: 1s all;
    }
    &-close {
      right: -300px;
      transition: 1s all;
    }
    &-call_info {
      text-align: center;
      padding: 0.5rem;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      background: #2d2d2d;
      height: 15%;
      h3 {
        color: white;
      }
      &-search {
        position: relative;
        width: 80%;
        margin: 0 auto;
        input {
          padding: 0;
          height: 25px;
          padding-left: 30px;
        }
      }
    }

    &-call_keyboard {
      background: #2d2d2d;
      height: 85%;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      .absolute-icon {
        position: absolute;
        right: 20px;
      }
      .calling-numbers {
        p {
          font-size: 14px !important;
          width: 100%;
          display: flex;
          margin: 0px;
          justify-content: space-between;
          cursor: pointer;
        }

        .callHold p {
          color: #828282;
        }
        .callUnHold p {
          color: #828282;
        }
        width: 100%;
      }
      .calling {
        padding: 1rem 0;
        flex-direction: column;
      }
      .calling-time {
        padding: 0.5rem 0;
        font-size: 1rem;
        color: #828282;
      }
      &-number {
        text-align: center;
        padding: 1rem 1.5rem;
        padding-bottom: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 60px;
        height: 10%;

        p {
          font-size: 1.5rem;
          color: white;
          margin: 0;
          line-height: 25px;
        }
        svg {
          color: #f1f1f1;
          cursor: pointer;
          &:active {
            transform: scale(0.85);
          }
        }
      }
      &-footer {
        width: 45px;
        background: #34c38f;
        cursor: pointer;
        text-align: center;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        .number {
          color: white;
        }
      }
      .close {
        background: #ce1818;
        opacity: 1;
      }
      &-keys {
        padding: 0 2rem;
        justify-content: center;
        text-align: center;
        position: relative;
        height: 70%;
        .absolute-icon-back {
          position: absolute;
          right: 20px;
          bottom: 0px;
          color: rgba(0, 0, 0, 0.8);
        }
        .call {
          .number {
            background: #34c38f;
            color: white;
          }
        }
        .close {
          opacity: 0.9;
          background: red;
          .number {
            background: red;
            color: white;
          }
          .numberText {
            font-size: 15px;
            color: white;
          }
        }
        div,
        button {
          display: flex;
          flex-direction: column;
          &:active {
            transform: scale(0.85);
          }
          span {
            color: #828282;
          }
          .number {
            margin: 0.6rem 0.4rem;
            margin-bottom: 0rem;
            width: 100%;
            height: 40px;
            width: 40px;
            font-size: 1.5rem;
            color: white;
            cursor: pointer;
          }
          .number-text {
            font-size: 10px;
            margin-bottom: 0.4rem;
            cursor: pointer;
            color: #828282;
          }
          .contact {
            background-color: #1b7ced;
            color: white;
          }
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &-call_controller {
      background: #2d2d2d;
      height: 15%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      .buttonClose {
        background: #ce1818;
        color: white;
        text-align: center;
        border-radius: 15px;
        cursor: pointer;
      }
      .buttonCall {
        color: white;
        text-align: center;
        background: #34c38f;
        border-radius: 15px;
        cursor: pointer;
      }
    }
    &-call_controller-option {
      cursor: pointer;
      text-align: center;
      color: #f1f1f1;
      p {
        font-size: 10px;
      }
    }
    &-call_controller-active {
      color: #1b7ced;
    }
    &-open_call,
    &-close_call {
      background: none;
      width: 80%;

      margin: 0 auto;
      border-radius: 0.5rem;
      height: 30px;
      color: white;
      border: none;
      box-shadow: inset 0px 0px 5px rgba(255, 255, 255, 0.3);
      &:active {
        transform: scale(0.95);
      }
    }
    &-open_call {
      background: #34c38f;
    }
    &-close_call {
      background: #f46a6a;
    }

    &-contact {
      .container-contact {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      padding: 0.5rem 0.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      cursor: pointer;
      p {
        font-size: 1rem;
        color: white;
        margin: 0;
        font-weight: 600;
      }
      span {
        color: white;
      }
    }
  }

  .phone-call_info {
    text-align: left;
    padding: 0.5rem 1rem;
    position: relative;
    p {
      margin: 0;
    }
    .title {
      font-size: 15px;
      color: white;
    }
    .subtitle {
      font-size: 12px;
    }
    .phone {
      animation: ${phone} 1s infinite ease-in-out;
    }
    .alerts {
      position: absolute;
      align-items: center;
      bottom: 5px;
      right: 5px;
    }
  }
`;

export default ContainerPhoneStyle;
