import {set, get, unset} from "lodash";
import petition_get from "../../../../../petitions/petition_get";

import {v4 as uuidv4} from "uuid";
export const addModule = async (json, data, moduleConfig) => {
  const {module, node, ports} = moduleConfig.text;

  let parseJson = JSON.parse(json);

  let getData = await options[module](data);

  let moduleJson = {
    module,
    children: {},
    route: node
      ? node.route === ""
        ? `${node.route}flow`
        : `${node.route}.children.${
            node.currentModule === "menu" ||
            node.currentModule === "timecondition"
              ? data.extraData.timegroup || data.extraData.menu
              : "_"
          }`
      : moduleConfig?.ports[0]?.route || moduleConfig?.text?.route,
    ...getData,
  };

  let dataFind = get(parseJson, moduleJson.route);

  if (dataFind) moduleJson.children = dataFind.children || {};
  else moduleJson.children = {};

  set(parseJson, moduleJson.route, moduleJson);

  return JSON.stringify(parseJson);
};

const getExternalTransferSchema = ({
  number,
  timeout,
  require_keypress,
  keep_caller_id,
}) => {
  return {
    to_number: number,
    dial_timeout: timeout,
    data: {
      id: number,
      dial_timeout: timeout,
      destination: number,
      confirm_call: require_keypress,
      keep_original_callerid: keep_caller_id,
    },
  };
};
const getUser = async ({flow, userId, timeout, can_call_self}) => {
  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  let callflowId;

  await petition_get("allCallflows", {id})
    .then(({data: result}) => {
      callflowId = result.result.find(
        (element) => element.owner_id === userId.toString()
      );
    })
    .catch((error) => console.log(error));

  return {
    module: flow === "callflow" ? "callflow" : "user",
    data: {
      id: flow === "callflow" ? callflowId.pk : userId,
      idUser: userId,
      idCallflow: callflowId.pk,
      can_call_self,
      flow,
      timeout,
    },
  };
};
const getVoicemail = ({voicemailId}) => {
  return {
    data: {
      id: voicemailId,
      timeout: "20",
    },
  };
};
const getPlay = ({mediaId}) => {
  return {
    data: {
      id: mediaId,
    },
  };
};
const getQueue = ({queueId}) => {
  return {
    data: {
      id: queueId,
    },
  };
};
const getDirectory = ({}) => {
  return {
    data: {},
  };
};
const getTimecondition = ({timezone}) => {
  return {
    data: {
      /*  rules: [], */
      id: timezone === "_" ? "" : timezone,
      timezone: timezone === "_" ? "" : timezone,
    },
  };
};
const getMenu = ({
  mediaId,
  retries,
  timeout_retries,
  max_digits,
  timeout,
  direct_dialing,
}) => {
  return {
    data: {
      id: mediaId,
      media_id: mediaId,
      retries: retries,
      max_digits: max_digits,
      timeout_retries: timeout_retries,
      timeout: parseInt(timeout || "8000"),
      direct_dialing: direct_dialing,
    },
  };
};

const options = {
  externaltransfer: getExternalTransferSchema,
  callflow: getUser,
  user: getUser,
  voicemail: getVoicemail,
  play: getPlay,
  directory: getDirectory,
  queue: getQueue,
  menu: getMenu,
  timecondition: getTimecondition,
};

export const deleteModule = (json, moduleConfig) => {
  let parseJson = JSON.parse(json);

  unset(parseJson, moduleConfig.text.route);

  return JSON.stringify(parseJson);
};
