import React, {useState, useEffect} from "react";
import CustomForm from "../../../../../../components/utils/CustomForm";
import petition_get from "../../../../../petitions/petition_get";
export default function Voicemail({
  handleDeleteModule,
  handleSubmitModule,
  defaultOption,
}) {
  const [customForm, setCustomForm] = useState(null);

  const {data} = defaultOption || {data: {}};

  console.log("testing", data);

  const formConfig = {
    defaultValues: {
      voicemailId: data.id || "",
    },
  };

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  useEffect(() => {
    petition_get("voicemail-v2", {id})
      .then(({data: result}) => {
        setCustomForm([
          {
            type: "select",
            options: result.result.map((voicemail) => ({
              value: voicemail.pk,
              label: voicemail.email,
            })),
            label: "User Voicemail",
            name: "voicemailId",
          },
        ]);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <CustomForm
        activeDelete={!data.new ? handleDeleteModule : null}
        onSubmit={handleSubmitModule}
        customForm={customForm}
        formConfig={formConfig}
      />
    </div>
  );
}
