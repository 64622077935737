import React, { useState, useEffect, useRef } from "react";
import verificationImg from "../../../../../assets/images/verification-img.png";
import {
    Button,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Badge,
} from "reactstrap";

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import PhoneInput from 'react-phone-input-2'

import { csv } from 'd3-request'

import csvToJson from "convert-csv-to-json"

import CustomTable from "../../../../../components/utils/CustomTable/CustomTable";

import petition_post from "../../../../petitions/petition_post";
import petition_get from "../../../../petitions/petition_get";
import toastr from "toastr";
export default function ModalUtility({
    modal,
    setModal,
    reloadData
}) {
    toastr.options = {
        showDuration: 300,
    };


    const params = new URL(document.location).searchParams;
    const id = params.get("id");
    const inputRef = useRef();
    const [reload, setReload] = useState(1);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null)
    const [dataUsers, setDataUsers] = useState(null)

    const [step, setStep] = useState(1)




    const onHandleClick = (data, index, value) => {

        let newData = { ...data }

        let findField = newData.content.find(element => element.selected === value)

        if (findField) return toastr.error("This field has already been chosen", "Error")

        newData.content = newData.content.map(element => element.row === index ? { ...element, selected: value } : element)

        setData(newData)



    }

    const onSubmitContacts = async () => {


        if (inputRef.current?.files.length > 0) {

            let pathCsv = URL.createObjectURL(inputRef.current.files[0]);

            csv(pathCsv, function (err, dataJson) {

                let tempJson = []

                if (dataJson.length > 0) {

                    console.log("testing", dataJson)

                    let tempKeys = Object.keys(dataJson[0])
                    tempKeys.map(element => tempJson.push({ row: element, selected: "" }))

                }
                const newData = {
                    title: [
                        { title: "Row ", key: "row" },
                        {
                            title: "Select Option", key: "selected", type: "dropdown",
                            options: [
                                { value: "name", label: "Name" },
                                { value: "number", label: "Number" },
                                { value: "email", label: "Email" },
                                { value: "notes", label: "Notes" },
                                { value: "number_label", label: "Number Label" },
                                { value: "email_label", label: "Email Label" },
                            ],
                            onHandleClick
                        }],
                    content: tempJson,
                }
                setData(newData)
                setDataUsers(dataJson)
            });

            setStep(2)
        } else { toastr.error("You need File to upload ", "Error") }


    }



    const onSubmitConfig = () => {

        let oldJsonData = data.content

        let newContacts = []

        dataUsers.map(element => {

            let newUser = {}

            oldJsonData.map(elementTwo => { if (elementTwo.selected) newUser[elementTwo.selected] = element[elementTwo.row] })

            if (newUser.number && !newUser.number_label) newUser.number_label = "Mobile"
            if (newUser.email && !newUser.email_label) newUser.email_label = "Default"
            newContacts.push(newUser)


        })
        let options = [
            { value: "name", label: "Name" },
            { value: "number", label: "Number" },
            { value: "email", label: "Email" },
            { value: "notes", label: "Notes" },
            { value: "number_label", label: "Number Label" },
            { value: "email_label", label: "Email Label" },
        ]

        const newData = { title: [], content: newContacts }

        if (newContacts.length > 0) {

            Object.keys(newContacts[0]).map(contact => {
                console.log(contact)
                let findLabel = options.find(option => option.value === contact)
                if (findLabel) newData.title.push({ title: findLabel.label, key: findLabel.value })
            })
        }

        let findNameRequire = oldJsonData.find(elementTwo => elementTwo.selected === "name")
        if (!findNameRequire) return toastr.error(`Option "Name" is Required`, "Error");


        console.log(newData)
        setDataUsers(newData)
        setStep(3)
    }

    const onSubmitData = () => {


        setLoading(true);


        petition_post("importContactCsv", { id, data: { new_contacts: dataUsers.content } })
            .then(({ data: result }) => {
                setModal(false);
                setLoading(false);
                toastr.success("Action completed", "Success");
                reloadData(true)

            })
            .catch((error) => {
                toastr.error("Action Error", "Error");
                console.log(error);
                setLoading(false);
            });

    }

    const onSubmitImportContact = () => {

        petition_get("importContactsGmail", { id })
            .then(({ data: result }) => {
                localStorage.setItem("contactApiId", JSON.stringify({ id }));
                window.location.href = result.result
            })
            .catch((error) => {
                toastr.error("Action Error", "Error");
                setLoading(false);
            });

    }


    return (
        <Modal centered isOpen={modal} className="info">
            <ModalHeader className="bg-soft-primary">

                <Row>
                    <Col xs="7">
                        <div className="text-primary p-3">
                            <h3 className="text-primary"> Import Contacts</h3>
                        </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                        <img src={verificationImg} alt="" className="img-fluid" />
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>

                {step === 1 && <div className="col-12 my-3 mb-5">
                    <h5 className="mb-3 text-center"> Import contacts from CSV files  </h5>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <label
                            style={{
                                color: "white",
                                marginRight: "1rem",
                                marginBottom: "0",
                            }}
                            className="bg-primary btn col-12"
                            htmlFor="input_file"
                        >
                            Browse Files
                        </label>

                        <input
                            onChange={(e) => {
                                setTimeout(() => {
                                    setReload(reload + 1);
                                }, [100]);
                            }}
                            id="input_file"
                            className="d-none"
                            type="file"
                            accept=".csv"
                            ref={inputRef}
                        />

                    </div>
                    <span className="mt-3">
                        {inputRef.current &&
                            inputRef.current.files.length !== 0 &&
                            inputRef.current.files[0].name}
                    </span>

                    <hr />
                    <div className="col-12 mt-5">
                        <h5 className="mb-3 text-center" > Import contacts from your Gmail Account </h5>
                        <button onClick={onSubmitImportContact} className="col-12 bg-danger btn" color="danger" style={{
                            borderRadius: "0.25rem", color: "white",
                            marginRight: "1rem",
                            marginBottom: "0",
                        }}>
                            Import contacts
                        </button>
                    </div>
                </div>}

                {step === 2 &&
                    <div>

                        <h3 className="text-center"> Settings </h3>
                        <p className="text-center"> Associate the fields of the CSV with the required fields </p>

                        <CustomTable bordered={true} size="md" data={data} />

                    </div>
                }

                {step === 3 && <>

                    <h3 className="text-center"> View Contacts </h3>
                    <CustomTable bordered={true} size="md" data={dataUsers} />

                </>}


            </ModalBody>
            <ModalFooter>
                <Button
                    outline
                    color="secondary"
                    onClick={() => { setModal(false); }}
                >
                    Cancel
                </Button>
                <Button
                    disabled={loading}
                    color="primary"
                    onClick={() => { if (step === 1) onSubmitContacts(); if (step === 2) onSubmitConfig(); if (step === 3) onSubmitData() }}
                >
                    {loading && (
                        <Spinner
                            style={{ marginRight: "0.5em" }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                    Next
                </Button>
            </ModalFooter>
        </Modal>
    );
}
