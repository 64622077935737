import React, { useState, useEffect } from 'react'
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";

import classnames from "classnames";
export default function NavTabCustom({ options, update }) {

    useEffect(() => {
        if (update) setCustomActiveTab(update)
    }, [update])

    const [customActiveTab, setCustomActiveTab] = useState("1");

    const toggleCustom = (tab) => { if (customActiveTab !== tab) setCustomActiveTab(tab) }

    return (
        <>
            <Nav tabs className="nav-tabs-custom  flex-column flex-md-row  text-center  "  >
                {options.map((element, index) =>
                    <NavItem key={index} className="pt-3 pt-md-0">
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({ active: customActiveTab === (index + 1).toString() })}
                            onClick={() => { toggleCustom((index + 1).toString()); }}
                        >
                            <span>{element.title}</span>
                        </NavLink>
                    </NavItem>
                )}
            </Nav>
            <TabContent activeTab={customActiveTab}>
                {options.map((element, index) =>
                    <TabPane key={index} tabId={(index + 1).toString()} className="p-3">
                        {parseInt(customActiveTab) === (index + 1) && (<> {element.component} </>)}
                    </TabPane>
                )}


            </TabContent>

        </>

    )
}
