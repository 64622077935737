import React, {useState} from "react";
import Menu from "./GraphModules/Menu";
import ExternalNumber from "./GraphModules/ExternalNumber";
import Voicemail from "./GraphModules/Voicemail";
import PlayMedia from "./GraphModules/PlayMedia";
import CallflowUser from "./GraphModules/CallFlowUser";
import Queues from "./GraphModules/Queues";
import TimeConditions from "./GraphModules/TimeConditions";
import TimeconditionOption from "./GraphModules/TimeconditionOption";
import Directory from "./GraphModules/Directory";
import MenuOption from "./GraphModules/MenuOption";
import AddModule from "./GraphModules/AddModule";
import {get} from "lodash";
import {
  GraphSidebarClose,
  GraphSidebarContainer,
  GraphSidebarTitle,
} from "./styles";
import {addModule, deleteModule} from "./utils/addModule";

export default function GraphSidebar({
  activeSidebar,
  setActiveSidebar,
  json,
  setJson,
}) {
  const [activeModule, setActiveModule] = useState(0);
  const [extraData, setExtraData] = useState({});

  const modules = {
    externaltransfer: {
      title: "External Transfer",
      Component: ExternalNumber,
    },
    menu: {title: "Menu", Component: Menu},
    callflow: {title: "Users", Component: CallflowUser},
    voicemail: {title: "Voicemail", Component: Voicemail},
    play: {title: "Play", Component: PlayMedia},
    user: {title: "User", Component: CallflowUser},
    timecondition: {title: "Time Conditions", Component: TimeConditions},
    queue: {title: "Queues", Component: Queues},
    directory: {title: "Directory", Component: Directory},
    createModule: {
      title: "Add option",
      Component: AddModule,
    },
    timeconditionOptions: {
      subMenu: true,
      title: "Time condition option",
      Component: TimeconditionOption,
    },
    menuOptions: {
      subMenu: true,
      title: "Menu option",
      Component: MenuOption,
    },
  };

  if (!activeSidebar) return <> </>;

  const getModuleName = () => {
    if (activeModule > 0) return activeSidebar?.text?.module;

    if (
      activeSidebar.text?.node?.currentModule === "timecondition" ||
      activeSidebar.text?.node?.currentModule === "menu"
    )
      return `${activeSidebar.text?.node?.currentModule}Options`;

    return activeSidebar?.text?.module;
  };

  const {Component, subMenu, title} = modules[getModuleName()];

  const handleSubmitModule = async (data) => {
    let newJson = await addModule(json, {...data, extraData}, activeSidebar);
    setJson(newJson);
    setActiveSidebar(null);
    setActiveModule(false);
  };
  const handleSubmitSubModule = async (data) => {
    setExtraData(data);
    setActiveModule(activeModule + 1);
  };

  const handleDeleteModule = async () => {
    let newJson = await deleteModule(json, activeSidebar);
    setJson(newJson);
    setActiveSidebar(null);
    setActiveModule(false);
  };

  return (
    <GraphSidebarContainer activeSidebar={activeSidebar}>
      <GraphSidebarClose onClick={() => setActiveSidebar(null)}>
        X
      </GraphSidebarClose>

      <GraphSidebarTitle>{title || ""}</GraphSidebarTitle>
      <Component
        defaultOption={
          activeSidebar.ports
            ? get(
                JSON.parse(json),
                activeSidebar?.ports[0]?.route || activeSidebar?.text?.route
              )
            : {data: {new: true}}
        }
        json={json}
        activeSidebar={activeSidebar}
        setActiveSidebar={setActiveSidebar}
        handleSubmitModule={
          subMenu ? handleSubmitSubModule : handleSubmitModule
        }
        handleDeleteModule={handleDeleteModule}
      />
    </GraphSidebarContainer>
  );
}
