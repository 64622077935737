import React, {useState, useEffect} from "react";
import * as Styled from "./styles";
import AddIcon from "@material-ui/icons/Add";

import MenuIcon from "@material-ui/icons/Menu";
import TimeConditionalIcon from "@material-ui/icons/AvTimer";
import DisplaySettingsIcon from "@material-ui/icons/Group";
import VoicemailIcon from "@material-ui/icons/Voicemail";
import PersonIcon from "@material-ui/icons/Person";
import CallIcon from "@material-ui/icons/Call";
import RouteIcon from "@material-ui/icons/Map";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
const ObjectNode = ({
  width,
  height,
  value,
  x,
  y,
  hasChildren,
  positionX,
  setActiveSidebar,
}) => {
  const [moduleType, setModuleType] = useState("");
  const [containerType, setContainerType] = useState("");

  useEffect(() => {
    let saveModule;
    let saveContainer;

    value.map((val, idx) => {
      if (val[0] === "module") saveModule = val[1];
    });
    value.map((val, idx) => {
      if (val[0] === "container") saveContainer = val[1];
    });

    setContainerType(saveContainer);
    setModuleType(saveModule);
  }, []);

  const modules = {
    externaltransfer: {
      type: "button",
      label: "External Transfer",
      name: "externaltransfer",
      icon: <CallIcon style={{fontSize: "40px"}} />,
    },
    menu: {
      type: "button",
      label: "Menu",
      name: "menu",
      icon: <MenuIcon style={{fontSize: "40px"}} />,
    },
    user: {
      type: "button",
      label: "User",
      name: "user",
      icon: <PersonIcon style={{fontSize: "40px"}} />,
    },
    callflow: {
      type: "button",
      label: "Callflow",
      name: "callflow",
      icon: <PersonIcon style={{fontSize: "40px"}} />,
    },
    voicemail: {
      type: "button",
      label: "Voicemail",
      name: "voicemail",
      icon: <VoicemailIcon style={{fontSize: "40px"}} />,
    },
    play: {
      type: "button",
      label: "Play Sound",
      name: "play",
      icon: <PlayArrowIcon style={{fontSize: "40px"}} />,
    },

    timecondition: {
      type: "button",
      label: "Time Conditions",
      name: "timecondition",
      icon: <TimeConditionalIcon style={{fontSize: "40px"}} />,
    },
    queue: {
      type: "button",
      label: "Queue",
      name: "queue",
      icon: <DisplaySettingsIcon style={{fontSize: "40px"}} />,
    },
    directory: {
      type: "button",
      label: "Directory",
      name: "directory",
      icon: <RouteIcon style={{fontSize: "40px"}} />,
    },
  };

  return (
    <Styled.StyledForeignObject width={width} height={height} x={0} y={0}>
      <Styled.StyledTextWrapper>
        <Styled.StyledText width={width} height={height}>
          {moduleType ? (
            <Styled.StyledRow
              custom
              data-key={moduleType}
              data-x={x}
              data-y={y}
              key={moduleType}
              width={width}
            >
              {modules[moduleType]?.icon}{" "}
              <span> {modules[moduleType]?.label} </span>
            </Styled.StyledRow>
          ) : containerType ? (
            <Styled.StyledRow
              custom
              data-key={moduleType}
              data-x={x}
              data-y={y}
              key={moduleType}
              width={width}
            >
              <h5> {value[1][1]}</h5>
              {JSON.parse(value[2][1])?.map((number) => (
                <p>-{number} </p>
              ))}
            </Styled.StyledRow>
          ) : (
            value.map(
              (val, idx) =>
                val[1] && (
                  <Styled.StyledRow
                    data-key={val[1]}
                    data-x={x}
                    data-y={y}
                    key={idx}
                    width={width}
                  >
                    <Styled.StyledKey objectKey>{val[0]}: </Styled.StyledKey>
                    {val[1]}
                  </Styled.StyledRow>
                )
            )
          )}
        </Styled.StyledText>
      </Styled.StyledTextWrapper>
      {!hasChildren && (
        <Styled.StyledButton
          positionX={positionX}
          onClick={() => {
            console.log("active");
          }}
        >
          <AddIcon
            style={{
              width: "100%",
              height: "100%",
              color: "white",
              marginBottom: "12px",
            }}
          />
        </Styled.StyledButton>
      )}
    </Styled.StyledForeignObject>
  );
};

export default ObjectNode;
