import React, {useState, useEffect} from "react";
import CustomForm from "../../../../../../components/utils/CustomForm";
import petition_get from "../../../../../petitions/petition_get";
export default function Menu({
  handleDeleteModule,
  handleSubmitModule,
  defaultOption,
}) {
  const [customForm, setCustomForm] = useState(null);

  const {data} = defaultOption || {data: {}};

  const formConfig = {
    defaultValues: {
      mediaId: data.media_id || "",
      retries: data.retries || "1",
      timeout_retries: data.timeout_retries || "3",
      max_digits: data.max_digits || "4",
      timeout: data.timeout || "8000",
      direct_dialing: data.direct_dialing || false,
    },
  };

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  useEffect(() => {
    petition_get("musicOnHoldMedia", {id})
      .then(({data: result}) =>
        setCustomForm([
          {
            type: "select",
            options: result.result.map((play) => ({
              value: play.pk,
              label: play.name,
            })),
            label: "Select Media",
            name: "mediaId",
          },
          {
            type: "select",
            options: [
              {label: "0", value: "0"},
              {label: "1", value: "1"},
              {label: "2", value: "2"},
              {label: "3", value: "3"},
            ],
            label: "Invalid Attempts",
            name: "retries",
            tooltip:
              "This is the number of attempts a user has when entering an invalid option. Once they have reached the number of attempts, they will be routed to the 'Invalid Destination' if the menu has one set. If the menu does not have one set,the call will be terminated.",
          },
          {
            type: "select",
            options: [
              {label: "0", value: "0"},
              {label: "1", value: "1"},
              {label: "2", value: "2"},
              {label: "3", value: "3"},
            ],
            label: "Timeout retries",
            name: "timeout_retries",
          },
          {
            type: "select",
            options: [
              {label: "3", value: "3"},
              {label: "4", value: "4"},
              {label: "5", value: "5"},
              {label: "6", value: "6"},
              {label: "7", value: "7"},
              {label: "8", value: "8"},
            ],
            label: "Max digits",
            name: "max_digits",
          },
          {
            type: "select",
            options: [
              {label: "2", value: "2000"},
              {label: "2.5", value: "2500"},
              {label: "3", value: "3000"},
              {label: "3.5", value: "3500"},
              {label: "4", value: "4000"},
              {label: "4.5", value: "4500"},
              {label: "5", value: "5000"},
              {label: "5.5", value: "5500"},
              {label: "6", value: "6000"},
              {label: "6.5", value: "6500"},
              {label: "7", value: "7000"},
              {label: "7.5", value: "7500"},
              {label: "8", value: "8000"},
              {label: "8.5", value: "8500"},
              {label: "9", value: "9000"},
              {label: "9.5", value: "9500"},
              {label: "10", value: "10000"},
            ],
            label: "Timeout (seconds)",
            name: "timeout",
            tooltip:
              " Amount of seconds before replaying message or going to' timeout' destination if number of retries has exceeded the maximum amount",
          },
          {
            type: "check",
            label: "Direct Extension Dialing",
            name: "direct_dialing",
            tooltip: "Allows to shorten the delay after a user dials a number",
          },
        ])
      )
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <CustomForm
        activeDelete={!data.new ? handleDeleteModule : null}
        onSubmit={handleSubmitModule}
        customForm={customForm}
        formConfig={formConfig}
      />
    </div>
  );
}
