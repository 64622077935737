import React, {Component} from "react";
import {Switch, BrowserRouter as Router} from "react-router-dom";
import {connect} from "react-redux";
// Import Routes
import {authProtectedRoutes, publicRoutes} from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import {changeAdmin, didPrice, didPriceLegacy} from "./store/actions";
import petition_get from "./pages/petitions/petition_get";
import WebPhone from "../src/pages/ITPVoice/OperatorPanel/WebPhone";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePhone: true,
      webPhoneActive: false,
      services: null,
    };
    this.getLayout = this.getLayout.bind(this);
  }

  /**
   * Returns the layout
   */

  getLayout = () => {
    let layoutCls = VerticalLayout;
    if (
      window.location.pathname === "/ITPVoice" ||
      window.location.pathname === "/Voicemail" ||
      window.location.pathname === "/CallHistory" ||
      window.location.pathname === "/OperatorPanel" ||
      window.location.pathname === "/WebPhone" ||
      window.location.pathname === "/Fax" ||
      window.location.pathname === "/ContactList" ||
      window.location.pathname === "/SettingsAndDevices" ||
      window.location.pathname === "/new-numbers" ||
      window.location.pathname === "/all-numbers" ||
      window.location.pathname === "/transfers-in-progress" ||
      window.location.pathname === "/transfer-your-number" ||
      window.location.pathname === "/ListAllUsers" ||
      window.location.pathname === "/AddUsers" ||
      window.location.pathname === "/DashboardUsers" ||
      window.location.pathname === "/TimeSchedules" ||
      window.location.pathname === "/GeneralSettings" ||
      window.location.pathname === "/Callflows" ||
      window.location.pathname === "/MediaFiles" ||
      window.location.pathname === "/Queues" ||
      window.location.pathname === "/RingGroups" ||
      window.location.pathname === "/Chat" ||
      window.location.pathname === "/EnabledApp" ||
      window.location.pathname === "/Permissions" ||
      window.location.pathname === "/AppDirectory" ||
      window.location.pathname === "/InstalledApps" ||
      window.location.pathname === "/DashboardITPVoiceLegacy" ||
      window.location.pathname === "/CallReports" ||
      window.location.pathname === "/CallLogs" ||
      window.location.pathname === "/AddNumbersPBX" ||
      window.location.pathname === "/ListAllNumbersPBX" ||
      window.location.pathname === "/transfer-your-number-pbx" ||
      window.location.pathname === "/transfers-in-progress-pbx" ||
      window.location.pathname === "/AdminOperatorPanel" ||
      window.location.pathname === "/advancedHelp" ||
      window.location.pathname === "/keys" ||
      window.location.pathname === "/Menus" ||
      window.location.pathname === "/AuthorizeTransfers" ||
      window.location.pathname === "/CallCenterApp" ||
      window.location.pathname === "/SmsCampaign"
    ) {
      const params = new URL(document.location).searchParams;
      const id = params.get("id");

      if (!id || id === "null") {
        return (window.location.href = "/Home");
      }
      layoutCls = VerticalLayout;
      if (!this.state.webPhoneActive) this.setState({webPhoneActive: true});

      if (window.localStorage.getItem("reload") === "false") {
        window.localStorage.setItem("reload", "true");
        window.location.reload(true);
      }
    } else {
      if (this.state.webPhoneActive) this.setState({webPhoneActive: false});

      layoutCls = HorizontalLayout;
      window.localStorage.setItem("reload", "false");
    }
    return layoutCls;
  };

  componentDidMount() {
    const token = JSON.parse(localStorage.getItem("authUser"));

    if (token && token.access_token) {
      const params = new URL(document.location).searchParams;
      const id = params.get("id");

      if (
        window.location.pathname != "/DashboardITPVoiceLegacy" &&
        window.location.pathname != "/AddNumbersPBX" &&
        window.location.pathname != "/ListAllNumbersPBX" &&
        window.location.pathname != "/transfer-your-number-pbx" &&
        window.location.pathname != "/transfers-in-progress-pbx" &&
        id != null
      ) {
        petition_get("userDetails", {id})
          .then(({data: result}) =>
            this.props.changeAdmin(
              result.result.priv_level === "admin",
              result.result
            )
          )
          .catch((error) => {
            console.log("error", error);
          });
      }

      petition_get("services")
        .then(({data: result}) => {
          this.setState({services: result.result});
          const priceVoiceDid = result.result.filter(
            (element) => element.item_type === "itp_voice"
          );
          const priceVoiceDidLegacy = result.result.filter(
            (element) => element.item_type === "itp_voice_legacy"
          );
          if (priceVoiceDid.length > 0) {
            this.props.didPrice(priceVoiceDid[0].metadata.price_per_did);
          }
          if (priceVoiceDidLegacy.length > 0) {
            this.props.didPriceLegacy(
              priceVoiceDidLegacy[0].metadata.price_per_did
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (!localStorage.getItem("theme")) {
      this.setState({theme: this.props.layout.topbarTheme});
      document.body.className = "dark";
      localStorage.setItem("theme", this.props.layout.topbarTheme);
    } else {
      const theme = localStorage.getItem("theme");
      if (theme === "dark") {
        document.body.className = "dark";
      } else {
        document.body.className = "";
      }
    }
  }

  render() {
    const Layout = this.getLayout();

    const updateActivePhone = (value) => {
      this.setState({activePhone: value});
    };

    require(`./assets/scss/theme.scss`);
    return (
      <React.Fragment>
        {this.state.webPhoneActive && (
          <WebPhone updateActivePhone={updateActivePhone} />
        )}
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                webPhoneActive={this.state.webPhoneActive}
                activePhone={this.state.activePhone}
                services={this.state.services}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStateToProps, {
  changeAdmin,
  didPrice,
  didPriceLegacy,
})(App);
