import React from "react";
import useWebhooks from "../../../../../../hooks/useWebhooks";
import CustomTable from "../../../../../../components/utils/CustomTable/CustomTable";
export default function Webhooks() {
  const {
    data,
    dataTable,
    getAddWebhooksModal,
    getUpdateWebhooksModal,
    getDeleteWebhooksModal,
  } = useWebhooks();

  return (
    <>
      {getUpdateWebhooksModal()}
      {getAddWebhooksModal()}
      {getDeleteWebhooksModal()}
      <CustomTable data={dataTable} />
    </>
  );
}
