import React, {useState, useEffect} from "react";
import CustomForm from "../../../../../../components/utils/CustomForm";
import petition_get from "../../../../../petitions/petition_get";
import {get} from "lodash";
export default function Queue({
  handleSubmitModule,
  defaultOption,
  json,
  activeSidebar,
}) {
  const [customForm, setCustomForm] = useState(null);

  const {data} = defaultOption;

  const formConfig = {
    defaultValues: {
      timegroup: data.timegroup || "",
    },
  };

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  useEffect(() => {
    let data = get(JSON.parse(json), activeSidebar.text.node.route);

    let currentOptions = Object.keys(data.children);

    petition_get("timeGroups", {id})
      .then(({data: result}) => {
        let tempPetitionTimeRules = [];

        let tempTimeGroups = [];

        result.result.map((element) =>
          tempPetitionTimeRules.push(
            petition_get("timeRules", {id, query: element.pk}).then(
              (result) =>
                result.data.result.length > 0 &&
                tempTimeGroups.push({
                  ...element,
                  id: element.pk.toString(),
                  pk: element.pk.toString(),
                })
            )
          )
        );

        Promise.all(tempPetitionTimeRules)
          .then((result) => {
            let filterOptions = [];

            tempTimeGroups.push({id: "_", name: "All other times"});

            tempTimeGroups.map((option) => {
              let findOption = currentOptions.find(
                (current) => current === option.id
              );
              if (!findOption)
                filterOptions.push({value: option.id, label: option.name});
            });

            setCustomForm([
              {
                type: "select",
                options: filterOptions,
                label: "Time group",
                name: "timegroup",
              },
            ]);
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <CustomForm
        onSubmit={handleSubmitModule}
        customForm={customForm}
        formConfig={formConfig}
      />
    </div>
  );
}
