

const tutorialFax = [

    {
        title: "",
        intro: "Welcome to Fax Tutorial 👋",
    },
    {
        element: ".fax-send",
        intro: "Click Button to Create the fax",
    },
    {
        element: ".fax-select",
        intro: "See all the fax you have received",
    },
    {
        element: ".fax-search",
        intro: "Find one Fax",
    },

    {
        element: ".fax-content",
        intro: "Fax information",
    },
    {
        element: ".fax-number",
        intro: "Fax Number",
    },
    {
        element: ".fax-download",
        intro: "Download Fax",
    },
    {
        title: "Welcome",
        intro: "Finish Tutorial",
    },

]



export default { tutorialFax }