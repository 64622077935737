import axios from "axios";
export default function post_user(key, value) {
    const json = require("../../config.json");
    const urlBase = json.urlBase;
    const token = JSON.parse(localStorage.getItem("authUser"));
    var url;
    var data;
    if (token.access_token) {
        switch (key) {
            case "updateOperatorPanelCards":
                url = urlBase + `/itpvoice/v2/${value.id}/my-extension`;
                data = value.data;
                break;
            case "userDetails":
                url = urlBase + `/itpvoice/v2/${value.id}/my-extension/users`;
                data = value.data;
                break;
            default:
                return "error";
        }
        var config = {
            headers: {
                Authorization: "Bearer " + token.access_token.replace(/['"]+/g, ""),
            },
        };

        return axios.put(url, data, config);
    } else {
        window.location.href = "./profile";
    }
}
