import React, {useState, useEffect} from "react";
import {SidebarItem} from "../../../components/Index";
import {Button} from "reactstrap";
import {get} from "lodash";
import Input from "../../../../utils/Input";
import * as yup from "yup";
import {useFormik} from "formik";
export default function RingGroup({
  setDetails,
  listUsers,
  updateAction,
  state,
  startPoint,
  StartItemStyle,
  timeGroups,
  selectNode,
  dataCallFlow,
}) {
  const [activeItemRingGroup, setActiveItemRingGroup] = useState(false);

  const [defaultValue, setDefaultValue] = useState(true);
  const [timeGroupsValues, setTimeGroupsValues] = useState([]);

  const [activeTimeGroups, setActiveTimeGroups] = useState(false);
  const [selectTimeGroup, setSelectTimeGroup] = useState(null);

  const [activeMenu, setActiveMenu] = useState(false);
  const [menuOptions, setMenuOptions] = useState(false);
  const [selectMenu, setSelectMenu] = useState(null);

  const onChangeAutocompleteMenu = (e) => {
    setSelectMenu(e.target.value);
  };

  const onChangeAutocompleteTimeGroup = (e) => {
    setSelectTimeGroup(e.target.value);
  };

  useEffect(() => {
    if (selectNode && selectNode.type.split("_")[0] === "Time") {
      setDefaultValue(true);

      let values;
      if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
      else {
        values = get(dataCallFlow.flow, selectNode.childrenUrl).children;
      }
      const timeGroupsKeys = Object.keys(values);
      const newTimeGroups = [];
      timeGroups.map((element) => {
        const findOne = timeGroupsKeys.find(
          (elementTwo) => elementTwo === element.id
        );
        if (!findOne) {
          newTimeGroups.push(element);
        }
      });

      if (values._) {
        setDefaultValue(false);
        setSelectTimeGroup(newTimeGroups.length > 0 ? newTimeGroups[0].id : "");
      } else {
        setSelectTimeGroup("_");
      }
      setTimeGroupsValues(newTimeGroups);
      setActiveTimeGroups(false);
    } else if (selectNode && selectNode.type.split("_")[0] === "Menu") {
      let MenuOptions = [
        {id: "1"},
        {id: "2"},
        {id: "3"},
        {id: "4"},
        {id: "5"},
        {id: "6"},
        {id: "7"},
        {id: "8"},
        {id: "9"},
        {id: "0"},
        {id: "timeout"},
        {id: "invalid"},
      ];
      let values;
      if (selectNode.childrenUrl === "") values = dataCallFlow.flow.children;
      else values = get(dataCallFlow.flow, selectNode.childrenUrl).children;

      const MenuKeys = Object.keys(values);
      const newMenu = [];
      MenuOptions.map((element) => {
        const findOne = MenuKeys.find(
          (elementTwo) => elementTwo === element.id
        );
        if (!findOne) {
          newMenu.push(element);
        }
      });
      setSelectMenu(newMenu[0].id);
      setMenuOptions(newMenu);
      setActiveMenu(true);
      setActiveTimeGroups(true);
    } else {
      setActiveTimeGroups(true);
    }
  }, []);

  const allUsersForm = [];
  listUsers.map((element) =>
    allUsersForm.push({
      name: `${element.first_name} ${element.last_name}`,
      id: element.id,
    })
  );
  const initialForm = [
    {name: "name", placeholder: "Name", label: "Name", type: "text"},
    {
      name: "strategy",
      placeholder: "Strategy",
      label: "Strategy",
      type: "dropdown",
      dropdownOptions: [
        {label: "Single", value: "single"},
        {label: "Simultaneous", value: "simultaneous"},
      ],
    },
    {
      name: "timeout",
      placeholder: "Number of Seconds To Ring",
      label: "Number Of Second To Ring",
      type: "number",
    },
    {name: "repeats", placeholder: "Repeats", label: "Repeats", type: "number"},
    {
      name: "ignore_forward",
      placeholder: "Ignore Call Forwarding",
      label: "Ignore Call Forwarding",
      type: "dropdown",
      dropdownOptions: [
        {label: "True", value: true},
        {label: "False", value: false},
      ],
    },
    {
      name: "users",
      placeholder: "Users",
      label: "Members",
      type: "check",
      checkOptions: allUsersForm,
    },
  ];
  const formik = useFormik({
    initialValues: {
      name: "",
      strategy: "single",
      users: "",
      timeout: "20",
      repeats: 1,
      ignore_forward: true,
    },
    validationSchema: yup.object({
      name: yup.string().required("Name is Required"),
      strategy: yup.string().required("Strategy is Required"),
      timeout: yup.string().required("Number of Time is Required"),
    }),
    onSubmit: (e) => {
      if (e.users === "" || e.users.length === 0) {
        formik.touched = true;
        return (formik.errors.users = "Users is Required");
      }
      console.log(e);
      setDetails({...e, timeGroups: selectTimeGroup, menuOption: selectMenu});
      setActiveItemRingGroup(e.name);
    },
  });

  return (
    <>
      {activeTimeGroups ? (
        <>
          {activeMenu ? (
            <>
              <select
                onChange={onChangeAutocompleteMenu}
                className="form-control"
                list="datalistOptions"
                id="exampleDataList"
                placeholder="Type to search..."
              >
                {menuOptions.map((element, i) => (
                  <option key={i} value={element.id}>
                    {element.id}
                  </option>
                ))}
              </select>
              {selectMenu && (
                <Button
                  onClick={async () => {
                    setActiveMenu(false);
                  }}
                  className=" mt-3 w-100"
                  color="primary"
                >
                  Create
                </Button>
              )}{" "}
            </>
          ) : (
            <>
              {initialForm.map((element, i) => (
                <Input
                  formik={formik}
                  key={i}
                  {...element}
                  onBlur={formik.handleBlur}
                  value={formik.values[element.name]}
                  onChangeInput={formik.handleChange}
                />
              ))}
              <Button
                onClick={async () => {
                  formik.handleSubmit();
                }}
                className=" mt-3 w-100"
                color="primary"
              >
                Create
              </Button>

              {activeItemRingGroup && (
                <SidebarItem
                  updateAction={updateAction}
                  state={state}
                  label={`Ring Group: ${activeItemRingGroup}`}
                  type={`ring_${activeItemRingGroup}`}
                  ports={startPoint(`ring_${activeItemRingGroup}`)}
                  ItemStyle={StartItemStyle}
                />
              )}
            </>
          )}
        </>
      ) : (
        <>
          <select
            onChange={onChangeAutocompleteTimeGroup}
            className="form-control"
            list="datalistOptions"
            id="exampleDataList"
            placeholder="Type to search..."
          >
            {defaultValue && <option value="_">All Other Times</option>}
            {selectTimeGroup === "" && (
              <option value="">You have no more options </option>
            )}
            {timeGroupsValues.map((element, i) => (
              <option key={i} value={element.id}>
                {element.name}
              </option>
            ))}
          </select>

          {selectTimeGroup && (
            <Button
              disabled={selectTimeGroup === ""}
              onClick={async () => {
                setActiveTimeGroups(true);
              }}
              className=" mt-3 w-100"
              color="primary"
            >
              Create
            </Button>
          )}
        </>
      )}
    </>
  );
}
