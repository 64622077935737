import React, { useState, useEffect } from "react";
import profileImg from "../../../../../assets/images/verification-img.png";
import { Button, Modal, ModalBody, Row, Col, Spinner, ModalHeader } from "reactstrap";

export default function ModalUtility({
    modal,
    setModal,
    downloadCall,
    spinner,
}) {

    const [selectMedia, setSelectMedia] = useState()

    useEffect(() => {
        if (modal) {

            downloadCall(modal.call_recording_filename)
        }

    }, [modal])

    return (
        <Modal centered isOpen={!modal ? false : true} className="info ">
            <ModalHeader className="bg-soft-primary">

                <Row>
                    <Col xs="7">
                        <div className="text-primary p-3">
                            <h3 className="text-primary">Download Recording</h3>
                        </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                </Row>
            </ModalHeader>
            <ModalBody>
                <div
                    style={{
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                    }}
                >
                    {!spinner ? (
                        <div className="text-left w-100">
                            <h3 className="mt-3">Are you sure to download this call</h3>
                            <label className="pt-3" htmlFor="last_name w-100">
                                Media Recording
                            </label>
                            <select className="mb-3" value={selectMedia} onChange={(e) => { setSelectMedia(e.target.value) }} className="form-control" >

                                {modal && modal.map((element, i) => <option key={i} value={element.id}> {element.caller_id_name}{" "}{element.caller_id_number} </option>)}
                            </select>

                        </div>
                    ) : (
                        <Spinner
                            animation="grow"
                            style={{
                                marginTop: "10vh",
                                marginBottom: "10vh",
                                height: "10vh",
                                width: "10vh",
                            }}
                        />
                    )}
                </div>
                {!spinner && (
                    <div style={{ textAlign: "center" }}>
                        <Button
                            style={{ margin: "1rem" }}
                            outline
                            color="secondary"
                            onClick={() => {
                                setModal(false);
                            }}
                        >
                            Cancel
                        </Button>

                        <Button
                            style={{ margin: "1rem" }}
                            color="danger"
                            onClick={() => {
                                downloadCall(selectMedia);
                            }}
                        >
                            Download
                        </Button>

                    </div>
                )}
            </ModalBody>
        </Modal>
    );
}
