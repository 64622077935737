import React, { useState } from "react";
import { Col, Row, Label, Input } from "reactstrap";
import Switch from "react-switch";
export default function BasicSettings({ onChange, form, setForm }) {
  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  return (
    <Row>
      <Col className="d-flex col-12 col-lg-9 pt-4 row">

        <Col
          className="col-12 col-lg-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Label
            htmlFor="name"
            style={{
              cursor: "pointer",

              margin: "0",
            }}
          >
            Queue Name
          </Label>{" "}
        </Col>
        <Col className="col-12 col-lg-5 mt-2 mt-lg-0">
          <Input
            value={form.queue_name}
            onChange={onChange}
            id="name"
            type="text"
            name="queue_name"
          />{" "}
        </Col>
      </Col>
      <Col className="d-flex col-12 col-lg-9 pt-4 row">
        {" "}
        <Col
          className="col-12 col-lg-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Label
            htmlFor="description"
            style={{
              cursor: "pointer",
              margin: "0",
            }}
          >
            Description
          </Label>{" "}
        </Col>
        <Col className="col-12 col-lg-5 mt-2 mt-lg-0">
          <Input
            value={form.queue_description}
            onChange={onChange}
            id="description"
            type="text"
            name="queue_description"
          />
        </Col>
      </Col>

      {/* <Col className="d-flex col-12 col-lg-9  pt-4 row">
        <Col
          className="col-12 col-lg-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Label
            htmlFor="strategy"
            style={{
              cursor: "pointer",
              margin: "0",
            }}
          >
            Strategy
          </Label>{" "}
        </Col>
        <Col className="col-12 col-lg-5 mt-2 mt-lg-0">
          <select
            value={form.strategy}
            onChange={onChange}
            className="form-control"
            name="strategy"
            id="strategy"
          >
            <option value="round_robin"> Round Robin</option>
            <option value="most_idle"> Most Idle</option>
          </select>
        </Col>
      </Col>
      <Col className="d-flex col-12 col-lg-9  pt-4 row">
        <Col
          className="col-12 col-lg-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Label
            htmlFor="agent_ring_timeout"
            style={{
              cursor: "pointer",
              margin: "0",
            }}
          >
            Agent Ring Timeout
          </Label>{" "}
        </Col>
        <Col className="col-8 col-md-4 col-lg-3 mt-2 mt-lg-0">
          <Input
            value={form.agent_ring_timeout}
            onChange={onChange}
            id="agent_ring_timeout"
            type="number"
            name="agent_ring_timeout"
          />
        </Col>
      </Col>
      <Col className="d-flex col-12 col-lg-9  pt-4 row">
        <Col
          className="col-12 col-lg-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Label
            htmlFor="connection_timeout"
            style={{
              cursor: "pointer",
              margin: "0",
            }}
          >
            Connection Timeout
          </Label>{" "}
        </Col>
        <Col className="col-8 col-md-4 col-lg-3 mt-2 mt-lg-0">
          <Input
            value={form.connection_timeout}
            onChange={onChange}
            id="connection_timeout"
            type="number"
            name="connection_timeout"
          />
        </Col>
      </Col>
      <Col className="d-flex col-12 col-lg-9  pt-4 row">
        <Col
          className="col-12 col-lg-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Label
            htmlFor="max_queue_size"
            style={{
              cursor: "pointer",
              margin: "0",
            }}
          >
            Max Queue Size
          </Label>{" "}
        </Col>
        <Col className="col-8 col-md-4 col-lg-3 mt-2 mt-lg-0">
          <Input
            value={form.max_queue_size}
            onChange={onChange}
            id="max_queue_size"
            type="number"
            name="max_queue_size"
          />
        </Col>
      </Col>
      <Col className="d-flex col-12 col-lg-9  pt-4 row">
        <Col
          className="col-12 col-lg-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Label
            htmlFor="ring_simultaneously"
            style={{
              cursor: "pointer",
              margin: "0",
            }}
          >
            Ring Simultaneously
          </Label>{" "}
        </Col>
        <Col className="col-8 col-md-4 col-lg-3 mt-2 mt-lg-0">
          <Input
            value={form.ring_simultaneously}
            onChange={onChange}
            id="ring_simultaneously"
            type="number"
            name="ring_simultaneously"
          />
        </Col>
      </Col>
      <Col className="d-flex col-12 col-lg-9  pt-4 row">
        <Col
          className="col-12 col-lg-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Label
            style={{
              cursor: "pointer",
              margin: "0",
            }}
          >
            Enter When Empty
          </Label>{" "}
        </Col>
        <Col className="col-8 col-md-4 col-lg-2 mt-2 mt-lg-0">
          <Switch
            uncheckedIcon={<Offsymbol />}
            className="mr-1"
            checkedIcon={<OnSymbol />}
            onColor="#626ed4"
            onChange={() => {
              setForm({...form, enter_when_empty: !form.enter_when_empty});
            }}
            checked={form.enter_when_empty}
          />
        </Col>
      </Col>
      <Col className="d-flex col-12 col-lg-9  pt-4 row">
        <Col
          className="col-12 col-lg-4"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Label
            style={{
              cursor: "pointer",
              margin: "0",
            }}
          >
            Record Caller
          </Label>{" "}
        </Col>
        <Col className="col-8 col-md-4 col-lg-2 mt-2 mt-lg-0">
          <Switch
            uncheckedIcon={<Offsymbol />}
            className="mr-1"
            checkedIcon={<OnSymbol />}
            onColor="#626ed4"
            onChange={() => {
              setForm({...form, record_caller: !form.record_caller});
            }}
            checked={form.record_caller}
          />
        </Col>
      </Col> */}
    </Row>
  );
}
