import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom"
export default function RedirectContacts() {

    useEffect(() => {


        const params = new URL(document.location).searchParams;
        const code = params.get("code");

        if (code) {
            let contactApiId = JSON.parse(localStorage.getItem("contactApiId"));
            localStorage.removeItem("contactApiId")
            return (window.location.href = `/ContactList?id=${contactApiId.id}&&code=${code}`);
        }
        else {
            return (window.location.href = `Home`);
        }

    }, [])

    return (
        <div></div>
    )
}
