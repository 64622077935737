import React, {useEffect, useState} from "react";
import GraphChart from "./GraphChart";
import GraphJson from "./GraphJson";
import GraphSidebar from "./GraphSidebar";
import GraphTopBar from "./GraphTopBar";
import {GraphContainer, GraphContent} from "./styles";
import ModalAddGraphCallflow from "./ModalAddGraphCallflow";
import toastr from "toastr";
import petition_post from "../../../../petitions/petition_post";
import petition_patch from "../../../../petitions/petition_patch";
export default function GraphCallflow({reload, modal: modalExternal}) {
  toastr.options = {
    showDuration: 300,
  };

  const [layout, setLayout] = useState(0);
  const [activeSidebar, setActiveSidebar] = useState(null);

  const [form, setForm] = useState({
    nameCallflow: modalExternal?.name || "",
    inputNumber: "",
    addNumbers: [],
    selectedNumbers: [],
  });

  const params = new URL(document.location).searchParams;
  const id = params.get("id");

  const [modal, setModal] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let tempJson = JSON.parse(json);

    setJson(
      JSON.stringify(
        {
          ...tempJson,
          name: form.nameCallflow,
          numbers: JSON.stringify(
            form.addNumbers.map((number) => number.number.number)
          ),
        },
        null,
        2
      )
    );
  }, [form]);

  const [json, setJson] = useState(
    JSON.stringify(
      {
        container: "main",
        name: modalExternal?.name || "",
        numbers: "[]",
        flow: modalExternal?.flow || {},
      },
      null,
      2
    )
  );

  const createCallflow = () => {
    let tempJson = JSON.parse(json);

    if (modalExternal) {
      petition_patch("callflowEdit", {
        id,
        data: {
          numbers: form.addNumbers.map((number) => number.number.number),
          type: "CustomByUser",
          name: tempJson.name,
          callflow_object: {
            flow: tempJson.flow,
            type: "CustomByUser",
          },
        },
        callflow_id: modalExternal.pk,
      })
        .then(async ({data: result}) => {
          toastr.success("Action completed", "Success");
          reload();
        })
        .catch((error) => {
          toastr.error(error.response.data.error.error_message, "Error");
        });
    } else {
      petition_post("callflow", {
        id,
        data: {
          numbers: form.addNumbers.map((number) => number.number.number),
          type: "CustomByUser",
          name: tempJson.name,
          callflow_object: {
            flow: tempJson.flow,
            type: "CustomByUser",
          },
        },
      })
        .then(async ({data: result}) => {
          toastr.success("Action completed", "Success");
          reload();
        })
        .catch((error) => {
          toastr.error(error.response.data.error.error_message, "Error");
        });
    }
  };

  return (
    <GraphContainer>
      <ModalAddGraphCallflow
        numbers={modalExternal?.numbers || []}
        form={form}
        setForm={setForm}
        setModal={setModal}
        modal={modal}
        loading={loading}
      />
      <GraphTopBar
        createCallflow={createCallflow}
        setModal={setModal}
        setLayout={setLayout}
      />
      <GraphContent>
        <GraphChart
          activeSidebar={activeSidebar}
          setActiveSidebar={setActiveSidebar}
          json={json}
          layout={layout}
        />
        <GraphSidebar
          setJson={setJson}
          json={json}
          activeSidebar={activeSidebar}
          setActiveSidebar={setActiveSidebar}
        />
      </GraphContent>
    </GraphContainer>
  );
}
